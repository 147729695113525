import { useState } from "react";
import { sendGaEvent } from "@root/utils/sendGaEvent";
import { csrfToken } from "@root/utils/axiosForRails";
import { FetchJson } from "@root/utils/fetchJson";

type ResponseDataType = {
  status: boolean;
  days: number;
  message: string;
};

export const useExtendScoutDeadlineButton = (): {
  handleClickExtendScoutDeadlineButton: (url: string, gaAction: string, gaLabel: string) => void;
} => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClickExtendScoutDeadlineButton = async (
    url: string,
    gaAction: string,
    gaLabel: string,
  ) => {
    if (!isClicked) {
      setIsClicked(true);
      sendGaEvent({ category: "Click", action: gaAction, label: gaLabel });
      document.dispatchEvent(new CustomEvent("track:trackTapScoutConsiderButton", {}));
      const res = await FetchJson<ResponseDataType>(
        url,
        { authenticity_token: csrfToken() },
        "POST",
      );
      if (!res) return;
      if (res.status === false && res.message === "UnexpectedValueException") {
        alert("すでに期限延長済みのスカウトです。");
      }
      window.location.reload();
    }
  };

  return { handleClickExtendScoutDeadlineButton };
};
