// @deprecated
// 今後の方針: このutils/colors.tsを廃止し、このsprinkleを利用する運用にする予定です。
// growiを確認してください: https://docs.rejob.co.jp/657bd2c8d45322707e0641a1

export const Colors: Record<string, string> = {
  /* ===========================================================
    figma -> rejob_common_libraries -> Colorsの変数名と揃えて設定
  =========================================================== */
  REJOB_BRAND_DEEP: "#F96075",
  REJOB_BRAND_LIGHT: "#FFF5F7",

  CARE_BRAND_DEEP: "#00B048",
  CARE_BRAND_LIGHT: "#F2FBF6",

  // 企業管理画面で使われているブランドカラーたち。古いものなので将来的には削除すること。
  REJOB_BRAND_CLIENT: "#FD7085",
  REJOB_BRAND_CLIENT_DEEP: "#ED1E79", // Figma未定義
  CARE_BRAND_CLIENT: "#00B048", // Figma未定義
  CARE_BRAND_CLIENT_DEEP: "#008000", // Figma未定義

  PRIMARY: "#FB6341",

  LINK: "#0F71D2",

  ALERT: "#EF4242",
  ALERT_F00: "#F00", // Figma未定義
  DANGER: "#CC2D2D",

  LINE: "#06C755",

  TEXT_DEEP: "#222",
  TEXT_MEDIUM: "#575757",
  TEXT_LIGHT: "#949494",

  GRAY_SCALE_D9: "#D9D9D9",
  GRAY_SCALE_EE: "#EEE",
  GRAY_SCALE_F2: "#F2F2F2",
  GRAY_SCALE_F9: "#F9F9F9",
  GRAY_SCALE_FC: "#FCFCFC",
  GRAY_SCALE_88: "#888",
  GRAY_SCALE_CC: "#CCC",
  GRAY_SCALE_BB: "#BBB",

  WHITE: "#FFF",

  HIGHLIGHT_BLUE: "#508FFF",
  HIGHLIGHT_LIGHT_GOLD: "#FEFDF4",
  HIGHLIGHT_GOLD: "#C28800",

  // 背景色
  MODAL_BACKGROUND: "rgba(0, 0, 0, 0.6)",

  BLUE_LIGHT: "#E5F0FB",

  // 企業管理画面で使われているラベルカラーたち。V3.5では削除予定。Figma未定義。
  ORANGE_CLIENT: "#E5912F",
  ORANGE_CLIENT_LIGHT: "#DE912F",
  YELLOW_CLIENT: "#F5B90A",
  YELLOW_CLIENT_LIGHT: "#FFF8D6",

  // 企業管理画面 スカウト画面で使用されているラベルカラーたち
  SCOUT_LABEL_LIGHT_BLUE: "#F0FBFF",
  SCOUT_LABEL_BLUE: "#03A3D6",
  SCOUT_LABEL_LIGHT_GREEN: "#ECFAF2",
  SCOUT_LABEL_GREEN: "#1AA574",
  SCOUT_LABEL_LIGHT_ORANGE: "#FFF6EA",
};
