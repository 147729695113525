import { FC } from "react";
import { Heading } from "@userFrontend/components/Heading";
import { ClientReviewCassette } from "@userFrontend/features/clientReview/components/ClientReviewCassette";
import { TextButton } from "@root/shared/components/TextButton";
import { FormedCassetteInformation } from "./types";
import * as styles from "./styles.css";

type Props = {
  cassetteInformationArray: (FormedCassetteInformation & { isHidden: boolean })[];
  reviewSubmissionUrlOrRegistrationUrl?: string;
  isSp: boolean;
  title: string;
  handleLoadMore?: () => void;
};

export const Presentation: FC<Props> = ({
  cassetteInformationArray,
  reviewSubmissionUrlOrRegistrationUrl,
  isSp,
  title,
  handleLoadMore,
}) => {
  return (
    <>
      <Heading tag="h2" size="small">
        {title}
      </Heading>
      {cassetteInformationArray.map((cassetteInformation) => (
        <div
          className={`${styles.cassetteContainer} ${cassetteInformation.isHidden ? styles.hiddenCassette : ""}`}
          key={cassetteInformation.textId}
        >
          <ClientReviewCassette
            cassetteInformation={cassetteInformation}
            reviewSubmissionUrlOrRegistrationUrl={reviewSubmissionUrlOrRegistrationUrl}
            isSp={isSp}
          />
        </div>
      ))}
      {handleLoadMore && (
        <div className={styles.buttonOuter}>
          <TextButton size="large" onClick={handleLoadMore} leftIconName="add-more" isBold>
            もっと見る
          </TextButton>
        </div>
      )}
    </>
  );
};
