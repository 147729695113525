import { FC } from "react";
import { PopOver } from "@root/shared/components/PopOver";
import * as styles from "./styles.css";

export const Presentation: FC = () => {
  return (
    <PopOver direction="bottomRight" top="-40px" right="0" left="-146px" zIndex="5" autoHide>
      <p className={styles.text}>口コミが見れるようになりました！</p>
    </PopOver>
  );
};
