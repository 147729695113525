import { FC, ReactNode } from "react";
import { RecipeVariants } from "@vanilla-extract/recipes";
import * as styles from "./styles.css";

type Props = {
  children: ReactNode;
} & RecipeVariants<typeof styles.outer>;

export const GuidanceBox: FC<Props> = ({ backgroundColor = "gray", children }) => (
  <div className={styles.outer({ backgroundColor })}>{children}</div>
);
