import { FC } from "react";
import { Icon } from "@root/shared/components/Icon";
import * as styles from "./styles.css";

type Props = {
  telNum: string;
  workCode?: string;
};

export const TelInformationFloating: FC<Props> = ({ telNum, workCode }) => {
  return (
    <div className={`${styles.container} js-fixed-tel-num`}>
      <div className={styles.telTextContainer}>
        <Icon name="tel" fontSize="15px" />
        <p className={styles.telText}>電話で応募する</p>
      </div>
      <p className={styles.telNum}>{telNum}</p>
      {workCode && <p className={styles.workNumText}>求人番号：{workCode}</p>}
    </div>
  );
};
