import { FC } from "react";
import {
  useStickyAnchorLinks,
  AnchorLinkProps,
} from "@root/shared/components/StickyAnchorLinks/useStickyAnchorLinks";
import { Presentation } from "./Presentation";

type Props = {
  anchorLinks: AnchorLinkProps[];
  className?: string;
  size?: "small" | "large";
};

export const AnchorLinks: FC<Props> = ({
  anchorLinks: anchorLinkProps,
  className,
  size = "small",
}) => {
  const { anchorLinks, anchorLinksRef } = useStickyAnchorLinks(anchorLinkProps, true);

  return (
    <Presentation
      anchorLinks={anchorLinks}
      anchorLinksRef={anchorLinksRef}
      anchorLinkLength={anchorLinks.length}
      className={className}
      size={size}
    />
  );
};
