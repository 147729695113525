/* eslint-disable react/jsx-props-no-spreading */
import { FC, lazy, Suspense, useState } from "react";
import { useFormContext } from "react-hook-form";
import { UnstyledButton, ValidationErrorMessage } from "@legacy_user_frontend/components/ui_parts";
import { PhotoProps } from "@legacy_user_frontend/components/pages/mypage/profiles/product_photo_edit/src/product_photo_edit_props";
import {
  StyledCassetteOuter,
  StyledCassetteHeading,
  StyledCassetteContent,
  StyledImageOuter,
  StyledTextAreaOuter,
  StyledCassette,
  StyledTextCountOuter,
} from "@legacy_user_frontend/components/pages/mypage/profiles/product_photo_edit/src/styled_elements";
import { textAndLineBreakLength } from "@legacy_user_frontend/components/pages/mypage/profiles/product_photo_edit/src/modules";

const ConfirmModal = lazy(() => import("./confirm_modal"));
const PreviewModal = lazy(() => import("./preview_modal"));

export const Photo: FC<PhotoProps> = ({ photoIndex, imageUrl, maxLength, remove }) => {
  const { register, formState, clearErrors, watch } = useFormContext();
  const errors = formState.errors.product_photos_update?.product_photos_attributes;
  const currentErrors = errors && errors[photoIndex] ? errors[photoIndex] : null;
  const descriptionName = `product_photos_update[product_photos_attributes[${photoIndex}]][description]`;
  const watchedDescription: string | null = watch(descriptionName);

  // プレビュー用画像パスの指定と画像プレビューモーダルの開閉
  const [previewPhoto, setPreviewPhoto] = useState<string | null>(null);
  const openPhotoModal = () => {
    setPreviewPhoto(imageUrl);
  };
  const closePhotoModal = () => setPreviewPhoto(null);

  // 削除確認モーダルの開閉
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const openDeleteModal = () => {
    setIsConfirmModalOpen(true);
  };
  const closeConfirmModal = () => setIsConfirmModalOpen(false);

  // 削除の実行
  const handleOnClick = () => {
    remove(photoIndex, imageUrl);
  };
  return (
    <StyledCassetteOuter>
      <StyledCassette>
        <StyledCassetteHeading>
          <p>画像{photoIndex + 1}</p>
          <UnstyledButton type="button" onClick={openDeleteModal} />
        </StyledCassetteHeading>
        <StyledCassetteContent>
          <StyledImageOuter>
            <button type="button" onClick={openPhotoModal}>
              <img src={imageUrl} alt="" />
            </button>
          </StyledImageOuter>
          <input
            type="hidden"
            {...register(`product_photos_update[product_photos_attributes[${photoIndex}]][id]`)}
          />
          <input
            type="hidden"
            {...register(`product_photos_update[product_photos_attributes[${photoIndex}]][image]`)}
          />
          <StyledTextAreaOuter isError={!!currentErrors?.description}>
            <textarea
              placeholder="説明を追加できます"
              {...register(descriptionName, {
                onChange: () => clearErrors(descriptionName),
              })}
            />
            <StyledTextCountOuter>
              <div>
                {currentErrors && (
                  <>
                    {currentErrors.image && (
                      <ValidationErrorMessage message={currentErrors.image.message} />
                    )}
                    {currentErrors.description && (
                      <ValidationErrorMessage message={currentErrors.description.message} />
                    )}
                  </>
                )}
              </div>
              <div>
                {watchedDescription ? textAndLineBreakLength(watchedDescription) : 0}/{maxLength}
                文字入力
              </div>
            </StyledTextCountOuter>
          </StyledTextAreaOuter>
        </StyledCassetteContent>
      </StyledCassette>
      <Suspense fallback={null}>
        {previewPhoto && (
          <PreviewModal
            isOpen={!!previewPhoto}
            onRequestClose={closePhotoModal}
            photoPath={previewPhoto}
            heading="画像"
          />
        )}
      </Suspense>
      <Suspense fallback={null}>
        {isConfirmModalOpen && (
          <ConfirmModal
            isModalOpen={isConfirmModalOpen}
            closeModal={closeConfirmModal}
            photoIndex={photoIndex}
            handleOnClick={handleOnClick}
          />
        )}
      </Suspense>
    </StyledCassetteOuter>
  );
};
