import { FC } from "react";
import { isDefaultChecked } from "@legacy_user_frontend/components/pages/jobs/job_search_navigation/src/search_modal/modal_contents/modules";
import {
  StyledCheckBox,
  StyledChildList,
  StyledChildLabel,
  StyledRadioButton,
} from "@legacy_user_frontend/components/pages/jobs/job_search_navigation/src/search_modal/modal_contents/src/styled_elements";
import { ModalLink } from "./modal_link";

type Props = {
  contents: {
    name: string;
    url: string;
    value: string;
    showLink: boolean;
  }[];
  formState: Record<string, { url: string }>;
  checkBoxName: string;
  changeEvent: (e: React.ChangeEvent<HTMLInputElement>, url: string) => void;
  type: string;
};

export const Lists: FC<Props> = ({ contents, formState, checkBoxName, changeEvent, type }) => (
  <>
    {contents.map(({ value, url, name, showLink }) => (
      <StyledChildList key={value}>
        <StyledChildLabel>
          {type === "checkbox" ? (
            <StyledCheckBox
              type="checkbox"
              value={value}
              name={checkBoxName}
              onChange={(e) => changeEvent(e, url)}
              defaultChecked={isDefaultChecked(formState, value)}
            />
          ) : (
            <StyledRadioButton
              type="radio"
              value={value}
              name={checkBoxName}
              onChange={(e) => changeEvent(e, url)}
              defaultChecked={isDefaultChecked(formState, value)}
            />
          )}
          {name}
          {showLink && <ModalLink url={url} />}
        </StyledChildLabel>
      </StyledChildList>
    ))}
  </>
);
