import { FC } from "react";
import { SectionTitle } from "@userFrontend/features/reward/components/ContinuedRewardInputs/uiParts/SectionTitle";
import {
  commonSectionOuter,
  commonContainer,
} from "@userFrontend/features/reward/components/ContinuedRewardInputs/styles.css";
import * as styles from "./styles.css";
// uiParts
import { StepSelectionBox } from "./uiParts/StepSelectionBox";
// types
import { Props, Step3Questions, ErrorTexts } from "./type";

const getQuestionText = (categoryName: keyof Step3Questions): string => {
  const questionTexts: { [key in keyof Step3Questions]: string } = {
    atmosphere: "あなたの職場の雰囲気は？",
    salaryStandard: "あなたの職場の給与体系は？",
    debut: "独り立ち・デビューまでは？",
    education: "教育方針は？",
    workTime: "勤務時間は？",
    salaryChange: "前職から比べた給与の変化",
  };
  return questionTexts[categoryName];
};

export const Step3: FC<Props> = ({
  errorTexts,
  radioValues,
  handleRadioChange,
  questions,
  atmosphereRef,
  salaryStandardRef,
  debutRef,
  educationRef,
  workTimeRef,
  salaryChangeRef,
}) => {
  const getRefByCategoryName = (
    categoryName: keyof Step3Questions,
  ): React.RefObject<HTMLDivElement> | null => {
    switch (categoryName) {
      case "atmosphere":
        return atmosphereRef;
      case "salaryStandard":
        return salaryStandardRef;
      case "debut":
        return debutRef;
      case "education":
        return educationRef;
      case "workTime":
        return workTimeRef;
      case "salaryChange":
        return salaryChangeRef;
      default:
        return null;
    }
  };
  return (
    <div className={commonSectionOuter}>
      <SectionTitle text="職場の口コミ（特徴）" />
      <div className={commonContainer}>
        {Object.entries(questions).map(([categoryName, category]) => (
          <div
            key={categoryName}
            className={styles.stepSelectionBox}
            ref={getRefByCategoryName(categoryName as keyof Step3Questions)}
          >
            <StepSelectionBox
              categoryName={categoryName}
              radioValues={radioValues}
              handleRadioChange={handleRadioChange}
              questionText={getQuestionText(categoryName as keyof Step3Questions)}
              errorText={errorTexts?.[categoryName as keyof ErrorTexts]}
              leftText={Array.isArray(category) ? category[0].optionText : ""}
              rightText={Array.isArray(category) ? category[4].optionText : ""}
              optionIds={
                Array.isArray(category)
                  ? category.map((item) => ({ value: String(item.optionId) }))
                  : []
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};
