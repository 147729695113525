import styled from "styled-components";
import { Breakpoints, Colors } from "@legacy_user_frontend/utils";

type AccordionOuterProps = {
  height: number;
  isOpen: boolean;
};

export const StyledElements = styled.div<AccordionOuterProps>`
  > .accordion-container {
    display: flex;
    flex-direction: column;

    .accordion-outer {
      max-height: ${({ isOpen, height }) => (isOpen ? "inherit" : `${height}px`)};
      overflow: hidden;
      &.is-active {
        max-height: inherit;
      }
    }

    .main-table-list {
      span {
        display: block;

        a {
          font-weight: bold;
        }
      }

      :not(:first-child) {
        margin-top: 4px;

        ${Breakpoints.MEDIUM} {
          margin-top: 6px;
        }
      }
    }

    .shop-remining {
      display: block;

      ${Breakpoints.MEDIUM} {
        margin-top: 6px;
      }
    }

    .acoordion-button {
      margin-top: 12px;
      color: ${Colors.LIGHT_BLACK};
      font-size: 11px;
      font-weight: bold;
      text-align: right;
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none;
      padding: 0;
      appearance: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

      ${Breakpoints.MEDIUM} {
        color: ${Colors.LINK};
        text-align: left;
        margin-top: 18px;
      }
    }

    .accordion-arrow {
      display: inline-block;
      cursor: pointer;
      transition: transform 0.3s ease;

      &.is-active {
        transform: rotate(180deg);
      }
    }
  }
`;
