import { FC } from "react";
import { RadioButton } from "@root/shared/components/RadioButton";
import { ErrorInformationText } from "@userFrontend/components/ErrorInformationText";
// types
import { SiteId } from "@root/shared/types/type";
import {
  ErrorText,
  JobEntries,
} from "@userFrontend/features/reward/components/ContinuedRewardInputs/type";
import * as styles from "./styles.css";

export type JobEntry = {
  siteId: SiteId;
  clientName: string;
  brandName: string;
  jobEntryId: string;
  workContentTitle: string;
  businessTypeId: number;
};

type Props = {
  jobEntries: JobEntries;
  jobEntryValue?: string;
  handleJobEntryChange: (
    targetJobEntryId: string,
    targetSiteId: SiteId,
    targetBusinessTypeId: number,
  ) => void;
  jobEntriesErrorText?: ErrorText;
};

export const JobEntriesRadio: FC<Props> = ({
  jobEntries,
  jobEntryValue,
  handleJobEntryChange,
  jobEntriesErrorText,
}) => {
  return (
    <>
      {jobEntries.map((entry) => (
        <div className={styles.radioContainer} key={entry.jobEntryId}>
          <div className={styles.radioOuter}>
            <RadioButton
              name="job_entry_id"
              id={`radio-jobEntry-${entry.jobEntryId}`}
              value={entry.jobEntryId}
              checked={jobEntryValue === entry.jobEntryId}
              onChange={() =>
                handleJobEntryChange(entry.jobEntryId, entry.siteId, entry.businessTypeId)
              }
              hasError={!!jobEntriesErrorText}
            />
          </div>
          <label
            htmlFor={`radio-jobEntry-${entry.jobEntryId}`}
            className={styles.radioButtonTextOuter}
          >
            <p className={styles.radioButtonText}>企業名: {entry.clientName}</p>
            <p className={styles.radioButtonText}>ブランド名: {entry.brandName}</p>
            <p className={styles.title}>{entry.workContentTitle}</p>
          </label>
        </div>
      ))}
      {jobEntriesErrorText && <ErrorInformationText text={jobEntriesErrorText} />}
    </>
  );
};
