/* eslint-disable jsx-a11y/no-onchange */
import { FC } from "react";

type Props = {
  optionLinks: {
    text: string;
    link: string;
  }[];
  selected: string;
  className?: string;
  handleOnChange: React.ChangeEventHandler<HTMLSelectElement>;
  id?: string;
};

export const SelectBox: FC<Props> = ({ optionLinks, selected, handleOnChange, className, id }) => (
  <select className={className} onChange={handleOnChange} defaultValue={selected} id={id || ""}>
    {optionLinks.map((optionLink) => (
      <option value={optionLink.link} key={optionLink.link} label={optionLink.text}>
        {optionLink.text}
      </option>
    ))}
  </select>
);
