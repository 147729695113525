import styled from "styled-components";
import { Breakpoints } from "@legacy_user_frontend/utils";
import { Colors } from "@root/utils/colors";

export const StyledDiv = styled.div`
  > .columns {
    margin-top: 16px;
  }

  ${Breakpoints.MEDIUM} {
    display: flex;
    margin-left: -8px;
    margin-right: -8px;
    > .columns {
      flex-basis: 50%;
      padding: 0 8px;
    }
  }
`;

export const StyledHeading = styled.h3`
  padding-top: 8px;
  padding-bottom: 16px;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.4;
  color: ${Colors.TEXT_DEEP};
`;
