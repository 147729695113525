import "app/packs/shared/components/Accordion/styles.css.ts.vanilla.css!=!../../../../../../../shared/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"app/packs/shared/components/Accordion/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VTXU/CMBR951c0ISb6UDImoI5n9jdMt96NYmmb7k5A43+3LWx0SIjG9O32nHO/zp28TnWqNniYks8RIQUr32qrW8VpqaW2GRnneb4cfY0mHTANQMM4F6qmqE1GpguzX/6O/XhMoy0HSy3jom0yMjvRQ9TJmT1ptBScjFer1YA+C/ROPE1Tz6u0QtqIDzhXIoUCugZRr9EFJ49P8x64O0ULLflAexE31klF/0/hn4vGSHbIiFAhSSF1+ebFt8zWQlEJlU+ZXJBfAtlqZOjLfE441MuolfxlkfgiI0pxlRIBygDYCY5rnzG5W8b1VRLCKDZtg6I6uI0oBOVqawwrXd2AOwDlEUyKWlGBsHW7KB0GrA8j7JGGv4z4rq7MdX5962iZclmsk4r3qrSC0HNrGw8zWnS5dIte+oxhxgBzMmUfixrnAwsWGlFvIxcezdWF07Ob8tS/gRT8U+rnBi9Y9MaGq5C8G2d6OoPegckAXP8FvA7gkyXDjT4f8b093pm9p7TDJw8Durh2Cn1jx5u/ONLb0t83WRTcZwQAAA==\"}!../../../../../../../shared/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var button = {'default':'_1o2njtyd _1o2njtyc',defaultIsOpened:'_1o2njtye _1o2njtyc',panel:'_1o2njtyf _1o2njtyc',panelIsOpened:'_1o2njtyg _1o2njtyc'};
export var buttonBase = '_1o2njtyc';
export var contentDisplay = 'var(--_1o2njty0)';
export var contentsWrapper = {'default':'_1o2njtyh',panel:'_1o2njtyi'};
export var iconWrapper = {'default':'_1o2njty7',defaultIsOpened:'_1o2njty9 _1o2njty7',panel:'_1o2njty7',panelIsOpened:'_1o2njtyb _1o2njty7'};
export var iconWrapperBase = '_1o2njty7';
export var title = {'default':'_1o2njty4',panel:'_1o2njty6 _1o2njty4'};
export var titleBase = '_1o2njty4';
export var wrapper = {'default':'_1o2njty2 _1o2njty1',panel:'_1o2njty3 _1o2njty1'};
export var wrapperBase = '_1o2njty1';