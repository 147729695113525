import { FC, lazy, Suspense } from "react";
import { Colors } from "@legacy_user_frontend/utils";
import styled from "styled-components";

const LineComponent = lazy(() => import("react-chartjs-2").then(({ Line }) => ({ default: Line })));

type Props = {
  monthlyWorkCountsData: MonthlyWorkDataProps;
};

type MonthlyWorkDataProps = {
  monthes: Date[];
  workCounts: number[];
};

const AverageBorder = styled.span`
  height: 10px;
  border-radius: 0;
  border-top: 4px dotted rgba(249, 170, 119, 1);
  background: none;
  vertical-align: middle;
  margin-right: 5px;
  display: inline-block;
  width: 32px;
`;

const AverageOuter = styled.div`
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: flex-end;
  font-weight: bold;
  line-height: 1.14;
  margin-bottom: 16px;
`;

const AverageNum = styled.span`
  padding-left: 8px;
  padding-right: 2px;
  font-size: 3.5rem;
  line-height: 1;
  color: ${Colors.ORANGE};
`;

export const JobAverageGraph: FC<Props> = ({ monthlyWorkCountsData: { monthes, workCounts } }) => {
  // 平均値
  const sum = workCounts.reduce((prev, current) => prev + current);
  const average = Math.floor(sum / workCounts.length);
  const averageArray = new Array(monthes.length).fill(average);

  // グラフの最大値と最小値
  const maxVal = Math.max(...workCounts);
  const minVal = Math.min(...workCounts);

  const maxLineVal = maxVal + (maxVal - minVal) * (1 / 3);
  const minLineVal = minVal - (maxVal - minVal) * (1 / 3);

  // グラフの縦横比をPC/SPで変更
  const isSP = window.matchMedia("screen and (max-width: 767px)").matches;

  const data = {
    labels: monthes,
    datasets: [
      {
        label: "求人数の推移",
        data: workCounts,
        fill: false,
        tension: 0.1,
        borderColor: "#b5c2eb",
        pointRadius: isSP ? 4 : 7,
        pointBackgroundColor: "#fff",
        pointBorderWidth: 2,
        borderWidth: 2,
      },
      {
        label: "求人数の平均",
        data: averageArray,
        fill: false,
        tension: 0.1,
        borderDash: [5, 5],
        borderColor: "#f9aa77",
        pointRadius: 0,
        borderWidth: 4,
      },
    ],
  };
  const options = {
    legend: {
      display: false,
    },
    scales: {
      backdropColor: "rgba(209, 224, 252, 1)",
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
            tickMarkLength: 8,
            offsetGridLines: false,
            color: "#666",
          },
          ticks: {
            callback: (value: string, index: number) => {
              if (index === 0 || index === monthes.length - 1) {
                return value;
              }
              return null;
            },
            fontSize: 12,
            fontColor: "#666",
            maxRotation: 0,
            minRotation: 0,
            padding: 4,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: "transparent",
            drawBorder: false,
            tickMarkLength: 0,
            zeroLineColor: "#666",
            z: 1,
          },
          ticks: {
            callback: (value: string) => `${Number(value).toFixed()}件`,
            fontColor: "#949494",
            min: minLineVal,
            max: maxLineVal,
            maxTicksLimit: 1,
            padding: 4,
          },
        },
      ],
    },
    hover: {
      mode: null,
    },
    tooltips: {
      enabled: false,
    },
  };
  return (
    <div>
      <AverageBorder />
      <AverageOuter>
        <span>
          平均
          <br />
          求人数
        </span>
        <AverageNum>{average}</AverageNum>
        <span>件</span>
      </AverageOuter>
      <Suspense fallback={<div>Loading...</div>}>
        <LineComponent
          data={data}
          options={options}
          width={isSP ? 343 : 932}
          height={isSP ? 120 : 140}
        />
      </Suspense>
    </div>
  );
};
