import { ComponentProps, FC, useCallback, useEffect, useState } from "react";
import type { ClientImage } from "@userFrontend/features/publication/components/AlbumContents/type";
import { AlbumTabContent } from "@userFrontend/features/publication/components/AlbumContents/uiParts/AlbumTabContent";
import { Tab } from "@root/shared/components/Tab";
import { Presentation } from "./Presentation";
import { useHashChange } from "./hooks/useHashChange";

type AlbumCategoryItem = {
  categoryName: string;
  images: ClientImage[];
};

type Props = {
  isShowWorkDetailBackButton: boolean;
  isBiyo: boolean;
  albumCategoryItems: AlbumCategoryItem[];
};

export const AlbumContents: FC<Props> = ({
  isShowWorkDetailBackButton = true,
  isBiyo,
  albumCategoryItems,
}) => {
  const [expandedImage, setExpandedImage] = useState<ClientImage | undefined>();
  const [albumCategoryImages, setAlbumCategoryImages] = useState<ClientImage[]>([]);
  const [tabContents, setTabContents] = useState<ComponentProps<typeof Tab>["tabContents"]>([]);
  const { isShowAlbum, setUrlHashName, clearUrlHashName } = useHashChange(
    expandedImage,
    setExpandedImage,
  );

  const updateTabContents = useCallback(
    (selectedTabCategoryName: string) => {
      const newTabContents = albumCategoryItems.map((albumCategoryItem) => {
        const isCurrent = albumCategoryItem.categoryName === selectedTabCategoryName;
        const isDisabled = !albumCategoryItem.images.length;
        return {
          buttonText: albumCategoryItem.categoryName,
          content: (
            <AlbumTabContent
              albumCategoryImages={albumCategoryItem.images}
              setExpandedImage={setExpandedImage}
            />
          ),
          isCurrent,
          isDisabled,
        };
      });

      setTabContents(newTabContents);
    },
    [albumCategoryItems],
  );

  const handleClickTab = useCallback(
    (index: number) => {
      const albumCategoryItem = albumCategoryItems[index];
      updateTabContents(albumCategoryItem.categoryName);
      setAlbumCategoryImages(albumCategoryItem.images);
    },
    [albumCategoryItems, updateTabContents, setAlbumCategoryImages],
  );

  const handleClickArrow = useCallback(
    (direction: "next" | "prev") => {
      // expandedImageは絶対にある前提だが、lintでエラーが起きるので早期returnする
      if (!expandedImage) return;

      const expandedImageIndex = albumCategoryImages.findIndex(
        (image) => image.id === expandedImage.id,
      );
      if (direction === "next") {
        setExpandedImage(albumCategoryImages[expandedImageIndex + 1]);
      } else {
        setExpandedImage(albumCategoryImages[expandedImageIndex - 1]);
      }
    },
    [albumCategoryImages, expandedImage, setExpandedImage],
  );

  useEffect(() => {
    // 初回は「すべて」タブを表示させ、すべての求人詳細画像を表示させる
    const albumCategoryItem = albumCategoryItems.find((image) => image.categoryName === "すべて");
    // すべてのalbumCategoryItemは絶対にある前提だが、lintでエラーが起きるので早期returnする
    if (!albumCategoryItem) return;

    updateTabContents(albumCategoryItem.categoryName);
    setAlbumCategoryImages(albumCategoryItem.images);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 画像ギャラリーを開いた時にぺージ自体がスクロールされるのを防ぐ
  useEffect(() => {
    document.body.style.overflow = isShowAlbum ? "hidden" : "";
  }, [isShowAlbum]);

  return (
    <Presentation
      isBiyo={isBiyo}
      albumCategoryImages={albumCategoryImages}
      tabContents={tabContents}
      expandedImage={expandedImage}
      isShowAlbum={isShowAlbum}
      isShowWorkDetailBackButton={isShowWorkDetailBackButton}
      setUrlHashName={setUrlHashName}
      clearUrlHashName={clearUrlHashName}
      setExpandedImage={setExpandedImage}
      handleClickTab={handleClickTab}
      handleClickArrow={handleClickArrow}
    />
  );
};
