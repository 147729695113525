import styled from "styled-components";
import { TextButton } from "@root/shared/components/TextButton";

export const StyledList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  > li {
    display: flex;
    align-items: center;
    flex-basis: calc(50% - 8px);

    input {
      flex-shrink: 0;
    }
  }
`;

export const StyledFacilityTypesOuter = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  > div {
    flex-basis: calc(50% - 4px);
  }

  & + & {
    margin-top: 8px;
  }
`;

export const StyledAddFacilityTypesButtonOuter = styled.div`
  margin-top: 8px;
  padding: 8px 0;
`;

export const StyledFacilityTypesAnnotation = styled.p`
  margin-top: 8px;
`;

export const StyledAddFacilityTypesButton = styled(TextButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  font-size: 14px;
  font-weight: bold;

  &::before {
    content: "";
    display: block;
    width: 9px;
    height: 8px;
    margin-right: 8px;
    background-image: url("/images/user_frontend/global/icon/icn_plus_blue.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

export const StyledLabel = styled.label`
  margin-left: 8px;
  cursor: pointer;
`;

export const StyledSmallInputOuter = styled.div`
  display: inline-block;
  width: 166px;
  vertical-align: middle;
  margin-right: 8px;
`;

export const StyledCassetteHeadingOuter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 38px;
  margin-bottom: 24px;

  p {
    font-weight: bold;
    font-size: 14px;
  }
`;

export const StyledEmploymentPeriod = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledAdditionButtonOuter = styled.div`
  padding: 16px;
`;

export const StyledModalContent = styled.div`
  text-align: center;
`;

export const StyledModalTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
`;

export const StyledModalPrimaryButtonOuter = styled.div`
  padding-top: 32px;
`;

export const StyledModalTextLinkOuter = styled.div`
  padding-top: 24px;
  text-align: center;
`;

export const StyledErrorMessageOuter = styled.div`
  margin-top: 10px;
`;

export const StyledOccupationOuter = styled.div`
  margin-top: 24px;
`;

export const StyledRemoveModalOuter = styled.div`
  text-align: center;
`;

export const StyledRemoveModalHeading = styled.p`
  font-weight: bold;
  font-size: 20px;
  line-height: 1.5;
`;

export const StyledRemoveButtonOuter = styled.div`
  margin-top: 32px;
`;

export const StyledModalCloseButtonOuter = styled.div`
  margin-top: 24px;
`;
