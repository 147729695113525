import { FC } from "react";
import { StyledFrameButton } from "./styled_elements";
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
declare const $: any;

export type telButtonProps = {
  telNumber: string;
  position: string;
  clientShopId: number | string;
  gaLabel: string;
  pageType: string;
  capitalizePageType: string;
  workCode: string;
  isPreview: boolean;
};

export const TelButton: FC<telButtonProps> = ({
  telNumber,
  position,
  clientShopId,
  gaLabel,
  pageType,
  capitalizePageType,
  workCode,
  isPreview,
}) => {
  // 電話応募ボタンクリック時にクリックされた求人情報を送信
  const handleOnClick = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    $.fn.sendTelButtonAction({
      code: workCode,
      client_shop_id: clientShopId,
    });
  };

  return (
    <StyledFrameButton
      /* eslint no-script-url: 0 */
      href={isPreview ? "javascript:void(0)" : `tel:${telNumber}`}
      className="ga-click repro-click disable-on-preview"
      data-ga-action={`${pageType}_entry_tel-${position}`}
      data-ga-label={gaLabel}
      data-repro-action={`trackTapJob${capitalizePageType}EntryTel`}
      data-client-shop-id={clientShopId}
      onClick={handleOnClick}
    >
      <i className="icon-phone" />
      電話で応募する
    </StyledFrameButton>
  );
};
