import { FC } from "react";
import styled from "styled-components";
import { Breakpoints, Colors } from "@legacy_user_frontend/utils";

export type TelContentsProps = {
  telNumber: string;
  workCode: string;
};

const TelList = styled.ul`
  display: none;

  ${Breakpoints.LARGE} {
    display: block;
    flex-basis: 30%;
    position: relative;
    padding-left: 34px;
    font-size: 12px;

    [class*="icon-"] {
      font-size: 32px;
      position: absolute;
      left: 0px;
      color: ${Colors.OLD_MEDIUM_GREY};

      &:before {
        margin: 0;
      }
    }
  }
`;

const TelNum = styled.span`
  ${Breakpoints.MEDIUM} {
    color: ${Colors.PRIMARY};
    font-size: 20px;
    font-weight: bold;
  }
`;

const TelWorkNum = styled.li`
  ${Breakpoints.MEDIUM} {
    display: block;
  }
`;

export const TelContents: FC<TelContentsProps> = ({ telNumber, workCode }) => (
  <TelList>
    <i className="icon-phone" />
    <li>
      TEL応募：
      <TelNum>{telNumber}</TelNum>
    </li>
    <TelWorkNum>
      求人番号：<b>{workCode}</b>をお控えください
    </TelWorkNum>
  </TelList>
);
