import { FC } from "react";
import { LazyLoadImage } from "@root/shared/components/LazyLoadImage";

type Props = {
  href: string;
  src: string;
  alt: string;
  dummyImgPath: string;
  height: number;
  width: number;
  className?: string;
  onClick?: () => void;
};

export const Banner: FC<Props> = ({
  href,
  src,
  alt,
  dummyImgPath,
  height,
  width,
  className = "",
  onClick,
}) => {
  return (
    <a className={className} href={href} rel="nofollow" onClick={onClick}>
      <LazyLoadImage
        src={src}
        dummyImgPath={dummyImgPath}
        alt={alt}
        width={width}
        height={height}
      />
    </a>
  );
};
