import { FC } from "react";
import styled from "styled-components";
import { Colors } from "@legacy_user_frontend/utils";

type Props = {
  message: string;
};

const StyledMessage = styled.p`
  color: ${Colors.ALERT_RED};
  font-weight: bold;
`;

export const ValidationErrorMessage: FC<Props> = ({ message }) => (
  <StyledMessage>{message}</StyledMessage>
);
