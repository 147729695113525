import {
  FC,
  useState,
  useReducer,
  useCallback,
  useEffect,
  useContext,
  lazy,
  Suspense,
} from "react";
import { ModalContext } from "@legacy_user_frontend/components/pages/jobs/job_search_navigation/src/modules";
import {
  getSearchModalSubmitUrl,
  initialState,
  formStateReducer,
  getSerializeQueryArrayData,
} from "./modules";
import { BusinessTypeModal } from "./modal_contents/business_type_modal";
import { PrefectureModal } from "./modal_contents/prefecture_modal";
import { CityModal } from "./modal_contents/city_modal";
import { StationModal } from "./modal_contents/station_modal";
import { SearchModalProps } from "./src/search_modal_props";
import {
  StyledModal,
  StyledOverlay,
  StyledContentWrapper,
  StyledModalHeader,
  StyledCloseButton,
  StyledModalMain,
  StyledTitle,
  StyledModalBottom,
  StyledNumArea,
  StyledNewLine,
  StyledNum,
  StyledBtnArea,
  StyledSearchButton,
} from "./src/styled_elements";

const EasedNumber = lazy(() => import("@userFrontend/features/publication/components/EasedNumber"));

declare const $: any; // eslint-disable-line @typescript-eslint/no-explicit-any

export const SearchModal: FC<SearchModalProps> = ({
  title,
  numberOfSearchResultsUrl,
  modalContents,
  setIsAccordionOpen,
  isAccordionOpen,
  setTotalCount,
  totalCount,
}) => {
  const [formState, dispatchFormState] = useReducer(formStateReducer, initialState);

  const [resultUrl, setResultUrl] = useState("");

  const { modalType, closeModal } = useContext(ModalContext);

  // TODO: $.ajaxを使用する為にjqueryを読み込んでいるので、他の方法にする
  // 現在の検索条件を送ると、ヒットした求人数が返却される
  const getData = useCallback(
    (arrayData: { name: string; value: string }[]) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      $.ajax({
        method: "POST",
        url: numberOfSearchResultsUrl,
        data: arrayData,
        dataType: "json",
      }).then((data: { count: number }) => {
        setTotalCount(data.count);
      });
    },
    [numberOfSearchResultsUrl, setTotalCount],
  );

  // 検索数・遷移先URLを検索条件が変更される度にリアルタイムで表示
  useEffect(() => {
    setResultUrl(getSearchModalSubmitUrl(modalContents.searchUrl, formState));
    getData(getSerializeQueryArrayData(formState, modalContents.searchUrl, modalType));
  }, [formState, modalContents.searchUrl, getData, numberOfSearchResultsUrl, modalType]);

  return (
    <StyledModal>
      <StyledOverlay onClick={closeModal} />
      <StyledContentWrapper>
        <StyledModalHeader>
          <StyledTitle>{title}を選択</StyledTitle>
          <StyledCloseButton type="button" onClick={closeModal}>
            <i className="icon-cancel-circled" />
          </StyledCloseButton>
        </StyledModalHeader>
        <StyledModalMain modalType={modalType}>
          {modalType === "business_type" && (
            <BusinessTypeModal
              businessTypeContents={modalContents.businessTypeContents}
              dispatchFormState={dispatchFormState}
              formState={formState.businessTypes}
            />
          )}
          {modalType === "prefecture" && (
            <PrefectureModal
              prefectureContents={modalContents.prefectureContents}
              dispatchFormState={dispatchFormState}
              formState={formState.prefecture}
            />
          )}
          {modalType === "city" && (
            <CityModal
              cityContents={modalContents.cityContents}
              setIsAccordionOpen={setIsAccordionOpen}
              isAccordionOpen={isAccordionOpen}
              dispatchFormState={dispatchFormState}
              formState={formState.cities}
            />
          )}
          {modalType === "station" && (
            <StationModal
              stationContents={modalContents.stationContents}
              setIsAccordionOpen={setIsAccordionOpen}
              isAccordionOpen={isAccordionOpen}
              dispatchFormState={dispatchFormState}
              formState={formState.stations}
            />
          )}
        </StyledModalMain>
        <StyledModalBottom>
          <StyledNumArea>
            絞り込み結果
            <StyledNewLine />
            <StyledNum>
              <Suspense fallback={<div>Loading...</div>}>
                <EasedNumber
                  value={totalCount} // 目標値
                  speed={800} // アニメーションの速度を設定
                  decimals={0}
                />
              </Suspense>
            </StyledNum>
            件
          </StyledNumArea>
          <StyledBtnArea>
            <StyledSearchButton
              type="button"
              className={resultUrl.length ? "" : "is-disabled"}
              href={resultUrl}
            >
              <i className="icon-search" />
              検索する
            </StyledSearchButton>
          </StyledBtnArea>
        </StyledModalBottom>
      </StyledContentWrapper>
    </StyledModal>
  );
};
