import { FC } from "react";
import { LazyLoadImage } from "@root/shared/components/LazyLoadImage";
import { StyledElements } from "./src/styled_elements";
import { StatusLabels } from "./src/status_labels";
import { FeatureLabels } from "./src/feature_labels";
import { ButtonContents } from "./src/button_contents";
import { Header } from "./src/header";
import { RelatedHeader } from "./src/related_header";
import { SalaryAndShopRequirements } from "./src/salary_and_shop_requirements";
import { DetailedInformation } from "./src/detailed_information";
import { SalaryAndWorktimeRequirements } from "./src/salary_and_worktime_requirements";
import { CassetteProps } from "./src/cassette_props";

export const Cassette: FC<CassetteProps> = ({
  cassetteHash: {
    workCode,
    detailUrl,
    entryUrl,
    query,
    shopOrBrandName,
    businessTypeAndOccupation,
    businessType,
    occupation,
    title,
    imgSrc,
    rewardPrice,
    statusLabelFlags,
    awards2024Labels,
    awards2023Labels,
    awards2022Labels,
    awardsLabels,
    featureLabels,
    dream,
    commitment,
    requirements,
    buttonContents,
    telNumberContent,
    isShowPinpontHighRankLabel,
    isReviewLabel,
    searchEvents: {
      cassetteEvent: {
        cassetteGaAction,
        cassetteGaLabel,
        cassetteReproAction,
        cassetteSearchListRank,
      },
      clientShopsEvent,
      keepButtonEvent,
      scoutButtonEvent,
      entryButtonEvent,
    },
    isRejobAgentClient,
  },
  dummyImgPath,
  isSp,
  login,
  keepedFlag,
  cassetteType,
  className,
  showAwardsLabelFlag,
  isContractRunning,
}) => (
  <StyledElements className={className} cassetteType={cassetteType}>
    <section
      className="js-link-expand cassette-outer"
      data-job-code={workCode}
      id={`code-${workCode}`}
    >
      {Object.values(statusLabelFlags).includes(true) && (
        <StatusLabels
          premiumScoutFlag={statusLabelFlags.premiumScoutFlag}
          newContentFlag={statusLabelFlags.newContentFlag}
          publishingEndUpCloseFlag={statusLabelFlags.publishingEndUpCloseFlag}
          scoutFlag={statusLabelFlags.scoutFlag}
        />
      )}
      {isContractRunning && (
        <div className="image-outer-sp">
          <LazyLoadImage src={imgSrc} dummyImgPath={dummyImgPath} alt="" width={657} height={370} />
        </div>
      )}
      <div className="header-outer">
        {isShowPinpontHighRankLabel && (
          <span className="pinpoint-label">これはピンポイント上位表示です</span>
        )}
        <a
          className="js-link-expand__target ga-click repro-click js-add-query-strings ga-impression header-link"
          href={detailUrl}
          data-ga-action={cassetteGaAction}
          data-ga-label={cassetteGaLabel}
          data-query-str={query}
          data-repro-action={cassetteReproAction}
          data-search-list-rank={cassetteSearchListRank}
        >
          {(() => {
            if (
              cassetteType === "search" ||
              cassetteType === "search_results_with_dream_and_commitment"
            ) {
              return (
                <Header
                  businessTypeAndOccupation={businessTypeAndOccupation}
                  shopOrBrandName={shopOrBrandName}
                />
              );
            }
            return <RelatedHeader businessType={businessType} occupation={occupation} />;
          })()}
          <div className="header-text">
            <p>{title}</p>
            <i className="icon-right-open-big" />
          </div>
        </a>
        <FeatureLabels
          awards2024Labels={awards2024Labels}
          awards2023Labels={awards2023Labels}
          awards2022Labels={awards2022Labels}
          awardsLabels={awardsLabels}
          rewardPrice={rewardPrice}
          featureLabels={featureLabels}
          showAwardsLabelFlag={showAwardsLabelFlag}
          isContractRunning={isContractRunning}
          isReviewLabel={isReviewLabel}
          isRejobAgentClient={isRejobAgentClient}
        />
      </div>
      <div className="main-contents-outer">
        <div className="main-contents-inner">
          {(() => {
            if (cassetteType === "shop") {
              return <SalaryAndWorktimeRequirements requirements={requirements} />;
            }
            if (cassetteType === "search_results_with_dream_and_commitment") {
              return (
                <DetailedInformation
                  dream={dream}
                  commitment={commitment}
                  requirements={requirements}
                  clientShopsEvent={clientShopsEvent}
                  isSp={isSp}
                  query={query}
                />
              );
            }
            return (
              <SalaryAndShopRequirements
                requirements={requirements}
                clientShopsEvent={clientShopsEvent}
                isSp={isSp}
                query={query}
              />
            );
          })()}
          {isContractRunning && (
            <div className="image-outer-pc">
              <LazyLoadImage
                src={imgSrc}
                dummyImgPath={dummyImgPath}
                alt=""
                width={657}
                height={370}
              />
            </div>
          )}
        </div>
      </div>
      <ButtonContents
        buttonContents={buttonContents}
        telNumberContent={telNumberContent}
        keepButtonEvent={keepButtonEvent}
        scoutButtonEvent={scoutButtonEvent}
        entryButtonEvent={entryButtonEvent}
        workCode={workCode}
        query={query}
        entryUrl={entryUrl}
        login={login}
        keepedFlag={keepedFlag}
        detailUrl={detailUrl}
        isSp={isSp}
      />
    </section>
  </StyledElements>
);
