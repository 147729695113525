import { FC, useState, useRef, createRef, lazy, Suspense, RefObject } from "react";
import { JobTypesListProps } from "@legacy_user_frontend/components/pages/mypage/profiles/credentials_edit/src/credentials_edit_props";
import { StyledBusinessTypeButton } from "@legacy_user_frontend/components/pages/mypage/profiles/credentials_edit/src/styled_elements";

const CredentialModal = lazy(
  () =>
    import(
      "@legacy_user_frontend/components/pages/mypage/profiles/credentials_edit/src/ui_parts/credential_modal"
    ),
);

export const BusinessTypesList: FC<JobTypesListProps> = ({ name, credentialItems }) => {
  // モーダルの開閉処理
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => setIsModalOpen(false);

  // 追加するボタン押下で選択内容を送信用のHiddenCredentialFormに反映
  const credentialRefs = useRef<RefObject<HTMLInputElement>[]>([]);
  const isWillRefs = useRef<RefObject<HTMLInputElement>[]>([]);
  credentialItems.forEach((_, i) => {
    credentialRefs.current[i] = createRef();
    isWillRefs.current[i] = createRef();
  });

  return (
    <li>
      <StyledBusinessTypeButton type="button" onClick={openModal}>
        {name}
      </StyledBusinessTypeButton>
      <Suspense fallback={null}>
        {isModalOpen && (
          <CredentialModal
            jobTypeName={name}
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            credentialItems={credentialItems}
            credentialRefs={credentialRefs}
            isWillRefs={isWillRefs}
          />
        )}
      </Suspense>
    </li>
  );
};
