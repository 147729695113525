import { FC } from "react";
import { TextField } from "@root/shared/components/TextField";
import { ErrorInformationText } from "@userFrontend/components/ErrorInformationText";
import { ErrorText } from "@userFrontend/features/reward/components/ContinuedRewardInputs/type";
import * as styles from "./styles.css";

export type Props = {
  emailValue?: string;
  handleEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  emailErrorText?: ErrorText;
};

export const EmailInput: FC<Props> = ({ emailValue, handleEmailChange, emailErrorText }) => {
  return (
    <>
      <TextField
        className={styles.text}
        name="email"
        defaultValue={emailValue}
        onChange={handleEmailChange}
        hasError={!!emailErrorText}
        placeholder="入力してください"
      />
      {emailErrorText && <ErrorInformationText text={emailErrorText} />}
    </>
  );
};
