import { FC, useState } from "react";
import { axiosPost } from "@root/utils/axiosForRails";
import { Presentation } from "./Presentation";

type Props = {
  initialIsShow: boolean;
  restoredNewUserRewardUrl: string;
  deleteBackupUrl: string;
  mypageUrl: string;
};

export const RestoreUserRewardInputsModal: FC<Props> = ({
  initialIsShow,
  restoredNewUserRewardUrl,
  deleteBackupUrl,
  mypageUrl,
}) => {
  const [isShow, setIsShow] = useState(initialIsShow);

  const deleteBackup = async () => {
    await axiosPost.post(deleteBackupUrl);
    setIsShow(false);
  };

  const linkToMypage = () => {
    window.location.href = mypageUrl;
  };

  return (
    <Presentation
      isShow={isShow}
      closeModalHandler={() => setIsShow(false)}
      deleteBackupHandler={deleteBackup}
      linkToMypageHandler={linkToMypage}
      restoreUrl={restoredNewUserRewardUrl}
    />
  );
};
