import styled from "styled-components";

export const StyledCassetteHeadingOuter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 38px;
  margin-bottom: 16px;
  font-size: 14px;
`;

export const StyledCassetteHeading = styled.p`
  font-size: 14px;
  font-weight: bold;
`;

export const StyledDateSelectOuter = styled.div`
  display: flex;
  gap: 8px;

  > select {
    flex-basis: 50%;
  }
`;

export const StyledErrorMessageOuter = styled.div`
  margin-top: 10px;
`;

export const StyledAdditionButtonOuter = styled.div`
  padding: 16px;
`;
