import { FC } from "react";
import { SelectBox, OptionProps } from "@root/shared/components/SelectBox";
import { ErrorInformationText } from "@userFrontend/components/ErrorInformationText";
import { ErrorText } from "@userFrontend/features/reward/components/ContinuedRewardInputs/type";

type Props = {
  categoryName: string;
  value?: number;
  handleSelect: React.FocusEventHandler<HTMLSelectElement>;
  selectOptions: OptionProps[];
  errorText?: ErrorText;
};

export const SelectBoxContents: FC<Props> = ({
  categoryName,
  value,
  handleSelect,
  selectOptions,
  errorText,
}) => {
  return (
    <>
      <SelectBox
        key={`select_${categoryName}_type_${value}`}
        defaultValue={value}
        onBlur={handleSelect}
        options={selectOptions}
        placeholder="選択してください"
        selected={!!value}
        hasError={!!errorText}
      />
      {errorText && <ErrorInformationText text={errorText} />}
    </>
  );
};
