import { FC } from "react";
import * as styles from "./styles.css";

export const CautionaryNoteBox: FC = () => (
  <div className={styles.outer}>
    <dl className={styles.list}>
      <dd className={styles.description}>企業への口コミは、匿名で投稿されます。</dd>
      <dd className={styles.description}>
        個人情報やプライバシーへの配慮をした上で、口コミ投稿をお願いします。
      </dd>
    </dl>
  </div>
);
