import axios from "axios";

export const csrfToken = (): string => {
  const csrfMeta = document.querySelector("meta[name='csrf-token']");
  if (!csrfMeta) {
    return "";
  }
  return csrfMeta.getAttribute("content") || "";
};

const axiosInstance = axios.create();
axiosInstance.defaults.headers.common["X-CSRF-Token"] = csrfToken();

export { axiosInstance as axiosPost };
