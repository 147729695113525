import { FC, ReactElement } from "react";
import styled from "styled-components";
import { Colors } from "@legacy_user_frontend/utils";

type Props = {
  id?: string;
  children: ReactElement[] | ReactElement;
};

export const StyledBoxWithShadow = styled.div`
  padding: 24px 16px;
  background-color: ${Colors.WHITE};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);

  & + & {
    margin-top: 16px;
  }
`;

export const BoxWithShadow: FC<Props> = ({ id, children }) => (
  <StyledBoxWithShadow id={id}>{children}</StyledBoxWithShadow>
);
