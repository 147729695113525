import { createContext, useState, useCallback, Dispatch, SetStateAction } from "react";
/*
  モーダルの状態管理
*/
export type ModalContextProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  modalType: string;
  setModalType: Dispatch<SetStateAction<string>>;
  openModal: (modalType: string) => void;
  closeModal: () => void;
};

export const ModalContext = createContext({} as ModalContextProps);

export const useProvideModal = (): ModalContextProps => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState<string>("");
  const [position, setPosition] = useState(0);

  const afterOpen = () => {
    const currentPosition = document.documentElement.scrollTop || document.body.scrollTop;
    setPosition(currentPosition);
    document.body.style.position = "fixed";
    document.body.style.width = "100%";
    document.body.style.top = `${-1 * currentPosition}px`;
  };

  const afterClose = useCallback(() => {
    document.body.style.position = "static";
    document.body.style.width = "auto";
    document.body.style.top = "";
    window.scrollTo(0, position);
  }, [position]);

  const openModal = useCallback((modalName: string) => {
    setModalType(modalName);
    setIsOpen(true);
    afterOpen();
  }, []);

  const closeModal = useCallback(() => {
    setModalType("");
    setIsOpen(false);
    afterClose();
  }, [afterClose]);

  return {
    isOpen,
    setIsOpen,
    setModalType,
    modalType,
    openModal,
    closeModal,
  };
};
