import { useState, useCallback } from "react";
import { useDetectWindowSizeInfo } from "@userFrontend/hooks/useDetectWindowSize";

type UsePopOverReturnType = {
  isOpacityZero: boolean;
  isDisplayNone: boolean;
  hideWithDelay: () => void;
  autoHidePopOver: (autoHideWaitingTime: number) => void;
};

const WAITING_TIME_MS = {
  PC: 7000,
  SP: 5000,
};

export const usePopOver = (): UsePopOverReturnType => {
  const { isSP } = useDetectWindowSizeInfo();
  const waitingTime = isSP ? WAITING_TIME_MS.SP : WAITING_TIME_MS.PC;
  const [isOpacityZero, setIsOpacityZero] = useState(false);
  const [isDisplayNone, setIsDisplayNone] = useState(false);

  const hideWithDelay = useCallback(() => {
    setIsOpacityZero(true);
    setTimeout(() => {
      setIsDisplayNone(true);
    }, waitingTime);
  }, [waitingTime]);

  const autoHidePopOver = useCallback(
    (autoHideWaitingTime: number) => {
      setTimeout(() => {
        hideWithDelay();
      }, autoHideWaitingTime);
    },
    [hideWithDelay],
  );

  return {
    isOpacityZero,
    isDisplayNone,
    hideWithDelay,
    autoHidePopOver,
  };
};
