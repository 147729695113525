import { Icon } from "@root/shared/components/Icon";
import { Colors } from "@root/utils/colors";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { FC } from "react";
import * as styles from "./styles.css";

type Props = {
  isScrollingUp: boolean;
  iconName: string;
  text: string;
  onClick?: () => void;
  gaAction?: string;
  gaLabel?: string;
  buttonWidth: number;
};

export const Presentation: FC<Props> = ({
  isScrollingUp,
  iconName,
  text,
  onClick,
  gaAction,
  gaLabel,
  buttonWidth,
}) => {
  return (
    <button
      type="button"
      className={styles.wrapper}
      onClick={onClick}
      data-ga-action={gaAction}
      data-ga-label={gaLabel}
    >
      <div
        className={`${styles.floatingButton} ${isScrollingUp ? "scrolled" : ""}`}
        style={assignInlineVars({
          [styles.buttonWidth]: `${buttonWidth}px`,
        })}
      >
        <div className={styles.iconWrapper}>
          <div className={`${styles.iconOuter} ${isScrollingUp ? "scrolled" : ""}`}>
            <Icon name={iconName} fontSize="29px" color={Colors.TEXT_DEEP} />
          </div>
        </div>
        <p className={styles.text}>{text}</p>
      </div>
    </button>
  );
};
