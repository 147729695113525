import { FC, ReactNode } from "react";
import * as styles from "./styles.css";

type Props = {
  children: ReactNode;
  className?: string;
};

export const InformationCard: FC<Props> = ({ children, className }) => {
  return <div className={`${styles.container} ${className || ""}`}>{children}</div>;
};
