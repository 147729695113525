import { FC, ReactElement } from "react";
import styled from "styled-components";
import { Colors } from "@legacy_user_frontend/utils";

type Props = {
  children: ReactElement | ReactElement[];
};

const StyledList = styled.dl`
  > dt {
    border-top: 1px solid ${Colors.LIGHT_GREY};
    font-weight: bold;
  }
  > dd {
    display: flex;
  }
`;

export const JobList: FC<Props> = ({ children }) => <StyledList>{children}</StyledList>;
