/* eslint-disable react/jsx-props-no-spreading */
import { FC, Fragment, lazy, Suspense, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  StyledCategoryAccordionButton,
  StyledAccordionContent,
  StyledBusinessTypesList,
  StyledBusinessTypeButton,
  StyledHiddenCheckboxOuter,
  StyledCategoryModalButton,
} from "@legacy_user_frontend/components/pages/mypage/profiles/skills_edit/src/styled_elements";
import { CategorizedSkillProps } from "@legacy_user_frontend/components/pages/mypage/profiles/skills_edit/src/skill_edit_props";

const SkillModal = lazy(
  () =>
    import(
      "@legacy_user_frontend/components/pages/mypage/profiles/skills_edit/ui_parts/skill_modal"
    ),
);

export const CategorizedSkill: FC<CategorizedSkillProps> = ({ name, businessTypes }) => {
  const { register } = useFormContext();
  // アコーディオンの開閉
  const [isOpen, setIsOpen] = useState(false);
  const handleOnClick = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  // モーダルの開閉
  const [isModalOpen, setIsModalOpen] = useState<Record<string, boolean>>({});
  const openModal = (target: string) => {
    setIsModalOpen({ ...isModalOpen, [target]: true });
  };
  const closeModal = (target: string) => {
    setIsModalOpen({ ...isModalOpen, [target]: false });
  };

  return (
    <>
      <dt>
        {businessTypes.length === 1 && (
          <StyledCategoryModalButton
            type="button"
            onClick={() => openModal(businessTypes[0].name)}
            isActive={false}
          >
            {name}
          </StyledCategoryModalButton>
        )}
        {businessTypes.length > 1 && (
          <StyledCategoryAccordionButton type="button" onClick={handleOnClick} isActive={isOpen}>
            {name}
          </StyledCategoryAccordionButton>
        )}
      </dt>
      <dd>
        <StyledAccordionContent isOpen={isOpen}>
          <StyledBusinessTypesList>
            {businessTypes.map((businessType) => (
              <li key={businessType.name}>
                <StyledBusinessTypeButton
                  type="button"
                  onClick={() => openModal(businessType.name)}
                >
                  {businessType.name}
                </StyledBusinessTypeButton>
                <Suspense fallback={null}>
                  {isModalOpen[businessType.name] && (
                    <SkillModal
                      name={businessTypes.length === 1 ? name : businessType.name}
                      isModalOpen={isModalOpen[businessType.name]}
                      closeModal={() => closeModal(businessType.name)}
                      skillCategories={businessType.skillCategories}
                    />
                  )}
                </Suspense>
                {/* モーダルで選んだ内容を反映するチェックボックス */}
                <StyledHiddenCheckboxOuter>
                  {businessType.skillCategories.map((skillCategory) => (
                    <Fragment key={skillCategory.name}>
                      {skillCategory.skills.map((skill) => (
                        <Fragment key={skill.id}>
                          <input
                            type="checkbox"
                            value={`${skill.id}`}
                            defaultChecked={skill.registered}
                            id={`form_user_frontend_user_skill_update_user_skills_ids_${skill.id}`}
                            {...register("form_user_frontend_user_skill_update[user_skills_ids][]")}
                          />
                        </Fragment>
                      ))}
                    </Fragment>
                  ))}
                </StyledHiddenCheckboxOuter>
              </li>
            ))}
          </StyledBusinessTypesList>
        </StyledAccordionContent>
      </dd>
    </>
  );
};
