import { FC } from "react";
import { Presentation } from "./Presentation";

type Props = {
  handleClickSelectButton: () => void;
  handleChangeFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickDeleteButton: (index: number) => void;
  fileInputRef: React.RefObject<HTMLInputElement>;
  previewUrlArray: string[];
};

export const ImageFileUploader: FC<Props> = ({
  handleClickSelectButton,
  handleChangeFile,
  handleClickDeleteButton,
  fileInputRef,
  previewUrlArray,
}) => {
  return (
    <Presentation
      handleClickSelectButton={handleClickSelectButton}
      handleChangeFile={handleChangeFile}
      fileInputRef={fileInputRef}
      previewUrlArray={previewUrlArray}
      handleClickDeleteButton={handleClickDeleteButton}
    />
  );
};
