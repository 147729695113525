import { FC } from "react";
import { navigateWithDeferredDeepLink } from "@userFrontend/utils/deferredDeepLinks";
import { OsType, SiteType } from "@root/shared/types/type";
import { GaScreenName } from "@userFrontend/components/SmartAppBanner/type";
import * as styles from "./styles.css";
import { Title } from "./uiParts/Title";

type APatternProps = {
  isLogin: boolean;
  downloadUrl: string;
  osType: OsType;
  siteType: SiteType;
  gaScreenName: GaScreenName;
  requestFullPath: string;
};

export const APattern: FC<APatternProps> = ({
  isLogin,
  downloadUrl,
  osType,
  siteType,
  gaScreenName,
  requestFullPath,
}) => {
  const smartBannerIconPath =
    siteType === "kaigo"
      ? "/images/user_frontend/sp/img_app_icon_kaigo.png"
      : "/images/user_frontend/sp/img_app_icon.png";

  return (
    <>
      <input type="checkbox" id="smartap_close" className={styles.smartappCheckbox} />
      <div
        className="p-smartapp-banner js-smartapp-banner"
        data-ga-action="imp_smart_app_banner"
        data-ga-screen_name={gaScreenName}
        data-ga-appeal_pattern="appeal_original"
        data-ga-label={requestFullPath}
      >
        <div className={styles.smartappBannerColumnClose}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="smartap_close" id="js-smartapp-close">
            <i className="icon-cancel" />
          </label>
        </div>
        <div className={styles.smartappBannerColumnIcon}>
          {/* NOTE:buttonタグに修正すると見た目が崩れるため、disabledコメントで許容しつつaタグを使用している */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <a
            className="js-smartapp-banner-link"
            data-ga-action="tap_smart_app_banner"
            data-ga-screen_name={gaScreenName}
            data-ga-appeal_pattern="appeal_original"
            data-ga-label={requestFullPath}
            onClick={() => {
              navigateWithDeferredDeepLink({ isLogin, href: downloadUrl });
            }}
          >
            <img
              src={smartBannerIconPath}
              alt="アプリインストール訴求"
              className={styles.smartappBannerAppIcon}
            />
          </a>
        </div>
        <Title osType={osType} siteType={siteType} />
        <div className={styles.smartappBannerColumnDlbtn}>
          {/* NOTE:buttonタグに修正すると見た目が崩れるため、disabledコメントで許容しつつaタグを使用している */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <a
            className={`${styles.smartappBannerDlbtn} js-smartapp-banner-link`}
            data-ga-action="tap_smart_app_banner"
            data-ga-screen_name={gaScreenName}
            data-ga-appeal_pattern="appeal_original"
            data-ga-label={requestFullPath}
            onClick={() => {
              navigateWithDeferredDeepLink({ isLogin, href: downloadUrl });
            }}
          >
            開く
          </a>
        </div>
      </div>
    </>
  );
};
