import { FC } from "react";
import { Colors } from "@legacy_user_frontend/utils";
import styled from "styled-components";

const StyledLabel = styled.span`
  display: inline-block;
  height: 14px;
  padding: 2px;
  background-color: ${Colors.DEEP_GREY};
  color: ${Colors.WHITE};
  font-size: 10px;
  font-weight: bold;
  line-height: 1;
`;

export const PrivateLabel: FC = () => <StyledLabel>非公開</StyledLabel>;
