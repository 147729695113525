import { FC, ReactElement, ReactNode, useRef, useEffect, useState } from "react";
import { useCheckOverflow } from "./useCheckOverflow";
import { Presentation } from "./Presentation";

type Props = {
  isShow: boolean; // 初期値false
  pcWidthType?: "small" | "large"; // 初期値"large"
  /**
   * SPモーダルを上下中央のスタイルにする場合は"center", SPモーダルを下付けスタイルにする場合は""bottom"
   */
  spAppearanceType?: "center" | "bottom"; // 初期値"center"
  headerTitle?: string;
  headerTitleStyle?: "gray" | "white" | "none"; // 初期値"gray"
  onClose?: () => void;
  children: ReactElement;
  footerContent?: ReactNode;
  footerContentStyle?: "none" | "button"; // 初期値none
  canOutsideClick?: boolean; // 初期値true
};

const Modal: FC<Props> = ({
  isShow = false,
  pcWidthType = "large",
  spAppearanceType = "center",
  headerTitle,
  headerTitleStyle = "gray",
  onClose,
  children,
  footerContent,
  footerContentStyle = "none",
  canOutsideClick = true,
}) => {
  // refs
  const parentRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  // states
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);

  // margins
  const headerMarginBottom = headerTitleStyle === "gray" ? 16 : 0;

  // useCheckOverflowで使用するため
  useEffect(() => {
    const handleResize = () => {
      if (headerRef.current) {
        setHeaderHeight(headerRef.current.clientHeight);
      }
      if (footerRef.current) {
        setFooterHeight(footerRef.current.clientHeight);
      }
    };

    if (isShow) {
      handleResize();
      window.addEventListener("resize", handleResize);
    }

    return () => window.removeEventListener("resize", handleResize);
  }, [isShow, headerRef, footerRef]);

  const returnFlag = footerContentStyle === "none" || !footerContent;
  // コンテンツの高さ（スクロールの有無）に応じて、フッターにshadowをつけるため
  const hasOverFlow = useCheckOverflow(
    returnFlag,
    isShow,
    parentRef,
    headerHeight,
    headerMarginBottom,
    contentRef,
    footerHeight,
  );

  return isShow ? (
    <Presentation
      pcWidthType={pcWidthType}
      spAppearanceType={spAppearanceType}
      headerTitle={headerTitle}
      headerTitleStyle={headerTitleStyle}
      onClose={onClose}
      footerContent={footerContent}
      footerContentStyle={footerContentStyle}
      canOutsideClick={canOutsideClick}
      parentRef={parentRef}
      headerRef={headerRef}
      contentRef={contentRef}
      footerRef={footerRef}
      headerHeight={headerHeight}
      headerMarginBottom={headerMarginBottom}
      footerHeight={footerHeight}
      hasOverFlow={hasOverFlow}
    >
      {children}
    </Presentation>
  ) : null;
};

export default Modal;
