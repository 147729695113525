import { FC } from "react";
import { Presentation } from "./Presentation";
import { useWorkDetailBottomButtons } from "./useWorkDetailBottomButtons";

type Props = {
  fetchUrl: string;
  recommendFetchUrl: string;
};

export const WorkDetailBottomButtons: FC<Props> = ({ fetchUrl, recommendFetchUrl }) => {
  const formattedData = useWorkDetailBottomButtons(fetchUrl, recommendFetchUrl);
  return formattedData ? <Presentation {...formattedData} /> : null;
};
