import { useState } from "react";
import { CassetteInformation, FormedCassetteInformation } from "./types";

const MAX_TEXT_LENGTH = 45;
const ITEMS_INCREMENT = 5;
const ITEMS_BEFORE_TEXT_HIDDEN = 3;

const transformCassetteInformation = (
  original: CassetteInformation,
  isTextHidden: boolean,
): FormedCassetteInformation => {
  const text =
    isTextHidden && original.text.length > MAX_TEXT_LENGTH
      ? `${original.text.substring(0, MAX_TEXT_LENGTH)}...`
      : original.text;
  return {
    iconCode: original.icon_code,
    continuedServiceYears: original.continued_service_years,
    employmentStatus: original.employment_status,
    ageText: original.age,
    employment: original.employment,
    businessType: original.business_type,
    sex: original.sex,
    isWorkConfirmed: original.is_work_confirmed,
    text,
    createdDate: original.created_date,
    textId: original.text_id,
    isTextHidden,
  };
};

type UseClientReviewCassettesReturn = {
  transformedArray: FormedCassetteInformation[];
  displayedItemsCount: number;
  handleLoadMore: () => void;
};

export const useClientReviewCassettes = (
  cassetteInformationArray: CassetteInformation[],
  isTextHidden: boolean,
  initialItemsToShow = 5,
): UseClientReviewCassettesReturn => {
  const [displayedItemsCount, setDisplayedItemsCount] = useState(initialItemsToShow);

  const handleLoadMore = () => {
    setDisplayedItemsCount((prev) => prev + ITEMS_INCREMENT);
  };

  const transformedArray = cassetteInformationArray.map((item, index) =>
    transformCassetteInformation(item, isTextHidden && index >= ITEMS_BEFORE_TEXT_HIDDEN),
  );

  return {
    transformedArray,
    displayedItemsCount,
    handleLoadMore,
  };
};
