import { FC, ReactElement } from "react";
import styled from "styled-components";
import { Colors } from "@legacy_user_frontend/utils";

type Props = {
  children: ReactElement[] | ReactElement;
};

const StyledLabel = styled.span`
  display: inline-block;
  padding: 8px;
  border: 1px solid ${Colors.REJOB_BRAND_DEEP};
  background-color: transparent;
  color: ${Colors.REJOB_BRAND_DEEP};
  font-weight: bold;
`;

export const Label: FC<Props> = ({ children }) => <StyledLabel>{children}</StyledLabel>;
