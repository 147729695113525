import styled from "styled-components";
import { Breakpoints } from "@legacy_user_frontend/utils";
import { Colors } from "@root/utils/colors";

export const StyledDiv = styled.div`
  ${Breakpoints.SMALL} {
    > .owner-image {
      width: 50%;
      margin: 0 auto;
    }
    > .owner-message {
      margin-top: 16px;
    }
  }
  ${Breakpoints.MEDIUM} {
    display: flex;
    > .owner-image {
      flex-basis: 35%;
      flex-shrink: 0;
      margin-right: 24px;
    }
    > .owner-message {
      flex-grow: 1;
    }
  }
`;

export const StyledHeading = styled.h3`
  padding-top: 8px;
  padding-bottom: 16px;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.4;
  color: ${Colors.TEXT_DEEP};
`;
