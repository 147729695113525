import { FC, useState, useCallback } from "react";
import { useInView } from "react-intersection-observer";
import { KeepButton } from "@legacy_user_frontend/components/ui_parts/buttons/keep_button";
import {
  StyledKeepButtonArea,
  StyledKeepButtonOuter,
  StyledKeepBalloon,
} from "./src/styled_elements";

export type KeepButtonContentProps = {
  keepButtonContent: {
    keepButtonUrl: string;
    keepButtonQuery: string;
    hasKeeped: boolean;
    isShowKeepBalloon: boolean;
    isShowConfirmRemoveKeepModal: boolean;
  };
  workCode: string;
  position: string;
  gaLabel: string;
  isPreview: boolean;
  pageType: string;
  capitalizePageType: string;
  dataStyleColumn: number;
};
const WAITING_TIME = {
  PC: 7000,
  SP: 5000,
  LIMIT: 500,
};

export const KeepButtonContent: FC<KeepButtonContentProps> = ({
  keepButtonContent: {
    keepButtonUrl,
    keepButtonQuery,
    hasKeeped,
    isShowKeepBalloon,
    isShowConfirmRemoveKeepModal,
  },
  workCode,
  position,
  gaLabel,
  isPreview,
  pageType,
  capitalizePageType,
  dataStyleColumn,
}) => {
  const keepButtonReproAction = hasKeeped
    ? `trackTapJob${capitalizePageType}KeepReleaseButton`
    : `trackTapJob${capitalizePageType}KeepAddButton`;
  const keepGaAction = hasKeeped
    ? `${pageType}_release_keep-${position}`
    : `${pageType}_keep-${position}`;
  const keepFireBaseAction = hasKeeped ? "release_keep" : "keep";
  const [isHide, setHide] = useState(false);
  const [isHideEl, setHideEl] = useState(false);

  const hideKeepBalloon = useCallback(() => {
    setHide(true);

    setTimeout(() => {
      setHideEl(true);
    }, WAITING_TIME.LIMIT);
  }, []);

  const { ref, inView } = useInView({
    threshold: 1.0,
    triggerOnce: true, // 最初の一度だけ実行
  });
  // ポップが画面に表示されてから一定時間後にフェードアウト
  const autoHideBalloon = useCallback(() => {
    const waitingTime = window.matchMedia("(min-width: 768px)").matches
      ? WAITING_TIME.PC
      : WAITING_TIME.SP;
    setTimeout(() => {
      hideKeepBalloon();
    }, waitingTime);
  }, [hideKeepBalloon]);
  if (inView) {
    autoHideBalloon();
  }

  return (
    <StyledKeepButtonArea data-style-column={dataStyleColumn}>
      <StyledKeepButtonOuter>
        <KeepButton
          url={keepButtonUrl}
          query={keepButtonQuery}
          gaAction={keepGaAction}
          gaLabel={gaLabel}
          reproAction={keepButtonReproAction}
          firebaseAction={keepFireBaseAction}
          workCode={workCode}
          hasKeeped={hasKeeped}
          isPreview={isPreview}
          pageType={pageType}
        />
      </StyledKeepButtonOuter>
      {isShowKeepBalloon && !hasKeeped && position === "top" && pageType === "detail" && (
        <StyledKeepBalloon isHide={isHide} isHideEl={isHideEl} onClick={hideKeepBalloon} ref={ref}>
          以前もこの求人を閲覧しました。
          <br />
          キープしますか？
          <i className="icon-cancel p-job-balloon__icon-cancel" />
        </StyledKeepBalloon>
      )}
      {isShowConfirmRemoveKeepModal && hasKeeped && pageType === "detail" && (
        <div
          className={`c-medium-modal p-confirm-remove-keep-modal js-confirm-remove-keep-modal-${workCode}`}
        >
          <button
            type="button"
            className="c-medium-modal__close js-confirm-remove-keep-modal__close"
          />
          <div>
            <p className="p-confirm-remove-keep-modal__text">
              キープしておくと
              <br className="u-show-only-sp" />
              <span>
                スカウト
                <i className="icon-paper-plane" />
              </span>
              が届きやすくなります！
              <br />
              本当に解除しますか？
            </p>
            <div className="p-confirm-remove-keep-modal__button-outer">
              <button
                type="button"
                className="c-btn--tertiary-flat p-confirm-remove-keep-modal__button--narrow js-confirm-remove-keep-modal__remove-button--detail"
                data-work-code={workCode}
              >
                解除する
              </button>
              <button
                type="button"
                className="c-btn--secondary-flat p-confirm-remove-keep-modal__button--wide js-confirm-remove-keep-modal__close"
              >
                キープしておく
              </button>
            </div>
          </div>
        </div>
      )}
    </StyledKeepButtonArea>
  );
};
