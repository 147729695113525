import { FC } from "react";
import { StyledTableList } from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_show/src/styled_elements";
import { AvailableStartDayCassetteProps } from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_show/src/profile_show_props";
import { changeDateFormat } from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_show/src/module";

export const AvailableStartDayCassette: FC<AvailableStartDayCassetteProps> = ({
  availableStartDay: { isUndecided, availableStartDate, availableStartDateNote },
}) => (
  <StyledTableList>
    {(availableStartDate || isUndecided) && (
      <>
        <dt>勤務開始可能日</dt>
        <dd>
          {isUndecided && <>未定</>}
          {!isUndecided && <>{changeDateFormat(availableStartDate)}</>}
        </dd>
      </>
    )}
    {availableStartDateNote && (
      <>
        <dt>備考</dt>
        <dd>{availableStartDateNote}</dd>
      </>
    )}
  </StyledTableList>
);
