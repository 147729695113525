/* eslint-disable react/jsx-props-no-spreading */
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import {
  HiddenCredentialFormProps,
  TempCredentialItemListProps,
} from "@legacy_user_frontend/components/pages/mypage/profiles/credentials_edit/src/credentials_edit_props";
import { StyledHiddenFormOuter } from "@legacy_user_frontend/components/pages/mypage/profiles/credentials_edit/src/styled_elements";

export const HiddenCredentialForm: FC<HiddenCredentialFormProps> = ({
  credentialItemsMaxLength,
  categorizedCredentialItemList,
  otherCredentialItemList,
}) => {
  const { register } = useFormContext();
  const credentialItemList: TempCredentialItemListProps = [...Array(credentialItemsMaxLength)].map(
    () => ({
      key: "",
      value: "",
    }),
  );
  categorizedCredentialItemList.forEach((jobTypeCredentialItemList) => {
    jobTypeCredentialItemList.jobTypes.forEach((credentialItems) => {
      credentialItems.credentialItems.forEach(({ key, value }) => {
        credentialItemList[value] = { key, value };
      });
    });
  });
  otherCredentialItemList.forEach(({ key, value }) => {
    credentialItemList[value] = { key, value };
  });
  // CredentialItemListで選択された内容を反映するフォーム
  return (
    <StyledHiddenFormOuter>
      {[...Array(credentialItemsMaxLength)].map((_, index) => {
        const credentialItemFormName = `form_user_frontend_user_update[credentials_attributes][${index}]`;
        return (
          <div key={credentialItemFormName}>
            {/* nameが共通のhiddenのinputを置くことで、資格の選択を解除した際にvalueを空の状態で送信できる */}
            <input
              type="hidden"
              value=""
              name={`${credentialItemFormName}[user_credential_item_id]`}
            />
            <input
              type="checkbox"
              value={credentialItemList[index].value}
              {...register(`${credentialItemFormName}[user_credential_item_id]`)}
            />
            {/* will_flagは未選択の場合0を送信する */}
            <input type="hidden" value="0" name={`${credentialItemFormName}[will_flag]`} />
            <input
              type="checkbox"
              value="1"
              {...register(`${credentialItemFormName}[will_flag]`)}
            />
          </div>
        );
      })}
    </StyledHiddenFormOuter>
  );
};
