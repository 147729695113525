import { FC } from "react";
import { Presentation } from "./Presentation";
import { useClientReviewCassettes } from "./useClientReviewCassettes";
import { CassetteInformation } from "./types";

type Props = {
  cassetteInformationArray: CassetteInformation[];
  reviewSubmissionUrlOrRegistrationUrl?: string;
  isSp: boolean;
  title: string;
  isTextHidden: boolean;
};

export const ClientReviewCassettes: FC<Props> = ({
  cassetteInformationArray,
  reviewSubmissionUrlOrRegistrationUrl,
  isSp,
  title,
  isTextHidden,
}) => {
  const { transformedArray, displayedItemsCount, handleLoadMore } = useClientReviewCassettes(
    cassetteInformationArray,
    isTextHidden,
  );

  const isHidden = (index: number) => index >= displayedItemsCount;
  const hasMoreItems = displayedItemsCount < cassetteInformationArray.length;

  return (
    <Presentation
      cassetteInformationArray={transformedArray.map((item, index) => ({
        ...item,
        isHidden: isHidden(index),
      }))}
      reviewSubmissionUrlOrRegistrationUrl={reviewSubmissionUrlOrRegistrationUrl}
      isSp={isSp}
      title={title}
      handleLoadMore={hasMoreItems ? handleLoadMore : undefined}
    />
  );
};
