import { FC } from "react";
import { Icon } from "@root/shared/components/Icon";
import { colors } from "@root/shared/styles/sprinkles.css";
import { LazyLoadImage } from "@root/shared/components/LazyLoadImage";
import { ImageNavigationButton } from "@userFrontend/features/publication/components/AlbumContents/uiParts/ImageNavigationButton";
import * as styles from "./styles.css";

type Props = {
  expandedImageUrl: string;
  expandedImageNo: number;
  totalImagesCount: number;
  handleClickBackground: () => void;
  handleClickArrow: (direction: "next" | "prev") => void;
};

export const ImageLightbox: FC<Props> = ({
  expandedImageUrl,
  expandedImageNo,
  totalImagesCount,
  handleClickBackground,
  handleClickArrow,
}) => {
  return (
    <div className={styles.background} aria-hidden="true" onClick={handleClickBackground}>
      <div className={styles.modalWrapper}>
        <div className={styles.iconAlignment}>
          <div className={styles.iconWrapper}>
            <Icon name="cross-thin" fontSize="24px" color={colors.White} />
          </div>
        </div>
        <div
          className={styles.modalContentWrapper}
          aria-hidden="true"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className={styles.imageWrapper}>
            <LazyLoadImage
              src={expandedImageUrl}
              alt="企業が登録している画像"
              width={414}
              height={232}
            />
          </div>
          <div className={styles.roundButtonWrapper}>
            <ImageNavigationButton
              iconName="arrow-bold-left"
              onClick={() => handleClickArrow("prev")}
              disabled={expandedImageNo === 1}
            />
            <div className={styles.imagesCountText}>
              {expandedImageNo}/{totalImagesCount}
            </div>
            <ImageNavigationButton
              iconName="arrow-bold-right"
              onClick={() => handleClickArrow("next")}
              disabled={totalImagesCount === expandedImageNo}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
