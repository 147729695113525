import { FC } from "react";
import {
  StyledTableList,
  StyledList,
} from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_show/src/styled_elements";
import { ContactableTimeRangeCassetteProps } from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_show/src/profile_show_props";

export const ContactableTimeRangeCassette: FC<ContactableTimeRangeCassetteProps> = ({
  contactableTimeRange: { contactableTimeRanges, contactableTimeRangesNote },
}) => (
  <StyledTableList>
    {contactableTimeRanges.length > 0 && (
      <>
        <dt>連絡可能時間帯</dt>
        <dd>
          <StyledList>
            {contactableTimeRanges.map(({ label }) => (
              <li key={label}>{label}</li>
            ))}
          </StyledList>
        </dd>
      </>
    )}
    {contactableTimeRangesNote && (
      <>
        <dt>備考</dt>
        <dd>{contactableTimeRangesNote}</dd>
      </>
    )}
  </StyledTableList>
);
