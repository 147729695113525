import { FC } from "react";
import * as styles from "./styles.css";

type Props = {
  /** trの内容 */
  children?: React.ReactNode;
  /** コンポーネントに適用するクラス名 */
  className?: string;
};

export const Tr: FC<Props> = ({ children, className }) => (
  <tr
    className={`
      ${styles.tr}
      ${className || ""}
    `}
  >
    {children}
  </tr>
);
