import { FC } from "react";
import { Salaries, SalariesProps } from "@legacy_user_frontend/components/ui_parts/salaries";
import { StyledElements } from "./src/styled_elements";
import { Shop } from "./src/shop";
import { ShopAccordion, ShopAccordionProps } from "./src/shop_accordion";

type RequirementsProps = {
  dream: string;
  commitment: string;
  requirements: {
    clientShops: {
      totalNumber: ShopAccordionProps["totalNumber"];
      shopInformations: ShopAccordionProps["shopInformations"] | [];
    };
    salaries: SalariesProps["salaries"] | [];
  };
  clientShopsEvent: {
    clientShopsGaAction: ShopAccordionProps["gaAction"];
    clientShopsGaLabels: ShopAccordionProps["gaLabels"];
  };
  isSp: boolean;
  query: string;
  className?: string;
};

export const DetailedInformation: FC<RequirementsProps> = ({
  dream,
  commitment,
  requirements: {
    salaries,
    clientShops: { totalNumber, shopInformations },
  },
  clientShopsEvent: { clientShopsGaAction, clientShopsGaLabels },
  isSp,
  query,
  className,
}) => (
  <StyledElements className={className}>
    <dl className="table">
      <dt>
        <span>
          <i className="icon-yen" />
          <span className="table-title">給与</span>
        </span>
      </dt>
      <dd>{salaries && salaries.length > 0 && <Salaries salaries={salaries} />}</dd>
      {dream && (
        <>
          <dt className="dream-and-commitment">想い</dt>
          <div className="dream-and-commitment-wrapper">
            <dd className="dream-and-commitment">{dream}</dd>
          </div>
        </>
      )}
      {commitment && (
        <>
          <dt className="dream-and-commitment">
            こだ
            <br className="dream-and-commitment-br" />
            わり
          </dt>
          <div className="dream-and-commitment-wrapper">
            <dd className="dream-and-commitment">{commitment}</dd>
          </div>
        </>
      )}
      <dt>
        <span>
          <i className="icon-location" />
          <span className="table-title">店舗</span>
        </span>
      </dt>
      <dd className="js-link-expand__unlink">
        {((isSp && totalNumber > 1) || (!isSp && totalNumber > 3)) &&
          shopInformations.length > 0 && (
            <ShopAccordion
              totalNumber={totalNumber}
              shopInformations={shopInformations}
              gaAction={clientShopsGaAction}
              gaLabels={clientShopsGaLabels}
              isSp={isSp}
              query={query}
            />
          )}
        {((isSp && totalNumber <= 1) || (!isSp && totalNumber <= 3)) &&
          shopInformations.length > 0 && (
            <Shop
              totalNumber={totalNumber}
              shopInformations={shopInformations}
              gaAction={clientShopsGaAction}
              gaLabels={clientShopsGaLabels}
              isSp={isSp}
              query={query}
            />
          )}
      </dd>
    </dl>
  </StyledElements>
);
