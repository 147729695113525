import { ComponentProps, FC } from "react";
import { Card } from "@userFrontend/features/publication/components/Card";
import { UserVoiceCassettes } from "@userFrontend/features/publication/components/UserVoiceCassettes";
import { SatisfactionRate } from "./uiParts/SatisfactionRate";
import * as styles from "./styles.css";

type Props = {
  searchConditionsLabel?: string;
  userRate: {
    averageRate: number;
    totalNumberOfReviews: number;
  };
  userVoices: ComponentProps<typeof UserVoiceCassettes>["cassettes"];
};

const DENOMINATOR_OF_SATISFACTION_RATE = 5;

export const UserExperienceVoice: FC<Props> = ({
  searchConditionsLabel,
  userRate: { averageRate, totalNumberOfReviews },
  userVoices,
}) => {
  const satisfactionRate = {
    numerator: averageRate,
    denominator: DENOMINATOR_OF_SATISFACTION_RATE,
  };
  return (
    <section className={styles.wrapper}>
      <div className={styles.headingWrapper}>
        <h2 className={styles.heading}>{searchConditionsLabel} リジョブを利用した転職者の体験談</h2>
      </div>
      <div>
        <Card>
          <h3 className={styles.sectionHeading}>転職者の満足度</h3>
          <div className={styles.contentsWrapper}>
            <SatisfactionRate
              satisfactionRate={satisfactionRate}
              numberOfSatisfactions={totalNumberOfReviews}
            />
          </div>
        </Card>
        {userVoices.length > 0 && (
          <Card>
            <h3 className={styles.sectionHeading}>転職者の声</h3>
            <UserVoiceCassettes cassettes={userVoices} />
          </Card>
        )}
      </div>
    </section>
  );
};
