import { FC } from "react";
import { container } from "./styles.css";

type Props = {
  considerationNumText: string;
};

export const ConsiderationNumInformation: FC<Props> = ({ considerationNumText }) => {
  return <p className={container}>{considerationNumText}</p>;
};
