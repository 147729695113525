import { FC } from "react";
import { Button } from "@root/shared/components/Button";
import { Icon } from "@root/shared/components/Icon";
import * as styles from "./styles.css";

type Props = {
  handleClickSelectButton: () => void;
  handleChangeFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickDeleteButton: (index: number) => void;
  fileInputRef: React.RefObject<HTMLInputElement>;
  previewUrlArray: string[];
};

export const Presentation: FC<Props> = ({
  handleClickSelectButton,
  handleChangeFile,
  handleClickDeleteButton,
  fileInputRef,
  previewUrlArray,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.buttonOuter}>
        <Button theme="tertiary" onClick={handleClickSelectButton} className={styles.button}>
          ファイルを選択する
        </Button>
      </div>
      <input
        type="file"
        multiple
        onChange={handleChangeFile}
        accept="image/*"
        ref={fileInputRef}
        style={{ display: "none" }}
      />
      {previewUrlArray && previewUrlArray.length > 0 && (
        <div className={styles.previewContainer}>
          {previewUrlArray.map((url, index) => (
            <div key={`file-previews-${String(index)}`} className={styles.imageContainer}>
              <button
                type="button"
                onClick={() => handleClickDeleteButton(index)}
                className={styles.deleteButton}
              >
                <Icon name="cross-bold" fontSize="24px" />
              </button>
              <img src={url} alt="画像のプレビュー" className={styles.image} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
