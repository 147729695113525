// TODO: TextLinkコンポーネントがdevelop反映されたら差し替える
import { FC } from "react";
import { LazyLoadImage } from "@root/shared/components/LazyLoadImage";
import { InformationCard } from "@userFrontend/components/InformationCard";
import * as styles from "./styles.css";

type Props = {
  termsUrl: string;
  guidelineUrl: string;
};

export const RequestConfirmationPanel: FC<Props> = ({ termsUrl, guidelineUrl }) => (
  <InformationCard>
    <div className={styles.informationWrapper}>
      <p>
        おつかれさまでした。
        <br />
        口コミはリジョブに掲載され多くの方の転職活動に役立ちます。
      </p>
      <div className={styles.imageWrapper}>
        <LazyLoadImage
          src="/images/user_frontend/sp/img_reward_request_confirmation.png"
          alt=""
          width={127}
          height={90}
        />
      </div>
    </div>
    <div className={styles.cautionWrapper}>
      <p className={styles.cautionText}>
        口コミの内容は投稿後の編集・削除ができません。
        <br />
        内容をご確認の上、ご投稿をお願いいたします。
      </p>
      <p className={styles.cautionText}>
        <a className={styles.link} href={termsUrl} target="_blank" rel="noreferrer">
          利用規約
        </a>
        ・
        <a className={styles.link} href={guidelineUrl} target="_blank" rel="noreferrer">
          ガイドライン
        </a>
        に反する書き込みは､掲載されない場合があります。ご了承ください。
      </p>
    </div>
  </InformationCard>
);
