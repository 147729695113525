import { FC, useState } from "react";
import Modal from "@root/shared/components/Modal/legacy";
import { Icon } from "@root/shared/components/Icon";
import { colors } from "@root/shared/styles/sprinkles.css";
import * as styles from "./styles.css";

export const SearchResultExplainModal: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      <button className={styles.helpButton} onClick={handleOpen} type="button">
        <div className={styles.helpIcon}>
          <Icon name="tooltip" fontSize="16px" color={colors.GrayScale94} />
        </div>
      </button>
      <Modal
        headerTitle="表示している求人数について"
        isShow={isOpen}
        onClose={handleClose}
        isBottomSheetMode={false}
        maxWidth="833px"
      >
        <div className={styles.modalContent}>
          <p>
            リジョブケアでは、職種別・就業場所別・雇用形態別での求人数の合計を検索結果に表示しています。
          </p>
        </div>
      </Modal>
    </>
  );
};
