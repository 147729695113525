import { useEffect, useMemo, useCallback, ComponentProps } from "react";
import { CassetteSliderSectionList } from "@userFrontend/components/CassetteSliderSectionList";
import { useEntryLink } from "@userFrontend/hooks/useEntryLink";
import useSWR, { KeyedMutator } from "swr";
import { Cassette } from "./type";

/* eslint-disable @typescript-eslint/no-explicit-any */
declare const newrelic: any;

// NOTE: app/packs/utils/fetchData.ts内のFetchDataを使用すると、useSWRImmutableが使用されているため、操作手順によってはレコメンド求人の最新情報が再フェッチされません。
// それにより、キープ押下後レコメンド求人と、このファイルで生成しているレコメンド求人のキープステータスがズレる可能性があるため、再フェッチが走るように、useSWRを使用します。
const FetchData = <T>(
  fetchUrl: string,
  option = {},
): { data: T | undefined; error: any; mutate: KeyedMutator<T> } => {
  const fetcher = (url: string) => fetch(url, option).then((res) => res.json());
  const { data, error, mutate } = useSWR<T>(fetchUrl, fetcher);

  // 非同期通信に失敗した際にnewrelicにエラーを送る
  if (error) {
    if (typeof newrelic !== "undefined") {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      newrelic.noticeError(error);
    }
    return { data, error, mutate };
  }

  return { data, error, mutate };
};

export const useRecommendWorksSection = (
  fetchUrl: string,
  gaActionPrefix: string,
  inView: boolean,
): ComponentProps<typeof CassetteSliderSectionList>["sections"] | undefined => {
  const { handleClickEntryLink } = useEntryLink();
  const { data, mutate } = FetchData<{ data: Cassette[] }>(fetchUrl);

  useEffect(() => {
    // NOTE:
    // キープ押下後レコメンド(AfterKeepRecommendWorks)がすでに表示されている状態で、
    // レコメンド求人(当コンポーネント)が画面内に入った場合、
    // 両者のキープボタンの見た目を一致させる目的で、再フェッチを走らせる。
    // 求人詳細以外では、再フェッチを走らせる必要がないため、この関数の呼び出し側からfalseを渡す。
    if (document.getElementById("after-keep-recommend-works-component") && inView) {
      mutate();
    }
  }, [inView, mutate]);

  const createData = useCallback(
    (cassettes: Cassette[]) => {
      return cassettes.map((item: Cassette) => ({
        type: "simplified" as const,
        gaAction: `${gaActionPrefix}_recommend`,
        gaLabel: item.gaLabel,
        detailUrl: item.linkHref,
        headerContents: {
          imgSrc: item.imgSrc,
          dummyImgPath: item.dummyImgPath,
          shopOrBrandName: item.shopOrBrandName,
          businessTypeAndOccupation: item.businessTypeAndOccupation,
          title: item.title,
        },
        salaries: item.salaryTexts,
        shopLocation: item.shopLocation,
        buttonProps: {
          workCode: item.workCode,
          hasKeeped: item.keepButtonContent.hasKeeped,
          keepButtonUrl: item.keepButtonContent.keepButtonUrl,
          keepGaAction: `${gaActionPrefix}_recommend_keep`,
          keepGaLabel: item.gaLabel,
          entryUrl: item.entryUrl,
          onClickEntryLink: handleClickEntryLink(`${gaActionPrefix}_recommend_entry`, item.gaLabel),
          entryGaAction: `${gaActionPrefix}_recommend_entry`,
          entryGaLabel: item.gaLabel,
        },
      }));
    },
    [gaActionPrefix, handleClickEntryLink],
  );

  const cassettesData = useMemo(
    () => (data?.data ? createData(data.data) : []),
    [data, createData],
  );

  const sections = useMemo(
    () => (cassettesData.length ? [{ cassettes: cassettesData }] : undefined),
    [cassettesData],
  );

  return sections;
};
