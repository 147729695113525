import { FC } from "react";
import Modal from "@root/shared/components/Modal/legacy";
import { Button } from "@root/shared/components/Button";
import * as styles from "./styles.css";

type Props = {
  isShow: boolean;
  closeModalHandler: () => void;
  restoreBackupHandler: () => void;
  deleteBackupHandler: () => void;
};

export const Presentation: FC<Props> = ({
  isShow,
  closeModalHandler,
  restoreBackupHandler,
  deleteBackupHandler,
}) => {
  return (
    <Modal
      isShow={isShow}
      onClose={closeModalHandler}
      isBottomSheetMode={false}
      canOutsideClick={false}
    >
      <div className={styles.modalBody}>
        <p className={styles.strongMsg}>バックアップを復元しますか？</p>
        <p className={styles.msg}>以前の入力内容の、自動バックアップが残っています。</p>
        <div className={styles.buttonsWrapper}>
          <Button
            theme="primary"
            onClick={restoreBackupHandler}
            data-ga-action="continued_reward_rewrite_popup"
          >
            復元する
          </Button>
          <Button theme="tertiary" onClick={deleteBackupHandler}>
            削除して入力しなおす
          </Button>
        </div>
      </div>
    </Modal>
  );
};
