import { ComponentPropsWithRef, FC, forwardRef } from "react";
import * as styles from "./styles.css";

type Props = ComponentPropsWithRef<"input"> & {
  labelName: string;
};

export const ChoiceChip: FC<Props> = forwardRef(
  ({ labelName, type = "checkbox", ...inputElementProps }, ref) => (
    <span className={styles.wrapper} key={inputElementProps.id}>
      <input {...inputElementProps} ref={ref} id={inputElementProps.id} type={type} />
      <label className={styles.label} htmlFor={inputElementProps.id}>
        {labelName}
      </label>
    </span>
  ),
);
ChoiceChip.displayName = "ChoiceChip";
