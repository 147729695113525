import { FC } from "react";
import { useScrollDirection } from "./useScrollDirection";
import { Presentation } from "./Presentation";

type Props = {
  iconName: string;
  text: string;
  onClick?: () => void;
  gaAction: string;
  gaLabel: string;
  buttonWidth: number;
};

export const ScrollFloatingButton: FC<Props> = ({
  iconName,
  text,
  onClick,
  gaAction,
  gaLabel,
  buttonWidth,
}) => {
  const isScrollingUp = useScrollDirection();

  return (
    <Presentation
      isScrollingUp={isScrollingUp}
      iconName={iconName}
      text={text}
      onClick={onClick}
      gaAction={gaAction}
      gaLabel={gaLabel}
      buttonWidth={buttonWidth}
    />
  );
};
