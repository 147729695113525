import styled from "styled-components";
import { Colors, Breakpoints } from "@legacy_user_frontend/utils";
import { StyledBaseButton } from "@legacy_user_frontend/components/ui_parts/buttons/styled_elements";

export const StyledKeepButton = styled(StyledBaseButton)`
  background-color: ${Colors.WHITE};
  border: 1px solid ${Colors.PRIMARY};
  color: ${Colors.PRIMARY};
  i:before {
    font-weight: bold;
    vertial-align: baseline;
  }
  .c-icn {
    margin-right: 4px;
  }
  &.is-keeped {
    border: none;
    background-color: ${Colors.LIGHT_GREY};
    color: ${Colors.LIGHT_BLACK};
    ${Breakpoints.MEDIUM} {
      &:hover {
        opacity: 1;
        background: ${Colors.LIGHT_GREY};
        box-shadow:
          0px 0px 8px ${Colors.MEDIUM_GREY},
          inset 0px 0px 1px ${Colors.MEDIUM_GREY};
      }
    }
  }
  ${Breakpoints.MEDIUM} {
    &:hover {
      background: rgba(251, 99, 65, 0.2);
      border-color: ${Colors.WHITE};
      box-shadow:
        0px 0px 8px ${Colors.PRIMARY},
        inset 0px 0px 1px ${Colors.PRIMARY};
    }
  }
`;
