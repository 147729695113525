import { FC, Fragment } from "react";

type Props = {
  text: string;
};

export const MultiLineText: FC<Props> = ({ text }) => {
  const splitText = text.split(/(\r?\n)/);
  return (
    <p>
      {splitText.map((item, index) => {
        const key = `${splitText}_${index}`;
        return <Fragment key={key}>{item.match(/(\r?\n)/) ? <br /> : item}</Fragment>;
      })}
    </p>
  );
};
