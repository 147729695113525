type Props = {
  setIsShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const showAlertBeforeBrowserBack = (props: Props): void => {
  const { setIsShowAlert, setIsModalOpen } = props;
  window.history.pushState(null, "", window.location.href);
  window.addEventListener("popstate", () => {
    setIsShowAlert(false);
    setIsModalOpen(true);
    window.history.go(1);
  });
};
