import { FC } from "react";
import { RadioButton } from "@root/shared/components/RadioButton";
import { ErrorInformationText } from "@userFrontend/components/ErrorInformationText";
import { ErrorText } from "@userFrontend/features/reward/components/ContinuedRewardInputs/type";
import * as styles from "./styles.css";

type Props = {
  employmentStatus: {
    index: number;
    optionId: number;
    optionText: string;
  }[];
  employmentStatusValue?: number;
  employmentStatusErrorText?: ErrorText;
  handleEmploymentStatusChange: (value?: number) => void;
};

export const EmploymentStatusRadio: FC<Props> = ({
  employmentStatus,
  employmentStatusValue,
  employmentStatusErrorText,
  handleEmploymentStatusChange,
}) => {
  return (
    <>
      <div className={styles.container}>
        {employmentStatus.map((question) => {
          const id = `checkbox-${question.index}-${question.optionId}`;
          return (
            <label className={styles.label} htmlFor={id} key={question.optionId}>
              <RadioButton
                name="employmentStatus"
                id={id}
                value={question.optionId}
                checked={Number(employmentStatusValue) === question.optionId}
                onChange={() => handleEmploymentStatusChange(question.optionId)}
                className={styles.radio}
                hasError={!!employmentStatusErrorText}
              />
              {question.optionText}
            </label>
          );
        })}
      </div>
      {employmentStatusErrorText && <ErrorInformationText text={employmentStatusErrorText} />}
    </>
  );
};
