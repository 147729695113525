import { FC, ReactElement, ReactNode } from "react";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { Icon } from "@root/shared/components/Icon";
import { Colors } from "@root/utils/colors";
import * as styles from "./styles.css";

type Props = {
  isShow: boolean;
  headerTitle?: string;
  onClose?: () => void;
  children: ReactElement;
  footerContent?: ReactNode;
  canOutsideClick?: boolean;
  isBottomSheetMode: boolean;
  maxWidth?: string;
  isScrollable?: boolean;
};

const HEADER_HEIGHT = "52px";
const FOOTER_HEIGHT = "64px";
const CONTENT_HEIGHT = "100%";
const DEFAULT_MAX_WIDTH = "480px";
const CONTENT_INNER_HEIGHT = `calc(${CONTENT_HEIGHT} - ${HEADER_HEIGHT})`;
const CONTENT_INNER_HEIGHT_WITH_FOOTER = `calc(${CONTENT_HEIGHT} - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT})`;

const Modal: FC<Props> = ({
  isShow,
  headerTitle,
  onClose,
  children,
  footerContent,
  canOutsideClick = true,
  isBottomSheetMode = false,
  maxWidth = DEFAULT_MAX_WIDTH,
  isScrollable = false,
}) => {
  return isShow ? (
    <div
      className={styles.background}
      onClick={() => onClose && canOutsideClick && onClose()}
      aria-hidden="true"
    >
      <div
        className={`${isBottomSheetMode ? styles.wrapperBottomSheet : styles.wrapper} ${
          isScrollable ? styles.wrapperScroll : ""
        }`}
        style={assignInlineVars({
          [styles.maxWidth]: maxWidth,
        })}
        onClick={(e) => e.stopPropagation()}
        aria-hidden="true"
      >
        <div
          className={isBottomSheetMode ? styles.headerBottomSheet : styles.header}
          style={assignInlineVars({
            [styles.headerHeight]: HEADER_HEIGHT,
            [styles.headerTitlePosition]: headerTitle ? "space-between" : "right",
          })}
        >
          {headerTitle && <p className={styles.title}>{headerTitle}</p>}
          <button
            type="button"
            className={styles.closeButton}
            onClick={onClose}
            aria-label="モーダルを閉じる"
          >
            <Icon name="cross-thin" color={Colors.TEXT_MEDIUM} fontSize="18px" />
          </button>
        </div>
        <div
          className={isBottomSheetMode ? styles.contentBottomSheet : styles.content}
          style={assignInlineVars({
            [styles.contentHeight]: footerContent
              ? CONTENT_INNER_HEIGHT_WITH_FOOTER
              : CONTENT_INNER_HEIGHT,
          })}
        >
          {children}
        </div>
        {footerContent && (
          <div
            className={styles.footer}
            style={assignInlineVars({
              [styles.footerHeight]: isBottomSheetMode ? "auto" : FOOTER_HEIGHT,
            })}
          >
            {footerContent}
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export default Modal;
