import { FC } from "react";
import { Cassette } from "@root/shared/components/Cassette";
import { sendGaEvent } from "@root/utils/sendGaEvent";
import { LazyLoadImage } from "@root/shared/components/LazyLoadImage";
import { KeepForm } from "@userFrontend/features/publication/components/KeepForm";
import { SalaryAndShopLocation } from "./uiParts/SalaryAndShopLocation";
import * as styles from "./styles.css";

type Props = {
  gaAction: string;
  gaLabel: string;
  detailUrl: string;
  imgSrc: string;
  dummyImgPath: string;
  shopOrBrandName: string;
  businessTypeAndOccupation: string;
  title: string;
  salaries: {
    employmentTypeName: string;
    salaryTypeName: string;
    minPrice: string;
    minUnit: string;
    maxPrice: string;
    maxUnit: string;
  }[];
  shopLocation: string;
  workCode: string;
  hasKeeped: boolean;
  keepButtonUrl: string;
  keepGaAction: string;
  keepGaLabel: string;
};

export const SmallWorkCassette: FC<Props> = ({
  gaAction,
  gaLabel,
  detailUrl,
  shopOrBrandName,
  businessTypeAndOccupation,
  title,
  imgSrc,
  dummyImgPath,
  salaries,
  shopLocation,
  keepButtonUrl,
  workCode,
  hasKeeped,
  keepGaAction,
  keepGaLabel,
}) => {
  return (
    <Cassette>
      <div
        onClick={(e) => navigateToDetail(e, detailUrl)}
        onKeyPress={(e) => navigateToDetail(e, detailUrl)}
        role="button"
        tabIndex={0}
        className={styles.wrapper}
      >
        <a
          href={detailUrl}
          className={styles.linkWrapper}
          {...(gaAction && {
            onClick: () => {
              handleClick(gaAction, gaLabel);
            },
          })}
        >
          <p className={styles.shopAndOccupation}>
            {shopOrBrandName}|{businessTypeAndOccupation}
          </p>
          <div className={styles.imageAndSalaryWrapper}>
            <div className={styles.image}>
              <LazyLoadImage height={56} width={100} src={imgSrc} dummyImgPath={dummyImgPath} />
            </div>
            <SalaryAndShopLocation salaries={salaries} shopLocation={shopLocation} />
          </div>
          <p className={styles.title}>{title}</p>
        </a>
        <div className={styles.buttonWrapper}>
          <KeepForm
            url={keepButtonUrl}
            workCode={workCode}
            hasKeeped={hasKeeped}
            gaAction={keepGaAction}
            gaLabel={keepGaLabel}
          />
        </div>
      </div>
    </Cassette>
  );
};

const navigateToDetail = (e: React.BaseSyntheticEvent, detailUrl: string) => {
  if (e.target.tagName !== "BUTTON") {
    window.location.href = detailUrl;
  }
};

const handleClick = (gaAction: string, gaLabel?: string) =>
  sendGaEvent({ category: "Click", action: gaAction, label: gaLabel });
