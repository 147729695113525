import { FC, useState } from "react";
import { sendGaEvent } from "@root/utils/sendGaEvent";
import { navigateWithDeferredDeepLink } from "@userFrontend/utils/deferredDeepLinks";
import { InView } from "react-intersection-observer";
import { container } from "./styles.css";
import { Presentation } from "./Presentation";

type Props = {
  href: string;
  gaLabel: string;
  gaAction: string;
  isLogin: boolean;
  src: string;
  alt: string;
  dummyImgPath: string;
};

export const WebToAppBanner: FC<Props> = ({
  href,
  gaLabel,
  gaAction,
  isLogin,
  src,
  alt,
  dummyImgPath,
}) => {
  const [tapped, setTapped] = useState(false);
  const handleInView = (inView: boolean) => {
    if (inView) {
      sendGaEvent({ category: "Show", action: gaAction, label: gaLabel });
    }
  };

  const handleClick = () => {
    if (!tapped) {
      setTapped(true);
      navigateWithDeferredDeepLink({ isLogin, href });
      sendGaEvent({ category: "Click", action: gaAction, label: gaLabel });
    }
  };

  return (
    <InView onChange={handleInView} triggerOnce className={container}>
      <Presentation
        href={href}
        src={src}
        alt={alt}
        dummyImgPath={dummyImgPath}
        onClick={handleClick}
      />
    </InView>
  );
};
