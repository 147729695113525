import { FC } from "react";
import { ActionProps } from "@legacy_user_frontend/components/pages/jobs/job_search_navigation/src/search_modal/src/search_modal_props";
import { Lists } from "./ui_parts/lists";
import {
  StyledOuter,
  StyledParentOuter,
  StyledParentName,
  StyledChildLists,
} from "./src/styled_elements";

export type PrefectureModalProps = {
  prefectureContents: {
    name: string;
    prefecture: {
      name: string;
      url: string;
      value: string;
      showLink: boolean;
    }[];
  }[];
  dispatchFormState: React.Dispatch<ActionProps>;
  formState: Record<string, { url: string }>;
};

export const PrefectureModal: FC<PrefectureModalProps> = ({
  prefectureContents,
  dispatchFormState,
  formState,
}) => {
  const handleOnPrefectureStateChange = (e: React.ChangeEvent<HTMLInputElement>, url: string) => {
    dispatchFormState({
      type: "selectPref",
      value: e.target.value,
      url,
    });
  };
  return (
    <>
      {prefectureContents.map(({ name, prefecture }) => (
        <StyledOuter key={name}>
          <StyledParentOuter>
            <StyledParentName>{name}</StyledParentName>
          </StyledParentOuter>
          <dd>
            <StyledChildLists>
              <Lists
                contents={prefecture}
                formState={formState}
                checkBoxName="q[pref]"
                changeEvent={handleOnPrefectureStateChange}
                type="radio"
              />
            </StyledChildLists>
          </dd>
        </StyledOuter>
      ))}
    </>
  );
};
