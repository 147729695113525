import { FC } from "react";
import Modal from "@root/shared/components/Modal/legacy";
import { Button } from "@root/shared/components/Button";
import * as styles from "./styles.css";

type Props = {
  isShow: boolean;
  onClose: () => void;
  returnPath: string;
};

export const CancelModal: FC<Props> = ({ isShow, onClose, returnPath }) => (
  <Modal isShow={isShow} onClose={onClose} isBottomSheetMode={false} maxWidth="980px">
    <div className={styles.contents}>
      <p className={styles.title}>
        編集内容は
        <br />
        保存されていません
      </p>
      <div className={styles.buttonWrapper}>
        <Button theme="primary" onClick={onClose}>
          編集を続ける
        </Button>
      </div>
      <div className={styles.closeLink}>
        <a href={returnPath}>このページを離れる</a>
      </div>
    </div>
  </Modal>
);
