import { FC } from "react";
import { Button } from "@root/shared/components/Button";
import { Icon } from "@root/shared/components/Icon";
import { keepButton } from "./styles.css";

type Props = {
  hasKeeped: boolean;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

export const KeepButton: FC<Props> = ({ hasKeeped, className, onClick, ...rest }) => {
  return (
    <Button
      theme="secondary"
      className={`js-btn--keep disable-on-preview
      ${hasKeeped ? "is-keeped" : ""} ${keepButton} ${className}`}
      onClick={onClick}
      // data-style-typeは、2回目以降の押下時に、古いjQuery記述を使用してアイコン・文言をセットするために現状必要である。その仕組みをやめるリファクタを行えば、削除して構わない。
      data-style-type="new"
      {...rest}
    >
      {hasKeeped ? (
        <>
          <Icon name="keep-star" />
          解除
        </>
      ) : (
        <>
          <Icon name="keep-star-outline" />
          キープする
        </>
      )}
    </Button>
  );
};
