import { FetchJson } from "@root/utils/fetchJson";
import { csrfToken } from "@root/utils/axiosForRails";

/* eslint-disable camelcase */
declare const Routes: {
  user_login_status_user_frontend_ajax_deferred_deep_links_path: () => string;
  deferred_deep_link_token_user_frontend_ajax_deferred_deep_links_path: () => string;
  inflow_route_user_frontend_ajax_deferred_deep_links_path: () => string;
};

type Props = {
  isLogin: boolean;
  href: string;
};

type Data = Record<string, string>;

export const navigateWithDeferredDeepLink = async ({ isLogin, href }: Props): Promise<void> => {
  const fetchPath = createFetchPath(isLogin);
  const requestOptions = {
    authenticity_token: csrfToken(),
  };

  // カスタムパラメータに値を付加する
  await FetchJson<Data>(fetchPath, requestOptions, "POST")
    .then((res) => {
      if (res) {
        window.location.href = formatParams(href, res);
      }
    })
    .catch(() => {
      window.location.href = href;
    });
};

const isIOS = (userAgent: string) => /iP(hone|od|ad)/.test(userAgent);

const createFetchPath = (isLoggedIn: boolean) => {
  let result: string;
  if (isLoggedIn) {
    result = Routes.user_login_status_user_frontend_ajax_deferred_deep_links_path();
    // iOS端末か否か
  } else if (isIOS(navigator.userAgent)) {
    result = Routes.deferred_deep_link_token_user_frontend_ajax_deferred_deep_links_path();
  } else {
    result = Routes.inflow_route_user_frontend_ajax_deferred_deep_links_path();
  }
  return result;
};

const formatParams = (url: string, data: Data) => {
  // iOSの場合：全てのパラメータをdeep_link_valueに格納して渡す
  if (isIOS(navigator.userAgent)) {
    // 既に設定されているパラメータをdata(deep_link_value)に詰め直す (?install_path=XXX 等)
    const [href, params] = url.split("?");
    if (params) {
      const keyValuePairs = params.split("&");
      const newData: Record<string, string> = { ...data };
      keyValuePairs.forEach((pair) => {
        const [key, value] = pair.split("=");
        newData[key] = value;
      });
      return `${href}?deep_link_value=${encodeURIComponent(JSON.stringify(newData))}`;
    }
  }

  // Androidの場合：通常のクエリ形式で渡す
  let urlParams = "";
  Object.entries(data).forEach(([key, value]) => {
    urlParams += `&${key}=${value}`;
  });
  return url + urlParams;
};
