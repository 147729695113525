import { FC, useState } from "react";
import { Presentation } from "./Presentation";

type Props = {
  isBiyo: boolean;
  isLogin: boolean;
  isLineLink: boolean;
  isAppInstalled: boolean;
};

const generateUrl = (isBiyo: boolean, isLogin: boolean, type: "line" | "app"): string => {
  const lineBaseUrl = "https://autoline.link/rejob/apis/line_login_link?register_from=rejob_site";
  const appBaseUrl = isBiyo
    ? "https://relax-job.onelink.me/bIVn"
    : "https://relax-job-kaigo.onelink.me/NSr1";
  const suffix = isBiyo ? "__search_bottom" : "_care__search_bottom";

  if (type === "line") {
    return `${lineBaseUrl}${suffix}`;
  }

  if (isBiyo) {
    return `${appBaseUrl}/${isLogin ? "56hmoiqd" : "6f49hsb5"}`;
  }

  return `${appBaseUrl}/${isLogin ? "giotwhaf" : "2dtrwgim"}`;
};

const setGaLabel = (isLogin: boolean): string => {
  if (isLogin) {
    return "fix_login";
  }
  return "fix_not_login";
};

export const LineAppModal: FC<Props> = ({ isBiyo, isLogin, isLineLink, isAppInstalled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Presentation
      isShow={isOpen}
      onClose={handleClose}
      isLineLink={isLineLink}
      lineUrl={generateUrl(isBiyo, isLogin, "line")}
      isAppInstalled={isAppInstalled}
      appUrl={generateUrl(isBiyo, isLogin, "app")}
      onClick={handleClick}
      gaLabel={setGaLabel(isLogin)}
    />
  );
};
