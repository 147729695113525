import { FC } from "react";
import { Salaries, SalariesProps } from "@legacy_user_frontend/components/ui_parts/salaries";
import { StyledElements } from "./src/styled_elements";
import { WorkTimes, WorkTimesProps } from "./src/work_times";

type RequirementsProps = {
  requirements: {
    salaries: SalariesProps["salaries"] | [];
    workTimes: WorkTimesProps["workTimes"] | [];
  };
  className?: string;
};

export const SalaryAndWorktimeRequirements: FC<RequirementsProps> = ({
  requirements: { salaries, workTimes },
  className,
}) => (
  <StyledElements className={className}>
    <dl className="table">
      <dt>
        <span>
          <i className="icon-yen" />
          <span className="table-title">給与</span>
        </span>
      </dt>
      <dd>{salaries.length > 0 && <Salaries salaries={salaries} />}</dd>
      <dt>
        <span>
          <i className="icon-clock-1" />
          <span className="table-title">
            勤務
            <br />
            時間
          </span>
        </span>
      </dt>
      <dd>{workTimes.length > 0 && <WorkTimes workTimes={workTimes} />}</dd>
    </dl>
  </StyledElements>
);
