import { useState, useEffect, useRef } from "react";

type ReturnType = {
  files: File[];
  previewUrlArray: string[];
  fileInputRef: React.RefObject<HTMLInputElement>;
  isLoading: boolean;
  handleClickSelectButton: () => void;
  handleClickDeleteButton: (index: number) => void;
  handleChangeFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const useImageFileUploader = (): ReturnType => {
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [previewUrlArray, setPreviewUrlArray] = useState<string[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const newPreviewUrlArray = files.map((file) => URL.createObjectURL(file));
    setPreviewUrlArray(newPreviewUrlArray);

    return () => {
      newPreviewUrlArray.forEach((url) => URL.revokeObjectURL(url));
    };
  }, [files]);

  const addFiles = (selectedFiles: File[]) => {
    const newFiles = [...files];

    if (newFiles.length + selectedFiles.length >= 4) {
      alert("4枚以上は選択できません。");
      return;
    }

    selectedFiles.forEach((file) => {
      const fileSizeMB = file.size / 1024 / 1024;

      if (fileSizeMB >= 10) {
        alert("サイズが大きいため、アップロードできなかった画像がありました。");
      } else {
        newFiles.push(file);
      }
    });

    setFiles(newFiles);
  };

  const deleteFile = (index: number) => {
    const updatedFiles = [...files];
    const updatedPreviewUrlArray = [...previewUrlArray];
    updatedFiles.splice(index, 1);
    updatedPreviewUrlArray.splice(index, 1);

    setFiles(updatedFiles);
    setPreviewUrlArray(updatedPreviewUrlArray);
  };

  const handleClickSelectButton = () => {
    fileInputRef.current?.click();
  };

  const handleClickDeleteButton = (index: number) => {
    deleteFile(index);
  };

  const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);

    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files);
      // 画像選択直後だとアプリ側でalert()イベントが正常に発火しないため、1秒待ってから後続処理を実行
      setTimeout(() => {
        addFiles(selectedFiles);
        setIsLoading(false);
      }, 1000);
    }
  };

  return {
    files,
    previewUrlArray,
    fileInputRef,
    isLoading,
    handleClickSelectButton,
    handleClickDeleteButton,
    handleChangeFile,
  };
};
