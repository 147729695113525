/* eslint-disable camelcase */
import { FC } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { ExperiencesProps } from "@legacy_user_frontend/components/pages/mypage/profiles/credentials_edit/src/credentials_edit_props";
import { AdditionButton } from "@userFrontend/components/Buttons/AdditionButton";
import {
  StyledCassetteOuter,
  StyledCassetteButtonOuter,
  StyledErrorMessageOuter,
} from "@legacy_user_frontend/components/pages/mypage/profiles/credentials_edit/src/styled_elements";
import { ValidationErrorMessage } from "@legacy_user_frontend/components/ui_parts";
import { ExperienceCassette } from "./src/experience_casette";

export const Experiences: FC<ExperiencesProps> = ({
  experienceOptionsFetchPath,
  categorizedJobTypeList,
  experienceLists,
}) => {
  const { control, formState } = useFormContext();
  const { fields, remove, append } = useFieldArray({
    control,
    name: "form_user_frontend_user_update[experiences_attributes]",
  });
  const experiencesErrors = formState.errors?.form_user_frontend_user_update?.experiences;

  return (
    <>
      <StyledCassetteOuter>
        {fields.map(({ id }, index) => (
          <ExperienceCassette
            key={id}
            cassetteIndex={index}
            experienceOptionsFetchPath={experienceOptionsFetchPath}
            categorizedJobTypeList={categorizedJobTypeList}
            experienceList={experienceLists[index]}
            remove={remove}
          />
        ))}
      </StyledCassetteOuter>
      {experiencesErrors && (
        <StyledErrorMessageOuter>
          <ValidationErrorMessage message={experiencesErrors.message} />
        </StyledErrorMessageOuter>
      )}
      <StyledCassetteButtonOuter>
        <AdditionButton
          onClick={() => {
            append({
              job_type_id: "",
              job_type_experience_id: "",
            });
          }}
        >
          経験職種を追加する
        </AdditionButton>
      </StyledCassetteButtonOuter>
    </>
  );
};
