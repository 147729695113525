import { FC } from "react";
import { useStickyAnchorLinks, AnchorLinkProps } from "./useStickyAnchorLinks";
import { Presentation } from "./Presentation";

type Props = {
  anchorLinks: AnchorLinkProps[];
  className?: string;
  size?: "small" | "large";
};

export const StickyAnchorLinks: FC<Props> = ({
  anchorLinks: anchorLinkProps,
  className,
  size = "small",
}) => {
  const { anchorLinks, isFixed, anchorLinksRef } = useStickyAnchorLinks(anchorLinkProps, false);

  return (
    <Presentation
      anchorLinks={anchorLinks}
      isFixed={isFixed}
      anchorLinksRef={anchorLinksRef}
      anchorLinkLength={anchorLinks.length}
      className={className}
      size={size}
    />
  );
};
