import { FC, lazy, Suspense, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { RequiredLabel, ValidationErrorMessage } from "@legacy_user_frontend/components/ui_parts";
import { TextButton } from "@root/shared/components/TextButton";
import {
  StyledList,
  StyledErrorMessageOuter,
  StyledLabel,
  StyledModalContent,
  StyledModalPrimaryButtonOuter,
  StyledModalTextLinkOuter,
  StyledModalTitle,
} from "@legacy_user_frontend/components/pages/mypage/profiles/user_career_edit/src/styled_elements";
import {
  JobTypeObjectProps,
  JobTypeProps,
} from "@legacy_user_frontend/components/pages/mypage/profiles/user_career_edit/src/user_career_edit_props";
import { Button } from "@root/shared/components/Button";
import styled from "styled-components";
import { ChoiceChip } from "@root/shared/components/ChoiceChip/legacy";
import { RadioButton } from "@root/shared/components/RadioButton";

const Modal = lazy(() => import("@root/shared/components/Modal/legacy"));

export const JobType: FC<JobTypeProps> = ({
  isBiyo,
  careerIndex,
  selectedJobTypeCategoryId,
  selectedJobTypeId,
  setSelectedJobTypeId,
  setSelectedJobTypeCategoryId,
  jobTypeCategoryList,
  setFacilityTypesList,
  setDescriptionPlaceholder,
  removeFacilityType,
  isRemoved,
  careJobTypeCategoryId,
}) => {
  const { formState, setValue, clearErrors } = useFormContext();
  const formName = `user_careers_update[user_careers_attributes[${careerIndex}]]`;
  const validateErrors = formState.errors.user_careers_update?.user_careers_attributes;

  // 職種・業種変更確認モーダルに必要な、選択した内容をuseStateで管理する
  const [willChangeJobTypeCategoryId, setWillChangeJobTypeCategoryId] = useState<
    number | string | null
  >(null);
  const [willChangeJobTypeId, setWillChangeJobTypeId] = useState<number | string | null>(null);
  const selectedJobTypeCategory = jobTypeCategoryList.find(
    (jobTypeCategory) => `${jobTypeCategory.value}` === `${selectedJobTypeCategoryId}`,
  );
  const [jobTypeList, setJobTypeList] = useState<JobTypeObjectProps[] | undefined>(
    selectedJobTypeCategory?.jobTypes,
  );

  // モーダルの開閉
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => setIsModalOpen(false);

  // 業種の変更に合わせて必要な値を変更する
  const setJobTypeCategoryAndJobType = (
    categoryId: number | string,
    jobTypeId: number | string | null,
  ) => {
    const selectedJobTypeCategoryList = jobTypeCategoryList.find(
      (jobTypeCategory) => `${jobTypeCategory.value}` === `${categoryId}`,
    );

    // 変更のフォームへの反映
    setValue(`${formName}[job_type_category_id]`, categoryId);
    setValue(`${formName}[job_type_id]`, jobTypeId);
    // ラジオボタンへの反映
    setJobTypeList(selectedJobTypeCategoryList?.jobTypes);
    setSelectedJobTypeCategoryId(categoryId);
    setSelectedJobTypeId(jobTypeId);
    // 施設形態リストの指定
    setFacilityTypesList(selectedJobTypeCategoryList?.facilityTypes);
    // 職務内容placeholderの指定
    if (selectedJobTypeCategoryList) {
      selectedJobTypeCategoryList.jobTypes.forEach((jobType) => {
        if (jobType.value === jobTypeId) {
          setDescriptionPlaceholder(jobType.descriptionPlaceholder);
        }
      });
    }

    // エラーメッセージの削除
    clearErrors(`${formName}[job_type_id]`);

    // 入力内容のリセットと、施設形態のfieldArrayを削除
    setValue(`${formName}[clientele]`, "");
    setValue(`${formName}[average_number_of_customers]`, "");
    setValue(`${formName}[average_percentage_of_new_customers]`, "");
    setValue(`${formName}[average_nomination_ratio]`, "");
    setValue(`${formName}[average_customer_spending]`, "");
    setValue(`${formName}[average_personal_sales]`, "");
    setValue(`${formName}[user_career_work_patterns][]`, "");
    setValue(`${formName}[description]`, "");
    removeFacilityType();
  };

  // 職種・業種の選択を反映し、入力内容をリセットする
  const reflectChanges = () => {
    if (!willChangeJobTypeCategoryId) {
      // 業種が未選択の状態で確認モーダルが出ることはない
      // setJobTypeCategoryAndJobTypeの型チェックエラー回避のための早期リターン
      return;
    }
    setJobTypeCategoryAndJobType(willChangeJobTypeCategoryId, willChangeJobTypeId || null);
    closeModal();
  };

  // 職種の変更
  const handleOnChangeJobTypeId = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const valueNum = Number(value);
    if (!selectedJobTypeCategoryId) {
      // 業種が未選択の状態で職種が選択されることはない
      // setJobTypeCategoryAndJobTypeの型チェックエラー回避のための早期リターン
      return;
    }
    if (selectedJobTypeId) {
      // 新規選択でない場合は確認モーダルを表示する
      if (!willChangeJobTypeCategoryId) {
        setWillChangeJobTypeCategoryId(selectedJobTypeCategoryId);
      }
      setWillChangeJobTypeId(valueNum);
      openModal();
    } else {
      // 新規選択の場合はモーダルを出さずに選択を反映する
      setJobTypeCategoryAndJobType(selectedJobTypeCategoryId, valueNum);
    }
  };

  // 業種の変更
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const valueNum = Number(value);
    if (selectedJobTypeCategoryId) {
      // 業種を選択済みの場合は確認モーダルを表示する
      setWillChangeJobTypeCategoryId(valueNum);
      setWillChangeJobTypeId(null);
      openModal();
    } else {
      // 新規選択の場合はモーダルを出さずに選択
      setSelectedJobTypeCategoryId(valueNum);
      setValue(`${formName}[job_type_category_id]`, valueNum);
      jobTypeCategoryList.forEach((jobTypeCategories) => {
        if (jobTypeCategories.value === valueNum) {
          setJobTypeList(jobTypeCategories.jobTypes);
        }
      });
    }
  };

  // ケアで職務経歴を未入力の場合、介護を選択済みで表示する
  useEffect(() => {
    if (!isBiyo && !selectedJobTypeId && !isRemoved && careerIndex === 0) {
      setSelectedJobTypeCategoryId(careJobTypeCategoryId);
      setValue(`${formName}[job_type_category_id]`, careJobTypeCategoryId);
      setJobTypeList(jobTypeCategoryList[0].jobTypes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBiyo, selectedJobTypeId]);

  return (
    <>
      <dt>
        業種
        <span className="required-label-outer">
          <RequiredLabel />
        </span>
      </dt>
      <dd>
        <StyledList>
          {jobTypeCategoryList.map(({ key, value }) => {
            const id = `user_careers_update_user_careers_attributes_${careerIndex}__job_type_category_id_${value}`;
            return (
              <li key={key}>
                <ChoiceChip
                  name={`${formName}[job_type_category_id]`}
                  labelName={key}
                  value={`${value}`}
                  checked={`${value}` === `${selectedJobTypeCategoryId}`}
                  onChange={handleOnChange}
                  id={id}
                  type="radio"
                />
              </li>
            );
          })}
        </StyledList>
        {validateErrors && validateErrors[careerIndex]?.job_type_category_id?.message && (
          <StyledErrorMessageOuter>
            <ValidationErrorMessage
              message={validateErrors[careerIndex].job_type_category_id.message}
            />
          </StyledErrorMessageOuter>
        )}
      </dd>
      {selectedJobTypeCategoryId && (
        <>
          <dt>
            職種
            <span className="required-label-outer">
              <RequiredLabel />
            </span>
          </dt>
          <dd>
            <StyledList>
              {jobTypeList &&
                jobTypeList.map(({ key, value }) => {
                  const id = `user_careers_update_user_careers_attributes_${careerIndex}__job_type_id_${value}`;
                  const careerErrors = validateErrors ? validateErrors[careerIndex] : null;
                  return (
                    <li key={key}>
                      <RadioButton
                        name={`${formName}[job_type_id]`}
                        value={`${value}`}
                        checked={`${value}` === `${selectedJobTypeId}`}
                        hasError={!!careerErrors?.job_type_id?.message}
                        onChange={handleOnChangeJobTypeId}
                        id={id}
                      />
                      <StyledLabel htmlFor={id}>{key}</StyledLabel>
                    </li>
                  );
                })}
            </StyledList>
            {validateErrors && validateErrors[careerIndex]?.job_type_id?.message && (
              <StyledErrorMessageOuter>
                <ValidationErrorMessage message={validateErrors[careerIndex].job_type_id.message} />
              </StyledErrorMessageOuter>
            )}
          </dd>
        </>
      )}
      <Suspense fallback={null}>
        {isModalOpen && (
          <Modal isShow={isModalOpen} onClose={closeModal} isBottomSheetMode={false}>
            <StyledModalContent>
              <StyledModalTitle>
                変更すると[施設形態]から下の
                <br />
                入力情報が削除されてしまいます。
                <br />
                変更してよろしいですか？
              </StyledModalTitle>
              <StyledModalPrimaryButtonOuter>
                <ButtonWrapper>
                  <Button theme="primary" onClick={reflectChanges}>
                    変更する
                  </Button>
                </ButtonWrapper>
              </StyledModalPrimaryButtonOuter>
              <StyledModalTextLinkOuter>
                <TextButton type="button" onClick={closeModal} size="large" isBold>
                  変更しない
                </TextButton>
              </StyledModalTextLinkOuter>
            </StyledModalContent>
          </Modal>
        )}
      </Suspense>
    </>
  );
};

const ButtonWrapper = styled.div`
  height: 48px;
`;
