import { FC, ComponentProps } from "react";
import styled from "styled-components";
import { TextButton } from "@root/shared/components/TextButton";

type ButtonProps = ComponentProps<typeof TextButton>;

const StyledButton = styled(TextButton)`
  display: inline-flex;
  align-items: center;

  &::after {
    content: "";
    display: inline-block;
    width: 13px;
    height: 12px;
    margin-left: 4px;
    background-image: url("/images/user_frontend/global/icon/icn_trashbox_blue.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

export const DeleteTextLinkButton: FC<ButtonProps> = ({
  type,
  className,
  onClick,
  children,
  size,
}) => (
  <StyledButton type={type} onClick={onClick} className={className} size={size}>
    {children}
  </StyledButton>
);
