import { FC } from "react";
import { Icon } from "@root/shared/components/Icon";
import * as styles from "./styles.css";

type Props = {
  salaries: Salary[];
  shopLocation: string;
};

type Salary = {
  employmentTypeName: string;
  salaryTypeName: string;
  minPrice: string;
  minUnit: string;
  maxPrice: string;
  maxUnit: string;
};

export const SalaryAndShopLocation: FC<Props> = ({ salaries, shopLocation }) => {
  return (
    <dl className={styles.list}>
      <dt className={styles.term}>
        <Icon name="yen" fontSize="16px" />
      </dt>
      <dd className={styles.description}>{salaries && <Salary {...salaries[0]} />}</dd>
      {shopLocation && (
        <>
          <dt className={styles.term}>
            <Icon name="location" fontSize="16px" />
          </dt>
          <dd className={styles.description}>
            <p>{shopLocation}</p>
          </dd>
        </>
      )}
    </dl>
  );
};

const Salary: FC<Salary> = ({
  employmentTypeName,
  salaryTypeName,
  minPrice,
  minUnit,
  maxPrice,
  maxUnit,
}) => {
  return (
    <p>
      {employmentTypeName} / {salaryTypeName}
      {(minPrice || maxPrice) && (
        <>
          <b>{minPrice}</b>
          {minUnit} 〜 <b>{maxPrice}</b>
          {maxUnit}
        </>
      )}
    </p>
  );
};
