import { useLayoutEffect, useState } from "react";

type WindowSizeInfo = {
  width: number;
  isSP: boolean;
};

export const useDetectWindowSizeInfo = (): WindowSizeInfo => {
  const [windowSizeInfo, setWindowSizeInfo] = useState({
    width: window.innerWidth,
    isSP: window.innerWidth < 767,
  });

  useLayoutEffect(() => {
    const updateSize = (): void => {
      setWindowSizeInfo({
        width: window.innerWidth,
        isSP: window.innerWidth < 767,
      });
    };

    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return windowSizeInfo;
};
