import { FC, ReactElement } from "react";
import { Icon } from "@root/shared/components/Icon";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import * as styles from "./styles.css";

type Props = {
  title: string;
  children: ReactElement;
  accordionTheme?: "default" | "panel";
  isOpened: boolean;
  onClick?: () => void;
};

export const Accordion: FC<Props> = ({
  title,
  children,
  accordionTheme = "default",
  isOpened,
  onClick,
}) => {
  const accordionThemeWithOpenedSuffix: "defaultIsOpened" | "panelIsOpened" =
    `${accordionTheme}IsOpened`;

  return (
    <div className={styles.wrapper[accordionTheme]}>
      <div className={styles.title[accordionTheme]}>
        <button
          className={styles.button[isOpened ? accordionThemeWithOpenedSuffix : accordionTheme]}
          type="button"
          onClick={onClick}
        >
          {title}
          <div
            className={
              styles.iconWrapper[isOpened ? accordionThemeWithOpenedSuffix : accordionTheme]
            }
          >
            <Icon name="arrow-light-down" fontSize="22px" />
          </div>
        </button>
      </div>
      <div
        className={styles.contentsWrapper[accordionTheme]}
        style={assignInlineVars({
          [styles.contentDisplay]: isOpened ? "block" : "none",
        })}
        aria-expanded={isOpened}
        aria-label={`${title} コンテンツ`}
      >
        {children}
      </div>
    </div>
  );
};
