import styled from "styled-components";
import { Breakpoints, Colors } from "@legacy_user_frontend/utils";

export const StyledHeading = styled.p`
  font-size: 12px;
  font-weight: bold;
  padding-bottom: 6px;
  color: ${Colors.DEEP_BLACK};
`;

export const StyledListOuter = styled.div`
  margin: 0 -10px -10px;
  padding: 0 10px 10px;
  overflow-x: auto;

  ${Breakpoints.MEDIUM} {
    overflow-x: initial;
  }

  // PC chromeでSP表示した際にスクロールバーが表示されてしまうので非表示にする
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledList = styled.ul`
  display: flex;
  margin: 0 -4px;

  ${Breakpoints.MEDIUM} {
    flex-wrap: wrap;
    margin-top: -3px;
    margin-bottom: -3px;
  }

  > li {
    flex-shrink: 0;
    padding: 0 4px;

    ${Breakpoints.MEDIUM} {
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }
`;

export const StyledButton = styled.a`
  display: flex;
  align-items: center;
  height: 38px;
  padding: 12px;
  border: 1px solid ${Colors.MEDIUM_GREY};
  border-radius: 19px;
  background-color: ${Colors.WHITE};
  color: ${Colors.LINK};
  font-weight: bold;
  line-height: 1;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    opacity: 0.7;
    padding-top: 11px;
    padding-bottom: 11px;
  }
`;

export const StyledTotalNum = styled.span`
  padding-left: 4px;
  font-weight: normal;
`;
