import { FC } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import { Colors } from "@legacy_user_frontend/utils";
import { Button } from "@root/shared/components/Button";

type Props = {
  handleOnClick: () => void;
  appOsType?: number | null;
};

export const SubmitAndCancelButtons: FC<Props> = ({ handleOnClick, appOsType }) => {
  const { ref, inView } = useInView({
    threshold: 1,
  });

  return (
    <StyledSubmitButtonOuter ref={ref} isSticky={!inView && appOsType !== 2}>
      <div className="sticky-wrapper">
        <div className="sticky-wrapper-inner">
          <ButtonWrapper>
            <Button type="button" theme="secondary" onClick={handleOnClick}>
              キャンセル
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button type="submit" theme="primary">
              保存する
            </Button>
          </ButtonWrapper>
        </div>
      </div>
    </StyledSubmitButtonOuter>
  );
};

const StyledSubmitButtonOuter = styled.div<{ isSticky: boolean }>`
  min-height: 80px;
  margin-top: 16px;

  .sticky-wrapper {
    width: 100%;
    bottom: 0;
    padding: 16px;
    position: ${(props) => (props.isSticky ? "fixed" : "static")};
    box-shadow: 0px -2px 2px ${(props) => (props.isSticky ? "rgba(0, 0, 0, 0.25)" : "rgba(0, 0, 0, 0)")};
    background-color: ${(props) => (props.isSticky ? Colors.WHITE : "transparent")};
    transition:
      background-color 0.2s,
      box-shadow 0.2s;
    z-index: 3;

    .is-pc & {
      position: static;
      box-shadow: none;
      background-color: transparent;
    }
  }

  .sticky-wrapper-inner {
    display: flex;
    gap: 16px;
    max-width: 343px;
    margin: 0 auto;

    > button {
      flex-basis: 50%;
    }
  }
`;

const ButtonWrapper = styled.div`
  height: 48px;
  flex-basis: 50%;
`;
