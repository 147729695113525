/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { BoxWithShadow } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/box_with_shadow";
import { DefinitionList } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/definition_list";
import { SubmitAndCancelButtons } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/submit_and_cancel_buttons";
import {
  RequiredLabel,
  TextArea,
  DateSelector,
  ValidationErrorMessage,
} from "@legacy_user_frontend/components/ui_parts";
import styled from "styled-components";
import { showAlertBeforeBrowserBack } from "@legacy_root/user_frontend/components/pages/mypage/profiles/utils/show_alert_before_browser_back";
import { Checkbox } from "@root/shared/components/Checkbox";
import { CancelModal } from "@userFrontend/features/userInfo/components/CancelModal";
import { toSnakeCase } from "@root/utils";
import { AvailableStartDateEditProps, InputProps } from "./src/available_start_date_edit_props";

const StyledCheckboxOuter = styled.div`
  margin-top: 24px;

  label {
    margin-left: 8px;
  }
`;

const StyledErrorMessageOuter = styled.div`
  margin-top: 10px;
`;

export const AvailableStartDateEdit: FC<AvailableStartDateEditProps> = ({
  action,
  year,
  month,
  day,
  note,
  yearList,
  monthList,
  dayList,
  isUndecided,
  errors,
  returnPath,
  appOsType,
}) => {
  // 初期値の設定
  const defaultValues = useMemo(
    () => ({
      year,
      month,
      day,
      note,
      undecided: isUndecided,
    }),
    [day, month, note, year, isUndecided],
  );
  const methods = useForm<InputProps>({
    defaultValues: {
      authenticity_token: "",
      _method: "put",
      form_user_frontend_available_start_date_update: defaultValues,
    },
  });

  // サーバー側から渡ってきたエラーメッセージを項目に反映する
  const { setError, clearErrors } = methods;
  useEffect(() => {
    const setErrorMessage = (key: string) => {
      const snakeCaseKey = toSnakeCase(key);
      const errorFormName =
        `form_user_frontend_available_start_date_update.${snakeCaseKey}` as keyof InputProps;
      setError(errorFormName, {
        type: "manual",
        message: errors[key],
      });
    };
    Object.keys(errors).map((key) => setErrorMessage(key));
  }, [errors, setError]);
  const validateErrors = methods.formState.errors;
  const yearError = validateErrors.form_user_frontend_available_start_date_update?.year;
  const monthError = validateErrors.form_user_frontend_available_start_date_update?.month;
  const dayError = validateErrors.form_user_frontend_available_start_date_update?.day;
  const noteError = validateErrors.form_user_frontend_available_start_date_update?.note;

  // 反映されたサーバー側エラーのクリア
  const clearStartDateError = () => {
    clearErrors("form_user_frontend_available_start_date_update.available_start_date");
  };

  // 変更の検出
  const watchedValues = methods.watch("form_user_frontend_available_start_date_update");
  const [isChanged, setIsChanged] = useState(false);
  useEffect(() => {
    if (JSON.stringify(watchedValues) !== JSON.stringify(defaultValues)) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [defaultValues, watchedValues]);

  // キャンセルボタン押下時にモーダルを表示する
  const [isShowAlert, setIsShowAlert] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    if (isChanged) {
      setIsModalOpen(true);
      setIsShowAlert(false);
      return;
    }
    window.location.href = returnPath;
  };
  const closeModal = () => {
    setIsShowAlert(true);
    setIsModalOpen(false);
  };

  // ブラウザバックを検知して確認モーダルを表示する
  const ua = window.navigator.userAgent.toLowerCase();
  useEffect(() => {
    // 変更がない場合のほか、safariとiOSの場合（画面遷移後の動作に影響があるためブラウザバックを許容）は処理を抜ける
    if (
      ua.indexOf("iphone") > 0 ||
      ua.indexOf("ipod") > 0 ||
      (ua.indexOf("chrome") === -1 && ua.indexOf("safari") !== -1) ||
      !isChanged
    ) {
      return;
    }
    showAlertBeforeBrowserBack({ setIsShowAlert, setIsModalOpen });
  }, [isChanged, ua]);

  // 画面遷移を検知して確認アラートを表示する
  useEffect(() => {
    const showAlert = (event: BeforeUnloadEvent) => {
      if (!isChanged || !isShowAlert) {
        return;
      }
      // eslint-disable-next-line no-param-reassign
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", showAlert);
    return () => window.removeEventListener("beforeunload", showAlert);
  }, [isChanged, isShowAlert]);

  // 送信処理
  const formEl = useRef<HTMLFormElement>(null);
  const onSubmit: SubmitHandler<InputProps> = (data) => {
    const yearValue = data.form_user_frontend_available_start_date_update.year;
    const monthValue = data.form_user_frontend_available_start_date_update.month;
    const dayValue = data.form_user_frontend_available_start_date_update.day;
    setIsShowAlert(false);
    // 勤務開始可能日の年月日のうち、1つ以上選択しているが、選択漏れがある場合はバリデーションエラーになる
    if ((yearValue || monthValue || dayValue) && !(yearValue && monthValue && dayValue)) {
      if (!yearValue) {
        setError("form_user_frontend_available_start_date_update.year", {
          type: "require",
          message: "勤務開始可能日（年）を入力してください。",
        });
      }
      if (!monthValue) {
        setError("form_user_frontend_available_start_date_update.month", {
          type: "require",
          message: "勤務開始可能日（月）を入力してください。",
        });
      }
      if (!dayValue) {
        setError("form_user_frontend_available_start_date_update.day", {
          type: "require",
          message: "勤務開始可能日（日）を入力してください。",
        });
      }
      return;
    }
    formEl.current?.submit();
  };

  return (
    <>
      <FormProvider {...methods}>
        <form ref={formEl} action={action} onSubmit={methods.handleSubmit(onSubmit)} method="post">
          <input type="hidden" {...methods.register("authenticity_token")} />
          <input type="hidden" {...methods.register("_method")} />
          <BoxWithShadow>
            <DefinitionList>
              <dt>
                勤務開始可能日
                <span className="required-label-outer">
                  <RequiredLabel />
                </span>
              </dt>
              <dd>
                <DateSelector
                  yearList={yearList}
                  monthList={monthList}
                  dateList={dayList}
                  yearName="form_user_frontend_available_start_date_update[year]"
                  monthName="form_user_frontend_available_start_date_update[month]"
                  dateName="form_user_frontend_available_start_date_update[day]"
                  hasYearError={!!yearError}
                  hasMonthError={!!monthError}
                  hasDateError={!!dayError}
                  handleOnChange={clearStartDateError}
                />
                {validateErrors.form_user_frontend_available_start_date_update?.available_start_date
                  ?.message && (
                  <StyledErrorMessageOuter>
                    <ValidationErrorMessage
                      message={
                        validateErrors.form_user_frontend_available_start_date_update
                          .available_start_date.message
                      }
                    />
                  </StyledErrorMessageOuter>
                )}
                {yearError?.message && (
                  <StyledErrorMessageOuter>
                    <ValidationErrorMessage message={yearError.message} />
                  </StyledErrorMessageOuter>
                )}
                {monthError?.message && (
                  <StyledErrorMessageOuter>
                    <ValidationErrorMessage message={monthError.message} />
                  </StyledErrorMessageOuter>
                )}
                {dayError?.message && (
                  <StyledErrorMessageOuter>
                    <ValidationErrorMessage message={dayError.message} />
                  </StyledErrorMessageOuter>
                )}
                <StyledCheckboxOuter>
                  <input
                    name="form_user_frontend_available_start_date_update[undecided]"
                    type="hidden"
                    value="0"
                  />
                  <Checkbox
                    name="form_user_frontend_available_start_date_update[undecided]"
                    value="1"
                    id="form_user_frontend_available_start_date_update_undecided"
                  />
                  <label htmlFor="form_user_frontend_available_start_date_update_undecided">
                    未定
                  </label>
                </StyledCheckboxOuter>
              </dd>
              <dt>備考</dt>
              <dd>
                <TextArea
                  name="form_user_frontend_available_start_date_update[note]"
                  placeholder=""
                />
                {noteError?.message && (
                  <StyledErrorMessageOuter>
                    <ValidationErrorMessage message={noteError.message} />
                  </StyledErrorMessageOuter>
                )}
              </dd>
            </DefinitionList>
          </BoxWithShadow>
          <SubmitAndCancelButtons handleOnClick={openModal} appOsType={appOsType} />
        </form>
      </FormProvider>
      <CancelModal isShow={isModalOpen} onClose={closeModal} returnPath={returnPath} />
    </>
  );
};
