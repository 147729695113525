import Modal from "@root/shared/components/Modal/legacy";
import { SectionTitle } from "@userFrontend/features/clientReview/components/ClientReviewInputs/uiParts/SectionTitle";
import { ComponentProps, FC, Fragment } from "react";
import { HelpTextBox } from "@userFrontend/features/clientReview/components/ClientReviewInputs/uiParts/HelpTextBox";
import { SearchForm } from "@userFrontend/features/clientReview/components/ClientReviewInputs/uiParts/SearchForm";
import { SearchResultBox } from "@userFrontend/features/clientReview/components/ClientReviewInputs/uiParts/SearchResultBox";
import { SearchResultText } from "@userFrontend/features/clientReview/components/ClientReviewInputs/uiParts/SearchResultsText";
import { SiteId } from "@root/shared/types/type";
import { SearchResult } from "@userFrontend/features/clientReview/components/ClientReviewInputs/type";
import * as styles from "./styles.css";

type Props = {
  isShowSearchModal: boolean;
  handleCloseModal: () => void;
  searchResults?: SearchResult[];
  handleClientSelect: (selectedSiteId: SiteId, clientId: number, clientName: string) => void;
  isShowClientsDetail: { clientId: number; isShowShopName: boolean }[];
  handleShowTextButton: (clientId: number) => void;
  searchResultErrorText?: string;
  displaySearchText: boolean;
  isSearching: boolean;
  handleReturnToMyPageTop: () => void;
  userContactPath: string;
} & ComponentProps<typeof SearchForm>;

export const SearchModal: FC<Props> = ({
  isShowSearchModal,
  handleCloseModal,
  clientNameValue,
  shopNameValue,
  clientNameErrorText,
  shopNameErrorText,
  handleClientSearch,
  handleChangeClientNameValue,
  handleChangeShopNameValue,
  searchResults,
  handleClientSelect,
  isShowClientsDetail,
  handleShowTextButton,
  searchResultErrorText,
  displaySearchText,
  isSearching,
  handleReturnToMyPageTop,
  userContactPath,
}) => {
  return (
    <Modal
      isShow={isShowSearchModal}
      isBottomSheetMode
      headerTitle="投稿先の選択"
      onClose={handleCloseModal}
    >
      <>
        <div className={styles.title}>投稿先企業の検索</div>
        <SearchForm
          clientNameValue={clientNameValue}
          shopNameValue={shopNameValue}
          clientNameErrorText={clientNameErrorText}
          shopNameErrorText={shopNameErrorText}
          handleClientSearch={handleClientSearch}
          handleChangeClientNameValue={handleChangeClientNameValue}
          handleChangeShopNameValue={handleChangeShopNameValue}
        />
        <div className={styles.sectionTitleWrapper}>
          <SectionTitle text="検索結果" />
        </div>
        {searchResults && (
          <div className={styles.searchResultBoxWrapper}>
            {searchResults.map((searchResult) => (
              <Fragment key={searchResult.clientName}>
                <SearchResultBox
                  clientName={searchResult.clientName}
                  clientShopName={searchResult.clientShopName}
                  onClickSelect={() =>
                    handleClientSelect(
                      searchResult.siteId,
                      searchResult.clientId,
                      searchResult.clientName,
                    )
                  }
                  isShowShopName={
                    isShowClientsDetail.filter(
                      ({ clientId }) => clientId === searchResult.clientId,
                    )?.[0]?.isShowShopName
                  }
                  onClickShowTextButton={() => handleShowTextButton(searchResult.clientId)}
                />
                <div className={styles.underLine} />
              </Fragment>
            ))}
          </div>
        )}
        <SearchResultText
          errorText={searchResultErrorText}
          displaySearchText={displaySearchText}
          isSearching={isSearching}
        />
        <div className={styles.helpTextBoxWrapper}>
          <HelpTextBox handleOnClick={handleReturnToMyPageTop} href={userContactPath} />
        </div>
      </>
    </Modal>
  );
};
