import { FC } from "react";
import { USER_REWARD_NAME } from "@root/utils/constants";
import * as styles from "./styles.css";

type Props = {
  recruitmentRewardPrice: string;
  keepWorkRewardPrice: string;
};

export const OiwaiInformationBox: FC<Props> = ({ recruitmentRewardPrice, keepWorkRewardPrice }) => {
  return (
    <div className={styles.container}>
      <p className={styles.heading}>{`対象者全員に${USER_REWARD_NAME}贈呈！`}</p>
      <p className={styles.text}>
        採用が決まったら最大<span className={styles.highLightText}>{recruitmentRewardPrice}円</span>
        贈呈
        <br />
        さらに勤務し続けて最大<span className={styles.highLightText}>{keepWorkRewardPrice}円</span>
        贈呈
      </p>
    </div>
  );
};
