export const isElementInArea = ({
  topElement,
  bottomElement,
  topMargin = 0,
  bottomMargin = 0,
}: {
  topElement: Element;
  bottomElement: Element;
  topMargin?: number;
  bottomMargin?: number;
}): boolean => {
  const topPosition = Math.floor(topElement.getBoundingClientRect().top);
  const bottomPosition = Math.floor(bottomElement.getBoundingClientRect().bottom);
  return topPosition <= topMargin && bottomPosition >= bottomMargin;
};
