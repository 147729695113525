import { FC } from "react";
import {
  StyledTableList,
  StyledInnerCassetteList,
  StyledInnerCassette,
  StyledInnerCassetteHeadingOuter,
  StyledInnerCassetteHeading,
  StyledInnerCassetteContent,
} from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_show/src/styled_elements";
import { SchoolLogCassetteProps } from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_show/src/profile_show_props";
import { EditPageLink } from "./edit_page_link";

export const SchoolLogCassette: FC<SchoolLogCassetteProps> = ({
  schoolLog: { schools, schoolsNote },
  editPath,
}) => (
  <>
    {schools.length > 0 && (
      <StyledInnerCassetteList>
        {schools.map(
          ({ schoolName, course, graduatedYear, graduatedMonth, isWillGraduate }, index) => (
            <li key={schoolName}>
              <StyledInnerCassette>
                <StyledInnerCassetteHeadingOuter>
                  <StyledInnerCassetteHeading>
                    <span>学歴{index + 1}</span>
                    <EditPageLink url={`${editPath}#cassette-${index}`} />
                  </StyledInnerCassetteHeading>
                </StyledInnerCassetteHeadingOuter>
                <StyledInnerCassetteContent>
                  <StyledTableList>
                    <dt>学校名</dt>
                    <dd>{schoolName}</dd>
                    {course && (
                      <>
                        <dt>学部・学科・コース</dt>
                        <dd>{course}</dd>
                      </>
                    )}
                    <dt>卒業年</dt>
                    <dd>
                      {graduatedYear}年{graduatedMonth && ` ${graduatedMonth}月`}
                      {isWillGraduate && <> 卒業予定</>}
                    </dd>
                  </StyledTableList>
                </StyledInnerCassetteContent>
              </StyledInnerCassette>
            </li>
          ),
        )}
      </StyledInnerCassetteList>
    )}
    {schoolsNote && <>{schoolsNote}</>}
  </>
);
