import styled from "styled-components";

export const StyledList = styled.ul`
  list-style-type: none;
  margin-top: 24px;

  > li + li {
    margin-top: 24px;
  }
`;

export const StyledLabel = styled.label`
  margin-left: 8px;
  cursor: pointer;
`;

export const StyledOtherTimeRangeHeading = styled.p`
  margin-top: 24px;
  padding-bottom: 8px;
`;

export const StyledOtherTimeRangeSelectOuter = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  > div:nth-child(2n) {
    flex-basis: 12px;
    flex-shrink: 0;
    text-align: center;
  }

  > div:nth-child(2n + 1) {
    flex-basis: 50%;
  }
`;

export const StyledErrorMessageOuter = styled.div`
  margin-top: 8px;
`;
