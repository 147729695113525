import { FC, ChangeEvent } from "react";
import { useFormContext } from "react-hook-form";
import { Presentation } from "./Presentation";

type Props = {
  name: string;
  defaultValue: string | null;
  requiredMessage?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  items: {
    id: string;
    value: string;
    text: string;
  }[];
};

export const ChoiceChipList: FC<Props> = ({
  name,
  defaultValue,
  requiredMessage,
  onChange,
  items,
}) => {
  const { register, clearErrors } = useFormContext();
  const deleteErrorMessage = () => {
    clearErrors(name);
  };

  const inputRegistration = register(name, {
    required: requiredMessage,
    onChange: (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(e);
      deleteErrorMessage();
    },
  });

  return (
    <Presentation items={items} defaultValue={defaultValue} inputRegistration={inputRegistration} />
  );
};
