import { ComponentProps, FC } from "react";
import { CassetteSliderSectionList } from "@userFrontend/components/CassetteSliderSectionList";
import * as styles from "./styles.css";

type Props = {
  sections: ComponentProps<typeof CassetteSliderSectionList>["sections"];
};

export const Presentation: FC<Props> = ({ sections }) => {
  return (
    <aside className={styles.container}>
      <CassetteSliderSectionList sections={sections} />
    </aside>
  );
};
