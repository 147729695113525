import { FC } from "react";
import { Icon } from "@root/shared/components/Icon";
import { InformationCard } from "@userFrontend/components/InformationCard";
import { Colors } from "@root/utils/colors";
import * as styles from "./styles.css";

type Props = {
  handleOnClick: () => void;
  href: string;
};

export const HelpTextBox: FC<Props> = ({ handleOnClick, href }) => (
  <InformationCard>
    <p>
      <Icon name="tooltip" fontSize="16px" color={Colors.TEXT_LIGHT} />
      <span className={styles.iconText}>企業が見つからない</span>
    </p>
    <ul className={styles.textList}>
      <li className={styles.list}>
        現在、リジョブに求人を掲載したことのある企業のみ投稿を受け付けています。
      </li>
      <li className={styles.list}>
        勤務先がリジョブへ掲載されていない場合、口コミを投稿いただけません。
      </li>
    </ul>
    <div className={styles.buttonContainer}>
      <button type="button" onClick={handleOnClick} className={styles.button}>
        口コミ投稿を中断して戻る
      </button>
    </div>
    <div className={styles.bottomTextContainer}>
      <ul className={styles.textList}>
        <li>
          掲載中にも関わらず、勤務先が見つからない場合は、
          <a className={styles.link} href={href}>
            お問い合わせ
          </a>
          ください。
        </li>
      </ul>
    </div>
  </InformationCard>
);
