import { FC } from "react";
import { addQueryStrings } from "@userFrontend/utils/addQueryStrings";
import { SalonTourButton } from "@userFrontend/components/Buttons/SalonTourButton";
import { useSalonTourButton } from "@userFrontend/hooks/useSalonTourButton";
import { ExtendScoutDeadlineButton } from "@userFrontend/components/Buttons/ExtendScoutDeadlineButton";
import { useExtendScoutDeadlineButton } from "@userFrontend/hooks/useExtendScoutDeadlineButton";
import {
  StyledPrimaryButton,
  StyledScoutContents,
  StyledScoutContentsItem,
  StyledPrimaryButtonWrapper,
  StyledSalonTourButtonWrapper,
  StyledDeadLineText,
  StyledDeadLine,
  StyledTertiaryButton,
} from "./src/styled_elements";

export type ScoutButtonProps = {
  scoutButtonContent: {
    scoutDeadline?: number;
    isScouted: boolean;
    scoutedEntryUrl?: string;
    scoutClientShopId?: number;
    isScoutExtend: boolean;
    extendScoutUrl?: string;
    declineScoutUrl?: string;
  };
  salonTourButtonContent?: {
    isSalonTour: boolean;
    salonTourEntryUrl: string;
  };
  queryStr?: string;
  scoutGaLabel: string;
  hasEntried: boolean;
  workCode: string;
  position: string;
  isFixedButton: boolean;
  pageType: string;
  isApp: boolean;
  isPreview?: boolean;
  dataStyleColumn: number;
};

export const ScoutButton: FC<ScoutButtonProps> = ({
  scoutButtonContent: {
    isScouted,
    scoutDeadline,
    scoutedEntryUrl,
    scoutClientShopId,
    isScoutExtend,
    extendScoutUrl,
    declineScoutUrl,
  },
  salonTourButtonContent,
  queryStr,
  scoutGaLabel,
  hasEntried,
  workCode,
  position,
  isFixedButton,
  pageType,
  isApp,
  isPreview,
  dataStyleColumn,
}) => {
  const scoutGaAction = `${pageType}_scout_entry_tour-${position}`;
  const { handleClickSalonTourButton } = useSalonTourButton();
  const { handleClickExtendScoutDeadlineButton } = useExtendScoutDeadlineButton();
  const salonTourEntryUrl = !isPreview
    ? addQueryStrings(
        salonTourButtonContent ? salonTourButtonContent.salonTourEntryUrl : "",
        queryStr || "",
      )
    : undefined;

  return (
    <>
      {!isFixedButton && (
        <StyledDeadLineText>
          スカウトの受付期限まであと<StyledDeadLine>{scoutDeadline}</StyledDeadLine>日
        </StyledDeadLineText>
      )}
      <StyledScoutContents
        addMargin={!isFixedButton && !isApp && pageType !== "detail"}
        data-style-column={dataStyleColumn}
      >
        {!hasEntried && (
          <>
            <StyledPrimaryButtonWrapper>
              <StyledPrimaryButton
                href={scoutedEntryUrl}
                className="ga-click js-webhook disable-on-preview"
                data-ga-action={`${pageType}_scout_entry-${position}`}
                data-ga-label={scoutGaLabel}
                data-code={workCode}
                data-client-shop-id={scoutClientShopId}
                data-scout-view-type="screen_view"
              >
                スカウトを受ける
              </StyledPrimaryButton>
            </StyledPrimaryButtonWrapper>
            {salonTourButtonContent?.isSalonTour && (
              <StyledSalonTourButtonWrapper>
                <SalonTourButton
                  text="見学を希望する"
                  buttonTheme="secondary"
                  gaAction={scoutGaAction}
                  gaLabel={scoutGaLabel}
                  onClick={
                    !isPreview
                      ? () =>
                          handleClickSalonTourButton(
                            isApp,
                            workCode,
                            isScouted,
                            scoutClientShopId,
                            scoutGaAction,
                            scoutGaLabel,
                            salonTourEntryUrl,
                          )
                      : undefined
                  }
                />
              </StyledSalonTourButtonWrapper>
            )}
          </>
        )}
        {!isFixedButton && !isApp && (
          <>
            <StyledScoutContentsItem>
              {isScoutExtend && extendScoutUrl ? (
                <ExtendScoutDeadlineButton
                  gaAction={`${pageType}_scout_extend-${position}`}
                  gaLabel={scoutGaLabel}
                  onClick={() =>
                    handleClickExtendScoutDeadlineButton(
                      extendScoutUrl || "",
                      `${pageType}_scout_extend-${position}`,
                      scoutGaLabel,
                    )
                  }
                >
                  スカウト期限
                  <br />
                  延長する
                </ExtendScoutDeadlineButton>
              ) : (
                <StyledTertiaryButton as="button" type="button" disabled>
                  <span>スカウト期限</span>
                  <span>延長済み</span>
                </StyledTertiaryButton>
              )}
            </StyledScoutContentsItem>
            <StyledScoutContentsItem>
              <StyledTertiaryButton
                href={declineScoutUrl}
                className="js-scout-decline"
                data-ga-action={`${pageType}_scout_decline-${position}`}
                data-ga-label={scoutGaLabel}
              >
                辞退する
              </StyledTertiaryButton>
            </StyledScoutContentsItem>
          </>
        )}
      </StyledScoutContents>
    </>
  );
};
