import { FC } from "react";
import { StyledLink } from "@legacy_user_frontend/components/pages/jobs/job_search_navigation/src/search_modal/modal_contents/src/styled_elements";

type Props = {
  url: string;
  isTitleLink?: boolean;
};

export const ModalLink: FC<Props> = ({ url, isTitleLink }) => (
  <StyledLink className="js-link-expand__target" href={url} isTitleLink={isTitleLink}>
    <i className="c-icn c-icn-arrow-light-right" />
  </StyledLink>
);
