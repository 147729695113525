import styled from "styled-components";
import { Breakpoints, Colors } from "@legacy_user_frontend/utils";

export const StyledElements = styled.div`
  > .wrapper {
    ${Breakpoints.LARGE} {
      border-top: 1px solid ${Colors.GREY};
      padding-top: 16px;
      display: flex;
      justify-content: flex-end;
    }

    .button-list {
      display: flex;
      padding: 12px 16px;
      justify-content: flex-end;

      &.cassette-main__list-btn--scout {
        ${Breakpoints.SMALL} {
          display: block;
        }
      }

      ${Breakpoints.MEDIUM} {
        padding: 0;
      }

      ${Breakpoints.LARGE} {
        flex-basis: 70%;
        margin-left: 16px;
      }
    }

    .scout-deadline-outer {
      text-align: center;
      padding-top: 10px;
      line-height: 16px;

      ${Breakpoints.MEDIUM} {
        flex-basis: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-top: 0;
      }
    }

    .scout-deadline-text {
      ${Breakpoints.MEDIUM} {
        margin-right: 12px;
      }
    }

    .scout-deadline-num {
      color: ${Colors.PRIMARY};
      font-size: 16px;
      font-weight: bold;
    }

    .keep-button-outer {
      flex-basis: 50%;

      button {
        padding: 13px 0;
      }
    }

    .button-outer {
      font-size: 16px;
      flex-basis: 50%;

      :not(:first-child) {
        margin-left: 12px;
      }
    }

    .applied-button-outer {
      flex-basis: 50%;

      :not(:first-child) {
        margin-left: 12px;
      }

      ${Breakpoints.SMALL} {
        flex-basis: 100%;
      }
    }

    .apply-button {
      box-sizing: border-box;
      border-radius: 4px;
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
      height: 100%;
      text-decoration: none;
      padding: 13px 0;
      font-weight: bold;
      background: ${Colors.PRIMARY};
      color: ${Colors.WHITE};
      border: 1px solid ${Colors.PRIMARY};
      transition: all 0.2s;

      &:active {
        text-decoration: none;
      }

      ${Breakpoints.MEDIUM} {
        line-height: 1;
        font-size: 16px;

        &:hover {
          background: rgba(251, 99, 65, 0.8);
          opacity: 0.8;
          text-decoration: none;
          border-color: ${Colors.WHITE};
          box-shadow:
            0px 0px 8px ${Colors.PRIMARY},
            inset 0px 0px 1px ${Colors.PRIMARY};
        }
      }
    }

    .disabled-button {
      box-sizing: border-box;
      border-radius: 4px;
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
      height: 100%;
      text-decoration: none;
      padding: 13px 0;
      font-weight: bold;
      background-color: ${Colors.LIGHT_GREY};
      color: ${Colors.LIGHT_BLACK};
      border: none;
      transition: all 0.2s;

      ${Breakpoints.MEDIUM} {
        line-height: 1;
        font-size: 16px;
      }
    }
  }
`;
