import { ComponentProps, FC } from "react";
import { CassetteSliderSectionList } from "@userFrontend/components/CassetteSliderSectionList";
import * as styles from "./styles.css";

type Props = {
  sections: ComponentProps<typeof CassetteSliderSectionList>["sections"];
  headingText: string;
};

export const Presentation: FC<Props> = ({ sections, headingText }) => {
  return (
    <aside className={styles.container}>
      <div className={styles.content}>
        <h2 className={styles.heading}>{headingText}</h2>
        <CassetteSliderSectionList sections={sections} />
      </div>
    </aside>
  );
};
