import styled from "styled-components";
import { Breakpoints, Colors } from "@legacy_user_frontend/utils";

export const StyledElements = styled.div`
  > .table {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;

    dt {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      text-align: center;
      font-weight: bold;
      color: ${Colors.DEEP_GREY};
      width: 2em;
      margin-right: 0.1em;

      :not(:first-of-type) {
        margin-top: 10px;
      }

      [class*="icon-"] {
        font-size: 18px;
      }
    }

    dd {
      width: calc(100% - 2.1em);
      color: ${Colors.DEEP_BLACK};
      padding: 12px 0;

      :not(:first-of-type) {
        margin-top: 10px;
      }
    }

    dt.dream-and-commitment {
      height: 33px;
      font-size: 12px;
    }

    dd.dream-and-commitment {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      padding: 0 12px;
    }

    ${Breakpoints.SMALL} {
      dt {
        width: 8%;
        justify-content: center;
      }

      dd {
        width: 88%;
        margin-left: 6px;
      }

      dt.dream-and-commitment {
        font-size: 10px;
        line-height: 1.1;
      }

      .dream-and-commitment-wrapper {
        margin-top: 10px;
        width: 91%;
      }

      dd.dream-and-commitment {
        width: 100%;
        padding: 0;
      }
    }

    ${Breakpoints.MEDIUM} {
      display: flex;
      flex-wrap: wrap;
      max-width: 543px;
      font-size: 13px;

      dt {
        justify-content: center;
        vertical-align: middle;
        padding: 8px 0;
        width: 60px;
        background: ${Colors.LIGHT_GREY_01};
        text-align: center;
        margin-right: 0;
        border: none;
        color: ${Colors.DEEP_BLACK};

        [class*="icon-"] {
          display: block;
        }
      }

      dd {
        width: 85%;
        padding: 12px;
        background: ${Colors.WHITE};
        border: none;
      }

      dt,
      dd {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .dream-and-commitment-wrapper {
        margin-top: 10px;
        width: 85%;

        dd {
          width: 100%;
        }
      }

      .dream-and-commitment-br {
        display: none;
      }
    }

    ${Breakpoints.LARGE} {
      max-width: 566px;

      dd {
        width: 89%;
      }
    }

    .table-title {
      display: block;

      ${Breakpoints.SMALL} {
        display: none;
      }
    }

    .shop-num-outer {
      margin-top: 8px;

      a {
        font-weight: bold;
      }

      ${Breakpoints.MEDIUM} {
        margin-top: 16px;
      }
    }

    .omit-text {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .shop-num {
      font-size: 12px;
      color: ${Colors.ORANGE};
      font-weight: bold;
      margin-right: 3px;

      ${Breakpoints.MEDIUM} {
        font-size: 14px;
      }
    }
  }
`;
