import { ComponentProps, ReactElement, useMemo, lazy, Suspense } from "react";
import { Settings } from "react-slick";
import { WorkCassette } from "@userFrontend/components/WorkCassette";
import { SmallWorkCassette } from "@userFrontend/components/SmallWorkCassette";
import { wrapper } from "./styles.css";

const Slider = lazy(() => import("react-slick"));

type CassetteType = "WorkCassette" | "SmallWorkCassette";

type Props<T extends CassetteType> = {
  cassetteType?: T;
  cassettes: T extends "SmallWorkCassette"
    ? ComponentProps<typeof SmallWorkCassette>[]
    : ComponentProps<typeof WorkCassette>[];
  // cassetteTypeに"SmallWorkCassette"が渡された場合、cassettesにComponentProps<typeof SmallWorkCassette>[]を適用する。
  // それ以外の場合はComponentProps<typeof WorkCassette>[]を適用する。
};

export const CassetteSlider = <T extends CassetteType>({
  cassetteType,
  cassettes,
}: Props<T>): ReactElement => {
  const sliderContents = useMemo(() => {
    if (cassetteType === "SmallWorkCassette") {
      return (cassettes as ComponentProps<typeof SmallWorkCassette>[]).map(
        ({
          gaAction,
          gaLabel,
          workCode,
          detailUrl,
          imgSrc,
          dummyImgPath,
          title,
          salaries,
          shopOrBrandName,
          shopLocation,
          businessTypeAndOccupation,
          keepButtonUrl,
          keepGaAction,
          keepGaLabel,
          hasKeeped,
        }) => (
          <SmallWorkCassette
            key={workCode}
            detailUrl={detailUrl}
            gaAction={gaAction}
            workCode={workCode}
            gaLabel={gaLabel}
            imgSrc={imgSrc}
            dummyImgPath={dummyImgPath}
            title={title}
            salaries={salaries}
            shopOrBrandName={shopOrBrandName}
            businessTypeAndOccupation={businessTypeAndOccupation}
            shopLocation={shopLocation}
            keepButtonUrl={keepButtonUrl}
            keepGaAction={keepGaAction}
            keepGaLabel={keepGaLabel}
            hasKeeped={hasKeeped}
          />
        ),
      );
    }

    return (cassettes as ComponentProps<typeof WorkCassette>[]).map(
      ({
        type,
        gaAction,
        gaLabel,
        detailUrl,
        headerContents,
        salaries,
        shopLocation,
        buttonProps,
      }) => (
        <WorkCassette
          type={type}
          gaAction={gaAction}
          gaLabel={gaLabel}
          detailUrl={detailUrl}
          headerContents={headerContents}
          salaries={salaries}
          shopLocation={shopLocation}
          buttonProps={buttonProps}
          key={buttonProps.workCode}
        />
      ),
    );
  }, [cassetteType, cassettes]);

  return (
    <Suspense fallback={null}>
      <div className={wrapper}>
        {sliderContents.length >= 1 && <Slider {...settings}>{sliderContents}</Slider>}
      </div>
    </Suspense>
  );
};

// slickの設定
const settings: Settings = {
  lazyLoad: "ondemand",
  infinite: false,
  autoplay: false,
  pauseOnHover: true,
  slidesToScroll: 1,
  slidesToShow: 3,
  autoplaySpeed: 5000,
  touchThreshold: 10,
  dots: false,
  arrows: true,
  responsive: [
    {
      breakpoint: 979,
      settings: {
        speed: 300,
        slidesToShow: 2,
        dots: true,
        arrows: false,
      },
    },
    {
      breakpoint: 767,
      settings: {
        speed: 300,
        slidesToShow: 1,
        dots: true,
        arrows: false,
      },
    },
  ],
};

export default CassetteSlider;
