import styled from "styled-components";
import { Breakpoints, Colors } from "@legacy_user_frontend/utils";

export const StyledElements = styled.div<{ cassetteType?: string }>`
  > .cassette-outer {
    color: ${Colors.DEEP_BLACK};
    background: ${Colors.WHITE};
    box-shadow: ${(props) =>
      props.cassetteType === "search"
        ? "2px 4px 16px rgba(36, 31, 31, 0.2)"
        : "0px 0px 5px rgba(0, 0, 0, 0.1)"};
    margin: 16px 0;
    position: relative;
    transition: all 0.2s;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    ${Breakpoints.MEDIUM} {
      padding: 16px;
      &:hover {
        box-shadow: ${(props) =>
          props.cassetteType === "search"
            ? "2px 4px 16px rgba(0, 0, 0, 0.4)"
            : "2px 4px 16px rgba(0, 0, 0, 0.2)"};
        cursor: pointer;
      }
    }

    .pinpoint-label {
      display: inline-block;
      margin-bottom: 8px;
      background-color: ${Colors.ALERT_RED};
      color: ${Colors.WHITE};
    }

    .image-outer-sp {
      width: 100%;

      ${Breakpoints.MEDIUM} {
        display: none;
      }
    }

    .header-outer {
      padding: 12px 16px;

      ${Breakpoints.MEDIUM} {
        padding-bottom: 16px;
      }
    }

    .header-link {
      display: block;

      &:hover {
        text-decoration: none;
      }
    }

    .header-text {
      font-size: 16px;
      line-height: 1.4;
      color: ${Colors.LINK};
      margin-top: 8px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between;

      [class*="icon-"] {
        font-size: 12px;

        &:before {
          margin: 0;
        }
      }

      ${Breakpoints.MEDIUM} {
        font-size: 22px;
        font-weight: bold;
        margin-top: 12px;
        justify-content: flex-start;

        [class*="icon-"] {
          font-size: 16px;
          margin-left: 14px;
        }
      }
    }

    .main-contents-outer {
      padding: 0 16px 0 13px;
      background: ${Colors.OLD_LIGHT_GREY};

      ${Breakpoints.MEDIUM} {
        border-top: 1px solid ${Colors.GREY};
        background: ${Colors.WHITE};
        padding: 0;
      }
    }

    .main-contents-inner {
      ${Breakpoints.MEDIUM} {
        display: flex;
        padding: 16px 0;
        justify-content: space-between;
        align-items: flex-start;
      }
    }

    .image-outer-pc {
      width: 400px;

      ${Breakpoints.SMALL} {
        display: none;
      }
    }
  }
`;
