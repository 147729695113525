import { FC } from "react";
import { EditPageLink } from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_show/src/ui_parts/edit_page_link";
import styled from "styled-components";

export type HeadingProps = {
  text: string;
  editPath?: string;
};

const StyledHeadingOuter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledHeading = styled.h3`
  padding: 6px 0;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.4;
`;

const EditPageLinkOuter = styled.div`
  width: 82px;
`;

export const Heading: FC<HeadingProps> = ({ text, editPath }) => (
  <StyledHeadingOuter>
    <StyledHeading>{text}</StyledHeading>
    {editPath && (
      <>
        <EditPageLinkOuter>
          <EditPageLink url={editPath} />
        </EditPageLinkOuter>
      </>
    )}
  </StyledHeadingOuter>
);
