import { FC, ComponentProps } from "react";
import { useInView } from "react-intersection-observer";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { DUMMY_IMAGE_PATH } from "@root/utils/constants";
import * as styles from "./styles.css";

import "intersection-observer";

type Props = ComponentProps<"img"> & { dummyImgPath?: string; width: number; height: number };

export const LazyLoadImage: FC<Props> = ({
  src,
  dummyImgPath = DUMMY_IMAGE_PATH,
  alt,
  width,
  height,
}) => {
  const [ref, inView] = useInView({
    rootMargin: "100px 0px",
    triggerOnce: true,
  });
  const paddingBottomSize = src ? `${((height / width) * 100).toString()}%` : "0";
  return (
    // 親の高さにアスペクト比が親に影響されないようdivで囲む
    <div>
      <div
        style={{
          ...assignInlineVars({
            // 画像がロードされるまで担保する高さを、アスペクト比から計算
            [styles.paddingBottomSize]: paddingBottomSize,
          }),
        }}
        className={styles.container}
        ref={ref}
      >
        <img
          className={styles.image}
          data-original={src} // 検索エンジンへの影響を鑑みてリプレイス前の属性を残す
          src={inView ? src : dummyImgPath}
          alt={alt}
          width={width}
          height={height}
        />
      </div>
    </div>
  );
};
