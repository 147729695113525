import { FC } from "react";
import { variant, sizeVariant, leftIconOuter, rightIconOuter } from "./styles.css";

export type Themes = keyof typeof variant;
export type Size = keyof typeof sizeVariant;

type Props = JSX.IntrinsicElements["a"] & {
  children: React.ReactNode;
  className?: string;
  theme?: Themes;
  size?: Size;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
};

export const Link: FC<Props> = ({
  theme = "default",
  size = "large",
  href,
  children,
  className,
  leftIcon,
  rightIcon,
  ...rest
}) => {
  return (
    <a
      className={`${variant[theme]} ${sizeVariant[size]} ${className || ""}`}
      href={href}
      {...rest}
    >
      {leftIcon && <span className={leftIconOuter}>{leftIcon}</span>}
      {children}
      {rightIcon && <span className={rightIconOuter}>{rightIcon}</span>}
    </a>
  );
};
