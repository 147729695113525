import { FC } from "react";
import styled from "styled-components";
import { ShopAccordionProps } from "./shop_accordion";

const MainTableList = styled.li`
  span {
    display: block;

    a {
      font-weight: bold;
    }
  }

  :not(:first-child) {
    margin-top: 8px;
  }
`;

export const Shop: FC<ShopAccordionProps> = ({
  totalNumber,
  shopInformations,
  gaAction,
  gaLabels,
  query,
}) => (
  <ul>
    {shopInformations.map(
      ({ detailUrlWithShopId, name, detailAddress, detailNearestStation }, index) => (
        <MainTableList key={detailUrlWithShopId}>
          <span>
            <a
              href={detailUrlWithShopId}
              className="js-add-query-strings ga-click"
              data-ga-action={gaAction}
              data-ga-label={gaLabels[index]}
              data-query-str={query}
            >
              {name}
            </a>
          </span>
          {detailAddress && <p className="omit-text">{detailAddress}</p>}
          {detailNearestStation && <p className="omit-text">{detailNearestStation}</p>}
        </MainTableList>
      ),
    )}
    {totalNumber > 1 && (
      <p className="shop-num-outer">
        <span className="shop-num">{totalNumber}</span>
        店舗で募集中
      </p>
    )}
  </ul>
);
