import { FC } from "react";
import * as styles from "./styles.css";

type Props = {
  deviceType: "pc" | "sp";
};

export const AppealMessage: FC<Props> = ({ deviceType }) => {
  return (
    <>
      <div className={styles.wrapper[deviceType]}>
        <p className={styles.message[deviceType]}>
          もっと自分に合った求人を探したい！そんな時のサポートがあります！
        </p>
        <img
          src="/images/user_frontend/sp/img_matching_support_phone.png"
          alt="相談のイメージ"
          className={styles.image[deviceType]}
        />
      </div>
    </>
  );
};
