import { FC } from "react";
import { SelfPrProps } from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_show/src/profile_show_props";
import { StyledSelfPrList } from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_show/src/styled_elements";

export const SelfPrCassette: FC<SelfPrProps> = ({
  selfPr: { reasonForApply, importantPoint, futurePlan, selfPr },
}) => (
  <StyledSelfPrList>
    {reasonForApply && (
      <>
        <dt>なぜこの業界で働こうと思いましたか？</dt>
        <dd>{reasonForApply}</dd>
      </>
    )}
    {importantPoint && (
      <>
        <dt>お仕事をする上で大切にしていることは？</dt>
        <dd>{importantPoint}</dd>
      </>
    )}
    {futurePlan && (
      <>
        <dt>この先仕事でやってみたいことやキャリアプラン、将来の夢は？</dt>
        <dd>{futurePlan}</dd>
      </>
    )}
    {selfPr && (
      <>
        <dt>自己PR</dt>
        <dd>{selfPr}</dd>
      </>
    )}
  </StyledSelfPrList>
);
