/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
import { FC, useState } from "react";
import * as styles from "./styles.css";
import { SelectBox } from "./uiparts/SelectBox";

type OptionInfoProps = {
  text: string;
  link: string;
};

type Props = {
  selectLinkInformation: {
    area: {
      option_links: OptionInfoProps[];
      selected: string;
    };
    sort: {
      option_links: OptionInfoProps[];
      selected: string;
    };
  };
};

export const SelectLinkButton: FC<Props> = ({ selectLinkInformation: { area, sort } }) => {
  const setInitialSelectedAreaText = () => {
    return area.option_links.find((item) => item.link === area.selected)?.text;
  };

  const [selectedAreaText, setSelectedAreaText] = useState<string>(
    setInitialSelectedAreaText() || "",
  );

  const handleOnAreaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    window.location.href = e.target.value;
    const index = e.target.selectedIndex;
    setSelectedAreaText(e.target[index].label);
  };

  const handleOnSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    window.location.href = e.target.value;
  };

  return (
    <div className={styles.container}>
      <div className={styles.hiddenSelectBoxOuter}>
        <label className={`${styles.selectButton} ${styles.primary}`} htmlFor="area_select">
          <i className={`c-icn c-icn-arrow-bold-down ${styles.arrowIcon}`} />
          {selectedAreaText}
        </label>
        <SelectBox
          optionLinks={area.option_links}
          selected={area.selected}
          handleOnChange={handleOnAreaChange}
          className={styles.hiddenSelectBox}
          id="area_select"
        />
      </div>
      <div className={styles.hiddenSelectBoxOuter}>
        <label className={`${styles.selectButton} ${styles.secondary}`} htmlFor="sort_select">
          <i className={`c-icn c-icn-sort ${styles.sortIcon}`} />
          並び替え
        </label>
        <SelectBox
          optionLinks={sort.option_links}
          selected={sort.selected}
          handleOnChange={handleOnSortChange}
          className={styles.hiddenSelectBox}
          id="sort_select"
        />
      </div>
    </div>
  );
};
