import { FC } from "react";
import { Table } from "@root/shared/components/Table/Table";
import { Td } from "@root/shared/components/Table/Td";
import { Th } from "@root/shared/components/Table/Th";
import { Tr } from "@root/shared/components/Table/Tr";
import { Button } from "@root/shared/components/Button";
import { RequestConfirmationPanel } from "@userFrontend/features/reward/components/RequestConfirmationPanel";
import { MultiLineText } from "@root/shared/components/MultiLineText";
import * as styles from "./styles.css";
// types
import { Props } from "./type";

export const Step6: FC<Props> = ({
  questions,
  jobEntries,
  jobEntryValue,
  emailValue,
  businessTypeValue,
  employmentValue,
  employmentStatusValue,
  checkboxValues,
  radioValue,
  textAreaValue,
  previewUrls,
  termsUrl,
  guidelineUrl,
  handleClickBackButton,
  handleClickSubmitButton,
}) => {
  return (
    <div className={styles.container}>
      <Table>
        <Tr>
          <Th width="185px">基本情報</Th>
          <Td>
            <dl className={styles.list}>
              <dt className={styles.listTitle}>勤務先</dt>
              <dd>
                {jobEntries
                  .filter((jobEntry) => jobEntry.jobEntryId === jobEntryValue)
                  .map((job) => (
                    <p key={job.jobEntryId}>{job.clientName}</p>
                  ))}
              </dd>
              <dt className={styles.listTitle}>メールアドレス</dt>
              <dd>{emailValue}</dd>
            </dl>
          </Td>
        </Tr>
        <Tr>
          <Th width="185px">就業情報</Th>
          <Td>
            <dl className={styles.list}>
              <dt className={styles.listTitle}>就業状況</dt>
              <dd>
                {questions.step1Questions.employmentStatus && (
                  <>
                    {questions.step1Questions.employmentStatus
                      .filter(
                        (employmentState) => employmentState.optionId === employmentStatusValue,
                      )
                      .map((employment) => (
                        <p key={employment.optionText}>{employment.optionText}</p>
                      ))}
                  </>
                )}
              </dd>
              <dt className={styles.listTitle}>職種</dt>
              <dd>
                {questions.step1Questions.businessTypesSelectOptions && (
                  <>
                    {questions.step1Questions.businessTypesSelectOptions
                      .filter(
                        (businessTypeOption) => businessTypeOption.value === businessTypeValue,
                      )
                      .map((businessType) => (
                        <p key={businessType.label}>{businessType.label}</p>
                      ))}
                  </>
                )}
              </dd>
              <dt className={styles.listTitle}>雇用形態</dt>
              <dd>
                {questions.step1Questions.employmentsSelectOptions && (
                  <>
                    {questions.step1Questions.employmentsSelectOptions
                      .filter((employmentOption) => employmentOption.value === employmentValue)
                      .map((employment) => (
                        <p key={employment.label}>{employment.label}</p>
                      ))}
                  </>
                )}
              </dd>
            </dl>
          </Td>
        </Tr>
        <Tr>
          <Th width="185px">あなたの職場の口コミ【待遇面】</Th>
          <Td>
            <dl className={styles.list}>
              <dt className={styles.listTitle}>あなたの職場の良いところを教えてください</dt>
              <dd>
                <ul className={styles.dotList}>
                  {questions.step2Questions.treatmentQuestions && (
                    <>
                      {questions.step2Questions.treatmentQuestions
                        .filter((item) => checkboxValues.treatments.includes(item.optionId))
                        .map((treatmentsQuestion) => (
                          <li key={treatmentsQuestion.optionText} className={styles.dotListItem}>
                            {treatmentsQuestion.optionText}
                          </li>
                        ))}
                    </>
                  )}
                </ul>
              </dd>
            </dl>
          </Td>
        </Tr>
        <Tr>
          <Th width="185px">あなたの職場の口コミ【社風面】</Th>
          <Td>
            <dl className={styles.list}>
              <dt className={styles.listTitle}>あなたの職場の良いところを教えてください</dt>
              <dd>
                <ul className={styles.dotList}>
                  {questions.step2Questions.cultureQuestions && (
                    <>
                      {questions.step2Questions.cultureQuestions
                        .filter((item) => checkboxValues.cultures.includes(item.optionId))
                        .map((culturesQuestion) => (
                          <li key={culturesQuestion.optionText} className={styles.dotListItem}>
                            {culturesQuestion.optionText}
                          </li>
                        ))}
                    </>
                  )}
                </ul>
              </dd>
            </dl>
          </Td>
        </Tr>
        <Tr>
          <Th width="185px">あなたの職場の口コミ【特徴】</Th>
          <Td>
            <dl className={styles.list}>
              <dt className={styles.listTitle}>あなたの職場の雰囲気は？</dt>
              <dd>
                {questions.step3Questions.atmosphere
                  .filter((item) => item.optionId === radioValue.atmosphere)
                  .map((atmosphereQuestion) => (
                    <p key={atmosphereQuestion.optionText}>{atmosphereQuestion.optionText}</p>
                  ))}
              </dd>
              <dt className={styles.listTitle}>あなたの職場の給与体系は？</dt>
              <dd>
                {questions.step3Questions.salaryStandard
                  .filter((item) => item.optionId === radioValue.salaryStandard)
                  .map((salaryStandardQuestion) => (
                    <p key={salaryStandardQuestion.optionText}>
                      {salaryStandardQuestion.optionText}
                    </p>
                  ))}
              </dd>
              <dt className={styles.listTitle}>独り立ち・デビューまでは？</dt>
              <dd>
                {questions.step3Questions.debut
                  .filter((item) => item.optionId === radioValue.debut)
                  .map((debutQuestion) => (
                    <p key={debutQuestion.optionText}>{debutQuestion.optionText}</p>
                  ))}
              </dd>
              <dt className={styles.listTitle}>教育方針は？</dt>
              <dd>
                {questions.step3Questions.education
                  .filter((item) => item.optionId === radioValue.education)
                  .map((educationQuestion) => (
                    <p key={educationQuestion.optionText}>{educationQuestion.optionText}</p>
                  ))}
              </dd>
              <dt className={styles.listTitle}>勤務時間は？</dt>
              <dd>
                {questions.step3Questions.workTime
                  .filter((item) => item.optionId === radioValue.workTime)
                  .map((workTimeQuestion) => (
                    <p key={workTimeQuestion.optionText}>{workTimeQuestion.optionText}</p>
                  ))}
              </dd>
              <dt className={styles.listTitle}>前職から比べた給与の変化</dt>
              <dd>
                {questions.step3Questions.salaryChange
                  .filter((item) => item.optionId === radioValue.salaryChange)
                  .map((salaryChangeQuestion) => (
                    <p key={salaryChangeQuestion.optionText}>{salaryChangeQuestion.optionText}</p>
                  ))}
              </dd>
            </dl>
          </Td>
        </Tr>
        <Tr>
          <Th width="185px">{questions.step4Questions.workplaceMatching.questionText}</Th>
          <Td>
            <MultiLineText text={textAreaValue.workplaceMatching} />
          </Td>
        </Tr>
        <Tr>
          <Th width="185px">{questions.step4Questions.interestReward.questionText}</Th>
          <Td>
            <MultiLineText text={textAreaValue.interestReward} />
          </Td>
        </Tr>
        <Tr>
          <Th width="185px">{questions.step4Questions.challenge.questionText}</Th>
          <Td>
            <MultiLineText text={textAreaValue.challenge} />
          </Td>
        </Tr>
        <Tr>
          <Th width="185px">{questions.step4Questions.expectation.questionText}</Th>
          <Td>
            <MultiLineText text={textAreaValue.expectation} />
          </Td>
        </Tr>
        <Tr>
          <Th width="185px">勤続証明書類</Th>
          <Td>
            <dl className={styles.list}>
              <dt className={styles.listTitle}>勤続証明書類</dt>
              <dd>
                <div className={styles.imagesOuter}>
                  {previewUrls.map((previewUrl) => (
                    <div key={previewUrl} className={styles.imageContainer}>
                      <img src={previewUrl} alt="" className={styles.imagesItem} />
                    </div>
                  ))}
                </div>
              </dd>
            </dl>
          </Td>
        </Tr>
      </Table>
      <div className={styles.panelOuter}>
        <RequestConfirmationPanel termsUrl={termsUrl} guidelineUrl={guidelineUrl} />
      </div>
      <div className={styles.buttonContainer}>
        <div className={styles.buttonOuter}>
          <Button theme="tertiary" onClick={handleClickBackButton}>
            戻る
          </Button>
          <Button theme="primary" onClick={handleClickSubmitButton}>
            申請する
          </Button>
        </div>
      </div>
    </div>
  );
};
