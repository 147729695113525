import { FC } from "react";
import { InformationText } from "@root/shared/components/InformationText";
import { StepSelection } from "@root/shared/components/StepSelection";
import { Label } from "@root/shared/components/Label";
import { ErrorText } from "@userFrontend/features/reward/components/ContinuedRewardInputs/type";
import * as styles from "./styles.css";

type Props = {
  categoryName: string;
  radioValues: Record<string, number | null>;
  handleRadioChange: (category: string, optionId: number) => void;
  questionText: string;
  errorText?: ErrorText;
  leftText: string;
  rightText: string;
  optionIds: {
    value: string;
  }[];
};

export const StepSelectionBox: FC<Props> = ({
  categoryName,
  radioValues,
  handleRadioChange,
  questionText,
  errorText,
  leftText,
  rightText,
  optionIds,
}) => {
  return (
    <>
      <div className={styles.questionWrapper}>
        <p className={styles.question}>{questionText}</p>
        <Label theme="red">必須</Label>
      </div>
      {errorText && <InformationText text={errorText} isAlert />}
      <div className={styles.stepSelectionContainer}>
        <div className={styles.stepSelectionTextWrapper}>
          <p>{leftText}</p>
          <p>{rightText}</p>
        </div>
        <div className={styles.stepSelectionWrapper}>
          <StepSelection
            name={categoryName}
            options={optionIds}
            hasError={!!errorText}
            selectedOption={String(radioValues[categoryName])}
            categoryName={categoryName}
            handleRadioChange={handleRadioChange}
          />
        </div>
      </div>
    </>
  );
};
