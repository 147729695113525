import { FC } from "react";
import { UserCareersCassetteProps } from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_show/src/profile_show_props";
import {
  StyledInnerCassette,
  StyledInnerCassetteContent,
  StyledInnerCassetteHeading,
  StyledInnerCassetteHeadingOuter,
  StyledInnerCassetteList,
  StyledTableList,
} from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_show/src/styled_elements";
import { PrivateLabel } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/private_label";
import { EditPageLink } from "./edit_page_link";

export const UserCareersCassette: FC<UserCareersCassetteProps> = ({
  userCareers,
  editUserCareerPath,
}) => (
  <StyledInnerCassetteList>
    {userCareers.map(
      (
        {
          companyName,
          shopName,
          userEmploymentPatternName,
          employmentStartYear,
          employmentStartMonth,
          employmentEndYear,
          employmentEndMonth,
          occupation,
          clientele,
          jobTypeCategoryName,
          jobTypeName,
          averageNumberOfCustomers,
          averagePercentageOfNewCustomers,
          averageNominationRatio,
          averageCustomerSpending,
          averagePersonalSales,
          description,
          userCareerWorkPatternNames,
          userCareersFacilityTypes,
        },
        index,
      ) => (
        <li key={`${jobTypeCategoryName}_${jobTypeName}`}>
          <StyledInnerCassette>
            <StyledInnerCassetteHeadingOuter>
              <StyledInnerCassetteHeading>
                <span>職務経歴{index + 1}</span>
                <EditPageLink url={`${editUserCareerPath}#cassette-${index}`} />
              </StyledInnerCassetteHeading>
            </StyledInnerCassetteHeadingOuter>
            <StyledInnerCassetteContent>
              <StyledTableList>
                {companyName && (
                  <>
                    <dt>
                      <div>企業名</div>
                      <PrivateLabel />
                    </dt>
                    <dd>{companyName}</dd>
                  </>
                )}
                {shopName && (
                  <>
                    <dt>
                      <div>店舗名・施設名</div>
                      <PrivateLabel />
                    </dt>
                    <dd>{shopName}</dd>
                  </>
                )}
                {((employmentStartYear && employmentStartMonth) ||
                  (employmentEndYear && employmentEndMonth)) && (
                  <>
                    <dt>在籍期間</dt>
                    <dd>
                      {employmentStartYear && employmentStartMonth && (
                        <>
                          {employmentStartYear}年{employmentStartMonth}月
                        </>
                      )}
                      〜
                      {employmentEndYear && employmentEndMonth && (
                        <>
                          {employmentEndYear}年{employmentEndMonth}月
                        </>
                      )}
                    </dd>
                  </>
                )}
                <dt>業種</dt>
                <dd>{jobTypeCategoryName}</dd>
                <dt>職種</dt>
                <dd>{jobTypeName}</dd>
                {userEmploymentPatternName && (
                  <>
                    <dt>雇用形態</dt>
                    <dd>{userEmploymentPatternName}</dd>
                  </>
                )}
                {occupation && (
                  <>
                    <dt>役職</dt>
                    <dd>{occupation}</dd>
                  </>
                )}
                {userCareersFacilityTypes.length > 0 && (
                  <>
                    <dt>施設形態</dt>
                    <dd>
                      <StyledInnerCassetteList>
                        {userCareersFacilityTypes.map(({ facilityTypeName, yearsOfExperience }) => (
                          <li key={`${facilityTypeName}_${yearsOfExperience}`}>
                            {facilityTypeName}（{yearsOfExperience}）
                          </li>
                        ))}
                      </StyledInnerCassetteList>
                    </dd>
                  </>
                )}
                {userCareerWorkPatternNames.length > 0 && (
                  <>
                    <dt>
                      経験のある
                      <br />
                      勤務形態
                    </dt>
                    <dd>
                      <StyledInnerCassetteList>
                        {userCareerWorkPatternNames.map((workPatternName) => (
                          <li key={workPatternName}>{workPatternName}</li>
                        ))}
                      </StyledInnerCassetteList>
                    </dd>
                  </>
                )}
                {!!averageNumberOfCustomers && (
                  <>
                    <dt>
                      平均対応客数
                      <br />
                      （個人実績）
                    </dt>
                    <dd>{averageNumberOfCustomers}人 / 月</dd>
                  </>
                )}
                {!!averagePercentageOfNewCustomers && (
                  <>
                    <dt>
                      平均新規顧客割合
                      <br />
                      （個人実績）
                    </dt>
                    <dd>{averagePercentageOfNewCustomers}%</dd>
                  </>
                )}
                {!!averageNominationRatio && (
                  <>
                    <dt>
                      平均指名割合
                      <br />
                      （個人実績）
                    </dt>
                    <dd>{averageNominationRatio}%</dd>
                  </>
                )}
                {!!averageCustomerSpending && (
                  <>
                    <dt>
                      平均顧客単価
                      <br />
                      （個人実績）
                    </dt>
                    <dd>{averageCustomerSpending}円</dd>
                  </>
                )}
                {!!averagePersonalSales && (
                  <>
                    <dt>
                      平均売上
                      <br />
                      （個人実績）
                    </dt>
                    <dd>{averagePersonalSales}円 / 月</dd>
                  </>
                )}
                {clientele && (
                  <>
                    <dt>客層</dt>
                    <dd>{clientele}</dd>
                  </>
                )}
                {description && (
                  <>
                    <dt>職務内容</dt>
                    <dd>{description}</dd>
                  </>
                )}
              </StyledTableList>
            </StyledInnerCassetteContent>
          </StyledInnerCassette>
        </li>
      ),
    )}
  </StyledInnerCassetteList>
);
