import { ComponentProps, FC } from "react";
import { Cassette } from "@root/shared/components/Cassette";
import { FooterButtons } from "./uiParts/FooterButtons";
import { DefaultHeader } from "./uiParts/DefaultHeader";
import { SimplifiedHeader } from "./uiParts/SimplifiedHeader";
import { SalaryAndShopLocation } from "./uiParts/SalaryAndShopLocation";
import { wrapper, headerWrapper } from "./styles.css";

export type CassetteType = "default" | "simplified";
type Props = {
  type: CassetteType;
  gaAction: string;
  gaLabel?: string;
  detailUrl: string;
  // TODO: DefaultHeaderの中身が確定し次第、要修正。一旦値の仕様が確定しているSimplifiedHeaderを使用している。
  headerContents: ComponentProps<typeof SimplifiedHeader>;
  salaries: ComponentProps<typeof SalaryAndShopLocation>["salaries"];
  shopLocation: string;
  buttonProps: ComponentProps<typeof FooterButtons>;
};

export const WorkCassette: FC<Props> = ({
  type = "default",
  gaAction,
  gaLabel,
  detailUrl,
  headerContents,
  salaries,
  shopLocation,
  buttonProps,
}) => {
  const Header = () => renderHeader(type, headerContents);
  return (
    <Cassette>
      <div
        className={wrapper}
        onClick={(e) => navigateToDetail(e, detailUrl)}
        onKeyPress={(e) => navigateToDetail(e, detailUrl)}
        role="button"
        tabIndex={0}
      >
        <a
          href={detailUrl}
          className={`${headerWrapper} js-add-query-strings ga-impression`}
          data-ga-action={gaAction}
          data-ga-label={gaLabel}
        >
          <Header />
        </a>
        <SalaryAndShopLocation salaries={salaries} shopLocation={shopLocation} />
        <FooterButtons {...buttonProps} />
      </div>
    </Cassette>
  );
};

const navigateToDetail = (e: React.BaseSyntheticEvent, detailUrl: string) => {
  if (e.target.tagName !== "BUTTON") {
    window.location.href = detailUrl;
  }
};

const renderHeader = (
  type: CassetteType,
  headerContents: ComponentProps<typeof SimplifiedHeader>,
) => {
  switch (type) {
    case "default":
      return <DefaultHeader />;
    case "simplified":
      return <SimplifiedHeader {...headerContents} />;
    default:
      throw new Error("This type is not defined");
  }
};
