import { FC } from "react";
import * as styles from "./styles.css";

type Props = {
  headingText?: string;
  textArray: string[];
};

export const GuidanceBox: FC<Props> = ({ headingText, textArray }) => (
  <div className={styles.outer}>
    <ul className={styles.list}>
      {headingText && <p className={styles.heading}>{headingText}</p>}
      {textArray.map((text) => (
        <li key={text} className={styles.item}>
          {text}
        </li>
      ))}
    </ul>
  </div>
);
