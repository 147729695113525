import {
  object,
  string,
  literal,
  array,
  pipe,
  union,
  number,
  nullable,
  rawCheck,
  InferInput,
} from "valibot";

export const schema = object({
  authenticity_token: string(),
  _method: literal("put"),
  form_user_achievement_collection: object({
    user_achievements_attributes: array(
      pipe(
        object({
          name: string(),
          year: nullable(union([string(), number()])),
          month: nullable(union([string(), number()])),
        }),
        rawCheck(({ dataset, addIssue }) => {
          if (dataset.typed) {
            const { name, year, month } = dataset.value;
            if (!name && (month || year)) {
              addIssue({
                message: "受賞名を入力してください。",
                path: [
                  {
                    type: "object",
                    origin: "value",
                    input: dataset.value,
                    key: "name",
                    value: name,
                  },
                ],
              });
            }
            if (year && !month) {
              addIssue({
                message: "有効な月を入力してください。",
                path: [
                  {
                    type: "object",
                    origin: "value",
                    input: dataset.value,
                    key: "month",
                    value: month,
                  },
                ],
              });
            }
            if (!year && month) {
              addIssue({
                message: "有効な年を入力してください。",
                path: [
                  {
                    type: "object",
                    origin: "value",
                    input: dataset.value,
                    key: "year",
                    value: year,
                  },
                ],
              });
            }
          }
        }),
      ),
    ),
  }),
});

export type InputProps = InferInput<typeof schema>;
