import { FC, Fragment } from "react";
import {
  SkillLabelsProps,
  SelectedSkillLabelsProps,
} from "@legacy_user_frontend/components/pages/mypage/profiles/skills_edit/src/skill_edit_props";
import {
  StyledLabelCategoryList,
  StyledLabelList,
} from "@legacy_user_frontend/components/pages/mypage/profiles/skills_edit/src/styled_elements";
import { useFormContext, useWatch } from "react-hook-form";
import { Label } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/label";
import { LabelsCassette } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/labels_cassette";

export const SkillLabels: FC<SkillLabelsProps> = ({ categorizedSkills }) => {
  // スキルIDからスキル名を取得してリストに格納
  const { control } = useFormContext();
  const selectedSkills: string[] = useWatch({
    control,
    name: "form_user_frontend_user_skill_update[user_skills_ids]",
  });
  const selectedSkillLabels: SelectedSkillLabelsProps = [];
  categorizedSkills.forEach(({ name, businessTypes }) => {
    businessTypes.forEach((businessType) => {
      selectedSkillLabels.push({
        // 職種が一つの場合は親階層のnameを表示する
        businessTypeName: businessTypes.length > 1 ? businessType.name : name,
        skills: [],
      });
      businessType.skillCategories.forEach(({ skills }) => {
        skills.forEach((skill) => {
          if (selectedSkills.includes(`${skill.id}`)) {
            const index = selectedSkillLabels.length - 1;
            selectedSkillLabels[index].skills.push({ name: skill.name, id: `${skill.id}` });
          }
        });
      });
    });
  });

  if (selectedSkills.length < 1) {
    return null;
  }

  return (
    <LabelsCassette heading="追加済みの項目">
      <StyledLabelCategoryList>
        {selectedSkillLabels.map(({ businessTypeName, skills }) => (
          <Fragment key={businessTypeName}>
            {skills.length > 0 && (
              <>
                <dt>{businessTypeName}</dt>
                <dd>
                  <StyledLabelList>
                    {skills.map((skill) => (
                      <li key={`${skill.id}_${skill.name}`}>
                        <Label>
                          <>{skill.name}</>
                        </Label>
                      </li>
                    ))}
                  </StyledLabelList>
                </dd>
              </>
            )}
          </Fragment>
        ))}
      </StyledLabelCategoryList>
    </LabelsCassette>
  );
};
