import { FC } from "react";
import * as styles from "./styles.css";

type Props = {
  children: React.ReactNode;
};

export const Card: FC<Props> = ({ children }) => {
  return <div className={styles.card}>{children}</div>;
};
