import { assignInlineVars } from "@vanilla-extract/dynamic";
import { Icon } from "@root/shared/components/Icon";
import { FC } from "react";
import { Colors } from "@root/utils/colors";
import * as styles from "./styles.css";

type Props = {
  iconName?: string;
  anchorLinkName: string;
  anchorLinkLength: number;
  isCurrent: boolean;
  onClick: () => void;
};

export const AnchorLinkItem: FC<Props> = ({
  iconName,
  anchorLinkName,
  anchorLinkLength,
  isCurrent,
  onClick,
}) => {
  return (
    <li
      className={styles.li}
      style={assignInlineVars({ [styles.liVar]: String(anchorLinkLength) })}
    >
      <button
        type="button"
        className={`${styles.button} ${isCurrent ? "is-current" : ""}`}
        onClick={onClick}
      >
        {iconName && <Icon name={iconName} fontSize="12px" color={Colors.LINK} />}
        <span className={styles.title}>{anchorLinkName}</span>
        <Icon name="arrow-light-down" fontSize="16px" color={Colors.LINK} />
      </button>
    </li>
  );
};
