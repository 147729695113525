import { ComponentProps, Dispatch, FC, SetStateAction } from "react";
import { Tab } from "@root/shared/components/Tab";
import { Button } from "@root/shared/components/Button";
import { TextButton } from "@root/shared/components/TextButton";
import { ImageLightbox } from "@userFrontend/features/publication/components/AlbumContents/uiParts/ImageLightbox";
import { Icon } from "@root/shared/components/Icon";
import { fontSize } from "@root/shared/styles/sprinkles.css";
import type { ClientImage } from "@userFrontend/features/publication/components/AlbumContents/type";
import * as styles from "./styles.css";

type AlbumContentsProps = {
  isBiyo: boolean;
  albumCategoryImages: ClientImage[];
  tabContents: ComponentProps<typeof Tab>["tabContents"];
  expandedImage?: ClientImage;
  isShowAlbum: boolean;
  isShowWorkDetailBackButton: boolean;
  setUrlHashName: () => void;
  clearUrlHashName: () => void;
  setExpandedImage: Dispatch<SetStateAction<ClientImage | undefined>>;
  handleClickTab: (index: number) => void;
  handleClickArrow: (direction: "next" | "prev") => void;
};

export const Presentation: FC<AlbumContentsProps> = ({
  isBiyo,
  albumCategoryImages,
  tabContents,
  expandedImage,
  isShowAlbum,
  isShowWorkDetailBackButton,
  setUrlHashName,
  clearUrlHashName,
  setExpandedImage,
  handleClickTab,
  handleClickArrow,
}) => {
  return (
    <>
      {!isShowAlbum ? (
        <div className={styles.openAlbumContentButtonWrapper}>
          <Button
            theme="tertiary"
            size="small"
            leftIcon={<Icon name="square-split" fontSize={fontSize["fs-16"]} />}
            onClick={() => setUrlHashName()}
          >
            <div className={styles.openAlbumContentButtonText}>すべての画像を見る</div>
          </Button>
        </div>
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.pageWrapper}>
            <div className={styles.contentWrapper}>
              <div className={styles.headerWrapper}>
                {/* iOSのwebviewは、デフォルトの機能として「戻る」ボタンが搭載されていて、同じものが被るため表示させない */}
                {isShowWorkDetailBackButton && (
                  <TextButton
                    leftIconName="arrow-light-left"
                    size="large"
                    className={styles.backButton}
                    onClick={() => clearUrlHashName()}
                  >
                    戻る
                  </TextButton>
                )}
                <div className={styles.imageIndexText}>画像一覧</div>
              </div>
              <div className={styles.tabContentWrapper}>
                <Tab isBiyo={isBiyo} tabContents={tabContents} handleOnClick={handleClickTab} />
              </div>
            </div>
          </div>

          {expandedImage && (
            <ImageLightbox
              expandedImageUrl={expandedImage.imageUrl}
              expandedImageNo={
                albumCategoryImages.findIndex((image) => image.id === expandedImage.id) + 1
              }
              totalImagesCount={albumCategoryImages.length}
              handleClickBackground={() => setExpandedImage(undefined)}
              handleClickArrow={handleClickArrow}
            />
          )}
        </div>
      )}
    </>
  );
};
