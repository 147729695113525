import { FC } from "react";
import styled from "styled-components";
import { Colors } from "@legacy_user_frontend/utils";

export type SalariesProps = {
  salaryContents: {
    employmentTypeName: string;
    salaryTypeName: string;
    minPrice: string;
    minUnit: string;
    maxPrice: string;
    maxUnit: string;
    isEllipsisDisplay: boolean;
  }[];
};

const StyledSalary = styled.div`
  color: ${Colors.DEEP_BLACK};
  font-size: 1.1rem;
`;

const StyledSalaryNum = styled.span`
  font-size: 1.6rem;
  font-weight: bold;
  color: ${Colors.ORANGE};
`;

export const Salaries: FC<SalariesProps> = ({ salaryContents }) => (
  <StyledSalary>
    {salaryContents.map(
      ({
        employmentTypeName,
        salaryTypeName,
        minPrice,
        minUnit,
        maxPrice,
        maxUnit,
        isEllipsisDisplay,
      }) => (
        <p key={`${employmentTypeName}_${salaryTypeName}`}>
          {employmentTypeName} / {salaryTypeName}
          {(minPrice || maxPrice) && (
            <>
              <StyledSalaryNum>{minPrice}</StyledSalaryNum>
              {minUnit}〜<StyledSalaryNum>{maxPrice}</StyledSalaryNum>
              {maxUnit}
            </>
          )}
          {isEllipsisDisplay && <>…</>}
        </p>
      ),
    )}
  </StyledSalary>
);
