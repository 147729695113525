import { ClientImage } from "@userFrontend/features/publication/components/AlbumContents/type";
import { LazyLoadImage } from "@root/shared/components/LazyLoadImage";
import { Dispatch, FC, SetStateAction } from "react";
import * as styles from "./styles.css";

type AlbumTabContentProps = {
  albumCategoryImages: ClientImage[];
  setExpandedImage: Dispatch<SetStateAction<ClientImage | undefined>>;
};

// Tabコンポーネントの中身を表示させるコンポーネント。index.tsxファイルに書くのは違うのでこちらのファイルに切り出した。
// シンプルな内容なのでstorybookは作成しません
export const AlbumTabContent: FC<AlbumTabContentProps> = ({
  albumCategoryImages,
  setExpandedImage,
}) => {
  return (
    <div className={styles.imageContentWrapper}>
      {albumCategoryImages.map((image) => (
        <div
          key={image.id}
          className={styles.imageWrapper}
          onClick={() => setExpandedImage(image)}
          aria-hidden="true"
        >
          <LazyLoadImage
            src={image.imageUrl}
            alt="企業が登録している画像"
            width={118}
            height={68}
          />
        </div>
      ))}
    </div>
  );
};
