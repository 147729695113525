import { FC } from "react";
import { ActionProps } from "@legacy_user_frontend/components/pages/jobs/job_search_navigation/src/search_modal/src/search_modal_props";
import { isDefaultChecked } from "./modules";
import { AccordionLists } from "./ui_parts/accordion_lists";
import { ModalLink } from "./ui_parts/modal_link";
import {
  StyledBgOuter,
  StyledCityParentOuter,
  StyledCityParentLabel,
  StyledCheckBox,
  StyledAccordionLabel,
  StyledCityAccordionButton,
  StyledCityChildOuter,
  StyledChildLists,
} from "./src/styled_elements";

export type CityModalProps = {
  cityContents: {
    name: string;
    value: string;
    url: string;
    showLink: boolean;
    towns: {
      name: string;
      value: string;
      url: string;
      showLink: boolean;
    }[];
  }[];
  setIsAccordionOpen: React.Dispatch<React.SetStateAction<Record<number, boolean>>>;
  isAccordionOpen: Record<number, boolean>;
  dispatchFormState: React.Dispatch<ActionProps>;
  formState: Record<string, { url: string }>;
};

export const CityModal: FC<CityModalProps> = ({
  cityContents,
  setIsAccordionOpen,
  isAccordionOpen,
  dispatchFormState,
  formState,
}) => {
  const toggleAccordion = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAccordionOpen({
      ...isAccordionOpen,
      [e.target.value]: e.target.checked,
    });
  };
  const handleOnCityStateChange = (e: React.ChangeEvent<HTMLInputElement>, url: string) => {
    if (e.target.checked) {
      dispatchFormState({
        type: "selectCities",
        value: e.target.value,
        url,
      });
    } else {
      dispatchFormState({ type: "unSelectCities", value: e.target.value });
    }
  };
  return (
    <>
      {cityContents.map(({ name, value, url, showLink, towns }, index) => {
        // 「その他」のカテゴリしかない場合
        if (cityContents.length === 1) {
          return (
            <StyledBgOuter>
              <StyledChildLists>
                <AccordionLists
                  contents={towns}
                  formState={formState}
                  checkBoxName="q[city][]"
                  changeEvent={handleOnCityStateChange}
                />
              </StyledChildLists>
            </StyledBgOuter>
          );
        }
        // 複数のカテゴリがある場合
        // 「その他」のカテゴリ以外 (ex:「23区」など)
        if (value) {
          return (
            <StyledBgOuter key={value}>
              <StyledCityParentOuter>
                <StyledCityParentLabel>
                  <StyledCheckBox
                    type="checkbox"
                    value={value}
                    name="q[city][]"
                    onChange={(e) => handleOnCityStateChange(e, "")}
                    defaultChecked={isDefaultChecked(formState, value)}
                  />
                  <span>{name}</span>
                  {showLink && <ModalLink url={url} isTitleLink />}
                </StyledCityParentLabel>
                <StyledAccordionLabel>
                  <StyledCityAccordionButton
                    type="checkbox"
                    onChange={toggleAccordion}
                    value={index}
                    name="accordion"
                    defaultChecked={isAccordionOpen[index]}
                  />
                </StyledAccordionLabel>
              </StyledCityParentOuter>
              <StyledCityChildOuter isOpen={isAccordionOpen[index]}>
                <StyledChildLists>
                  <AccordionLists
                    contents={towns}
                    formState={formState}
                    checkBoxName="q[city][]"
                    changeEvent={handleOnCityStateChange}
                  />
                </StyledChildLists>
              </StyledCityChildOuter>
            </StyledBgOuter>
          );
        }
        // 複数のカテゴリがある場合
        // 「その他」のカテゴリ
        return (
          <StyledBgOuter key={name}>
            <StyledCityParentOuter>
              <StyledCityParentLabel>
                <span>{name}</span>
              </StyledCityParentLabel>
              <StyledAccordionLabel>
                <StyledCityAccordionButton
                  type="checkbox"
                  onChange={toggleAccordion}
                  value={index}
                  name="accordion"
                  defaultChecked={isAccordionOpen[index]}
                />
              </StyledAccordionLabel>
            </StyledCityParentOuter>
            <StyledCityChildOuter isOpen={isAccordionOpen[index]}>
              <StyledChildLists>
                <AccordionLists
                  contents={towns}
                  formState={formState}
                  checkBoxName="q[city][]"
                  changeEvent={handleOnCityStateChange}
                />
              </StyledChildLists>
            </StyledCityChildOuter>
          </StyledBgOuter>
        );
      })}
    </>
  );
};
