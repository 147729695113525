import { FC, MouseEvent } from "react";
import { Presentation } from "./Presentation";
import { Content } from "./type";

type Props = {
  contents: Content[];
};

const STICKY_ANCHOR_LINKS_HEIGHT = 56;

export const TableOfContents: FC<Props> = ({ contents }) => {
  const handleOnClick = (event: MouseEvent<HTMLElement>, link: string) => {
    event.preventDefault();
    const targetElement = document.getElementById(link.replace("#", ""));
    if (!targetElement) return;
    const rect = targetElement.getBoundingClientRect().top;
    const offset = window.scrollY;

    window.scrollTo({
      top: rect + offset - STICKY_ANCHOR_LINKS_HEIGHT,
      behavior: "smooth",
    });
  };

  return <Presentation handleOnClick={handleOnClick} contents={contents} />;
};
