// 配列からプルダウン用のObjectを作成する
export const arrayToObjects = (
  list: (string | number)[][],
): {
  key: string;
  value: number;
}[] =>
  list.map((value: (string | number)[]) => ({
    key: value[0] as string,
    value: value[1] as number,
  }));
