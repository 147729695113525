import { FC } from "react";
import { OsType, SiteType } from "@root/shared/types/type";
import { APattern } from "./variables/APattern";
import { BPattern } from "./variables/BPattern";
import { mainTextStyle } from "./styles.css";
import { GaScreenName } from "./type";

type Props = {
  isLogin: boolean;
  downloadUrl: string;
  osType: OsType;
  siteType: SiteType;
  gaScreenName: GaScreenName;
  requestFullPath: string;
};

export const SmartAppBanner: FC<Props> = ({
  isLogin,
  osType,
  siteType,
  downloadUrl,
  gaScreenName,
  requestFullPath,
}) => {
  switch (gaScreenName) {
    case "login": // ログイン画面
      return (
        <APattern
          isLogin={isLogin}
          downloadUrl={downloadUrl}
          gaScreenName={gaScreenName}
          osType={osType}
          siteType={siteType}
          requestFullPath={requestFullPath}
        />
      );
    case "search_result_list": // 検索結果一覧画面
      return (
        <BPattern
          isLogin={isLogin}
          downloadUrl={downloadUrl}
          gaScreenName={gaScreenName}
          siteType={siteType}
          gaAppealPattern="appeal_smoothness"
        >
          <span className={mainTextStyle}>
            アプリならサクサク動作で
            <br />
            使いやすい！
          </span>
        </BPattern>
      );
    default:
      return (
        <BPattern
          isLogin={isLogin}
          downloadUrl={downloadUrl}
          gaScreenName={gaScreenName}
          siteType={siteType}
          gaAppealPattern="appeal_employment_rate"
        >
          <span className={mainTextStyle}>アプリなら採用率1.75倍！</span>
        </BPattern>
      );
  }
};
