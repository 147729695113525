import { FC } from "react";
import { Link } from "@root/shared/components/Link";
import { Icon } from "@root/shared/components/Icon";
import { LazyLoadImage } from "@root/shared/components/LazyLoadImage";
import * as styles from "./styles.css";

type Props = {
  url: string;
  text: string;
  theme: "line" | "lineWhite";
};

export const LineLink: FC<Props> = ({ url, text, theme }) => {
  return (
    <div className={styles.wrapper}>
      <Link theme={theme} href={url} target="_blank">
        {theme === "line" ? (
          <div className={styles.iconWrapper}>
            <Icon name="line" />
          </div>
        ) : (
          <div className={styles.logoWrapper}>
            <LazyLoadImage src="/images/shared/LINE_Brand_icon.png" height={24} width={24} />
          </div>
        )}
        {text}
      </Link>
    </div>
  );
};
