import { FC } from "react";
import * as styles from "./styles.css";

export type LabelProps = {
  /**
   *
   * ラベルのサイズ
   */
  size?: "extraSmall" | "small" | "medium" | "large";
  /**
   *
   * 塗りつぶしのスタイル
   */
  filledType?: "contained" | "containedDarkText" | "outlined";
  /**
   *
   * ラベルの色
   */
  color: "blue" | "orange" | "lightOrange" | "yellow" | "pink" | "lightPink" | "deepPink" | "alert";
  /**
   *
   * ラベルを強調するかどうか
   */
  bold?: boolean;
  /**
   *
   * 角丸にするかどうか
   * 角丸のスタイル
   */
  round?: "none" | "slight" | "standard";
  /**
   * ラベル内に表示する内容
   */
  children: React.ReactNode;
  /**
   * コンポーネントに適用するクラス名
   */
  className?: string;
};

export const Label: FC<LabelProps> = ({
  size = "small",
  filledType = "contained",
  color,
  bold = true,
  round = "none",
  children,
  className,
}) => (
  <span
    className={`
      ${styles.label({
        size,
        filledType,
        isBold: bold,
        round,
      })}
      ${styles[color]}
      ${className || ""}
    `}
  >
    {children}
  </span>
);
