import { FC, ReactElement } from "react";
import styled from "styled-components";

type Props = {
  children: ReactElement[] | ReactElement;
};

export const StyledDefinitionList = styled.dl`
  > dt {
    position: relative;
  }

  > dd {
    margin-top: 8px;

    & + dt {
      margin-top: 24px;
    }
  }

  .required-label-outer {
    padding-left: 4px;
  }

  .private-label-outer {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
`;

export const DefinitionList: FC<Props> = ({ children }) => (
  <StyledDefinitionList>{children}</StyledDefinitionList>
);
