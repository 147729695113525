import { Banner } from "@root/shared/components/Banner";
import { FC } from "react";

type Props = {
  href: string;
  src: string;
  alt: string;
  dummyImgPath: string;
  onClick?: () => void;
};

export const Presentation: FC<Props> = ({ href, src, alt, dummyImgPath, onClick }) => {
  return (
    <Banner
      href={href}
      src={src}
      alt={alt}
      dummyImgPath={dummyImgPath}
      height={120}
      width={600}
      onClick={onClick}
    />
  );
};
