import { FC } from "react";
import * as styles from "./styles.css";

type Props = {
  remainingDaysNum: number;
};

export const ScoutDeadlineInformation: FC<Props> = ({ remainingDaysNum }) => {
  return (
    <p className={styles.container}>
      スカウトの受付期限まであと<span className={styles.numText}>{remainingDaysNum}</span>日
    </p>
  );
};
