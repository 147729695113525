import { FC } from "react";
import { GuidanceBox } from "@userFrontend/components/GuidanceBox";
import { USER_REWARD_NAME } from "@root/utils/constants";
import * as styles from "./styles.css";

export const Completed: FC = () => {
  return (
    <div className={styles.commonContainer}>
      <h3 className={styles.heading}>申請を下記の内容で受け付けました。</h3>
      <p>受理されましたら、ご登録のメールアドレスまでご連絡致します。</p>
      <div className={styles.boxWrapper}>
        <GuidanceBox
          headingText="今後の流れについて"
          textArray={[
            "(1)申請時に添付していただいた、勤務継続の証明資料より、1年間の勤務継続の確認が取れた後、楽天銀行よりご案内のメールが送付されます。",
            `(2)1のメールにて、${USER_REWARD_NAME}のご送金先口座のご指定をお願いいたします。`,
            "(3)ご指定いただいた口座にご送金されます。 　※なお、楽天銀行の口座をお持ちの方は自動的にそちらにご送金となります。",
          ]}
        />
      </div>
      <div className={styles.boxWrapper}>
        <GuidanceBox
          headingText="注意事項"
          textArray={[
            "(1)下記のいずれかのメールアドレスより、ご案内が届きますので、ドメイン指定の解除をお願いいたします。",
            "pre_reg@ac.rakuten-bank.co.jp",
            "service@ac.rakuten-bank.co.jp",
            "thanks@ac.rakuten-bank.co.jp",
            `(2)下記に該当する場合、${USER_REWARD_NAME}が受け取れない可能性があります。`,
            `・${USER_REWARD_NAME}申請後にアドレスの変更を行う、ドメイン指定を解除しないなど、楽天銀行のご案内メールを受け取れない場合`,
            "・申請いただいた際のアドレス、お名前、口座名義がご本人様のものと照会できない場合",
            "・申請時に提出いただいた資料が客観的な資料ではない（例.手書きの資料など)場合や、資料からは入社日から１年間の勤務を継続していると判断できない場合",
            `・1回目の${USER_REWARD_NAME}の給付を受けていない場合`,
          ]}
        />
      </div>
    </div>
  );
};
