/* eslint-disable react/jsx-props-no-spreading */
import { FC, ChangeEvent } from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import { Colors } from "@legacy_user_frontend/utils";

type Props = {
  name: string;
  errorName?: string;
  placeholder: string;
  requiredMessage?: string;
  isError?: boolean;
  isDisabled?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  list: {
    key: string | number;
    value: string | number;
  }[];
};

type StyledPulldownProps = {
  isUnselected: boolean;
  isError?: boolean;
};

export const StyledPulldown = styled.select<StyledPulldownProps>`
  appearance: none;
  width: 100%;
  height: 44px;
  padding: 0 8px;
  border: 1px solid ${({ isError }) => (isError ? Colors.ALERT_RED : Colors.MEDIUM_GREY)};
  border-radius: 0;
  background-image: url("/images/user_frontend/global/icon/icn_arrow_down.svg");
  background-repeat: no-repeat;
  background-position: top 50% right calc(4px + 2%);
  background-size: 12px;
  background-color: ${Colors.WHITE};
  box-shadow: ${({ isError }) => (isError ? `0px 0px 8px ${Colors.ALERT_RED}` : "none")};
  font-size: 14px;
  outline: none;
  color: ${({ isUnselected }) => (isUnselected ? Colors.BLACK : Colors.DEEP_GREY)};
  transition:
    border-color 0.1s,
    box-shadow 0.1s;

  &:focus {
    border-color: ${Colors.FOCUS_BLUE};
    box-shadow:
      0px 0px 8px ${Colors.FOCUS_BLUE},
      inset 0px 0px 1px ${Colors.FOCUS_BLUE};
  }

  &:disabled {
    background-image: url("/images/user_frontend/global/icon/icn_arrow_down.svg");
    background-repeat: no-repeat;
    background-position: top 50% right 12px;
    background-size: 12px;
    background-color: ${Colors.LIGHT_GREY};
    color: ${Colors.LIGHT_BLACK};
  }
`;

export const Pulldown: FC<Props> = ({
  name,
  errorName,
  placeholder,
  requiredMessage,
  isError,
  isDisabled,
  onChange,
  list,
}) => {
  const { register, clearErrors, watch } = useFormContext();
  // エラーメッセージの削除
  const deleteErrorMessage = () => {
    if (errorName) {
      // この項目と連動する他のエラーメッセージを削除する場合はerrorNameで指定する
      clearErrors(errorName);
    }
    clearErrors(name);
  };
  const isUnselected = watch(name);

  return (
    <StyledPulldown
      isUnselected={!!isUnselected}
      isError={isError}
      {...register(name, {
        required: requiredMessage,
        disabled: isDisabled,
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          if (onChange) onChange(e);
          deleteErrorMessage();
        },
      })}
    >
      <option value="">{placeholder}</option>
      {list &&
        list.length > 0 &&
        list.map((item) => (
          <option value={item.value} key={`${item.key}_${item.value}`}>
            {item.key}
          </option>
        ))}
    </StyledPulldown>
  );
};
