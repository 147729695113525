import { FC, useState } from "react";
import { Accordion } from "@root/shared/components/Accordion";
import { TextAreaWithWordCounter } from "@userFrontend/components/TextAreaWithWordCounter";
import * as styles from "./styles.css";

type InputProps = {
  textAreaValue: string | null;
  errorMessage: string | null;
};

type Props = {
  inputs: Record<string, InputProps>;
  wholeErrorMessage: string | null;
};

export const AboutWorkplaceInputs: FC<Props> = ({ inputs, wholeErrorMessage }) => {
  const [textAreaValues, setTextAreaValues] = useState<Record<string, string>>({
    workplaceMatching: inputs.workplaceMatching.textAreaValue ?? "",
    expectation: inputs.expectation.textAreaValue ?? "",
    interestReward: inputs.interestReward.textAreaValue ?? "",
    challenge: inputs.challenge.textAreaValue ?? "",
  });
  const [isAccordionOpen, setIsAccordionOpen] = useState({
    workplaceMatching: Boolean(inputs.workplaceMatching.textAreaValue),
    expectation: Boolean(inputs.expectation.textAreaValue),
    interestReward: Boolean(inputs.interestReward.textAreaValue),
    challenge: Boolean(inputs.challenge.textAreaValue),
  });
  const toggleAccordion = (
    key: "workplaceMatching" | "expectation" | "interestReward" | "challenge",
  ) => {
    setIsAccordionOpen((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const mergeInputs = (
    key: "workplaceMatching" | "expectation" | "interestReward" | "challenge",
    title: string,
    placeholder: string,
    textAreaName: string,
  ) => {
    return {
      key,
      title,
      placeholder,
      textAreaName,
      errorMessage: inputs[key].errorMessage,
    };
  };

  const AccordionContents = [
    mergeInputs(
      "workplaceMatching",
      "この職場に合う人はどんな人ですか？",
      "例）\nある程度個人の裁量で仕事ができます。そのため、自分の仕事のスタイルを持っている人には合うと思います。\nまた、指導や社員教育にも力を入れている会社なので、自分から学び・成長意欲のある方にもおすすめの会社です。",
      "form_user_frontend_user_reward_create[workplace_matching]",
    ),
    mergeInputs(
      "interestReward",
      "この職場で働くことの面白み・やりがいはどんなところですか？",
      "例）\nこの職場の土地柄もあり、様々なお客様がご来店くださいます。職業や年齢も異なる方と多く出会えるのは大きな魅力です。\nまた、職場の中で従業員同士の連携がスムーズなことでサービスや技術をお客様に気に入っていただけたときはやりがいを感じました。",
      "form_user_frontend_user_reward_create[interest_reward]",
    ),
    mergeInputs(
      "challenge",
      "この職場だからこそ挑戦できること・挑戦したいと思えることは何ですか？",
      "例）\nこの職場には充実した研修制度があるため、私はこの制度を活用しながら技術を磨き、多くの顧客を獲得することができました。\nまた研修後のフォローアップもあるため、現在は弱点の克服をしながら長所を伸ばし、さらなる技術力向上に挑戦中です。",
      "form_user_frontend_user_reward_create[challenge]",
    ),
    mergeInputs(
      "expectation",
      "この職場にこれから期待することは何ですか？",
      "例）\n職場のスタッフの人数が多くないため、一人ひとりへの技術指導や教育がしっかり行き渡らないことが稀にあります。\n技術指導や教育の時間がないときは雑務を任されることが多いため、その時間は個人の練習が出来たらいいなと思います。",
      "form_user_frontend_user_reward_create[expectation]",
    ),
  ];

  const handleTextAreaChange = (category: string, value: string) => {
    setTextAreaValues((prevState) => ({
      ...prevState,
      [category]: value,
    }));
  };

  return (
    <>
      {/* ex.「少なくとも一つに回答してください」のような包括したエラーメッセージを期待しています。 */}
      {wholeErrorMessage && <p className={styles.errorMsg}>{wholeErrorMessage}</p>}
      {AccordionContents.map(({ key, title, textAreaName, placeholder, errorMessage }) => (
        <div className={styles.accordionBox} key={key}>
          <Accordion
            title={title}
            isOpened={isAccordionOpen[key]}
            onClick={() => toggleAccordion(key)}
          >
            <>
              {errorMessage && <p className={styles.errorMsg}>{errorMessage}</p>}
              <TextAreaWithWordCounter
                name={textAreaName}
                size="large"
                className={styles.textArea}
                placeholder={placeholder}
                value={textAreaValues[key] ?? ""}
                counterMaxLength={100}
                categoryName={key}
                handleTextAreaChange={handleTextAreaChange}
              />
            </>
          </Accordion>
        </div>
      ))}
    </>
  );
};
