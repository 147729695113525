import { FC, lazy, Suspense, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  RequiredLabel,
  TextField,
  ValidationErrorMessage,
  Pulldown,
} from "@legacy_user_frontend/components/ui_parts";
import { BoxWithShadow } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/box_with_shadow";
import { DefinitionList } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/definition_list";
import { DeleteTextLinkButton } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/delete_text_button";
import {
  StyledCassetteHeadingOuter,
  StyledCassetteHeading,
  StyledErrorMessageOuter,
  StyledDateSelectOuter,
} from "@legacy_user_frontend/components/pages/mypage/profiles/achievement_edit/src/styled_elements";
import { CassetteProps } from "@legacy_user_frontend/components/pages/mypage/profiles/achievement_edit/src/achievement_edit_props";

const ConfirmModal = lazy(
  () =>
    import(
      "@legacy_user_frontend/components/pages/mypage/profiles/achievement_edit/src/confirm_modal"
    ),
);

export const Cassette: FC<CassetteProps> = ({
  cassetteIndex,
  removeCassette,
  formName,
  yearList,
  monthList,
}) => {
  // バリデーションエラー周りの処理
  const {
    formState: { errors },
  } = useFormContext();
  const validateErrors = errors?.form_user_achievement_collection?.user_achievements_attributes;
  const fieldError = validateErrors && validateErrors.length && validateErrors[cassetteIndex];
  const nameError = fieldError && fieldError.name;
  const yearError = fieldError && fieldError.year;
  const monthError = fieldError && fieldError.month;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <BoxWithShadow>
      <StyledCassetteHeadingOuter id={`cassette-${cassetteIndex}`}>
        <StyledCassetteHeading>受賞・表彰歴 {1 + cassetteIndex}</StyledCassetteHeading>
        <DeleteTextLinkButton type="button" onClick={openModal} size="small">
          削除する
        </DeleteTextLinkButton>
      </StyledCassetteHeadingOuter>
      <DefinitionList>
        <dt>
          名称
          <span className="required-label-outer">
            <RequiredLabel />
          </span>
        </dt>
        <dd>
          <TextField
            name={`${formName}[${cassetteIndex}][name]`}
            placeholder="例）リジョブコンテスト最優秀賞"
            isError={!!nameError}
          />
          {nameError && nameError.message && (
            <StyledErrorMessageOuter>
              <ValidationErrorMessage message={nameError.message} />
            </StyledErrorMessageOuter>
          )}
        </dd>
        <dt>年月</dt>
        <dd>
          <StyledDateSelectOuter>
            <Pulldown
              name={`${formName}[${cassetteIndex}][year]`}
              placeholder="年"
              list={yearList}
              isError={!!yearError}
              errorName={`${formName}[${cassetteIndex}][name]`}
            />
            <Pulldown
              name={`${formName}[${cassetteIndex}][month]`}
              placeholder="月"
              list={monthList}
              isError={!!monthError}
              errorName={`${formName}[${cassetteIndex}][name]`}
            />
          </StyledDateSelectOuter>
          {(yearError || monthError) && (
            <StyledErrorMessageOuter>
              {yearError && yearError.message && (
                <ValidationErrorMessage message={yearError.message} />
              )}
              {monthError && monthError.message && (
                <ValidationErrorMessage message={monthError.message} />
              )}
            </StyledErrorMessageOuter>
          )}
        </dd>
      </DefinitionList>
      <Suspense fallback={null}>
        {isModalOpen && (
          <ConfirmModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            cassetteIndex={cassetteIndex}
            removeCassette={removeCassette}
          />
        )}
      </Suspense>
    </BoxWithShadow>
  );
};
