import { FC } from "react";
import { Button } from "@root/shared/components/Button";
import * as styles from "./styles.css";

type Props = {
  text: string;
  buttonTheme?: "secondary" | "tertiary";
  gaAction?: string;
  gaLabel?: string;
  onClick?: () => void;
  className?: string;
};

export const SalonTourButton: FC<Props> = ({
  text,
  buttonTheme = "tertiary",
  gaAction,
  gaLabel,
  onClick,
  className = "",
}) => {
  return (
    <Button
      theme={buttonTheme}
      className={`${className} ${styles.button}`}
      data-ga-action={gaAction}
      data-ga-label={gaLabel}
      onClick={onClick}
    >
      <i className={`c-icn c-icn-shop ${styles.icon}`} />
      <span className={styles.text}>{text}</span>
    </Button>
  );
};
