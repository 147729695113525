import { FC } from "react";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import * as styles from "./styles.css";

type Props = {
  children: React.ReactNode;
  width?: string;
  height?: string;
  className?: string;
};

export const Cassette: FC<Props> = ({ children, width, height, className }) => {
  return (
    <div
      className={`${styles.cassette} ${className || ""}`}
      style={assignInlineVars({
        [styles.width]: width || "",
        [styles.height]: height || "",
      })}
    >
      {children}
    </div>
  );
};
