import { FC } from "react";
import * as styles from "./styles.css";

type Props = {
  /** テーブルの内容 */
  children?: React.ReactNode;
  /** コンポーネントに適用するクラス名 */
  className?: string;
  /** SP表示時に横並びにする */
  isSpHorizontal?: boolean;
  elementRef?: React.RefObject<HTMLTableElement>;
};

export const Table: FC<Props> = ({ children, className, isSpHorizontal = false, elementRef }) => (
  <table
    className={`
      ${styles.table}
      ${className || ""}
    `}
    ref={elementRef}
  >
    <tbody className={isSpHorizontal ? styles.isSpHorizontal : undefined}>{children}</tbody>
  </table>
);
