import styled from "styled-components";
import { Colors } from "@legacy_user_frontend/utils";
import { UnstyledButton } from "@legacy_user_frontend/components/ui_parts";

export const StyledEditLinkOuter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;

  > a {
    flex-basis: 7em;
    flex-shrink: 0;
    margin-left: 4px;
  }

  > span {
    font-weight: bold;
  }
`;

export const StyledSmallText = styled.span`
  font-size: 10px;
`;

export const StyledPostCodeOuter = styled.div`
  display: flex;
  justify-content: space-between;

  > input {
    flex-basis: calc(100% - 194px);
  }
`;

export const StyledPostCodeButton = styled(UnstyledButton)`
  display: inline;
  background-color: transparent;
  font-size: 14px;
  color: ${Colors.LINK_BLUE_OLD_02};

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledErrorMessageOuter = styled.div`
  margin-top: 10px;
`;
