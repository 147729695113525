import { FC } from "react";
import { TelButton } from "@legacy_user_frontend/components/projects/button_contents/src/tel_button";
import {
  ScoutButton,
  ScoutButtonProps,
} from "@legacy_user_frontend/components/projects/button_contents/src/scout_button";
import {
  StyledEntryButton,
  StyledFixBtnList,
  StyledFixTelButtonOuter,
  StyledFixEntryButtonOuter,
  StyledFixTelBtn,
} from "@legacy_user_frontend/components/projects/button_contents/src/styled_elements";
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
declare const $: any;

type FixButtonProps = {
  scoutButtonContent: ScoutButtonProps["scoutButtonContent"];
  entryButtonContent: {
    isEnterable: boolean;
    hasEntried: boolean;
    entryUrl: string;
  };
  telNumberContent: {
    isShowTellButton: boolean;
    telNumber: string;
  };
  gaLabel: string;
  queryStr: string;
  workCode: string;
  scoutGaLabel: string;
  clientShopId: number | string;
  pageType: string;
  capitalizePageType: string;
  isApp: boolean;
  dataStyleColumn: number;
  isPreview: boolean;
};

export const FixButton: FC<FixButtonProps> = ({
  scoutButtonContent,
  entryButtonContent: { isEnterable, hasEntried, entryUrl },
  telNumberContent,
  gaLabel,
  queryStr,
  workCode,
  scoutGaLabel,
  clientShopId,
  pageType,
  capitalizePageType,
  isApp,
  dataStyleColumn,
  isPreview,
}) => {
  const { isScouted } = scoutButtonContent;
  const { isShowTellButton, telNumber } = telNumberContent;
  // 電話応募ボタンクリック時にクリックされた求人情報を送信
  const handleOnClick = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    $.fn.sendTelButtonAction({
      code: workCode,
      client_shop_id: clientShopId,
    });
  };
  return (
    <>
      {workCode && (
        <StyledFixBtnList className="js-footer-fix__btn">
          {!isScouted && isEnterable && (
            <>
              {isShowTellButton && (
                <StyledFixTelButtonOuter>
                  <TelButton
                    telNumber={telNumber}
                    position="fixed"
                    clientShopId={clientShopId}
                    gaLabel={gaLabel}
                    pageType={pageType}
                    capitalizePageType={capitalizePageType}
                    workCode={workCode}
                    isPreview={isPreview}
                  />
                </StyledFixTelButtonOuter>
              )}
              <StyledFixEntryButtonOuter is2column={isShowTellButton}>
                <StyledEntryButton
                  href={entryUrl}
                  className="ga-click repro-click firebase-click disable-on-preview js-add-query-strings"
                  data-ga-action={`${pageType}_entry-fixed`}
                  data-ga-label={gaLabel}
                  data-repro-action={`trackTapJob$${capitalizePageType}Entry`}
                  data-firebase-action="entry"
                  data-firebase-view-type="screen_view"
                  data-query-str={queryStr}
                  isFixed
                >
                  応募画面へ進む
                </StyledEntryButton>
              </StyledFixEntryButtonOuter>
            </>
          )}
          {isScouted && (
            <ScoutButton
              scoutButtonContent={scoutButtonContent}
              scoutGaLabel={scoutGaLabel}
              hasEntried={hasEntried}
              workCode={workCode}
              position="fixed"
              isFixedButton
              pageType={pageType}
              isApp={isApp}
              dataStyleColumn={dataStyleColumn}
            />
          )}
        </StyledFixBtnList>
      )}
      {!workCode && isShowTellButton && (
        <StyledFixBtnList className="js-footer-fix__btn">
          <StyledFixTelBtn
            href={`tel:${telNumber}`}
            className="ga-click repro-click disable-on-preview"
            data-ga-action={`${pageType}_entry_tel-fixed`}
            data-ga-label={gaLabel}
            data-repro-action={`trackTapJob${capitalizePageType}EntryTel`}
            data-client-shop-id={clientShopId}
            onClick={handleOnClick}
          >
            <b>
              <i className="icon-phone" />
              電話で応募する
            </b>
          </StyledFixTelBtn>
        </StyledFixBtnList>
      )}
    </>
  );
};
