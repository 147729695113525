import { FC } from "react";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import * as styles from "./styles.css";

type Props = {
  name: string;
  color?: string;
  fontSize?: string;
};

export const Icon: FC<Props> = ({ name, color, fontSize }) => (
  <i
    className={`c-icn c-icn-${name} ${styles.iconColor} ${styles.iconFontSize}`}
    style={assignInlineVars({
      [styles.color]: color || "",
      [styles.fontSize]: fontSize || "",
    })}
  />
);
