import { useState, useCallback } from "react";
import { removeSpacesTabsNewlines } from "@root/utils/removeSpacesTabsNewlines";
import { countCharacters } from "@root/utils/countCharacters";

// カスタムフックの戻り値の型定義
type UseCharacterCountReturn = {
  text: string;
  setText: React.Dispatch<React.SetStateAction<string>>;
  count: number;
  handleChange: (value: string) => void;
};

/**
 * useCharacterCount - テキスト入力とその文字数カウントを管理するカスタムフック。
 *
 * @param {string} initialValue - テキスト入力の初期値（デフォルトは空の文字列）。
 * @returns {UseCharacterCountReturn} - テキストとその文字数カウント、および入力変更ハンドラー。
 */
export const useCharacterCount = (initialValue = ""): UseCharacterCountReturn => {
  // テキストの状態を管理するuseState
  const [text, setText] = useState<string>(initialValue);
  // 文字数カウントの状態を管理するuseState
  const [count, setCount] = useState<number>(() =>
    countCharacters(removeSpacesTabsNewlines(initialValue)),
  );

  // テキスト入力フィールドの変更を処理する関数
  // useCallbackを使用して、不要な再作成を防ぐ
  const handleChange = useCallback((value: string): void => {
    setText(value);
    // カウントしない文字を排除した文字数をカウント
    setCount(countCharacters(removeSpacesTabsNewlines(value)));
  }, []);

  return { text, setText, count, handleChange };
};
