import { FC, useState, useEffect } from "react";
import { axiosPost } from "@root/utils/axiosForRails";
import { SiteId } from "@root/shared/types/type";
import { Presentation } from "./Presentation";

type Data = {
  siteId: SiteId;
  clientId: number;
  clientName: string;
  businessTypeId: number;
  employmentStatus: number;
  continuedServiceYears: number;
  employmentId: number;
  atmosphere: number;
  salaryStandard: number;
  debut: number;
  education: number;
  workTime: number;
  salaryChange: number;
  workplaceMatching: string;
  interestReward: string;
  challenge: string;
  expectation: string;
  treatments: number[];
  cultures: number[];
};

type RestoreData = Data;

type Response = {
  status: number;
  data: Data;
};

type Props = {
  isShowBackupModal: boolean;
  restoreBackupPath: string;
  deleteBackupPath: string;
  handleRestore: (data: RestoreData) => void;
};

export const RestoreClientReviewInputsModal: FC<Props> = ({
  isShowBackupModal,
  restoreBackupPath,
  deleteBackupPath,
  handleRestore,
}) => {
  const [isShow, setIsShow] = useState(isShowBackupModal);

  const restoreBackup = async () => {
    const response: Response = await axiosPost.post(restoreBackupPath);
    setIsShow(false);
    if (response.status === 200) {
      // レスポンスデータを親コンポーネントに渡す
      handleRestore(response.data);
    }
  };

  const deleteBackup = async () => {
    await axiosPost.post(deleteBackupPath);
    setIsShow(false);
  };

  useEffect(() => {
    setIsShow(isShowBackupModal);
  }, [isShowBackupModal]);

  return (
    <Presentation
      isShow={isShow}
      closeModalHandler={() => setIsShow(false)}
      restoreBackupHandler={restoreBackup}
      deleteBackupHandler={deleteBackup}
    />
  );
};
