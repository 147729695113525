import { FC } from "react";
import { Button } from "@root/shared/components/Button";

type Props = {
  children: React.ReactNode;
  className?: string;
  gaAction: string;
  gaLabel: string;
  onClick?: () => void;
};

export const ExtendScoutDeadlineButton: FC<Props> = ({
  children,
  className,
  gaAction,
  gaLabel,
  onClick,
}) => (
  <Button
    theme="tertiary"
    className={className}
    onClick={onClick}
    data-ga-label={gaLabel}
    data-ga-action={gaAction}
  >
    {children}
  </Button>
);
