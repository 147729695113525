import { FC } from "react";
import styled from "styled-components";
import { Breakpoints, Colors } from "@legacy_user_frontend/utils";

export type StatusLabelProps = {
  premiumScoutFlag: boolean;
  newContentFlag: boolean;
  publishingEndUpCloseFlag: boolean;
  scoutFlag: boolean;
};

const LabelList = styled.ul`
  position: absolute;
  right: 0;
  z-index: 1;

  ${Breakpoints.MEDIUM} {
    margin: -16px;
    text-align: right;
    position: static;
  }
`;

const LabelListItem = styled.li`
  display: inline-block;
`;

const Label = styled.span`
  font-size: 12px;
  padding: 5px 8px;
  color: ${Colors.WHITE};
  display: inline-flex;
  margin-left: 4px;
`;

const LabelPremiunScout = styled(Label)`
  background: ${Colors.LIGHT_RED};
`;

const LabelNew = styled(Label)`
  ${Label};
  background: ${Colors.MEDIUM_YELLOW};
`;

const LabelNearEnd = styled(Label)`
  ${Label};
  background: ${Colors.MEDIUM_PINK};
`;

const LabelScout = styled(Label)`
  ${Label};
  background: ${Colors.MEDIUM_ORANGE};
`;

export const StatusLabels: FC<StatusLabelProps> = ({
  premiumScoutFlag,
  newContentFlag,
  publishingEndUpCloseFlag,
  scoutFlag,
}) => (
  <LabelList>
    {premiumScoutFlag && (
      <LabelListItem>
        <LabelPremiunScout>プレミアムスカウト</LabelPremiunScout>
      </LabelListItem>
    )}
    {newContentFlag && (
      <LabelListItem>
        <LabelNew>NEW</LabelNew>
      </LabelListItem>
    )}
    {publishingEndUpCloseFlag && (
      <LabelListItem>
        <LabelNearEnd>掲載終了間近</LabelNearEnd>
      </LabelListItem>
    )}
    {scoutFlag && !premiumScoutFlag && (
      <LabelListItem>
        <LabelScout>スカウト</LabelScout>
      </LabelListItem>
    )}
  </LabelList>
);
