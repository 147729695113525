import { FC, ReactNode } from "react";
import * as styles from "./styles.css";

type Size = styles.SizeVariants;

type Props = {
  className?: string;
  tag: React.ElementType;
  hasBackgroundColor?: boolean;
  icon?: ReactNode;
  children: ReactNode;
  label?: ReactNode;
  description?: ReactNode;
  button?: ReactNode;
} & Size;

export const Heading: FC<Props> = ({
  className = "",
  tag: Tag,
  hasBackgroundColor = false,
  size = "large",
  icon,
  children,
  label,
  description,
  button,
}) => (
  <div
    className={`
      ${styles.headingOuter({
        background: size === "medium" && hasBackgroundColor ? "gray" : "none",
      })}
      ${className}
    `}
  >
    <div
      className={`${styles.headingAndLabel} ${
        size === "medium" && hasBackgroundColor ? styles.headingAndLabelWithBackground : ""
      }`}
    >
      <Tag className={styles.heading({ size })}>
        {icon && <span className={styles.iconOuter({ size })}>{icon}</span>}
        {children}
      </Tag>
      {label && <span className={styles.labelOuter}>{label}</span>}
    </div>
    {description && (
      <p className={styles.descriptionOuter[hasBackgroundColor ? "hasBackgroundColor" : "default"]}>
        {description}
      </p>
    )}
    {button && (
      <div className={styles.buttonOuter[hasBackgroundColor ? "hasBackgroundColor" : "default"]}>
        {button}
      </div>
    )}
  </div>
);
