import { FC } from "react";
import * as styles from "./styles.css";

type Props = {
  text: string;
};

export const SectionTitle: FC<Props> = ({ text }) => (
  <h2 className={styles.sectionTitle}>{text}</h2>
);
