import { FC } from "react";
import { Icon } from "@root/shared/components/Icon";
import { StyledKeepButton } from "./src/styled_elements";

export type KeepButtonProps = {
  url: string;
  query?: string;
  gaAction?: string;
  gaLabel?: string;
  reproAction?: string;
  firebaseAction?: string;
  workCode: string;
  hasKeeped: boolean;
  isPreview?: boolean;
  pageType?: string;
};

export const KeepButton: FC<KeepButtonProps> = ({
  url,
  query,
  gaAction,
  gaLabel,
  reproAction,
  firebaseAction,
  workCode,
  hasKeeped,
  isPreview,
  pageType,
}) => {
  const isGaTagSet = gaAction && gaLabel;

  if (workCode) {
    return (
      <div className="js-bookmark-area">
        {!isPreview && (
          <>
            <form
              className="js-add-bookmark-form"
              action={url}
              acceptCharset="UTF-8"
              data-remote="true"
              method="post"
            >
              <input type="hidden" name="work[code]" value={workCode} />
              <input type="hidden" name="work[query]" value={query || ""} />
            </form>
            <form
              className="js-remove-bookmark-form"
              action={url}
              acceptCharset="UTF-8"
              data-remote="true"
              method="post"
            >
              <input type="hidden" name="_method" value="delete" />
              <input type="hidden" name="work[code]" value={workCode} />
            </form>
          </>
        )}
        <StyledKeepButton
          type="button"
          className={`js-btn--keep disable-on-preview
            ${firebaseAction ? "firebase-click" : ""}
            ${hasKeeped ? "is-keeped" : ""}
            ${isGaTagSet ? "ga-click" : ""}`}
          data-bookmark-status={hasKeeped ? "2" : "1"}
          data-bookmark-code={workCode}
          data-bookmark-view-type="screen_view"
          data-repro-action={reproAction}
          data-ga-action={gaAction}
          data-ga-label={gaLabel}
          data-firebase-action={firebaseAction}
          data-style-type="new"
          data-page-type={pageType || ""}
        >
          {hasKeeped ? (
            <>
              <Icon name="keep-star" />
              解除
            </>
          ) : (
            <>
              <Icon name="keep-star-outline" />
              キープする
            </>
          )}
        </StyledKeepButton>
      </div>
    );
  }
  return (
    <StyledKeepButton
      type="button"
      className={`firebase-click js-contract-expired-job-keep
        ${isGaTagSet ? "ga-click" : ""}`}
      data-ga-action="shop_keep_link-top"
      data-firebase-action="keep"
      data-firebase-action-type="jump_to_job_list"
    >
      <Icon name="keep-star-outline" />
      キープする
    </StyledKeepButton>
  );
};

KeepButton.defaultProps = {
  isPreview: false,
};
