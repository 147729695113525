/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import { FC, useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { FetchJson } from "@root/utils/fetchJson";
import {
  TextField,
  ValidationErrorMessage,
  RequiredLabel,
  DateSelector,
} from "@legacy_user_frontend/components/ui_parts";
import { PrivateLabel } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/private_label";
import { BoxWithShadow } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/box_with_shadow";
import { DefinitionList } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/definition_list";
import { SubmitAndCancelButtons } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/submit_and_cancel_buttons";
import { toSnakeCase } from "@root/utils";
import { showAlertBeforeBrowserBack } from "@legacy_root/user_frontend/components/pages/mypage/profiles/utils/show_alert_before_browser_back";
import { ChoiceChipList } from "@userFrontend/features/publication/components/ChoiceChipList";
import { CancelModal } from "@userFrontend/features/userInfo/components/CancelModal";
import { StationCassetteList } from "./ui_parts/station_cassette_list";
import { Address } from "./ui_parts/address";
import {
  StyledEditLinkOuter,
  StyledSmallText,
  StyledPostCodeOuter,
  StyledPostCodeButton,
  StyledErrorMessageOuter,
} from "./src/styled_elements";
import { arrayToObjects } from "./src/modules";
import {
  ProfilesEditProps,
  InputProps,
  PostCodeResponseProps,
  AddressResponseProps,
} from "./src/profiles_edit_props";

export const ProfilesEdit: FC<ProfilesEditProps> = ({
  action,
  editMailPath,
  editPasswordPath,
  birthDayYearList,
  birthDayMonthList,
  birthDayDayList,
  prefectureList,
  cityList,
  lineList,
  stationList,
  errors,
  name,
  nameKana,
  email,
  tel,
  postCode,
  prefectureId,
  cityId,
  address1,
  stations,
  birthDayYear,
  birthDayMonth,
  birthDayDay,
  sex,
  fetchCitiesListPath,
  fetchLinesListPath,
  fetchStationsListPath,
  fetchStationsGroupPath,
  fetchAddressPath,
  returnPath,
  sexList,
  appOsType,
}) => {
  // 初期値の設定
  const stationsDefaultValues = stations.map((value) => ({
    line_id: value.lineId,
    station_id: value.stationId,
  }));
  const defaultValues: InputProps["form_user_frontend_user_update"] = useMemo(
    (): InputProps["form_user_frontend_user_update"] => ({
      name: name || "",
      name_kana: nameKana || "",
      tel: tel || "",
      post_code: postCode || "",
      prefecture_id: prefectureId || "",
      city_id: prefectureId ? cityId : "",
      address1: prefectureId && cityId && address1 ? address1 : "",
      stations_attributes: stationsDefaultValues.length ? stationsDefaultValues : [{ line_id: "" }],
      birth_day_year: birthDayYear || "",
      birth_day_month: birthDayMonth || "",
      birth_day_day: birthDayDay || "",
      sex: sex || "",
    }),
    [
      birthDayDay,
      birthDayMonth,
      birthDayYear,
      name,
      nameKana,
      postCode,
      prefectureId,
      cityId,
      address1,
      sex,
      stationsDefaultValues,
      tel,
    ],
  );
  const methods = useForm<InputProps>({
    defaultValues: {
      authenticity_token: "",
      _method: "put",
      form_user_frontend_user_update: defaultValues,
    },
  });

  // サーバー側から渡ってきたエラーメッセージを項目に反映する
  const { setError, setValue, clearErrors } = methods;
  const setErrorMessage = useCallback(
    (key: string, message: string) => {
      const snakeCaseKey = toSnakeCase(key);
      const errorFormName = `form_user_frontend_user_update.${snakeCaseKey}` as keyof InputProps;
      setError(
        errorFormName,
        {
          type: "manual",
          message,
        },
        { shouldFocus: true },
      );
    },
    [setError],
  );
  useEffect(() => {
    Object.keys(errors).forEach((key) => {
      setErrorMessage(key, errors[key]);
    });
    stations.forEach((station, index) => {
      const stationErrors = station.errors;
      Object.keys(stationErrors).forEach((key) => {
        setErrorMessage(`stations_attributes.${index}.${key}`, stationErrors[key]);
      });
    });
  }, [errors, setErrorMessage, stations]);
  const validateErrors = methods.formState.errors;

  // コンポーネントに渡す性別リストの作成
  const sortAbcOrder = (a: { key: string; value: string }, b: { key: string; value: string }) => {
    const aValue = a.value.toLowerCase();
    const bValue = b.value.toLowerCase();
    if (aValue < bValue) return -1;
    if (aValue > bValue) return 1;
    return 0;
  };
  const sexListWithId = sexList.sort(sortAbcOrder).map(({ key, value }) => ({
    id: `form_user_frontend_user_update_sex_${value}`,
    value,
    text: `${key}性`,
  }));

  // 初期駅リストの重複削除
  const defaultLineList = useMemo(() => {
    if (!lineList || !lineList.length) {
      return [];
    }

    const uniqueObjects = Array.from(new Map(lineList.map((line) => [line.key, line])));
    return uniqueObjects.map((line) => line[1]);
  }, [lineList]);

  // 都道府県を変更した際に、路線と駅の選択を解除する
  const resetLinesAndStations = useCallback(() => {
    const stationsValue = methods.getValues("form_user_frontend_user_update.stations_attributes");
    const stationsLength = stationsValue ? stationsValue.length : 0;
    for (let i = 0; i < stationsLength; i += 1) {
      methods.resetField(`form_user_frontend_user_update.stations_attributes.${i}.line_id`, {
        defaultValue: "",
      });
      methods.resetField(`form_user_frontend_user_update.stations_attributes.${i}.station_id`, {
        defaultValue: "",
      });
    }
  }, [methods]);

  // 郵便番号から住所を取得し、都道府県、市区町村、町名・番地に反映する
  const [fetchedCityId, setFetchedCityId] = useState(cityId);
  const [selectedCityList, setSelectedCityList] = useState(cityList);
  const handleOnClickPostCode = async () => {
    const postCodeValue = methods.getValues("form_user_frontend_user_update.post_code");
    const postCodeResponse = await FetchJson<PostCodeResponseProps>(fetchAddressPath, {
      code: postCodeValue,
      only_address: "1",
    });
    if (!postCodeResponse) {
      return;
    }
    const { prefecture, city, address } = postCodeResponse[0];
    const citiesListResponse = await FetchJson<AddressResponseProps>(fetchCitiesListPath, {
      prefecture,
    });
    if (citiesListResponse) {
      setSelectedCityList(arrayToObjects(citiesListResponse));
    }
    setValue("form_user_frontend_user_update.prefecture_id", prefecture);
    setValue("form_user_frontend_user_update.city_id", city);
    setValue("form_user_frontend_user_update.address1", address);
    resetLinesAndStations();
    // 都道府県と市区町村のバリデーションエラーが残っている可能性があるので消す
    clearErrors("form_user_frontend_user_update.prefecture_id");
    clearErrors("form_user_frontend_user_update.city_id");
  };

  // 変更の検出
  const watchedValues = methods.watch("form_user_frontend_user_update");
  const [isChanged, setIsChanged] = useState(false);
  useEffect(() => {
    const tmpDefaultValues = defaultValues;
    if (!defaultValues.address1) {
      tmpDefaultValues.address1 = "";
    }
    if (!defaultValues.city_id) {
      tmpDefaultValues.city_id = "";
    }
    if (JSON.stringify(watchedValues) !== JSON.stringify(tmpDefaultValues)) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [defaultValues, watchedValues]);

  // キャンセルボタン押下時に確認モーダルを表示する
  const [isShowAlert, setIsShowAlert] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    if (isChanged) {
      setIsModalOpen(true);
      setIsShowAlert(false);
      return;
    }
    window.location.href = returnPath;
  };
  const closeModal = () => {
    setIsShowAlert(true);
    setIsModalOpen(false);
  };

  // ブラウザバックを検知して確認モーダルを表示する
  const ua = window.navigator.userAgent.toLowerCase();
  useEffect(() => {
    // 変更がない場合のほか、safariとiOSの場合（画面遷移後の動作に影響があるためブラウザバックを許容）は処理を抜ける
    if (
      ua.indexOf("iphone") > 0 ||
      ua.indexOf("ipod") > 0 ||
      (ua.indexOf("chrome") === -1 && ua.indexOf("safari") !== -1) ||
      !isChanged
    ) {
      return;
    }
    showAlertBeforeBrowserBack({ setIsShowAlert, setIsModalOpen });
  }, [isChanged, ua]);

  // 画面遷移を検知して確認アラートを表示する
  useEffect(() => {
    const showAlert = (event: BeforeUnloadEvent) => {
      if (!isChanged || !isShowAlert) {
        return;
      }
      // eslint-disable-next-line no-param-reassign
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", showAlert);
    return () => window.removeEventListener("beforeunload", showAlert);
  }, [isChanged, isShowAlert]);

  // 送信処理
  const formEl = useRef<HTMLFormElement>(null);
  const onSubmit = () => {
    setIsShowAlert(false);
    formEl.current?.submit();
  };
  // バリデーションエラー時の処理
  const onSubmitError = () => {
    setIsShowAlert(true);
  };
  // ふりがなのTextFieldに渡すひらがな形式チェックパターン
  const kanaValidation = {
    // eslint-disable-next-line no-irregular-whitespace
    value: /^[あ-んぁ-ぉゃ-ょ・ー　 ]*$/,
    message: "ふりがなはひらがなで入力してください",
  };

  return (
    <>
      <FormProvider {...methods}>
        <form
          ref={formEl}
          action={action}
          onSubmit={methods.handleSubmit(onSubmit, onSubmitError)}
          method="post"
        >
          <input type="hidden" {...methods.register("authenticity_token")} />
          <input type="hidden" {...methods.register("_method")} />
          <BoxWithShadow>
            <DefinitionList>
              <dt>
                氏名
                <span className="required-label-outer">
                  <RequiredLabel />
                </span>
                <span className="private-label-outer">
                  <PrivateLabel />
                </span>
              </dt>
              <dd>
                <TextField
                  name="form_user_frontend_user_update[name]"
                  placeholder="例）山田 咲子"
                  requiredErrorMessage="名前を入力してください"
                  isError={!!validateErrors.form_user_frontend_user_update?.name?.message}
                />
                {validateErrors.form_user_frontend_user_update?.name?.message && (
                  <StyledErrorMessageOuter>
                    <ValidationErrorMessage
                      message={validateErrors.form_user_frontend_user_update.name.message}
                    />
                  </StyledErrorMessageOuter>
                )}
              </dd>
              <dt>
                ふりがな
                <span className="required-label-outer">
                  <RequiredLabel />
                </span>
                <span className="private-label-outer">
                  <PrivateLabel />
                </span>
              </dt>
              <dd>
                <TextField
                  name="form_user_frontend_user_update[name_kana]"
                  placeholder="例）やまだ さきこ"
                  requiredErrorMessage="ふりがなを入力してください"
                  patternValidation={kanaValidation}
                  isError={!!validateErrors.form_user_frontend_user_update?.name_kana?.message}
                />
                {validateErrors.form_user_frontend_user_update?.name_kana?.message && (
                  <StyledErrorMessageOuter>
                    <ValidationErrorMessage
                      message={validateErrors.form_user_frontend_user_update.name_kana.message}
                    />
                  </StyledErrorMessageOuter>
                )}
              </dd>
              <dt>
                メールアドレス
                <span className="private-label-outer">
                  <PrivateLabel />
                </span>
              </dt>
              <dd>
                <StyledEditLinkOuter>
                  <span>{email}</span>
                  <a href={editMailPath}>編集画面へ進む</a>
                </StyledEditLinkOuter>
              </dd>
              <dt>
                パスワード
                <span className="private-label-outer">
                  <PrivateLabel />
                </span>
              </dt>
              <dd>
                <StyledEditLinkOuter>
                  <span>＊＊＊＊</span>
                  <a href={editPasswordPath}>編集画面へ進む</a>
                </StyledEditLinkOuter>
              </dd>
              <dt>
                性別
                <span className="required-label-outer">
                  <RequiredLabel />
                </span>
              </dt>
              <dd>
                <ChoiceChipList
                  name="form_user_frontend_user_update[sex]"
                  defaultValue={sex}
                  requiredMessage="性別を選択してください。"
                  items={sexListWithId}
                />
                {validateErrors.form_user_frontend_user_update?.sex?.message && (
                  <StyledErrorMessageOuter>
                    <ValidationErrorMessage
                      message={validateErrors.form_user_frontend_user_update.sex.message}
                    />
                  </StyledErrorMessageOuter>
                )}
              </dd>
              <dt>
                生年月日
                <span className="required-label-outer">
                  <RequiredLabel />
                </span>
              </dt>
              <dd>
                <DateSelector
                  yearList={birthDayYearList}
                  monthList={birthDayMonthList}
                  dateList={birthDayDayList}
                  yearName="form_user_frontend_user_update[birth_day_year]"
                  monthName="form_user_frontend_user_update[birth_day_month]"
                  dateName="form_user_frontend_user_update[birth_day_day]"
                  yearRequiredMessage="生年月日（年）を入力してください。"
                  monthRequiredMessage="生年月日（月）を入力してください。"
                  dateRequiredMessage="生年月日（日）を入力してください。"
                  hasYearError={
                    !!validateErrors.form_user_frontend_user_update?.birth_day_year?.message
                  }
                  hasMonthError={
                    !!validateErrors.form_user_frontend_user_update?.birth_day_month?.message
                  }
                  hasDateError={
                    !!validateErrors.form_user_frontend_user_update?.birth_day_day?.message
                  }
                />
                {validateErrors.form_user_frontend_user_update?.birth_day_year?.message && (
                  <StyledErrorMessageOuter>
                    <ValidationErrorMessage
                      message={validateErrors.form_user_frontend_user_update.birth_day_year.message}
                    />
                  </StyledErrorMessageOuter>
                )}
                {validateErrors.form_user_frontend_user_update?.birth_day_month?.message && (
                  <StyledErrorMessageOuter>
                    <ValidationErrorMessage
                      message={
                        validateErrors.form_user_frontend_user_update.birth_day_month.message
                      }
                    />
                  </StyledErrorMessageOuter>
                )}
                {validateErrors.form_user_frontend_user_update?.birth_day_day?.message && (
                  <StyledErrorMessageOuter>
                    <ValidationErrorMessage
                      message={validateErrors.form_user_frontend_user_update.birth_day_day.message}
                    />
                  </StyledErrorMessageOuter>
                )}
              </dd>
              <dt>
                郵便番号
                <span className="private-label-outer">
                  <PrivateLabel />
                </span>
              </dt>
              <dd>
                <StyledPostCodeOuter>
                  <TextField
                    name="form_user_frontend_user_update[post_code]"
                    placeholder="1234567"
                  />
                  <StyledPostCodeButton type="button" onClick={handleOnClickPostCode}>
                    郵便番号から住所を反映する
                  </StyledPostCodeButton>
                </StyledPostCodeOuter>
              </dd>
              <dt>
                住所
                <span className="required-label-outer">
                  <RequiredLabel />
                </span>
              </dt>
              <dd>
                <Address
                  prefectureList={prefectureList}
                  selectedCityList={selectedCityList}
                  setSelectedCityList={setSelectedCityList}
                  resetLinesAndStations={resetLinesAndStations}
                  fetchCitiesListPath={fetchCitiesListPath}
                  fetchedCityId={fetchedCityId}
                  setFetchedCityId={setFetchedCityId}
                />
              </dd>
              <dt>最寄り駅</dt>
              <dd>
                <div>
                  <StationCassetteList
                    lineList={defaultLineList}
                    stationList={stationList}
                    fetchStationsListPath={fetchStationsListPath}
                    fetchStationsGroupPath={fetchStationsGroupPath}
                    fetchLinesListPath={fetchLinesListPath}
                  />
                </div>
              </dd>
              <dt>
                電話番号
                <span className="required-label-outer">
                  <RequiredLabel />
                </span>
                <span className="private-label-outer">
                  <StyledSmallText>※半角数字11文字（ハイフン不要）</StyledSmallText>
                  <PrivateLabel />
                </span>
              </dt>
              <dd>
                <TextField
                  name="form_user_frontend_user_update[tel]"
                  placeholder="例）08012345678"
                  requiredErrorMessage="電話番号を入力してください"
                  isError={!!validateErrors.form_user_frontend_user_update?.tel?.message}
                />
                {validateErrors.form_user_frontend_user_update?.tel?.message && (
                  <StyledErrorMessageOuter>
                    <ValidationErrorMessage
                      message={validateErrors.form_user_frontend_user_update.tel.message}
                    />
                  </StyledErrorMessageOuter>
                )}
              </dd>
            </DefinitionList>
          </BoxWithShadow>
          <SubmitAndCancelButtons handleOnClick={openModal} appOsType={appOsType} />
        </form>
      </FormProvider>
      <CancelModal isShow={isModalOpen} onClose={closeModal} returnPath={returnPath} />
    </>
  );
};
