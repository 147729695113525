/* eslint-disable react/jsx-props-no-spreading */
import { FC, ChangeEvent } from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import { Colors } from "@legacy_user_frontend/utils";

type Props = {
  name: string;
  placeholder: string;
  requiredMessage?: string;
  isError?: boolean;
  isDisabled?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  groupedList: {
    name: string;
    list: {
      key: string | number;
      value: string | number;
    }[];
  }[];
};

type StyledPulldownProps = {
  isUnselected: boolean;
  isError?: boolean;
};

const StyledPulldown = styled.select<StyledPulldownProps>`
  appearance: none;
  width: 100%;
  height: 44px;
  padding: 0 8px;
  border: 1px solid ${({ isError }) => (isError ? Colors.ALERT_RED : Colors.MEDIUM_GREY)};
  border-radius: 0;
  box-shadow: ${({ isError }) => (isError ? `0px 0px 8px ${Colors.ALERT_RED}` : "none")};
  background-image: url("/images/user_frontend/global/icon/icn_arrow_down.svg");
  background-repeat: no-repeat;
  background-position: top 50% right 8px;
  background-size: 12px;
  background-color: ${Colors.WHITE};
  box-shadow: none;
  font-size: 14px;
  color: ${({ isUnselected }) => (isUnselected ? Colors.BLACK : Colors.DEEP_GREY)};
  transition:
    border-color 0.1s,
    box-shadow 0.1s;

  &:focus {
    border-color: ${Colors.FOCUS_BLUE};
    box-shadow:
      0px 0px 8px ${Colors.FOCUS_BLUE},
      inset 0px 0px 1px ${Colors.FOCUS_BLUE};
  }
`;

export const GroupedPulldown: FC<Props> = ({
  name,
  placeholder,
  requiredMessage,
  isError,
  isDisabled,
  onChange,
  groupedList,
}) => {
  const { register, clearErrors, watch } = useFormContext();
  const deleteErrorMessage = () => {
    clearErrors(name);
  };
  const isUnselected = watch(name);

  return (
    <StyledPulldown
      isUnselected={!!isUnselected}
      isError={isError}
      disabled={isDisabled}
      {...register(name, {
        required: requiredMessage,
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          if (onChange) onChange(e);
          deleteErrorMessage();
        },
      })}
    >
      <option value="">{placeholder}</option>
      {groupedList &&
        groupedList.length > 0 &&
        groupedList.map((group) => (
          <optgroup label={group.name} key={group.name}>
            {group.list.map((item) => (
              <option value={item.value} key={`${item.key}_${item.value}`}>
                {item.key}
              </option>
            ))}
          </optgroup>
        ))}
    </StyledPulldown>
  );
};
