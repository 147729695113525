import { FC } from "react";
import {
  StyledTableList,
  StyledTagLabelList,
  StyledList,
  StyledTagLabel,
} from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_show/src/styled_elements";
import { ExperienceAndCredentialCassetteProps } from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_show/src/profile_show_props";
import { PrivateLabel } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/private_label";

const credentialNames: string[] = [];
export const ExperienceAndCredentialCassette: FC<ExperienceAndCredentialCassetteProps> = ({
  experienceAndCredential: { experiences, credentialItems, experiencesNote },
}) => (
  <StyledTableList>
    {experiences.length > 0 && (
      <>
        <dt>経験</dt>
        <dd>
          <StyledList>
            {experiences.map(({ jobTypeName, name }) => (
              <li key={`${jobTypeName}_${name}`}>
                {jobTypeName} / {name}
              </li>
            ))}
          </StyledList>
        </dd>
      </>
    )}
    {experiencesNote && (
      <>
        <dt>
          <div>備考</div>
          <PrivateLabel />
        </dt>
        <dd>{experiencesNote}</dd>
      </>
    )}
    {credentialItems.length > 0 && (
      <>
        <dt>資格</dt>
        <dd>
          <StyledTagLabelList>
            {credentialItems.map(({ credentialName, isWill }) => {
              if (credentialNames.find((item) => item === credentialName) === undefined) {
                credentialNames.push(credentialName);
                return (
                  <li key={credentialName}>
                    <StyledTagLabel>
                      {credentialName}
                      {isWill && <>(取得予定)</>}
                    </StyledTagLabel>
                  </li>
                );
              }
              return undefined;
            })}
          </StyledTagLabelList>
        </dd>
      </>
    )}
  </StyledTableList>
);
