import { FC } from "react";
import { AchievementProps } from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_show/src/profile_show_props";
import {
  StyledInnerCassetteList,
  StyledInnerCassette,
  StyledInnerCassetteHeadingOuter,
  StyledInnerCassetteHeading,
  StyledInnerCassetteContent,
  StyledTableList,
} from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_show/src/styled_elements";
import { EditPageLink } from "./edit_page_link";

export const AchievementCassette: FC<AchievementProps> = ({ achievement, editAchievementPath }) => (
  <>
    {achievement.achievements.length > 0 && (
      <StyledInnerCassetteList>
        {achievement.achievements.map(({ name, year, month }, index) => (
          <li key={name}>
            <StyledInnerCassette>
              <StyledInnerCassetteHeadingOuter>
                <StyledInnerCassetteHeading>
                  <span>受賞・表彰歴{index + 1}</span>
                  <EditPageLink url={`${editAchievementPath}#cassette-${index}`} />
                </StyledInnerCassetteHeading>
              </StyledInnerCassetteHeadingOuter>
              <StyledInnerCassetteContent>
                <StyledTableList>
                  <dt>受賞した賞の名前</dt>
                  <dd>{name}</dd>
                  {year && (
                    <>
                      <dt>年月</dt>
                      <dd>
                        {year}年{month && ` ${month}月`}
                      </dd>
                    </>
                  )}
                </StyledTableList>
              </StyledInnerCassetteContent>
            </StyledInnerCassette>
          </li>
        ))}
      </StyledInnerCassetteList>
    )}
  </>
);
