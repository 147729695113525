import { IOS_APP_ID, ANDROID_APP_ID } from "@root/utils/constants";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const webkit: any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Android: any;

type Props = {
  iosEventMessage: Record<string, string>;
  androidEventName: string;
  androidEventMessage: string;
};

export const sendAppWebhook = ({
  iosEventMessage,
  androidEventName,
  androidEventMessage,
}: Props): void => {
  // アプリwebviewの場合、iOSであれば"1"、Androidであれば"2"が入る
  const { mobileApp } = document.getElementsByTagName("body")[0].dataset;

  if (mobileApp === String(IOS_APP_ID) && typeof webkit !== "undefined") {
    // iOSアプリ用webhook送信
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    webkit.messageHandlers.callbackHandler.postMessage(iosEventMessage);
    return;
  }

  if (mobileApp === String(ANDROID_APP_ID) && typeof Android !== "undefined") {
    // Androidアプリ用webhook送信
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    Android.postMessage(androidEventName, androidEventMessage);
  }
};
