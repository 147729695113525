import { FC } from "react";
import { LazyLoadImage } from "@root/shared/components/LazyLoadImage";
import * as styles from "./styles.css";

type Props = {
  imgSrc: string;
  dummyImgPath: string;
  shopOrBrandName: string;
  businessTypeAndOccupation: string;
  title: string;
};

export const SimplifiedHeader: FC<Props> = ({
  imgSrc,
  dummyImgPath,
  shopOrBrandName,
  businessTypeAndOccupation,
  title,
}) => {
  return (
    <div>
      <LazyLoadImage
        src={imgSrc}
        dummyImgPath={dummyImgPath}
        alt="求人イメージ画像"
        width={284}
        height={160}
      />
      <div className={styles.titleContainer}>
        <h2 className={styles.headingBold}>
          {shopOrBrandName}
          <span className={styles.heading}> | {businessTypeAndOccupation}</span>
        </h2>
        <p className={styles.title}>{title}</p>
      </div>
    </div>
  );
};
