import type { ClientImage } from "@userFrontend/features/publication/components/AlbumContents/type";
import { useCallback, useEffect, useState } from "react";

const URL_HASH_NAME = "album_index";
type UseHashChangeReturn = {
  isShowAlbum: boolean;
  setUrlHashName: () => void;
  clearUrlHashName: () => void;
};

// 処理概要: URLハッシュを操作して、画像一覧の開閉と拡大画像の閉じる操作を行う関数
export const useHashChange = (
  expandedImage: ClientImage | undefined,
  setExpandedImage: (image: ClientImage | undefined) => void,
): UseHashChangeReturn => {
  const isUrlHashAlbumIndex = () => {
    const windowUrlHashName = window.location.hash.slice(1);
    return windowUrlHashName === URL_HASH_NAME;
  };
  const [isShowAlbum, setIsShowAlbum] = useState(isUrlHashAlbumIndex());

  // URLハッシュにalbum_indexを付与すると以下の流れでUIの変更が起こる
  // 1. URLハッシュにalbum_indexが追加される 例:) show_page_url -> show_page_url#album_index
  // 2. handleHashChangeが実行され、UIの変更が起こる
  const setUrlHashName = () => (window.location.hash = URL_HASH_NAME);

  // NOTE: 画像一覧のブラウザバック/ブラウザフォワード対応について
  // useHistoryBackを使うと初回のブラウザバックしか対応しておらず、複数回同様の操作を行うと動作しなくなる
  // useHistoryBackと同様にpushStateとpopstateを用いた方法で実装すると、
  // 履歴が積み上がりブラウザバックしても求人詳細画面に留まり続けるというバグが発生したためこの実装となった
  // window.history.back()を行うと以下の流れでUIの変更が起きる
  // 1. ページ遷移は行われず、URLハッシュからalbum_indexが取り除かれる 例:) show_page_url#album_index -> show_page_url
  // 2. handleHashChangeが実行され、UIの変更が起こる
  const clearUrlHashName = () => {
    if (isUrlHashAlbumIndex()) {
      window.history.back();
    } else {
      throw new Error("URLハッシュにalbum_index以外の値が付与されている状態で関数が実行されました");
    }
  };

  const handleHashChange = useCallback(() => {
    if (expandedImage) {
      setExpandedImage(undefined);
    }

    setIsShowAlbum(isUrlHashAlbumIndex());
  }, [expandedImage, setExpandedImage]);

  useEffect(() => {
    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [handleHashChange]);

  return {
    isShowAlbum,
    setUrlHashName,
    clearUrlHashName,
  };
};
