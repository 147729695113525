import { ComponentPropsWithRef, FC, forwardRef } from "react";
import * as styles from "./styles.css";

type Props = Omit<ComponentPropsWithRef<"select">, "type"> & {
  selected: boolean;
  hasError?: boolean;
  placeholder?: string;
  options: OptionProps[];
};

export type OptionProps = {
  label: string;
  value: number | string;
};

export const SelectBox: FC<Props> = forwardRef(
  (
    {
      selected,
      hasError,
      defaultValue,
      name,
      id,
      className,
      disabled,
      onChange,
      onBlur,
      placeholder,
      options,
    },
    ref,
  ) => (
    <select
      name={name}
      id={id}
      className={`${styles.variant[selected ? "selected" : "unselected"]} ${
        hasError ? styles.hasError : ""
      } ${className || ""}`}
      disabled={disabled}
      ref={ref}
      onChange={onChange}
      onBlur={onBlur}
      defaultValue={defaultValue}
    >
      {placeholder && (
        <option value="" className={styles.placeholderOption}>
          {placeholder}
        </option>
      )}
      {options.map(({ label, value }) => (
        <option key={label} value={value} className={styles.option}>
          {label}
        </option>
      ))}
    </select>
  ),
);
SelectBox.displayName = "SelectBox";
