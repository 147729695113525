/* eslint-disable jsx-a11y/label-has-associated-control */
import { FC } from "react";
import { Icon } from "@root/shared/components/Icon";
import { Checkbox } from "@root/shared/components/Checkbox";
import * as styles from "./styles.css";

type Props = {
  gaAction?: string;
  url: string;
  bannerUrl: string;
  onClickBanner: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onChangeCheckbox: React.ChangeEventHandler<HTMLInputElement>;
  checked: boolean;
  onClose: () => void;
};

export const Presentation: FC<Props> = ({
  gaAction,
  url,
  bannerUrl,
  onClickBanner,
  onChangeCheckbox,
  checked,
  onClose,
}) => (
  <div
    onClick={() => onClose()}
    aria-hidden="true"
    className={styles.background}
    data-ga-action={gaAction}
  >
    <div className={styles.wrapper} aria-hidden="true">
      <a href={url} className={styles.link} onClick={onClickBanner}>
        <img
          src={bannerUrl}
          alt="リジョブ公式LINEで転職活動しませんか？ 友だち追加する"
          className={styles.image}
        />
      </a>
      <div className={styles.checkboxArea}>
        <label
          htmlFor="never-show"
          onClick={(e) => {
            e.stopPropagation();
          }}
          aria-hidden="true"
          className={styles.checkboxLabel}
        >
          <Checkbox id="never-show" onChange={onChangeCheckbox} checked={checked} />
          今後は表示しない
        </label>
      </div>
      <p className={styles.textWrapper}>
        <Icon name="cross-thin" fontSize="24px" />
      </p>
    </div>
  </div>
);
