import { FC } from "react";
import styled from "styled-components";
import { Breakpoints, Colors } from "@legacy_user_frontend/utils";

export type ScoutButtonProps = {
  url: string;
  clientShopId?: string;
  gaAction: string;
  gaLabel: string;
  workCode: string;
};

const ButtonOuter = styled.li`
  flex-basis: 50%;
`;

const Button = styled.a`
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  padding: 13px 0;
  font-weight: bold;
  background: ${Colors.PRIMARY};
  color: ${Colors.WHITE};
  border: 1px solid ${Colors.PRIMARY};
  transition: all 0.2s;
  margin-top: 8px;

  &:active {
    text-decoration: none;
  }

  ${Breakpoints.MEDIUM} {
    line-height: 1;
    font-size: 16px;
    margin-top: 0;

    &:hover {
      background: rgba(251, 99, 65, 0.8);
      opacity: 0.8;
      text-decoration: none;
      border-color: ${Colors.WHITE};
      box-shadow:
        0px 0px 8px ${Colors.PRIMARY},
        inset 0px 0px 1px ${Colors.PRIMARY};
    }
  }
`;

export const ScoutButton: FC<ScoutButtonProps> = ({
  url,
  clientShopId,
  gaAction,
  gaLabel,
  workCode,
}) => (
  <ButtonOuter>
    <Button
      className="ga-click js-webhook"
      href={url}
      data-client-shop-id={clientShopId}
      data-ga-action={gaAction}
      data-ga-label={gaLabel}
      data-code={workCode}
    >
      スカウトを受ける
    </Button>
  </ButtonOuter>
);
