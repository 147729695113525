/* eslint-disable react/jsx-props-no-spreading */
import { FC, lazy, Suspense } from "react";
import { ProductPhotoCassetteProps } from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_show/src/profile_show_props";
import type { Settings } from "react-slick";
import {
  StyledSliderDescription,
  StyledSliderImageOuter,
  StyledSliderOuter,
} from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_show/src/styled_elements";

const Slider = lazy(() => import("react-slick"));

export const ProductPhotoCassette: FC<ProductPhotoCassetteProps> = ({ productPhotos }) => {
  const settings: Settings = {
    responsive: [
      {
        // breakpointはmax-widthの指定のみのためPC指定に9999を使用する
        breakpoint: 9999,
        settings: "unslick",
      },
      {
        breakpoint: 768,
        settings: {
          infinite: false,
          arrows: false,
          autoplay: false,
          slidesToShow: 1,
          variableWidth: true,
          swipeToSlide: true,
        },
      },
    ],
  };

  return (
    <StyledSliderOuter>
      <Suspense fallback={<div>Loading...</div>}>
        <Slider {...settings}>
          {productPhotos.map(({ description, imageUrl }) => {
            const trimmedDescription =
              !!description && description.length > 15
                ? `${description.substring(0, 15)}...`
                : description;
            return (
              <div key={imageUrl}>
                <StyledSliderImageOuter>
                  <img src={imageUrl} alt={trimmedDescription} />
                </StyledSliderImageOuter>
                <StyledSliderDescription>{trimmedDescription}</StyledSliderDescription>
              </div>
            );
          })}
        </Slider>
      </Suspense>
    </StyledSliderOuter>
  );
};
