import "app/packs/shared/components/Modal/legacy/styles.css.ts.vanilla.css!=!../../../../../../../../shared/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"app/packs/shared/components/Modal/legacy/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81V246bMBB9z1dYqiqBtE5ZciPZlz7lPww24K5jW8YJJFX+vb5AcLJJt2lfqkQIxuOZc46Hw/RwWtaragF+TgCQoqGaCr4BJe0IfjMhLeQGJPaOkVL3ty3Fut6A1yQ5tPa5JrSqtQ/UNpCj4r1SYs8xLAQTagNUlaMoeQH9f7qMbd4JUo5J53aayufJ1MNZPoKTC63F7gOMryGKArEiskEAwSyRXXwf0ZftdutLKkwUVAjTfWOqLWXnL4nvkosONjXCojVtbXhYv6U0jwMGqxsGKG8E22ty0XThQXtV+wetEG9KoQxBd8uQJhE0iy/AXuOAdEhynvUkd6iD/foBqQhCj2V+WRwkWmQr2T0ty9+rkTk1xIGokokWHjegKZRgLEhZX6UYwfZa2IYSYUx5Zbr5PvOh4y2nkHEaNkefVX6qWu6qYdpIhgyPkhG398e+0bQ8GhW5JvymwKs7AMRoxSHVZGf0LEwSUeHYhhuS+BpgajhnlncakL93dmv7++OppidXvs81oYBn8f/w/IzKwxkeXz8Dung/XhvaaD4Bbexol4adlYeMw8EoJ5fpSOc+6PLaPrhMEj9FqqJ8sKja6AAbiQpTiotWIfl2NYw1xZhwB4x0Go4LhDEqG9oE2IjD1r/gs9RDGCANz14t24yT++o4a5FImZMZ8UJvRI7XpWH5LzYcnvPsOQ9Ohpfdi3+efN8RTJE1DUI4QByDaGcw953XmXV5h/Xq+/HIfwfXdR4aua+YcdFsNrNVvoE09kluUl49qZHyJdA3d9t85K7DPnTS8wi3/C3c8+T8C1KErcimBwAA\"}!../../../../../../../../shared/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var background = 'vz6h7g5';
export var closeButton = 'vz6h7ge';
export var content = 'vz6h7g9';
export var contentBottomSheet = 'vz6h7ga';
export var contentHeight = 'var(--vz6h7g2)';
export var footer = 'vz6h7gf';
export var footerHeight = 'var(--vz6h7g3)';
export var header = 'vz6h7gc';
export var headerBottomSheet = 'vz6h7gb';
export var headerHeight = 'var(--vz6h7g0)';
export var headerTitlePosition = 'var(--vz6h7g1)';
export var maxWidth = 'var(--vz6h7g4)';
export var title = 'vz6h7gd';
export var wrapper = 'vz6h7g7';
export var wrapperBottomSheet = 'vz6h7g6';
export var wrapperScroll = 'vz6h7g8';