import "app/packs/shared/components/Label/legacy/styles.css.ts.vanilla.css!=!../../../../../../../../shared/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"app/packs/shared/components/Label/legacy/styles.css.ts.vanilla.css\",\"source\":\"Ll8zYmx6cnoxIHsKICAtLV8zYmx6cnowOiAjNTA4RkZGOwp9Ci5fM2JsenJ6MiB7CiAgLS1fM2JsenJ6MDogI2ViNzE0YzsKfQouXzNibHpyejMgewogIC0tXzNibHpyejA6ICNlYmRjNGQ7Cn0KLl8zYmx6cno0IHsKICAtLV8zYmx6cnowOiAjRkI2MzQxOwp9Ci5fM2JsenJ6NSB7CiAgLS1fM2JsenJ6MDogI0ZGNzg3ODsKfQouXzNibHpyejYgewogIC0tXzNibHpyejA6ICNGRkY1Rjc7Cn0KLl8zYmx6cno3IHsKICAtLV8zYmx6cnowOiAjRjk2MDc1Owp9Ci5fM2JsenJ6OCB7CiAgLS1fM2JsenJ6MDogI0VGNDI0MjsKfQouXzNibHpyejkgewogIGJvcmRlci1jb2xvcjogdmFyKC0tXzNibHpyejApOwogIGJvcmRlci13aWR0aDogMXB4OwogIGJvcmRlci1zdHlsZTogc29saWQ7CiAgZGlzcGxheTogaW5saW5lLWJsb2NrOwp9Ci5fM2JsenJ6YSB7CiAgZm9udC1zaXplOiAxMXB4OwogIHBhZGRpbmc6IDNweDsKfQouXzNibHpyemIgewogIGZvbnQtc2l6ZTogOXB4OwogIHBhZGRpbmc6IDQuNXB4Owp9Ci5fM2JsenJ6YyB7CiAgZm9udC1zaXplOiAxMXB4OwogIHBhZGRpbmc6IDVweDsKfQouXzNibHpyemQgewogIGZvbnQtc2l6ZTogMTJweDsKICBwYWRkaW5nOiA3cHg7Cn0KLl8zYmx6cnplIHsKICBiYWNrZ3JvdW5kOiB2YXIoLS1fM2JsenJ6MCk7CiAgY29sb3I6ICNGRkY7Cn0KLl8zYmx6cnpmIHsKICBiYWNrZ3JvdW5kOiB2YXIoLS1fM2JsenJ6MCk7CiAgY29sb3I6ICM1NzU3NTc7Cn0KLl8zYmx6cnpnIHsKICBiYWNrZ3JvdW5kOiAjRkZGOwogIGNvbG9yOiB2YXIoLS1fM2JsenJ6MCk7Cn0KLl8zYmx6cnpoIHsKICBmb250LXdlaWdodDogYm9sZDsKfQouXzNibHpyemkgewogIGJvcmRlci1yYWRpdXM6IDA7Cn0KLl8zYmx6cnpqIHsKICBib3JkZXItcmFkaXVzOiA0cHg7Cn0KLl8zYmx6cnprIHsKICBib3JkZXItcmFkaXVzOiA2cHg7Cn0=\"}!../../../../../../../../shared/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var alert = '_3blzrz8';
export var blue = '_3blzrz1';
export var deepPink = '_3blzrz7';
export var label = _7a468({defaultClassName:'_3blzrz9',variantClassNames:{size:{extraSmall:'_3blzrza',small:'_3blzrzb',medium:'_3blzrzc',large:'_3blzrzd'},filledType:{contained:'_3blzrze',containedDarkText:'_3blzrzf',outlined:'_3blzrzg'},isBold:{true:'_3blzrzh'},round:{none:'_3blzrzi',slight:'_3blzrzj',standard:'_3blzrzk'}},defaultVariants:{},compoundVariants:[]});
export var labelColorVar = {color:'var(--_3blzrz0)'};
export var lightOrange = '_3blzrz4';
export var lightPink = '_3blzrz6';
export var orange = '_3blzrz2';
export var pink = '_3blzrz5';
export var yellow = '_3blzrz3';