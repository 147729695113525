import { FC } from "react";
import {
  NumberField,
  Pulldown,
  TextArea,
  ValidationErrorMessage,
} from "@legacy_user_frontend/components/ui_parts";
import {
  StyledFacilityTypesOuter,
  StyledAddFacilityTypesButtonOuter,
  StyledAddFacilityTypesButton,
  StyledSmallInputOuter,
  StyledList,
  StyledLabel,
  StyledOccupationOuter,
  StyledErrorMessageOuter,
} from "@legacy_user_frontend/components/pages/mypage/profiles/user_career_edit/src/styled_elements";
import { OccupationProps } from "@legacy_user_frontend/components/pages/mypage/profiles/user_career_edit/src/user_career_edit_props";
import { DefinitionList } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/definition_list";
import { DeletableCassette } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/deletable_cassette";
import { useFormContext } from "react-hook-form";
import { Checkbox } from "@root/shared/components/Checkbox";

const otherJobTypeCategoryId = 6;
const shikaJobTypeCategoryId = 7;

export const Occupation: FC<OccupationProps> = ({
  careerIndex,
  fields,
  facilityTypesList,
  appendField,
  removeFacilityType,
  selectedJobTypeCategoryId,
  selectedJobTypeId,
  workPatternList,
  workPatterns,
  descriptionPlaceholder,
  yearsOfExperienceList,
  careJobTypeCategoryId,
}) => {
  const formName = `user_careers_update[user_careers_attributes[${careerIndex}]]`;
  const { formState } = useFormContext();
  const formErrors = formState.errors?.user_careers_update?.user_careers_attributes;
  const occupationErrors = formErrors && formErrors[careerIndex];
  const careerErrors = occupationErrors && occupationErrors.user_careers_facility_types_attributes;
  return (
    <>
      <StyledOccupationOuter>
        <DefinitionList>
          <dt>施設形態</dt>
          <dd>
            {fields.map((field, index) => {
              const facilityName = `${formName}[user_careers_facility_types_attributes[${index}]]`;
              const facilityTypeError = careerErrors && careerErrors[index];
              const facilityTypeIdError = facilityTypeError && facilityTypeError.facility_type_id;
              const YearsOfExperienceError =
                facilityTypeError && facilityTypeError.years_of_experience;
              return (
                <DeletableCassette
                  key={field.id}
                  heading={`施設形態${index + 1}`}
                  handleOnClick={() => removeFacilityType(index)}
                >
                  <StyledFacilityTypesOuter>
                    <div>
                      <Pulldown
                        name={`${facilityName}[facility_type_id]`}
                        placeholder="施設形態を選択"
                        list={facilityTypesList || []}
                        isError={!!facilityTypeIdError}
                      />
                      {facilityTypeIdError && (
                        <StyledErrorMessageOuter>
                          <ValidationErrorMessage message={facilityTypeIdError.message} />
                        </StyledErrorMessageOuter>
                      )}
                    </div>
                    <div>
                      <Pulldown
                        name={`${facilityName}[years_of_experience]`}
                        placeholder="経験年数"
                        list={yearsOfExperienceList}
                        isError={!!YearsOfExperienceError}
                      />
                      {YearsOfExperienceError && (
                        <StyledErrorMessageOuter>
                          <ValidationErrorMessage message={YearsOfExperienceError.message} />
                        </StyledErrorMessageOuter>
                      )}
                    </div>
                  </StyledFacilityTypesOuter>
                </DeletableCassette>
              );
            })}
          </dd>
        </DefinitionList>
        <StyledAddFacilityTypesButtonOuter>
          <StyledAddFacilityTypesButton type="button" onClick={appendField} size="large">
            施設形態を追加する
          </StyledAddFacilityTypesButton>
        </StyledAddFacilityTypesButtonOuter>
      </StyledOccupationOuter>
      <StyledOccupationOuter>
        <DefinitionList>
          <>
            {selectedJobTypeCategoryId !== careJobTypeCategoryId &&
              selectedJobTypeCategoryId !== otherJobTypeCategoryId &&
              selectedJobTypeCategoryId !== shikaJobTypeCategoryId && (
                <>
                  {selectedJobTypeId !== otherJobTypeCategoryId && (
                    <>
                      <dt>平均対応客数（個人実績）</dt>
                      <dd>
                        <StyledSmallInputOuter>
                          <NumberField
                            name={`${formName}[average_number_of_customers]`}
                            placeholder="半角数字で入力"
                            min={0}
                          />
                        </StyledSmallInputOuter>
                        人 / 月
                        {occupationErrors && occupationErrors.average_number_of_customers && (
                          <StyledErrorMessageOuter>
                            <ValidationErrorMessage
                              message={occupationErrors.average_number_of_customers.message}
                            />
                          </StyledErrorMessageOuter>
                        )}
                      </dd>
                      <dt>平均新規顧客割合（個人実績）</dt>
                      <dd>
                        <StyledSmallInputOuter>
                          <NumberField
                            name={`${formName}[average_percentage_of_new_customers]`}
                            placeholder="半角数字で入力"
                            min={0}
                          />
                        </StyledSmallInputOuter>
                        %
                        {occupationErrors &&
                          occupationErrors.average_percentage_of_new_customers && (
                            <StyledErrorMessageOuter>
                              <ValidationErrorMessage
                                message={
                                  occupationErrors.average_percentage_of_new_customers.message
                                }
                              />
                            </StyledErrorMessageOuter>
                          )}
                      </dd>
                      <dt>平均指名割合（個人実績）</dt>
                      <dd>
                        <StyledSmallInputOuter>
                          <NumberField
                            name={`${formName}[average_nomination_ratio]`}
                            placeholder="半角数字で入力"
                            min={0}
                          />
                        </StyledSmallInputOuter>
                        %
                        {occupationErrors && occupationErrors.average_nomination_ratio && (
                          <StyledErrorMessageOuter>
                            <ValidationErrorMessage
                              message={occupationErrors.average_nomination_ratio.message}
                            />
                          </StyledErrorMessageOuter>
                        )}
                      </dd>
                    </>
                  )}
                  <dt>平均顧客単価（個人実績）</dt>
                  <dd>
                    <StyledSmallInputOuter>
                      <NumberField
                        name={`${formName}[average_customer_spending]`}
                        placeholder="半角数字で入力"
                        min={0}
                      />
                    </StyledSmallInputOuter>
                    円
                    {occupationErrors && occupationErrors.average_customer_spending && (
                      <StyledErrorMessageOuter>
                        <ValidationErrorMessage
                          message={occupationErrors.average_customer_spending.message}
                        />
                      </StyledErrorMessageOuter>
                    )}
                  </dd>
                  <dt>平均売上（個人実績）</dt>
                  <dd>
                    <StyledSmallInputOuter>
                      <NumberField
                        name={`${formName}[average_personal_sales]`}
                        placeholder="半角数字で入力"
                        min={0}
                      />
                    </StyledSmallInputOuter>
                    円 / 月
                    {occupationErrors && occupationErrors.average_personal_sales && (
                      <StyledErrorMessageOuter>
                        <ValidationErrorMessage
                          message={occupationErrors.average_personal_sales.message}
                        />
                      </StyledErrorMessageOuter>
                    )}
                  </dd>
                </>
              )}
            {selectedJobTypeCategoryId === careJobTypeCategoryId && (
              <>
                <dt>勤務形態</dt>
                <dd>
                  <StyledList>
                    {workPatternList.map(({ key, value }) => {
                      const id = `user_careers_update_user_careers_attributes_${careerIndex}__user_career_work_patterns_${key}`;
                      return (
                        <li key={key}>
                          <Checkbox
                            name={`${formName}[user_career_work_patterns][]`}
                            value={`${value}`}
                            id={id}
                            defaultChecked={workPatterns?.includes(value)}
                          />
                          <StyledLabel htmlFor={id}>{key}</StyledLabel>
                        </li>
                      );
                    })}
                  </StyledList>
                </dd>
              </>
            )}
            {selectedJobTypeCategoryId !== careJobTypeCategoryId &&
              selectedJobTypeCategoryId !== otherJobTypeCategoryId &&
              selectedJobTypeCategoryId !== shikaJobTypeCategoryId && (
                <>
                  <dt>客層</dt>
                  <dd>
                    <TextArea
                      name={`${formName}[clientele]`}
                      placeholder="商業施設の中にある施設だったので、ファミリー層が多く、下は10代から上は80代まで、幅広い年代の方が来店されていました。"
                    />
                  </dd>
                </>
              )}
            <dt>職務内容</dt>
            <dd>
              <TextArea
                name={`${formName}[description]`}
                placeholder={descriptionPlaceholder || ""}
                height={207}
              />
            </dd>
          </>
        </DefinitionList>
      </StyledOccupationOuter>
    </>
  );
};
