import { FC } from "react";
import { InformationText } from "@root/shared/components/InformationText";
import { container } from "./styles.css";

type Props = {
  text: string;
};

export const ErrorInformationText: FC<Props> = ({ text }) => (
  <div className={container}>
    <InformationText text={text} isAlert />
  </div>
);
