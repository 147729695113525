import { FC } from "react";
import { Link } from "@root/shared/components/Link";

type Props = {
  children: React.ReactNode;
  className?: string;
  url: string;
  onClick: () => void;
  gaAction: string;
  gaLabel: string;
  reproAction: string;
  clientShopId: string;
  theme: "primary" | "secondary";
};

export const TelLink: FC<Props> = ({
  children,
  className,
  url,
  onClick,
  gaAction,
  gaLabel,
  reproAction,
  clientShopId,
  theme = "secondary",
}) => {
  return (
    <Link
      theme={theme}
      className={className}
      href={url}
      onClick={onClick}
      data-ga-action={gaAction}
      data-ga-label={gaLabel}
      data-repro-action={reproAction}
      data-client-shop-id={clientShopId}
    >
      {children}
    </Link>
  );
};
