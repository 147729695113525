import { FC } from "react";
import { TextField } from "@root/shared/components/TextField";
import { Button } from "@root/shared/components/Button";
import { Icon } from "@root/shared/components/Icon";
import { ErrorInformationText } from "@userFrontend/components/ErrorInformationText";
import * as styles from "./styles.css";

type Props = {
  clientNameValue: string;
  shopNameValue: string;
  clientNameErrorText?: string;
  shopNameErrorText?: string;
  handleClientSearch: () => void;
  handleChangeClientNameValue: (value: string) => void;
  handleChangeShopNameValue: (value: string) => void;
};

export const SearchForm: FC<Props> = ({
  clientNameValue,
  shopNameValue,
  clientNameErrorText,
  shopNameErrorText,
  handleClientSearch,
  handleChangeClientNameValue,
  handleChangeShopNameValue,
}) => (
  <div className={styles.outer}>
    <div className={styles.textFieldContainer}>
      <p className={styles.textFieldText}>企業名</p>
      <TextField
        placeholder="企業名を入力"
        name="clientName"
        value={clientNameValue}
        onChange={(e) => handleChangeClientNameValue(e.target.value)}
        hasError={!!clientNameErrorText}
        className={styles.textField}
      />
      {clientNameErrorText && <ErrorInformationText text={clientNameErrorText} />}
    </div>
    <div className={styles.textFieldContainer}>
      <p className={styles.textFieldText}>店舗名・施設名</p>
      <TextField
        placeholder="店舗名・施設名を入力"
        name="shopName"
        value={shopNameValue}
        onChange={(e) => handleChangeShopNameValue(e.target.value)}
        hasError={!!shopNameErrorText}
        className={styles.textField}
      />
      {shopNameErrorText && <ErrorInformationText text={shopNameErrorText} />}
    </div>
    <Button theme="primary" onClick={handleClientSearch} className={styles.button}>
      <span className={styles.iconOuter}>
        <Icon name="search" />
      </span>
      検索する
    </Button>
  </div>
);
