import { FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import { Pulldown } from "@legacy_user_frontend/components/ui_parts";

type Props = {
  yearList: { key: string; value: number }[];
  monthList: { key: string; value: number }[];
  dateList: { key: string; value: number }[];
  yearName: string;
  monthName: string;
  dateName: string;
  handleOnChange?: () => void;
  yearRequiredMessage?: string;
  monthRequiredMessage?: string;
  dateRequiredMessage?: string;
  hasYearError?: boolean;
  hasMonthError?: boolean;
  hasDateError?: boolean;
};

const getLastDate = (year: number, month: number): number => {
  // 年と月からその月の最終日を返す
  const lastDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const leapYearLastDay = 29;
  if (month === 2 && ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0)) {
    return leapYearLastDay;
  }
  return lastDays[month - 1];
};

const StyledBirthDayFormLayout = styled.div`
  display: flex;
  gap: 8px;

  > select {
    flex-basis: 33.33%;
  }
`;

export const DateSelector: FC<Props> = ({
  yearList,
  monthList,
  dateList,
  yearName,
  monthName,
  dateName,
  handleOnChange,
  yearRequiredMessage,
  monthRequiredMessage,
  dateRequiredMessage,
  hasYearError,
  hasMonthError,
  hasDateError,
}) => {
  const { getValues, resetField, watch } = useFormContext();
  const [truncatedDayList, setTruncatedDayList] = useState(dateList);
  const watchedYear = watch(yearName);
  const watchedMonth = watch(monthName);

  // 選択した年と月に合わせて日のリストを作成する
  useEffect(() => {
    if (!watchedMonth) {
      // 月を選択していない場合は日付リストを空にして処理を抜ける
      resetField(dateName, { defaultValue: "" });
      setTruncatedDayList([]);
      return;
    }

    const lastDate = Number(getLastDate(Number(watchedYear), Number(watchedMonth)));
    const dateValue = getValues(dateName);

    if (Number(dateValue) > lastDate) {
      // 選択中の日付が存在しない場合、選択を解除する
      resetField(dateName, { defaultValue: "" });
    }
    const dateListArray = [...Array(lastDate)].map((_, i) => i + 1);
    const dateListObject = dateListArray.map((day) => ({
      key: `${day}日`,
      value: day,
    }));
    setTruncatedDayList(dateListObject);
  }, [dateName, getValues, resetField, watchedMonth, watchedYear]);

  return (
    <StyledBirthDayFormLayout>
      <Pulldown
        name={yearName}
        placeholder="年"
        list={yearList}
        requiredMessage={yearRequiredMessage}
        isError={!!hasYearError}
      />
      <Pulldown
        name={monthName}
        placeholder="月"
        list={monthList}
        requiredMessage={monthRequiredMessage}
        isError={!!hasMonthError}
      />
      <Pulldown
        name={dateName}
        placeholder="日"
        list={truncatedDayList}
        requiredMessage={dateRequiredMessage}
        onChange={handleOnChange}
        isError={!!hasDateError}
      />
    </StyledBirthDayFormLayout>
  );
};
