import { FC } from "react";
import { TelLink } from "@userFrontend/components/Links/TelLink";
import { Icon } from "@root/shared/components/Icon";
import { LineLink } from "@userFrontend/components/Links/LineLink";
import * as styles from "./styles.css";

type Props = {
  deviceType: "pc" | "sp";
};

const USER_MATCHING_SUPPORT_LINE_URL = "https://lin.ee/9b3IN1P";
const USER_MATCHING_SUPPORT_TEL_URL = "tel:0120954077";

export const ContactLinks: FC<Props> = ({ deviceType }) => {
  const businessHour = "営業時間：平日10:00～18:00";

  return (
    <>
      <div className={styles.imagesBlock[deviceType]}>
        <img
          src="/images/user_frontend/pc/img_matching_support_tel.png"
          alt="問い合わせ先"
          className={styles.telSupportImage}
        />
        <img
          src="/images/user_frontend/pc/img_matching_support_line.png"
          alt="LINEQRコード"
          className={styles.lineSupportImage}
        />
      </div>
      <div className={styles.buttonBlock[deviceType]}>
        <div className={styles.telLinkWrapper}>
          <div className={styles.telLinkTime}>{businessHour}</div>
          <TelLink
            url={USER_MATCHING_SUPPORT_TEL_URL}
            clientShopId=""
            gaAction=""
            gaLabel=""
            onClick={() => null}
            reproAction=""
            theme="primary"
            className={styles.telLinkButton}
          >
            <Icon name="tel" fontSize="16px" />
            <span className={styles.telLinkText}>電話で相談する</span>
          </TelLink>
        </div>
        <div className={styles.lineLinkWrapper}>
          <div className={styles.lineLinkTime}>{businessHour}</div>
          <LineLink url={USER_MATCHING_SUPPORT_LINE_URL} text="LINEで相談する" theme="line" />
        </div>
      </div>
    </>
  );
};
