import styled from "styled-components";
import { Breakpoints, Colors } from "@legacy_user_frontend/utils";

export const StyledElements = styled.div`
  > .table {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;

    dt {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      text-align: center;
      font-weight: bold;
      color: ${Colors.DEEP_GREY};
      width: 2em;
      margin-right: 0.1em;

      [class*="icon-"] {
        font-size: 18px;
      }
    }

    dd {
      width: calc(100% - 2.1em);
      color: ${Colors.OLD_DEEP_BLACK};
      padding: 12px 0;
    }

    ${Breakpoints.SMALL} {
      dt,
      dd {
        :not(:first-of-type) {
          border-top: 1px solid ${Colors.LIGHT_GREY};
        }
      }
    }

    ${Breakpoints.MEDIUM} {
      display: flex;
      flex-wrap: wrap;
      max-width: 543px;
      font-size: 13px;

      dt {
        justify-content: center;
        vertical-align: middle;
        padding: 8px 0;
        width: 4.5em;
        background: ${Colors.LIGHT_GREY_01};
        text-align: center;
        margin-right: 0;
        border: none;
        color: ${Colors.BLACK};

        :not(:first-of-type) {
          margin-top: 10px;
        }

        [class*="icon-"] {
          display: block;
        }
      }

      dd {
        width: calc(100% - 4.5em);
        padding: 12px;
        background: ${Colors.WHITE};
        border: none;

        :not(:first-of-type) {
          margin-top: 10px;
        }
      }

      dt,
      dd {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .table-title {
      display: block;

      ${Breakpoints.SMALL} {
        display: none;
      }
    }

    .shop-num-outer {
      margin-bottom: 8px;

      a {
        font-weight: bold;
      }

      ${Breakpoints.MEDIUM} {
        margin-bottom: 16px;
      }
    }

    .shop-num {
      font-size: 12px;
      color: ${Colors.ORANGE};
      font-weight: bold;
      margin-right: 3px;

      ${Breakpoints.MEDIUM} {
        font-size: 14px;
      }
    }
  }
`;
