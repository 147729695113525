/* eslint-disable camelcase */
import { FC, useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { CassetteListProps } from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_edit/src/profiles_edit_props";
import { StationCassette } from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_edit/ui_parts/station_cassette_list/src/station_cassette";
import { AdditionButton } from "@userFrontend/components/Buttons/AdditionButton";
import styled from "styled-components";
import { ValidationErrorMessage } from "@legacy_root/user_frontend/components/ui_parts/validation_error_message";
import { StyledErrorMessageOuter } from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_edit/src/styled_elements";

type FieldsItemProps = {
  id: string;
  line_id?: string;
  station_id?: string;
};

const StyledButtonOuter = styled.div`
  padding-top: 16px;
`;

export const StationCassetteList: FC<CassetteListProps> = ({
  lineList,
  stationList,
  fetchStationsListPath,
  fetchStationsGroupPath,
  fetchLinesListPath,
}) => {
  const { formState, control } = useFormContext();
  const { fields, remove, append } = useFieldArray({
    control,
    name: "form_user_frontend_user_update[stations_attributes]",
  });

  // 駅・路線が未選択の場合は選択フォームを1つ表示する
  useEffect(() => {
    if (fields.length === 0) {
      append({
        line_id: "",
        station_id: "",
      });
    }
  }, [append, fields]);

  return (
    <>
      {fields.map((item: FieldsItemProps, index) => {
        const stations = stationList?.length && stationList[index] ? stationList[index] : [];
        return (
          <StationCassette
            cassetteIndex={index}
            lineList={lineList}
            stationList={stations}
            remove={remove}
            fetchStationsListPath={fetchStationsListPath}
            fetchStationsGroupPath={fetchStationsGroupPath}
            fetchLinesListPath={fetchLinesListPath}
            key={item.id}
          />
        );
      })}
      {formState.errors.form_user_frontend_user_update?.stations && (
        <StyledErrorMessageOuter>
          <ValidationErrorMessage
            message={formState.errors.form_user_frontend_user_update.stations.message}
          />
        </StyledErrorMessageOuter>
      )}
      <StyledButtonOuter>
        <AdditionButton
          onClick={() => {
            append({
              line_id: "",
              station_id: "",
            });
          }}
        >
          最寄り駅を追加する
        </AdditionButton>
      </StyledButtonOuter>
    </>
  );
};
