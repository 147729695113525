import useSWRImmutable from "swr/immutable";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const newrelic: any;

export const FetchData = <T>(fetchUrl: string, option = {}): T | null => {
  const fetcher = (url: string): Promise<T> =>
    fetch(url, option).then((res) => res.json() as Promise<T>);
  const { data, error } = useSWRImmutable<T>(fetchUrl, fetcher);

  // 非同期通信に失敗した際にnewrelicにエラーを送る
  if (error) {
    if (typeof newrelic !== "undefined") {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      newrelic.noticeError(error);
    }
    return null;
  }
  if (!data) return null;

  return data;
};
