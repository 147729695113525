import { useState, useEffect, useRef, RefObject } from "react";

export const useCheckOverflow = (
  returnFlag: boolean,
  isShow: boolean,
  parentRef: RefObject<HTMLDivElement>,
  headerHeight: number,
  headerMarginBottom: number,
  contentRef: RefObject<HTMLDivElement>,
  footerHeight: number,
): boolean => {
  const [hasOverflow, setHasOverflow] = useState(false);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  useEffect(() => {
    const checkOverflow = () => {
      if (returnFlag) return;

      if (parentRef.current && contentRef.current) {
        const parentHeight =
          parentRef.current.clientHeight - headerHeight - headerMarginBottom - footerHeight;
        const contentHeight = contentRef.current.scrollHeight;
        setHasOverflow(contentHeight > parentHeight); // スクロールが生じる場合（中身の高さがが親要素より大きい場合）はtrueを返す
      }
    };

    if (!returnFlag) {
      // 「もっと見る」等、ユーザー操作によって後から高さが変わる場合に対応するため
      resizeObserverRef.current = new ResizeObserver(checkOverflow);
      if (contentRef.current) {
        resizeObserverRef.current.observe(contentRef.current);
      }
    }

    // 初期チェック
    checkOverflow();

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, [isShow, parentRef, headerHeight, headerMarginBottom, contentRef, footerHeight, returnFlag]);

  return hasOverflow;
};
