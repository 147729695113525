import { useState, useCallback } from "react";
import { sendGaEvent } from "@root/utils/sendGaEvent";

export const useEntryLink = (): {
  handleClickEntryLink: (gaAction: string, gaLabel: string) => () => void;
} => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClickEntryLink = useCallback(
    (gaAction, gaLabel) => () => {
      if (!isClicked) {
        setIsClicked(true);
        sendGaEvent({ category: "Click", action: gaAction, label: gaLabel });
      }
    },
    [isClicked],
  );

  return { handleClickEntryLink };
};
