import { FC } from "react";
import {
  UserVoiceCassette,
  UserVoiceCassetteProps,
} from "@userFrontend/features/publication/components/UserVoiceCassette";
import * as styles from "./styles.css";

type Props = {
  cassettes: UserVoiceCassetteProps[];
};

export const UserVoiceCassettes: FC<Props> = ({ cassettes }) => (
  <>
    {cassettes.map((cassette, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={`${index}_${cassette.commentText}`} className={styles.cassetteOuter}>
        <UserVoiceCassette {...cassette} />
      </div>
    ))}
  </>
);
