import { FC } from "react";
import { ActionProps } from "@legacy_user_frontend/components/pages/jobs/job_search_navigation/src/search_modal/src/search_modal_props";
import { AccordionLists } from "./ui_parts/accordion_lists";
import {
  StyledBgOuter,
  StyledCityParentOuter,
  StyledCityParentLabel,
  StyledAccordionLabel,
  StyledCityAccordionButton,
  StyledCityChildOuter,
  StyledChildLists,
} from "./src/styled_elements";

export type StationModalProps = {
  stationContents: {
    lineName: string;
    stations: {
      name: string;
      url: string;
      value: string;
      showLink: boolean;
    }[];
  }[];
  setIsAccordionOpen: React.Dispatch<React.SetStateAction<Record<number, boolean>>>;
  isAccordionOpen: Record<number, boolean>;
  dispatchFormState: React.Dispatch<ActionProps>;
  formState: Record<string, { url: string }>;
};

export const StationModal: FC<StationModalProps> = ({
  stationContents,
  setIsAccordionOpen,
  isAccordionOpen,
  dispatchFormState,
  formState,
}) => {
  const toggleAccordion = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAccordionOpen({
      ...isAccordionOpen,
      [e.target.value]: e.target.checked,
    });
  };
  const handleOnStationStateChange = (e: React.ChangeEvent<HTMLInputElement>, url: string) => {
    if (e.target.checked) {
      dispatchFormState({
        type: "selectStations",
        value: e.target.value,
        url,
      });
    } else {
      dispatchFormState({ type: "unSelectStations", value: e.target.value });
    }
  };
  return (
    <>
      {stationContents.map(({ lineName, stations }, index) => {
        return (
          <StyledBgOuter key={lineName}>
            <StyledCityParentOuter>
              <StyledCityParentLabel>
                <span>{lineName}</span>
              </StyledCityParentLabel>
              <StyledAccordionLabel>
                <StyledCityAccordionButton
                  type="checkbox"
                  onChange={toggleAccordion}
                  value={index}
                  name="accordion"
                  defaultChecked={isAccordionOpen[index]}
                />
              </StyledAccordionLabel>
            </StyledCityParentOuter>
            <StyledCityChildOuter isOpen={isAccordionOpen[index]}>
              <StyledChildLists>
                <AccordionLists
                  contents={stations}
                  formState={formState}
                  checkBoxName="q[station][]"
                  changeEvent={handleOnStationStateChange}
                />
              </StyledChildLists>
            </StyledCityChildOuter>
          </StyledBgOuter>
        );
      })}
    </>
  );
};
