import "app/packs/userFrontend/features/publication/components/DetailPageActionButtons/uiParts/DefaultStyleButtons/styles.css.ts.vanilla.css!=!../../../../../../../../../../../shared/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"app/packs/userFrontend/features/publication/components/DetailPageActionButtons/uiParts/DefaultStyleButtons/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61UTXOCMBC9+yv20hmdKQ5fAuJMp/+kE03EtBqYECu2438vRLAkIeLBC4fse2933+4y//AK6i1+Ig9+JwAZKlLw/KJaTS6TeRfz9RgApmWxR+cUMk5x83CiWOzquOu+KNxAcm3RUEZ3hGY7kUKYaIkX98ORqa2jAXKOCa/DCjN+hLndk8opd5yyrxRchb+840hDax7QnmbMoYIcyhQIky59HktBt2dnkzNBWJ1rU38JV7TReG099HrAolvTfm8y4VK1fiOJglTCkZUO1oL7tUiFe7l6RDJqUA/swfooRM5eofd0tcFw1b5nby1Ft+r9QDBFUG44IQwQwzA9UOa0QnFUw2aSOFdvYWDJL31U2KLa/gMDsFABvgEgKsAzAHeMATggntV9iLyQ29lQh5tF1X+zsdHsupXrhpy8GGVgC2TM22XiWr21XoJifDd17Zr6WHmlJ95sWvM1ivf1YVo02wtVuMHABBvjb4aGkVtUK9s4BpZFO2ubWJvwyEoirHv1DK3otkw36jfiU8fpAO7M4MQtR/k/UkYFRXslZbAarXM0WaJs+/VvAugo8qvJD7qgSCJNslFTtAI/GaCtH7B9tB38DBGi3WOnYU768gdb5mAQ4AcAAA==\"}!../../../../../../../../../../../shared/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var acceptButtonWrapper = '_1pi15z67';
export var buttonMaxWidth = 'var(--_1pi15z60)';
export var buttonWrapper = '_1pi15z61';
export var deadlineWrapper = '_1pi15z6c';
export var declineButtonWrapper = '_1pi15z6b';
export var entryButtonWrapper = '_1pi15z63';
export var flexButtonWrapper = '_1pi15z62';
export var hasEntriedButtonWrapper = '_1pi15z6a';
export var hasEntriedWrapper = '_1pi15z69';
export var isScoutextendButtonWrapper = '_1pi15z6d';
export var justifyCenterButtonWrapper = '_1pi15z68';
export var keepButtonWrapper = '_1pi15z65';
export var notScoutedSalonTourButtonWrapper = '_1pi15z64';
export var salonTourButtonWrapper = '_1pi15z66';
export var scoutExtendDeclineWrapper = '_1pi15z6e';