import { FC } from "react";
import * as styles from "./styles.css";

type Props = {
  /** tdの内容 */
  children?: React.ReactNode;
  /** コンポーネントに適用するクラス名 */
  className?: string;
};

export const Td: FC<Props> = ({ children, className }) => (
  <td
    className={`
      ${styles.td}
      ${className || ""}
    `}
  >
    {children}
  </td>
);
