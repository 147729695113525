export const addQueryStrings = (
  url: string,
  queryStr: string | undefined, // ex.premium_prefecture_id=1&business_type=biyoshi
  excludeQueryKeys?: string | undefined, // ex.premium_prefecture_id,business_type
): string => {
  let newUrl = url;

  if (queryStr) {
    const excludeQueryKeysList = excludeQueryKeys?.split(",");
    const queryStringList = queryStr.split("&");
    const newQueryStringList = queryStringList.filter((string) => {
      return !excludeQueryKeysList?.some((excludeQuery) => {
        const regexp = new RegExp(`^${excludeQuery}=`);
        return regexp.test(string);
      });
    });

    if (newQueryStringList.length) {
      const newQueryStr = newQueryStringList.join("&");
      newUrl = url?.includes("?") ? `${url}&${newQueryStr}` : `${url}?${newQueryStr}`;
    }
  }

  return newUrl;
};
