import { FC, ReactNode } from "react";
import { variant, sizeVariant, leftIconOuter, rightIconOuter } from "./styles.css";

export type Themes = keyof typeof variant;
export type Size = keyof typeof sizeVariant;

type Props = JSX.IntrinsicElements["button"] & {
  theme?: Themes;
  size?: Size;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
};

export const Button: FC<Props> = ({
  theme = "default",
  size = "large",
  children,
  className,
  leftIcon,
  rightIcon,
  ...rest
}) => {
  return (
    <button
      type="button"
      className={`${variant[theme]} ${sizeVariant[size]} ${className || ""}`}
      {...rest}
    >
      {leftIcon && <span className={leftIconOuter}>{leftIcon}</span>}
      {children}
      {rightIcon && <span className={rightIconOuter}>{rightIcon}</span>}
    </button>
  );
};
