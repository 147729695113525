export const PlaceholderText: Record<string, string> = {
  workplaceMatching:
    "例）ある程度個人の裁量で仕事ができます。そのため、自分の仕事のスタイルを持っている人には合うと思います。また、指導や社員教育にも力を入れている会社なので、自分から学び・成長意欲のある方にもおすすめの会社です。",
  interestReward:
    "例）この職場の土地柄もあり、様々なお客様がご来店くださいます。職業や年齢も異なる方と多く出会えるのは大きな魅力です。また、職場の中で従業員同士の連携がスムーズなことでサービスや技術をお客様に気に入っていただけたときはやりがいを感じました。",
  challenge:
    "例）いずれは自分のスタイルを確立できるよう、現在は先輩から接客や技術を必死に習得しています。また、お客様に薦めやすい資料を作成しリピーターを増やすことにも注力しております。最終的には独立して自分でお店をオープンしたいと思っています。",
  expectation:
    "例）職場の人数が多くないため、一人ひとりへの技術指導や教育がしっかり行き渡らないことが稀にあります。技術指導や教育の時間がないときは雑務を任されることが多い為、その時間は個人の練習が出来たらいいなと思います。",
};
