import { FC } from "react";
import * as styles from "./styles.css";

export type LabelProps = {
  /**
   * テキストの前に表示するアイコン
   */
  icon?: React.ReactNode;
  theme: "lightPink" | "gray" | "deepPink" | "red" | "lightGray";
  size?: "small" | "large";
  bold?: boolean;
  children: React.ReactNode;
  className?: string;
};

export const Label: FC<LabelProps> = ({
  icon,
  theme,
  size = "small",
  bold = false,
  children,
  className,
}) => (
  <span
    className={`
      ${styles.label({
        size,
        isBold: bold,
        theme,
      })}
      ${className || ""}
    `}
  >
    {icon && <span className={styles.prefixOuter}>{icon}</span>}
    {children}
  </span>
);
