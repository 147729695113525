import { FC, ReactNode, Ref } from "react";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { Icon } from "@root/shared/components/Icon";
import { Direction } from "./type";
import * as styles from "./styles.css";

type Props = {
  children: ReactNode;
  className?: string;
  direction: Direction;
  isOpacityZero: boolean;
  isDisplayNone: boolean;
  elementRef: Ref<HTMLDivElement>;
  hideWithDelay: () => void;
  right: string;
  left: string;
  bottom: string;
  top: string;
  zIndex: string;
};

export const Presentation: FC<Props> = ({
  children,
  className,
  direction,
  isOpacityZero,
  isDisplayNone,
  elementRef,
  hideWithDelay,
  bottom,
  top,
  right,
  left,
  zIndex,
}) => {
  return (
    <div
      className={`${styles.variant({ direction })} ${className}`}
      style={assignInlineVars({
        [styles.opacity]: isOpacityZero ? "0" : "1",
        [styles.display]: isDisplayNone ? "none" : "flex",
        [styles.bottom]: bottom,
        [styles.top]: top,
        [styles.right]: right,
        [styles.left]: left,
        [styles.zIndex]: zIndex,
      })}
      ref={elementRef}
    >
      <div className={styles.inner}>
        <button type="button" onClick={hideWithDelay} className={styles.closeButton}>
          <Icon name="cross-bold" fontSize="16px" />
        </button>
        {children}
      </div>
    </div>
  );
};
