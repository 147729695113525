import { FC, ReactNode } from "react";
import * as styles from "./styles.css";

type Props = {
  isFixed?: boolean;
  tabContents: {
    icon?: ReactNode;
    buttonText: string;
    content: ReactNode;
    isCurrent?: boolean;
    isDisabled?: boolean;
  }[];
  isBiyo?: boolean;
  isVerticalIcon?: boolean; // SPのみタブバーのアイコンとテキスト縦並びで表示する
  borderPosition?: "top" | "bottom";
  handleOnClick: (index: number) => void;
};

export const Tab: FC<Props> = ({
  isFixed = false,
  tabContents = [], // tabContentsが空の状態で使用しない想定だが、エラー回避のため
  isBiyo = true,
  isVerticalIcon = false,
  borderPosition = "top",
  handleOnClick,
}) => (
  <>
    <div className={isFixed ? styles.fixedButtonContainerOuter : ""}>
      <div className={isFixed ? styles.fixedButtonContainer : ""}>
        <div className={styles.buttonOuter}>
          {tabContents.map(({ icon, buttonText, isCurrent = false, isDisabled = false }, index) => (
            <button
              key={buttonText}
              type="button"
              className={styles.button({
                isCurrent,
                isBiyo,
                isVerticalIcon,
                borderPosition,
                isDisabled,
              })}
              onClick={() => handleOnClick && handleOnClick(index)}
              disabled={isDisabled}
            >
              {icon && <span className={styles.iconOuter}>{icon}</span>}
              {buttonText}
            </button>
          ))}
        </div>
      </div>
    </div>
    {tabContents.map(({ buttonText, content, isCurrent }) => (
      <div key={buttonText} className={styles.content[isCurrent ? "isCurrent" : "default"]}>
        {content}
      </div>
    ))}
  </>
);
