import { FC } from "react";
import styled from "styled-components";
import { Breakpoints, Colors } from "@legacy_user_frontend/utils";
import { LazyLoadImage } from "@root/shared/components/LazyLoadImage";

type Props = {
  url: string;
  gaAction: string;
  gaLabel: string;
  query: string;
  lazyLoadImage: {
    src: string;
    alt: string;
    width: number;
    height: number;
    dummyImgPath: string;
  };
};

const PremiumScoutBanner = styled.div`
  margin: 24px 5px;

  ${Breakpoints.MEDIUM} {
    margin: 40px 10px;
  }

  > .link {
    display: block;
    max-width: 770px;
    margin: 0 auto;
    border: 1px solid ${Colors.GREY};
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    transition: opacity, 0.2s;

    &:hover,
    &:focus {
      opacity: 0.7;
    }
  }
`;

export const PremiumBanner: FC<Props> = ({
  url,
  gaAction,
  gaLabel,
  query,
  lazyLoadImage: { src, alt, width, height, dummyImgPath },
}) => (
  <PremiumScoutBanner>
    <a
      href={url}
      className="link ga-click ga-impression js-add-query-strings"
      data-ga-action={gaAction}
      data-ga-label={gaLabel}
      data-query-str={query}
    >
      <LazyLoadImage
        src={src}
        alt={alt}
        width={width}
        height={height}
        dummyImgPath={dummyImgPath}
      />
    </a>
  </PremiumScoutBanner>
);
