import { FC, MouseEvent } from "react";
import { Content } from "./type";
import * as styles from "./styles.css";

type PresentationProps = {
  handleOnClick: (event: MouseEvent<HTMLElement>, link: string) => void;
  contents: Content[];
};

export const Presentation: FC<PresentationProps> = ({ handleOnClick, contents }) => (
  <nav className={styles.container}>
    <p className={styles.title}>目次</p>
    <ol className={styles.list}>
      {contents.length > 0 &&
        contents.map(({ title, link }) => (
          <li key={title} className={styles.listItem}>
            <button type="button" onClick={(e) => handleOnClick(e, link)} className={styles.button}>
              {title}
            </button>
          </li>
        ))}
    </ol>
  </nav>
);
