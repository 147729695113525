import { FC } from "react";
import { PrivateLabel } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/private_label";
import {
  StyledList,
  StyledTableList,
} from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_show/src/styled_elements";
import { ProfileCassetteProps } from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_show/src/profile_show_props";
import { changeDateFormat } from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_show/src/module";

export const ProfileCassette: FC<ProfileCassetteProps> = ({
  profile: {
    name,
    email,
    nameKana,
    tel,
    postCode,
    prefectureName,
    cityName,
    address1,
    stations,
    birthDay,
    sexText,
  },
}) => (
  <StyledTableList>
    {name && (
      <>
        <dt>
          <div>氏名</div>
          <PrivateLabel />
        </dt>
        <dd>{name}</dd>
      </>
    )}
    {nameKana && (
      <>
        <dt>
          <div>ふりがな</div>
          <PrivateLabel />
        </dt>
        <dd>{nameKana}</dd>
      </>
    )}
    <dt>
      <div>メールアドレス</div>
      <PrivateLabel />
    </dt>
    <dd>{email}</dd>
    <dt>
      <div>パスワード</div>
      <PrivateLabel />
    </dt>
    <dd>＊＊＊＊</dd>
    {sexText && (
      <>
        <dt>性別</dt>
        <dd>{sexText}性</dd>
      </>
    )}
    {birthDay && (
      <>
        <dt>生年月日</dt>
        <dd>{changeDateFormat(birthDay)}</dd>
      </>
    )}
    {postCode && (
      <>
        <dt>
          <div>郵便番号</div>
          <PrivateLabel />
        </dt>
        <dd>{postCode}</dd>
      </>
    )}
    {(prefectureName || cityName || address1) && (
      <>
        <dt>
          <div>住所</div>
          <PrivateLabel />
          <div className="address-annotation">
            ※市区町村
            <br className="address-annotation-line-break" />
            までは公開
          </div>
        </dt>
        <dd>
          {prefectureName}
          {cityName}
          {address1 && <>{address1}</>}
        </dd>
      </>
    )}
    {stations.length > 0 && (
      <>
        <dt>最寄り駅</dt>
        <dd>
          <StyledList>
            {stations.map((station) => (
              <li key={`${station.name}_${station.lineName}`}>
                {station.lineName} {station.name}駅
              </li>
            ))}
          </StyledList>
        </dd>
      </>
    )}
    {tel && (
      <>
        <dt>
          <div>電話番号</div>
          <PrivateLabel />
        </dt>
        <dd>{tel}</dd>
      </>
    )}
  </StyledTableList>
);
