import { FC, useEffect } from "react";
import { useCookies } from "react-cookie";
import { Presentation } from "./Presentation";

type Props = {
  neverShowPopoverCookieName: string;
};
export const ClientReviewPopover: FC<Props> = ({ neverShowPopoverCookieName }) => {
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [cookies, setCookie, removeCookie] = useCookies([neverShowPopoverCookieName]);

  // 10,000日後を日時に変換
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + 10000 * 24 * 60 * 60 * 1000);

  useEffect(() => {
    setCookie(neverShowPopoverCookieName, true, { path: "/", expires: expirationDate });
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return <Presentation />;
};
