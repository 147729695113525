import { FC, Fragment } from "react";
import { SkillCassetteProps } from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_show/src/profile_show_props";
import {
  StyledCategorizedSkillList,
  StyledSkillList,
  StyledTagLabel,
} from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_show/src/styled_elements";

export const SkillCassette: FC<SkillCassetteProps> = ({ skills }) => (
  <StyledSkillList>
    {skills.map((categorizedSkills) => (
      <Fragment key={categorizedSkills.name}>
        <dt>{categorizedSkills.name}</dt>
        <dd>
          <StyledCategorizedSkillList>
            {categorizedSkills.skills.map((skill) => (
              <li key={skill.name}>
                <StyledTagLabel>{skill.name}</StyledTagLabel>
              </li>
            ))}
          </StyledCategorizedSkillList>
        </dd>
      </Fragment>
    ))}
  </StyledSkillList>
);
