import { ComponentPropsWithRef, FC, forwardRef } from "react";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import * as styles from "./styles.css";

type Props = ComponentPropsWithRef<"textarea"> & {
  hasError?: boolean;
  size?: "small" | "large";
};

export const TextArea: FC<Props> = forwardRef((props, ref) => {
  const { hasError, className, size = "small", ...restProps } = props;
  return (
    <textarea
      {...restProps}
      className={`${styles.textArea} ${hasError ? styles.hasError : ""} ${className ?? ""}`}
      style={assignInlineVars({ [styles.height]: size === "large" ? "180px" : "66px" })}
      ref={ref}
    />
  );
});
TextArea.displayName = "TextArea";
