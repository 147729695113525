import { FC, useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { axiosPost } from "@root/utils/axiosForRails";
import { sendBeaconData, truncateText, showAlert } from "@root/utils";
import { useImageFileUploader } from "@userFrontend/features/reward/components/ImageFileUploader/useImageFileUploader";
import { SiteId } from "@root/shared/types/type";
import { Presentation } from "./Presentation";
// hooks
import { useValidationText } from "./useValidationText";
import { useFormatQuestion } from "./useFormatQuestion";
// types
import {
  Props,
  CheckboxState,
  RadioButtonState,
  TextAreaState,
  ErrorItem,
  JobEntries,
  CurrentStep,
} from "./type";
import { InputNames } from "./utils/InputNames";

// FormData型の定義
/* eslint-disable camelcase */
type BackupFormData = {
  form_user_frontend_user_continued_reward_create: {
    job_entry_id: string | null;
    email: string | null;
    business_type_id: number | null;
    employment_status: number | null;
    employment_id: number | null;
    atmosphere: number | null;
    salary_standard: number | null;
    debut: number | null;
    education: number | null;
    work_time: number | null;
    salary_change: number | null;
    workplace_matching: string | null;
    interest_reward: string | null;
    challenge: string | null;
    expectation: string | null;
    treatments: number[];
    cultures: number[];
  };
};

type RestoreData = {
  siteId: SiteId;
  jobEntryId: string;
  email: string;
  businessTypeId: number;
  employmentStatus: number;
  employmentId: number;
  atmosphere: number;
  salaryStandard: number;
  debut: number;
  education: number;
  workTime: number;
  salaryChange: number;
  workplaceMatching: string;
  interestReward: string;
  challenge: string;
  expectation: string;
  treatments: number[];
  cultures: number[];
};

type Response = {
  status?: number;
  data: {
    errors?: ErrorItem[];
  };
};

export const ContinuedRewardInputs: FC<Props> = ({
  initialSiteId,
  email,
  businessTypes,
  employments,
  jobEntries,
  selectiveQuestions,
  textAreaQuestions,
  userRewardContinuedCreatePath,
  userRewardContinuedConfirmPath,
  createBackupPath,
  restoreBackupPath,
  deleteBackupPath,
  termsPath,
  guidelinePath,
}) => {
  // 勤続先の存在チェック
  const hasJobEntry = Boolean(jobEntries.length);

  // 入力項目のstate
  const [siteId, setSiteId] = useState<SiteId>(initialSiteId);
  const [jobEntryValue, setJobEntryValue] = useState<string | undefined>();
  const [emailValue, setEmailValue] = useState<string | undefined>(email);
  const [employmentStatusValue, setEmploymentStatusValue] = useState<number | undefined>();
  const [businessTypeValue, setBusinessTypeValue] = useState<number | undefined>();
  const [employmentValue, setEmploymentValue] = useState<number | undefined>();
  const [checkboxValues, setCheckboxValues] = useState<CheckboxState>({});
  const [radioValues, setRadioValues] = useState<RadioButtonState>({});
  const [textAreaState, setTextAreaState] = useState<TextAreaState>({});

  // 入力値バックアップ関連のstate
  const [isShowBackupModal, setIsShowBackupModal] = useState<boolean>(false);

  // 画面ステップ管理のstate
  const [currentStep, setCurrentStep] = useState<CurrentStep>(1);

  // Rails側バリデーションエラー文言のstate
  const [railsValidationTexts, setRailsValidationTexts] = useState<ErrorItem[]>([]);

  // ボタン押下時バリデーションエラーのstate
  const [isFailed, setIsFailed] = useState(false);

  // 扱いやすい形に整形するため
  const formattedQuestions = useFormatQuestion(
    siteId,
    employments,
    businessTypes,
    selectiveQuestions,
    textAreaQuestions,
  );

  const {
    files,
    previewUrlArray,
    fileInputRef,
    handleClickSelectButton,
    handleClickDeleteButton,
    handleChangeFile,
    isLoading,
  } = useImageFileUploader();

  // バリデーション判定
  const { errorTexts, isValid, updateClickStatus } = useValidationText(
    checkboxValues,
    radioValues,
    textAreaState,
    files,
    railsValidationTexts,
    jobEntryValue,
    emailValue,
    businessTypeValue,
    employmentValue,
  );

  const inputContainerRefs = useRef<{
    [key in (typeof InputNames)[number]]: React.RefObject<HTMLDivElement>;
  }>({
    jobEntryId: useRef<HTMLDivElement>(null),
    email: useRef<HTMLDivElement>(null),
    businessTypeId: useRef<HTMLDivElement>(null),
    employmentStatus: useRef<HTMLDivElement>(null),
    employmentId: useRef<HTMLDivElement>(null),
    atmosphere: useRef<HTMLDivElement>(null),
    salaryStandard: useRef<HTMLDivElement>(null),
    debut: useRef<HTMLDivElement>(null),
    education: useRef<HTMLDivElement>(null),
    workTime: useRef<HTMLDivElement>(null),
    salaryChange: useRef<HTMLDivElement>(null),
    workplaceMatching: useRef<HTMLDivElement>(null),
    interestReward: useRef<HTMLDivElement>(null),
    challenge: useRef<HTMLDivElement>(null),
    expectation: useRef<HTMLDivElement>(null),
    treatments: useRef<HTMLDivElement>(null),
    cultures: useRef<HTMLDivElement>(null),
    images: useRef<HTMLDivElement>(null),
  });

  // 最初のエラー箇所にスクロールする
  const scrollToFirstError = (firstErrorKey: string) => {
    const firstErrorInputContainerRef = inputContainerRefs.current[firstErrorKey];
    if (firstErrorInputContainerRef && firstErrorInputContainerRef.current) {
      firstErrorInputContainerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  // 現在確認画面を表示しているかどうか
  const isCurrentViewInput = useCallback((): boolean => {
    return currentStep === 1;
  }, [currentStep]);
  const isCurrentViewConfirm = useCallback((): boolean => {
    return currentStep === 6;
  }, [currentStep]);

  // メールアドレス変更ハンドラ
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValue(e.target.value);
  };

  // 職種変更ハンドラ
  const handleBusinessTypeSelect = (e: React.FocusEvent<HTMLSelectElement>) => {
    setBusinessTypeValue(Number(e.target.value) || undefined);
  };

  // 雇用形態変更ハンドラ
  const handleEmploymentSelect = (e: React.FocusEvent<HTMLSelectElement>) => {
    setEmploymentValue(Number(e.target.value) || undefined);
  };

  // チェックボックス形式の変更ハンドラ(単一変更)
  const handleCheckboxChange = (category: string, optionId: number) => {
    setCheckboxValues((prevState) => {
      // 現在のカテゴリの選択状態を取得（もしくは空の配列）
      const currentSelection = prevState[category] || [];

      if (currentSelection.includes(optionId)) {
        // すでに選択されている場合は、その要素を削除
        return {
          ...prevState,
          [category]: currentSelection.filter((id) => id !== optionId),
        };
      }
      // 選択されていない場合は、その要素を追加
      return {
        ...prevState,
        [category]: [...currentSelection, optionId],
      };
    });
  };

  // チェックボックス形式の変更ハンドラ(複数変更)
  // バックアップの復元で利用
  const handleMultipleCheckboxChanges = (changes: Record<string, number[]>) => {
    setCheckboxValues((prevState: CheckboxState) => {
      let newState: CheckboxState = { ...prevState };

      Object.entries(changes).forEach(([category, ids]) => {
        ids.forEach((id: number) => {
          const currentSelection = newState[category] || [];
          if (currentSelection.includes(id)) {
            // 既に存在する場合は削除
            newState = {
              ...newState,
              [category]: currentSelection.filter((existingId) => existingId !== id),
            };
          } else {
            // 存在しない場合は追加
            newState = {
              ...newState,
              [category]: [...currentSelection, id],
            };
          }
        });
      });

      return newState;
    });
  };

  const handleJobEntryChange = (
    targetJobEntryId: string,
    targetSiteId: SiteId,
    targetBusinessTypeId: number,
  ) => {
    setJobEntryValue(() => targetJobEntryId);
    setSiteId(() => targetSiteId);
    setBusinessTypeValue(() => targetBusinessTypeId);
    setCheckboxValues({});
    setRadioValues({});
  };

  const handleEmploymentStatusChange = (value?: number) => {
    if (!value) return;
    setEmploymentStatusValue(value);
    handleRadioChange("employmentStatus", value);
  };
  const handleRadioChange = (category: string, value: number) => {
    setRadioValues((prevState) => ({
      ...prevState,
      [category]: value,
    }));
  };

  const handleTextAreaChange = (category: string, value: string) => {
    setTextAreaState((prevState) => ({
      ...prevState,
      [category]: value,
    }));
  };

  // 入力値バックアップのリストア処理
  const handleRestore = (data: RestoreData) => {
    handleJobEntryChange(data.jobEntryId, data.siteId, data.businessTypeId);
    setEmailValue(() => data.email);
    setBusinessTypeValue(() => data.businessTypeId);
    setEmploymentValue(() => data.employmentId);
    handleEmploymentStatusChange(data.employmentStatus);
    handleRadioChange("atmosphere", data.atmosphere);
    handleRadioChange("salaryStandard", data.salaryStandard);
    handleRadioChange("debut", data.debut);
    handleRadioChange("education", data.education);
    handleRadioChange("workTime", data.workTime);
    handleRadioChange("salaryChange", data.salaryChange);
    handleTextAreaChange("workplaceMatching", data.workplaceMatching);
    handleTextAreaChange("interestReward", data.interestReward);
    handleTextAreaChange("challenge", data.challenge);
    handleTextAreaChange("expectation", data.expectation);
    const backupTreatments: Record<string, number[]> = { treatments: data.treatments };
    handleMultipleCheckboxChanges(backupTreatments);
    const backupCultures: Record<string, number[]> = { cultures: data.cultures };
    handleMultipleCheckboxChanges(backupCultures);
  };

  const truncateWorkContentTitleJobEntries = (): JobEntries => {
    const maxLength = 23;
    return jobEntries.map((entry) => {
      return {
        ...entry,
        workContentTitle: truncateText(entry.workContentTitle, maxLength),
      };
    });
  };

  // 勤務先の初期値を設定する
  useEffect(() => {
    if (!hasJobEntry) return;
    handleJobEntryChange(
      jobEntries[0].jobEntryId,
      jobEntries[0].siteId,
      jobEntries[0].businessTypeId,
    );
  }, [hasJobEntry, jobEntries]);

  // 美容/ケアに応じて就業状況の初期値を設定する
  useEffect(() => {
    handleEmploymentStatusChange(
      formattedQuestions.step1Questions.employmentStatus?.filter(
        (item) => item.optionText === "現職",
      )[0].optionId,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedQuestions.step1Questions.employmentStatus]);

  // 入力値バックアップ作成処理
  useEffect(() => {
    const saveFormData = (formData: BackupFormData) => {
      sendBeaconData(createBackupPath, formData);
    };

    // 入力値バックアップformパラメータ
    // 勤続証明書類はバックアップしない(容量が大きいことと、個人情報であるため)
    const formData = {
      form_user_frontend_user_continued_reward_create: {
        site_id: siteId,
        job_entry_id: jobEntryValue ?? null,
        email: emailValue ?? "",
        business_type_id: businessTypeValue ?? null,
        employment_status: radioValues.employmentStatus ?? null,
        employment_id: employmentValue ?? null,
        atmosphere: radioValues.atmosphere ?? null,
        salary_standard: radioValues.salaryStandard ?? null,
        debut: radioValues.debut ?? null,
        education: radioValues.education ?? null,
        work_time: radioValues.workTime ?? null,
        salary_change: radioValues.salaryChange ?? null,
        workplace_matching: textAreaState.workplaceMatching ?? "",
        interest_reward: textAreaState.interestReward ?? "",
        challenge: textAreaState.challenge ?? "",
        expectation: textAreaState.expectation ?? "",
        treatments: checkboxValues.treatments ? checkboxValues.treatments : [],
        cultures: checkboxValues.cultures ? checkboxValues.cultures : [],
      },
    };

    const handleVisibilityChange = () => {
      if (
        document.visibilityState === "hidden" &&
        (isCurrentViewInput() || isCurrentViewConfirm())
      ) {
        saveFormData(formData);
      }
    };

    // イベントリスナーの設定
    document.addEventListener("visibilitychange", handleVisibilityChange);
    // useEffectのクリーンアップ
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [
    createBackupPath,
    siteId,
    jobEntryValue,
    emailValue,
    businessTypeValue,
    employmentValue,
    radioValues.atmosphere,
    radioValues.debut,
    radioValues.education,
    radioValues.employmentStatus,
    radioValues.salaryChange,
    radioValues.salaryStandard,
    radioValues.workTime,
    textAreaState.workplaceMatching,
    textAreaState.interestReward,
    textAreaState.challenge,
    textAreaState.expectation,
    checkboxValues.treatments,
    checkboxValues.cultures,
    isCurrentViewInput,
    isCurrentViewConfirm,
  ]);

  // 入力値バックアップの存在有無確認
  useEffect(() => {
    const checkBackup = async () => {
      const response = await axiosPost.post(restoreBackupPath);
      // 存在する場合200、しない場合204
      if (response.status === 200) {
        setIsShowBackupModal(true);
      }
    };

    checkBackup();
  }, [restoreBackupPath]);

  // 入力値バックアップの削除
  const deleteBackup = async () => {
    await axiosPost.post(deleteBackupPath);
  };

  // 送信処理
  const handleClickSubmitButton = async () => {
    // 確認画面の場合はバリデーションと送信、確認画面でなければバリデーションのみ実行するため
    const postPath = isCurrentViewConfirm()
      ? userRewardContinuedCreatePath
      : userRewardContinuedConfirmPath;

    const postData = {
      form_user_frontend_user_continued_reward_create: {
        job_entry_id: jobEntryValue ?? "",
        email: emailValue ?? "",
        business_type_id: businessTypeValue ?? "",
        employment_status: employmentStatusValue ?? "",
        employment_id: employmentValue ?? "",
        atmosphere: radioValues.atmosphere ?? "",
        salary_standard: radioValues.salaryStandard ?? "",
        debut: radioValues.debut ?? "",
        education: radioValues.education ?? "",
        work_time: radioValues.workTime ?? "",
        salary_change: radioValues.salaryChange ?? "",
        workplace_matching: textAreaState.workplaceMatching ?? "",
        interest_reward: textAreaState.interestReward ?? "",
        challenge: textAreaState.challenge ?? "",
        expectation: textAreaState.expectation ?? "",
        treatments: checkboxValues.treatments ? checkboxValues.treatments : [],
        cultures: checkboxValues.cultures ? checkboxValues.cultures : [],
        images: files,
      },
    };

    updateClickStatus();

    if (!isValid) {
      setIsFailed(true);
      return;
    }

    const formData = new FormData();
    Object.entries(postData.form_user_frontend_user_continued_reward_create).forEach(
      ([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((subValue) => {
            // 配列の場合（例：ファイル、チェックボックスなど）
            if (subValue instanceof File) {
              formData.append(
                `form_user_frontend_user_continued_reward_create[${key}][]`,
                subValue,
              );
            } else {
              // subValueが数値またはその他の場合は文字列に変換
              formData.append(
                `form_user_frontend_user_continued_reward_create[${key}][]`,
                String(subValue),
              );
            }
          });
        } else {
          formData.append(`form_user_frontend_user_continued_reward_create[${key}]`, String(value));
        }
      },
    );

    try {
      await axiosPost.post(postPath, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // 入力画面から確認画面へ遷移
      if (isCurrentViewInput()) {
        setCurrentStep(6);
        // 確認画面から完了画面へ遷移
      } else if (isCurrentViewConfirm()) {
        setCurrentStep(7);
        deleteBackup();
      }
    } catch (error) {
      if (!axios.isAxiosError(error) || !error.response) {
        showAlert("送信に失敗しました。時間をおいて再度お試しください。");
        return;
      }

      const response: Response = error.response as Response;
      switch (response.status) {
        case 400:
          if (response.data.errors) {
            // Rails側バリデーションエラーをerrorTextsに一括反映
            setRailsValidationTexts(response.data.errors);
            // 確認画面でサーバーサイドエラーが返却された場合、入力画面へ戻す
            if (isCurrentViewConfirm()) {
              setCurrentStep(1);
            }
            // 最初のエラー箇所にスクロール
            const firstErrorKey = Object.keys(response.data.errors[0])[0];
            scrollToFirstError(firstErrorKey);
          }
          break;
        default:
          showAlert("送信に失敗しました。時間をおいて再度お試しください。");
      }
    }
  };

  // 画面に遷移時に画面上部から表示
  useEffect(() => {
    if (isValid) {
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  // ボタン押下時にエラーがあった場合は、エラーメッセージをもつ最初の項目へスクロールする
  useEffect(() => {
    if (!isFailed || Object.values(errorTexts).filter((item) => item !== "").length === 0) return;

    let firstNonEmptyKey = Object.keys(errorTexts).find((key) => errorTexts[key]);

    // inputContainerRefsと一致させるため
    if (firstNonEmptyKey === "jobEntry") {
      firstNonEmptyKey = "jobEntryId";
    }
    if (firstNonEmptyKey === "businessType") {
      firstNonEmptyKey = "businessTypeId";
    }
    if (firstNonEmptyKey === "employment") {
      firstNonEmptyKey = "employmentId";
    }
    if (firstNonEmptyKey) {
      // errorTextの中で、エラーメッセージをもつ最初のキーの項目へスクロールする
      scrollToFirstError(firstNonEmptyKey);
    }
    setIsFailed(false);
  }, [errorTexts, isFailed]);

  const handleClickBackButton = () => {
    setIsShowBackupModal(false);
    setCurrentStep(1);
  };

  return (
    <Presentation
      currentStep={currentStep}
      formattedQuestions={formattedQuestions}
      errorTexts={errorTexts}
      // Step1 基本情報(勤務先)
      jobEntries={truncateWorkContentTitleJobEntries()}
      jobEntryValue={jobEntryValue}
      handleJobEntryChange={handleJobEntryChange}
      // Step1 基本情報(メールアドレス)
      emailValue={emailValue}
      handleEmailChange={handleEmailChange}
      // Step1 就業情報(就業状況)
      employmentStatusValue={employmentStatusValue || undefined}
      handleEmploymentStatusChange={(value) => handleEmploymentStatusChange(value)}
      // Step1 就業情報(職種)
      businessTypeValue={businessTypeValue}
      handleBusinessTypeSelect={handleBusinessTypeSelect}
      // Step1 就業情報(雇用形態s)
      employmentValue={employmentValue}
      handleEmploymentSelect={handleEmploymentSelect}
      // Step2 待遇面の口コミ・社風面の口コミ
      checkboxValues={checkboxValues}
      handleCheckboxChange={handleCheckboxChange}
      // Step3 職場の口コミ(特徴)
      radioValues={radioValues}
      handleRadioChange={handleRadioChange}
      // Step4 職場の口コミ(あなたの考え・思い)
      textAreaValue={textAreaState}
      handleTextAreaChange={handleTextAreaChange}
      // Step5 勤続証明書類
      handleClickSelectButton={handleClickSelectButton}
      handleChangeFile={handleChangeFile}
      fileInputRef={fileInputRef}
      previewUrlArray={previewUrlArray}
      handleClickDeleteButton={handleClickDeleteButton}
      handleClickBackButton={handleClickBackButton}
      handleClickSubmitButton={handleClickSubmitButton}
      isShowBackupModal={isShowBackupModal}
      restoreBackupPath={restoreBackupPath}
      deleteBackupPath={deleteBackupPath}
      termsPath={termsPath}
      guidelinePath={guidelinePath}
      handleRestore={handleRestore}
      inputContainerRefs={inputContainerRefs}
      isAvailableSubmit={hasJobEntry}
      isLoading={isLoading}
    />
  );
};
