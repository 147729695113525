import { ComponentPropsWithRef, FC, forwardRef } from "react";
import { variant } from "./styles.css";

type Props = Omit<ComponentPropsWithRef<"input">, "type"> & {
  hasError?: boolean;
};

export const RadioButton: FC<Props> = forwardRef(({ className, hasError, ...rest }, ref) => (
  <input
    {...rest}
    type="radio"
    ref={ref}
    className={`${variant[hasError ? "hasError" : "default"]} ${className ?? ""}`}
  />
));
RadioButton.displayName = "RadioButton";
