import { FC } from "react";
import { TargetAndPeriodBox } from "@userFrontend/features/reward/components/TargetAndPeriodBox";
import { BasicInfoGuidanceBox } from "@userFrontend/features/reward/components/BasicInfoGuidanceBox";
import { SectionTitle } from "@userFrontend/features/reward/components/ContinuedRewardInputs/uiParts/SectionTitle";
import { SectionWithTitleAndLabel } from "@userFrontend/components/SectionWithTitleAndLabel";
import { InformationCard } from "@userFrontend/components/InformationCard";
import { InformationText } from "@root/shared/components/InformationText";
import { Icon } from "@root/shared/components/Icon";
import * as styles from "./styles.css";
// uiParts
import { EmailInput } from "./uiParts/EmailInput";
import { EmploymentStatusRadio } from "./uiParts/EmploymentStatusRadio";
import { SelectBoxContents } from "./uiParts/SelectBoxContents";
import { JobEntriesRadio } from "./uiParts/JobEntriesRadio";
// types
import { Props } from "./type";

export const Step1: FC<Props> = ({
  jobEntries,
  questions,
  employmentStatusValue,
  jobEntriesErrorText,
  emailErrorText,
  businessTypeValue,
  employmentValue,
  jobEntryValue,
  emailValue,
  employmentStatusErrorText,
  businessTypesErrorText,
  employmentsErrorText,
  handleEmploymentSelect,
  handleBusinessTypeSelect,
  handleJobEntryChange,
  handleEmailChange,
  handleEmploymentStatusChange,
  jobEntryIdRef,
  emailRef,
  businessTypeIdRef,
  employmentStatusRef,
  employmentIdRef,
}) => {
  return (
    <div>
      <SectionTitle text="基本情報" />
      <div className={styles.contentsInner}>
        <TargetAndPeriodBox />
        <div className={styles.guidanceBoxOuter}>
          <BasicInfoGuidanceBox />
        </div>
        <div className={styles.inputOuter}>
          <SectionWithTitleAndLabel
            labelType="required"
            title="勤務先"
            containerRef={jobEntryIdRef}
          >
            {jobEntries.length ? (
              <JobEntriesRadio
                jobEntries={jobEntries}
                jobEntryValue={jobEntryValue}
                handleJobEntryChange={handleJobEntryChange}
                jobEntriesErrorText={jobEntriesErrorText}
              />
            ) : (
              <p>※選択可能な応募案件がありません。</p>
            )}
          </SectionWithTitleAndLabel>
        </div>
        <div className={styles.inputOuter}>
          <SectionWithTitleAndLabel
            labelType="required"
            containerRef={emailRef}
            title="メールアドレス"
            text="楽天銀行に登録されているアドレスの場合、受け取り手続き不要で自動的に楽天銀行にお振り込みいたします。"
          >
            <EmailInput
              emailValue={emailValue}
              handleEmailChange={handleEmailChange}
              emailErrorText={emailErrorText}
            />
          </SectionWithTitleAndLabel>
        </div>
        <InformationCard className={styles.informationOuter}>
          <InformationText
            icon={<Icon name="information" />}
            text={
              <>
                受取手続きに関するメールが、楽天のメールアドレスから届きますので、下記ドメインの指定受信設定をお願いいたします。
                <b>@ac.rakuten-bank.co.jp</b>
              </>
            }
          />
        </InformationCard>
      </div>
      <SectionTitle text="就業情報" />
      <div className={styles.contentsInner}>
        <SectionWithTitleAndLabel
          labelType="required"
          title="就業状況"
          containerRef={employmentStatusRef}
        >
          {questions.employmentStatus && (
            <EmploymentStatusRadio
              employmentStatus={questions.employmentStatus}
              handleEmploymentStatusChange={handleEmploymentStatusChange}
              employmentStatusValue={employmentStatusValue}
              employmentStatusErrorText={employmentStatusErrorText}
            />
          )}
        </SectionWithTitleAndLabel>
        <div className={styles.inputOuter}>
          <SectionWithTitleAndLabel
            labelType="required"
            title="職種"
            containerRef={businessTypeIdRef}
          >
            {questions.businessTypesSelectOptions && (
              <SelectBoxContents
                categoryName="businessType"
                value={businessTypeValue}
                handleSelect={handleBusinessTypeSelect}
                selectOptions={questions.businessTypesSelectOptions}
                errorText={businessTypesErrorText}
              />
            )}
          </SectionWithTitleAndLabel>
        </div>
        <div className={styles.inputOuter}>
          <SectionWithTitleAndLabel
            labelType="required"
            title="雇用形態"
            containerRef={employmentIdRef}
          >
            {questions.employmentsSelectOptions && (
              <SelectBoxContents
                categoryName="employment"
                value={employmentValue}
                handleSelect={handleEmploymentSelect}
                selectOptions={questions.employmentsSelectOptions}
                errorText={employmentsErrorText}
              />
            )}
          </SectionWithTitleAndLabel>
        </div>
      </div>
    </div>
  );
};
