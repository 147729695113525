/**
 * 指定された文字列からすべての空白文字と改行を削除します。
 * 空白文字には、半角スペース、全角スペース(\u3000)、タブが含まれ、
 * 改行には、LF (\n), CR (\r), CR+LF (\r\n), フォームフィード (\f)、
 * そしてUnicodeの行区切り記号 (\u2028) と段落区切り記号 (\u2029) が含まれます。
 *
 * 例:
 *   const cleanedString = removeSpacesTabsNewlines(" 例 文 \n\t");
 *   console.log(cleanedString); // "例文"
 *
 * @param str - 処理対象の文字列
 * @return - 空白文字と改行が削除された文字列
 */
export const removeSpacesTabsNewlines = (str: string): string => {
  return str.replace(/[\s\u3000\u2028\u2029]+/g, "");
};
