import { FC, createElement } from "react";
import { Label } from "@root/shared/components/Label";
import * as styles from "./styles.css";

type Props = {
  title: string;
  titleTag?: "h2" | "h3" | "h4" | "h5"; // 初期値: "h3"
  labelType?: "required" | "optional";
  text?: React.ReactNode;
  children: React.ReactNode;
  containerRef: React.RefObject<HTMLDivElement> | null;
};

export const SectionWithTitleAndLabel: FC<Props> = ({
  title,
  titleTag = "h3",
  labelType,
  text,
  children,
  containerRef,
}) => {
  const TitleTag = createElement(titleTag, { className: styles.title }, title);

  return (
    <>
      <div className={styles.titleOuter} ref={containerRef}>
        {TitleTag}
        {labelType === "required" && (
          <Label theme="red" size="small" className={styles.label}>
            <span>必須</span>
          </Label>
        )}
        {labelType === "optional" && (
          <Label theme="lightPink" size="small" className={styles.label}>
            <span>任意</span>
          </Label>
        )}
      </div>
      {text && <p className={styles.text}>{text}</p>}
      {children}
    </>
  );
};
