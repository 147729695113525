import { FC, lazy, Suspense } from "react";
import { useDetectWindowSizeInfo } from "@userFrontend/hooks/useDetectWindowSize";
import { ColorSetting, Name } from "@userFrontend/components/PieChart/colors";

const Presentation = lazy(() => import("./Presentation"));

type Props = {
  items: {
    label: string;
    value: number;
    name: Name;
  }[];
  colorSetting: ColorSetting;
};

export const PieChart: FC<Props> = ({ items, colorSetting }) => {
  const { isSP } = useDetectWindowSizeInfo();

  return (
    <Suspense fallback={null}>
      <Presentation isSP={isSP} items={items} colorSetting={colorSetting} />
    </Suspense>
  );
};
