/* eslint-disable camelcase */
import { FC, ReactElement, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Colors } from "@legacy_user_frontend/utils";
import { TextButton } from "@root/shared/components/TextButton";

type Props = {
  heading: string;
  children: ReactElement[] | ReactElement;
};

const StyledLabelsOuter = styled.div<{ isHide?: boolean }>`
  position: relative;
  max-height: ${(props) => (props.isHide ? "158px" : "none")};
  margin-top: 24px;
  padding: 10px;
  border-radius: 4px;
  background-color: ${Colors.LIGHT_GRAY_02};
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    display: ${(props) => (props.isHide ? "block" : "none")};
    width: 100%;
    height: 98px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.75) 34.38%,
      ${Colors.WHITE} 68.75%
    );
    z-index: 1;
  }
`;

const StyledLabelsHeading = styled.p`
  padding-bottom: 8px;
`;

const StyledAccordionButton = styled(TextButton)<{ isShow?: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  display: ${(props) => (props.isShow ? "block" : "none")};
  width: 100%;
  font-weight: bold;
  text-align: center;
  z-index: 2;
`;

export const LabelsCassette: FC<Props> = ({ heading, children }) => {
  // 追加済みの資格ラベルのアコーディオン
  const labelsOuterRef = useRef<HTMLDivElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);
  const labelsOuterMaxHeight = 158;
  useEffect(() => {
    const ref = labelsOuterRef.current;
    if (ref) {
      if (ref.clientHeight > labelsOuterMaxHeight) {
        setIsOverflow(true);
      }
    }
  }, []);
  const handleOnClick = () => {
    setIsOverflow(false);
  };

  return (
    <StyledLabelsOuter ref={labelsOuterRef} isHide={isOverflow}>
      <StyledLabelsHeading>{heading}</StyledLabelsHeading>
      {children}
      <StyledAccordionButton isShow={isOverflow} onClick={handleOnClick} size="small">
        + 続きを見る
      </StyledAccordionButton>
    </StyledLabelsOuter>
  );
};
