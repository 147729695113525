import { ComponentProps, FC } from "react";
import { CassetteSliderSectionList } from "@userFrontend/components/CassetteSliderSectionList";
import { useCategoryTopSectionList } from "./useCategoryTopSectionList";
import { Presentation } from "./Presentation";

type Props = {
  fetchUrl: string;
};

export const CategoryTopCassettes: FC<Props> = ({ fetchUrl }) => {
  const sections: ComponentProps<typeof CassetteSliderSectionList>["sections"] | undefined =
    useCategoryTopSectionList(fetchUrl);

  return sections ? <Presentation sections={sections} /> : null;
};
