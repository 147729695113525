import { ComponentProps, FC } from "react";
import { EntryLink } from "@userFrontend/components/Links/EntryLink";
import { KeepButton } from "@legacy_root/user_frontend/components/ui_parts/buttons/keep_button";
import * as styles from "./styles.css";

type Props = {
  workCode: string;
  hasKeeped: boolean;
  keepButtonUrl: string;
  keepGaAction: string;
  keepGaLabel: string;
  entryUrl: ComponentProps<typeof EntryLink>["url"];
  onClickEntryLink: ComponentProps<typeof EntryLink>["onClick"];
  entryGaAction?: ComponentProps<typeof EntryLink>["gaAction"];
  entryGaLabel?: ComponentProps<typeof EntryLink>["gaLabel"];
};

export const FooterButtons: FC<Props> = ({
  workCode,
  hasKeeped,
  keepButtonUrl,
  keepGaAction,
  keepGaLabel,
  entryUrl,
  onClickEntryLink,
  entryGaAction,
  entryGaLabel,
}) => {
  return (
    <div className={styles.footerButtons}>
      {/* TODO: legacyから既存のキープボタンを使用しているので要リファクタ ここから */}
      <KeepButton
        workCode={workCode}
        hasKeeped={hasKeeped}
        url={keepButtonUrl}
        gaAction={keepGaAction}
        gaLabel={keepGaLabel}
      />
      {/* 要リファクタ ここまで */}
      <EntryLink
        text="応募画面へ進む"
        className={`repro-click firebase-click disable-on-preview ${styles.entryLinkButton}`}
        firebaseAction="entry"
        url={entryUrl}
        onClick={onClickEntryLink}
        gaAction={entryGaAction}
        gaLabel={entryGaLabel}
      />
    </div>
  );
};
