import { FC } from "react";
import { BoxWithShadow } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/box_with_shadow";
import { ProfileCassette } from "./src/ui_parts/profile_cassette";
import { SchoolLogCassette } from "./src/ui_parts/school_log_casette";
import { ExperienceAndCredentialCassette } from "./src/ui_parts/experience_and_credential_cassette";
import { AvailableStartDayCassette } from "./src/ui_parts/available_start_day_cassette";
import { ContactableTimeRangeCassette } from "./src/ui_parts/contactable_time_range_cassette";
import { ProfilesShowProps } from "./src/profile_show_props";
import { Heading } from "./src/ui_parts/heading";
import {
  StyledCassetteContent,
  StyledCassetteDescription,
  StyledGhostButtonLink,
  StyledGhostButtonLinkOuter,
} from "./src/styled_elements";
import { SelfPrCassette } from "./src/ui_parts/self_pr_cassette";
import { ProductPhotoCassette } from "./src/ui_parts/product_photo_cassette";
import { UserCareersCassette } from "./src/ui_parts/user_careers_cassette";
import { SkillCassette } from "./src/ui_parts/skill_cassette";
import { AchievementCassette } from "./src/ui_parts/achievement_cassette";

export const ProfilesShow: FC<ProfilesShowProps> = ({
  profile,
  editProfilePath,
  schoolLog,
  editSchoolLogPath,
  userCareers,
  editUserCareerPath,
  experienceAndCredential,
  productPhotos,
  editProductPhotoPath,
  achievement,
  editAchievementPath,
  selfPr,
  editExperienceAndCredentialPath,
  skills,
  editSkillPath,
  availableStartDay,
  editAvailableStartDayPath,
  contactableTimeRange,
  editContactableTimeRangePath,
  editSelfPrPath,
}) => {
  const schoolsLength = schoolLog.schools.length;
  const userCareersLength = userCareers.length;
  const achievementsLength = achievement.achievements.length;
  return (
    <>
      <BoxWithShadow>
        <Heading text="基本情報" editPath={editProfilePath} />
        <StyledCassetteDescription>氏名・電話番号・住所・性別・生年月日</StyledCassetteDescription>
        <StyledCassetteContent>
          <ProfileCassette profile={profile} />
        </StyledCassetteContent>
      </BoxWithShadow>
      <BoxWithShadow>
        <>
          <Heading text="学歴" />
          {(schoolsLength > 0 || schoolLog.schoolsNote) && (
            <StyledCassetteContent>
              <SchoolLogCassette schoolLog={schoolLog} editPath={editSchoolLogPath} />
            </StyledCassetteContent>
          )}
          <StyledGhostButtonLinkOuter>
            <StyledGhostButtonLink
              href={
                schoolsLength > 0
                  ? `${editSchoolLogPath}#cassette-${schoolsLength}`
                  : editSchoolLogPath
              }
            >
              学歴を追加する
            </StyledGhostButtonLink>
          </StyledGhostButtonLinkOuter>
        </>
      </BoxWithShadow>
      <BoxWithShadow>
        <>
          <Heading text="職務経歴" />
          {userCareersLength > 0 && (
            <UserCareersCassette
              userCareers={userCareers}
              editUserCareerPath={editUserCareerPath}
            />
          )}
          <StyledGhostButtonLinkOuter>
            <StyledGhostButtonLink
              href={
                userCareersLength > 0
                  ? `${editUserCareerPath}#cassette-${userCareersLength}`
                  : editUserCareerPath
              }
            >
              職務経歴を追加する
            </StyledGhostButtonLink>
          </StyledGhostButtonLinkOuter>
        </>
      </BoxWithShadow>
      <BoxWithShadow>
        <>
          <Heading text="経験職種・資格" editPath={editExperienceAndCredentialPath} />
          {(experienceAndCredential.experiences.length > 0 ||
            experienceAndCredential.experiencesNote ||
            experienceAndCredential.credentialItems.length > 0) && (
            <StyledCassetteContent>
              <ExperienceAndCredentialCassette experienceAndCredential={experienceAndCredential} />
            </StyledCassetteContent>
          )}
        </>
      </BoxWithShadow>
      <BoxWithShadow>
        <>
          <Heading text="スキル・経験業務" editPath={editSkillPath} />
          {skills.length > 0 && <SkillCassette skills={skills} />}
        </>
      </BoxWithShadow>
      <BoxWithShadow>
        <>
          <Heading text="あなたを表現する画像" editPath={editProductPhotoPath} />
          {productPhotos.length > 0 && <ProductPhotoCassette productPhotos={productPhotos} />}
        </>
      </BoxWithShadow>
      <BoxWithShadow>
        <>
          <Heading text="受賞・表彰歴" />
          {achievementsLength > 0 && (
            <StyledCassetteContent>
              <AchievementCassette
                achievement={achievement}
                editAchievementPath={editAchievementPath}
              />
            </StyledCassetteContent>
          )}
          <StyledGhostButtonLinkOuter>
            <StyledGhostButtonLink
              href={
                achievementsLength > 0
                  ? `${editAchievementPath}#cassette-${achievementsLength}`
                  : editAchievementPath
              }
            >
              受賞・表彰歴を追加する
            </StyledGhostButtonLink>
          </StyledGhostButtonLinkOuter>
        </>
      </BoxWithShadow>
      <BoxWithShadow>
        <>
          <Heading text="想い・自己PR" editPath={editSelfPrPath} />
          {(selfPr.reasonForApply ||
            selfPr.importantPoint ||
            selfPr.futurePlan ||
            selfPr.selfPr) && (
            <StyledCassetteContent>
              <SelfPrCassette selfPr={selfPr} />
            </StyledCassetteContent>
          )}
        </>
      </BoxWithShadow>
      <BoxWithShadow>
        <>
          <Heading text="勤務開始可能日" editPath={editAvailableStartDayPath} />
          {(availableStartDay.availableStartDate ||
            availableStartDay.isUndecided ||
            availableStartDay.availableStartDateNote) && (
            <StyledCassetteContent>
              <AvailableStartDayCassette availableStartDay={availableStartDay} />
            </StyledCassetteContent>
          )}
        </>
      </BoxWithShadow>
      <BoxWithShadow>
        <>
          <Heading text="連絡可能時間帯" editPath={editContactableTimeRangePath} />
          {(contactableTimeRange.contactableTimeRanges.length > 0 ||
            contactableTimeRange.contactableTimeRangesNote) && (
            <StyledCassetteContent>
              <ContactableTimeRangeCassette contactableTimeRange={contactableTimeRange} />
            </StyledCassetteContent>
          )}
        </>
      </BoxWithShadow>
    </>
  );
};
