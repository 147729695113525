import { FC } from "react";
import { KeepButton } from "@legacy_user_frontend/components/ui_parts/buttons/keep_button";
import { StyledElements } from "./src/styled_elements";
import { TelContents } from "./src/tel_contents";
import { ScoutButton } from "./src/scout_button";
import { ButtonContentsProps } from "./src/button_contents_props";

export const ButtonContents: FC<ButtonContentsProps> = ({
  buttonContents: {
    keepButtonContent: { keepButtonUrl, keepButtonQuery },
    scoutButtonContent: { scoutedFlag, scoutDeadline, scoutedEntryUrl, clientShopId },
    entryButtonContent: { enterableFlag, entriedFlag },
  },
  telNumberContent: { showTellButtonFlag, telNumber },
  query,
  entryUrl,
  workCode,
  keepButtonEvent: { keepGaAction, keepGaLabel, keepReproAction, keepFirebaseAction },
  entryButtonEvent: { entryGaAction, entryGaLabel, entryReproAction },
  scoutButtonEvent: { scoutGaAction, scoutGaLabel },
  login,
  keepedFlag,
  className,
}) => (
  <StyledElements className={className}>
    <div className="wrapper">
      {showTellButtonFlag && <TelContents telNumber={telNumber} workCode={workCode} />}
      <div
        className={`js-link-expand__unlink button-list ${
          scoutDeadline ? "cassette-main__list-btn--scout" : ""
        }`}
      >
        {Number(scoutDeadline) > 0 && (
          <div className="scout-deadline-outer">
            <p className="scout-deadline-text">
              スカウトの受付期限まであと
              <span className="scout-deadline-num">{scoutDeadline}</span>日
            </p>
          </div>
        )}
        {/* キープボタン */}
        {(!login || (!entriedFlag && !scoutedFlag)) && (
          <div className="keep-button-outer">
            <KeepButton
              url={keepButtonUrl}
              query={keepButtonQuery}
              gaAction={keepGaAction}
              gaLabel={keepGaLabel}
              reproAction={keepReproAction}
              firebaseAction={keepFirebaseAction}
              workCode={workCode}
              hasKeeped={keepedFlag}
            />
          </div>
        )}
        {(() => {
          if (entriedFlag) {
            return (
              <div className="applied-button-outer">
                <div className="disabled-button">応募済み</div>
              </div>
            );
          }
          if (scoutedFlag) {
            return (
              <ScoutButton
                url={scoutedEntryUrl}
                clientShopId={clientShopId}
                gaAction={scoutGaAction}
                gaLabel={scoutGaLabel}
                workCode={workCode}
              />
            );
          }
          if (enterableFlag) {
            return (
              <div className="button-outer">
                <a
                  className="apply-button ga-click repro-click js-add-query-strings"
                  href={entryUrl}
                  data-ga-action={entryGaAction}
                  data-ga-label={entryGaLabel}
                  data-repro-action={entryReproAction}
                  data-query-str={query}
                >
                  応募画面へ進む
                </a>
              </div>
            );
          }
          return (
            <div className="button-outer">
              <div className="disabled-button">募集停止</div>
            </div>
          );
        })()}
      </div>
    </div>
  </StyledElements>
);
