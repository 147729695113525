import { ComponentProps, FC, TextareaHTMLAttributes, useEffect } from "react";
import { useCharacterCount } from "@root/utils/useCharacterCount";
import { TextArea } from "@root/shared/components/TextArea";
import * as styles from "./styles.css";

type ExcludeOnChange = Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "onChange">;

type TextAreaWithWordCounterProps = ExcludeOnChange & {
  value: string;
  counterMaxLength?: number;
  size?: ComponentProps<typeof TextArea>["size"];
  handleTextAreaChange: (categoryName: string, value: string) => void;
  categoryName: string;
  hasError?: boolean;
};

export const TextAreaWithWordCounter: FC<TextAreaWithWordCounterProps> = ({
  counterMaxLength = 100,
  value,
  size,
  handleTextAreaChange,
  categoryName,
  hasError,
  ...rest
}) => {
  const { text, handleChange, count } = useCharacterCount(value ?? "");

  const combinedChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    handleChange(event.target.value);
    if (handleTextAreaChange && categoryName) {
      handleTextAreaChange(categoryName, event.target.value);
    }
  };

  // valueが外部から変更された場合は以下のuseEffectで反映と再計算を行う
  useEffect(() => {
    handleChange(value ?? "");
  }, [handleChange, value]);

  return (
    <>
      <TextArea
        {...rest}
        value={text}
        size={size}
        onChange={combinedChangeHandler}
        hasError={hasError}
      />
      <div className={styles.counterText}>
        <p>
          <span className={count < counterMaxLength ? styles.textUnderLimit : ""}>{count}</span>/
          {counterMaxLength}文字以上
        </p>
      </div>
    </>
  );
};
