import { Blob, DirectUpload } from "@rails/activestorage";

export class DirectUploader {
  file: File;

  directUpload: DirectUpload;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onProgressCallback?: (event: ProgressEvent) => void;

  constructor(url: string, file: File, onProgressCallback?: (event: ProgressEvent) => void) {
    this.file = file;
    this.directUpload = new DirectUpload(file, url, this);
    this.onProgressCallback = onProgressCallback;
  }

  async upload(): Promise<Blob> {
    return new Promise((resolve) => {
      this.directUpload.create((error: Error, blob: Blob) => {
        if (error) {
          throw error;
        }

        resolve(blob);
      });
    });
  }

  directUploadWillStoreFileWithXHR(request: XMLHttpRequest): void {
    request.upload.addEventListener("progress", (event: ProgressEvent) =>
      this.directUploadDidProgress(event),
    );
  }

  directUploadDidProgress(event: ProgressEvent): void {
    if (!this.onProgressCallback) {
      return;
    }

    this.onProgressCallback(event); // event.loaded / event.total
  }
}
