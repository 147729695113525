import { FC } from "react";
import { Icon } from "@root/shared/components/Icon";
import { Presentation } from "./Presentation";

export type UserVoiceCassetteProps = {
  sex: "female" | "male";
  jobTypeName: string;
  ageAndPrefectureText: string;
  numberOfStars: number;
  commentText: string;
};

const TOTAL_NUMBER_OF_STARS = 5;

export const UserVoiceCassette: FC<UserVoiceCassetteProps> = ({
  sex,
  jobTypeName,
  ageAndPrefectureText,
  numberOfStars,
  commentText,
}) => {
  const renderStars = () => {
    return [...Array(TOTAL_NUMBER_OF_STARS)].map((_, index) => (
      <Icon
        name={numberOfStars > index ? "keep-star" : "keep-star-outline"}
        color="#fbb03b" // 例外としてカラーコード直書き デザイナー確認済
        key={index} // eslint-disable-line react/no-array-index-key
      />
    ));
  };

  const imgSrc =
    sex === "female"
      ? "/images/user_frontend/global/userVoice_icon_female.png"
      : "/images/user_frontend/global/userVoice_icon_male.png";
  return (
    <Presentation
      jobTypeName={jobTypeName}
      ageAndPrefectureText={ageAndPrefectureText}
      commentText={commentText}
      imgSrc={imgSrc}
      starsContent={renderStars()}
    />
  );
};
