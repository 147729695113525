/* eslint-disable react/jsx-props-no-spreading */
import { FC, ChangeEvent } from "react";
import { useFormContext } from "react-hook-form";
import { Colors } from "@legacy_user_frontend/utils";
import styled from "styled-components";

type InputProps = {
  name: string;
  errorName?: string;
  placeholder: string;
  max?: number;
  min?: number;
  requiredErrorMessage?: string;
  isError?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

type StyledInputProps = {
  isError?: boolean;
};

const StyledInput = styled.input<StyledInputProps>`
  appearance: none;

  &:not([type="submit"]) {
    width: 100%;
    height: 44px;
    padding: 0 8px;
    border: 1px solid ${({ isError }) => (isError ? Colors.ALERT_RED : Colors.MEDIUM_GREY)};
    background-color: ${Colors.WHITE};
    box-shadow: ${({ isError }) => (isError ? `0px 0px 8px ${Colors.ALERT_RED}` : "none")};
    font-size: 12px;
    outline: none;
    transition:
      border-color 0.1s,
      box-shadow 0.1s;

    &:focus {
      border-color: ${Colors.FOCUS_BLUE};
      box-shadow:
        0px 0px 8px ${Colors.FOCUS_BLUE},
        inset 0px 0px 1px ${Colors.FOCUS_BLUE};
    }

    ::placeholder {
      color: ${Colors.DEEP_GREY};
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

export const NumberField: FC<InputProps> = ({
  name,
  errorName,
  placeholder,
  max,
  min,
  requiredErrorMessage,
  isError,
  onChange,
}) => {
  const { register, clearErrors } = useFormContext();
  const deleteErrorMessage = () => {
    if (errorName) {
      clearErrors(errorName);
    } else {
      clearErrors(name);
    }
  };
  return (
    <StyledInput
      type="number"
      inputMode="numeric"
      max={max}
      min={min}
      placeholder={placeholder}
      isError={isError}
      {...register(name, {
        required: requiredErrorMessage,
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          if (onChange) onChange(e);
          deleteErrorMessage();
        },
      })}
    />
  );
};
