import { cloneElement, FC, ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { throttle } from "throttle-debounce";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { Icon } from "@root/shared/components/Icon";
import * as styles from "./styles.css";

type Props = {
  containerMaxHeight?: number;
  children: ReactElement;
  size: "small" | "large";
};

const SMALL_CONTAINER_MAX_HEIGHT = 240;
const LARGE_CONTAINER_MAX_HEIGHT = 200;
const THROTTLE_MS = 200;

export const ReadMoreButton: FC<Props> = ({
  size,
  containerMaxHeight = size === "small" ? SMALL_CONTAINER_MAX_HEIGHT : LARGE_CONTAINER_MAX_HEIGHT,
  children,
}) => {
  const [isContainerOpened, setIsContainerOpened] = useState(false);
  const [containerHeight, setContainerHeight] = useState(containerMaxHeight);
  const [contentsHeight, setContentsHeight] = useState<number>();

  const contentsRef = useRef<HTMLDivElement>(null);

  const childrenWithProps = cloneElement(children, { containerHeight, setContainerHeight });

  const openContainerIfNeeded = useCallback(() => {
    if (contentsRef?.current) {
      const { offsetHeight } = contentsRef.current;
      setContentsHeight(offsetHeight);

      // 閉じないサイズの場合は開く
      if (offsetHeight <= containerHeight) {
        setIsContainerOpened(true);
      }
    }
  }, [containerHeight]);

  const handleClick = useCallback(() => {
    setIsContainerOpened(true);
  }, []);

  useEffect(() => {
    if (!isContainerOpened) {
      openContainerIfNeeded();
      const openContainerIfNeededThrottle = throttle(THROTTLE_MS, openContainerIfNeeded);
      window.addEventListener("resize", openContainerIfNeededThrottle);
      return () => window.removeEventListener("resize", openContainerIfNeededThrottle);
    }
    return undefined;
  }, [isContainerOpened, openContainerIfNeeded]);

  return (
    <div
      className={styles.container}
      style={assignInlineVars({
        [styles.maxHeight]:
          !isContainerOpened && contentsHeight && contentsHeight > containerHeight
            ? `${containerHeight}px`
            : "none",
        [styles.overflow]: isContainerOpened ? "visible" : "hidden",
      })}
    >
      <div className={styles.contents} ref={contentsRef}>
        {childrenWithProps}
      </div>
      {!isContainerOpened && (
        <div
          className={styles.buttonOuter({
            size,
          })}
        >
          <button
            type="button"
            className={`
              ${styles.textButton({
                size,
              })}
              ${styles.emptyClassForUnderline}
            `}
            onClick={handleClick}
          >
            {size === "large" && (
              <span className={styles.iconOuter}>
                <Icon name="add-more" fontSize="16px" />
              </span>
            )}
            <span className={styles.text}>続きを見る</span>
          </button>
        </div>
      )}
    </div>
  );
};
