import styled from "styled-components";
import { Colors, Breakpoints } from "@legacy_user_frontend/utils";

export const StyledButtonBasicStyles = styled.button`
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  font-size: 16px;
`;

export const StyledKeepButtonArea = styled.div`
  margin-top: 12px;
  position: relative;
  display: flex;
  justify-content: center;

  button {
    padding: 8px 0;
    z-index: 3;
  }

  .is-pc & {
    ${Breakpoints.MEDIUM} {
      &[data-style-column="1"] {
        flex-basis: 49%;
        margin-top: 0;
        order: 1;

        button {
          padding: 15.5px 0;
        }
      }
    }
  }
`;

export const StyledKeepButtonOuter = styled.div`
  width: 100%;
`;

export const StyledKeepIcon = styled.i`
  font-size: 18px;
  margin-right: -5px;

  &::before {
    margin: 0;
  }
`;

export const StyledKeepBalloon = styled.div<{ isHide: boolean; isHideEl: boolean }>`
  min-width: 230px;
  height: 44px;
  padding: 0 10px;
  border-radius: 4px;
  position: absolute;
  bottom: -54px;
  justify-content: space-between;
  align-items: center;
  background-color: #1076db;
  color: ${Colors.WHITE};
  font-size: 13px;
  cursor: pointer;
  z-index: 4;

  &::before {
    position: absolute;
    content: "";
    top: -7px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 7px 10px;
    border-color: transparent transparent #1076db transparent;
  }

  transition: 1s;
  opacity: ${(props) => (props.isHide ? "0" : "1")};
  display: ${(props) => (props.isHideEl ? "none" : "flex")};
`;
