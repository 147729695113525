import { FC } from "react";
import styled from "styled-components";

export type EditLinkProps = {
  url: string;
};

const Link = styled.a`
  position: relative;
  padding-right: 14px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 6px;
    height: 13px;
    background-image: url("/images/user_frontend/global/icon/icn_arrow_blue_right.svg");
    background-size: contain;
  }
`;

export const EditPageLink: FC<EditLinkProps> = ({ url }) => <Link href={url}>編集する</Link>;
