import { ComponentPropsWithRef, FC, forwardRef } from "react";
import * as styles from "./styles.css";

type Props = Omit<ComponentPropsWithRef<"input">, "type"> & {
  type?: "text" | "number";
  hasError?: boolean;
};

export const TextField: FC<Props> = forwardRef((props, ref) => {
  const { hasError, className, type = "text", ...restProps } = props;
  return (
    <input
      type={type}
      {...restProps}
      className={`${styles.textField} ${hasError ? styles.hasError : ""} ${className ?? ""}`}
      ref={ref}
    />
  );
});
TextField.displayName = "TextField";
