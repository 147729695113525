import { FC } from "react";
import { KeepButton } from "@userFrontend/components/Buttons/KeepButton";
import { container } from "./styles.css";

type Props = {
  isPreview?: boolean;
  url: string;
  query?: string;
  gaAction?: string;
  gaLabel?: string;
  reproAction?: string;
  firebaseAction?: string;
  workCode: string;
  hasKeeped: boolean;
  pageType?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

export const KeepForm: FC<Props> = ({
  isPreview = false,
  url,
  query,
  gaAction,
  gaLabel,
  reproAction,
  firebaseAction,
  workCode,
  hasKeeped,
  pageType,
  onClick,
}) => {
  return (
    <div className={`js-bookmark-area ${container}`}>
      {!isPreview && (
        <>
          <form
            className="js-add-bookmark-form"
            action={url}
            acceptCharset="UTF-8"
            data-remote="true"
            method="post"
          >
            <input type="hidden" name="work[code]" value={workCode} />
            <input type="hidden" name="work[query]" value={query || ""} />
          </form>
          <form
            className="js-remove-bookmark-form"
            action={url}
            acceptCharset="UTF-8"
            data-remote="true"
            method="post"
          >
            <input type="hidden" name="_method" value="delete" />
            <input type="hidden" name="work[code]" value={workCode} />
          </form>
        </>
      )}
      <KeepButton
        hasKeeped={hasKeeped}
        className={`
        ${firebaseAction ? "firebase-click" : ""}
        ${gaAction && gaLabel ? "ga-click" : ""}`}
        data-bookmark-status={hasKeeped ? "2" : "1"}
        data-bookmark-code={workCode}
        data-bookmark-view-type="screen_view"
        data-repro-action={reproAction}
        data-ga-action={gaAction}
        data-ga-label={gaLabel}
        data-firebase-action={firebaseAction}
        data-firebase-view-type={firebaseAction ? "screen_view" : undefined}
        data-page-type={pageType || ""}
        onClick={onClick}
      />
    </div>
  );
};
