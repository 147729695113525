import styled from "styled-components";
import { Breakpoints } from "@legacy_user_frontend/utils";

export const StyledBaseButton = styled.button`
  font-weight: bold;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  ${Breakpoints.MEDIUM} {
    &:hover {
      opacity: 0.8;
    }
    &:hover,
    &:active {
      text-decoration: none;
    }
  }
`;
