import { FC } from "react";
import { Colors } from "@legacy_user_frontend/utils";
import styled from "styled-components";

const StyledLabel = styled.span`
  color: ${Colors.ALERT_RED};
  font-size: 10px;
`;

export const RequiredLabel: FC = () => <StyledLabel>必須</StyledLabel>;
