import { useEffect, useState } from "react";

export const useHistoryBack = (skipUseHistoryBack = false): boolean => {
  const [isExit, setIsExit] = useState(false);

  useEffect(() => {
    if (window === undefined || skipUseHistoryBack) return undefined;

    const { state } = window.history;
    if (!state) {
      window.history.replaceState({ isExit: true }, "");
      window.history.pushState({ isExit: false }, "");
    }

    const exit = (e: PopStateEvent) => {
      const isExitState: boolean = e.state?.isExit;
      setIsExit(typeof isExitState === "boolean" ? isExitState : false);
    };

    window.addEventListener("popstate", exit);
    return () => {
      window.removeEventListener("popstate", exit);
    };
  }, [skipUseHistoryBack]);
  return isExit;
};
