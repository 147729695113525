import { FC, useEffect, useState } from "react";
import { CategoryListProps } from "@legacy_user_frontend/components/pages/mypage/profiles/credentials_edit/src/credentials_edit_props";
import {
  StyledCategoryAccordionButton,
  StyledAccordionContent,
  StyledBusinessTypesList,
} from "@legacy_user_frontend/components/pages/mypage/profiles/credentials_edit/src/styled_elements";
import { BusinessTypesList } from "./business_types_list";

export const CategoryList: FC<CategoryListProps> = ({ categoryName, jobTypes, isBiyo }) => {
  // アコーディオンの開閉
  const [isOpen, setIsOpen] = useState(false);
  // ケアの場合は介護を開いた状態で表示する
  useEffect(() => {
    if (!isBiyo && categoryName === "介護") {
      setIsOpen(true);
    }
  }, [categoryName, isBiyo]);
  const handleOnClick = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  return (
    <>
      <dt>
        <StyledCategoryAccordionButton type="button" onClick={handleOnClick} isActive={isOpen}>
          {categoryName}
        </StyledCategoryAccordionButton>
      </dt>
      <dd>
        <StyledAccordionContent isOpen={isOpen}>
          <StyledBusinessTypesList>
            {jobTypes.map(({ name, credentialItems }) => (
              <BusinessTypesList name={name} credentialItems={credentialItems} key={name} />
            ))}
          </StyledBusinessTypesList>
        </StyledAccordionContent>
      </dd>
    </>
  );
};
