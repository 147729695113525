import styled from "styled-components";

export const StyledAdditionButtonOuter = styled.div`
  padding: 16px;
`;

export const StyledCassetteHeadingOuter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 38px;
`;

export const StyledCassetteHeading = styled.p`
  font-size: 14px;
  font-weight: bold;
`;

export const StyledCassetteContent = styled.div`
  margin-top: 24px;
`;

export const StyledCheckboxOuter = styled.p`
  padding-top: 24px;
`;

export const StyledCheckboxText = styled.label`
  margin-left: 8px;
  cursor: pointer;
`;

export const StyledErrorMessageOuter = styled.div`
  margin-top: 10px;
`;

export const StyledRemoveModalOuter = styled.div`
  text-align: center;
`;

export const StyledRemoveModalHeading = styled.p`
  font-weight: bold;
  font-size: 20px;
  line-height: 1.5;
`;

export const StyledRemoveButtonOuter = styled.div`
  margin-top: 32px;
`;

export const StyledModalCloseButtonOuter = styled.div`
  margin-top: 24px;
`;

export const StyledDateSelectOuter = styled.div`
  display: flex;
  gap: 8px;

  > select {
    flex-basis: 50%;
  }
`;
