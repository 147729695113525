import styled from "styled-components";
import { Colors, Breakpoints } from "@legacy_user_frontend/utils";
import { StyledButtonBasicStyles } from "@legacy_user_frontend/components/projects/button_contents/src/styled_elements";

export const StyledPrimaryButton = styled(StyledButtonBasicStyles)`
  display: inline-flex;
  align-item: center;
  justify-content: center;
  height: 48px;
  font-weight: bold;
  color: ${Colors.WHITE};
  background-color: ${Colors.PRIMARY};

  &:active {
    text-decoration: none;
  }

  .is-pc & {
    ${Breakpoints.MEDIUM} {
      &:hover {
        background: rgba(251, 99, 65, 0.8);
        opacity: 0.8;
        text-decoration: none;
        border-color: ${Colors.WHITE};
        box-shadow:
          0px 0px 8px ${Colors.PRIMARY},
          inset 0px 0px 1px ${Colors.PRIMARY};
      }
    }
  }
`;

export const StyledScoutContents = styled.div<{ addMargin: boolean }>`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
  max-width: 772px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${(props) => (props.addMargin ? "20px" : "")};
`;

const StyledScoutContentsItemBase = styled.p`
  height: 48px;
`;

export const StyledScoutContentsItem = styled(StyledScoutContentsItemBase)`
  flex-basis: calc(50% - 6px);

  .is-pc [data-style-column="1"] & {
    ${Breakpoints.MEDIUM} {
      flex-basis: calc(25% - 9px);
    }
  }
`;

export const StyledPrimaryButtonWrapper = styled(StyledScoutContentsItemBase)`
  flex-basis: 100%;

  .is-pc [data-style-column="1"] & {
    ${Breakpoints.MEDIUM} {
      flex-basis: calc(25% - 9px);
      order: 4;
    }
  }
`;

export const StyledSalonTourButtonWrapper = styled(StyledScoutContentsItemBase)`
  flex-basis: 100%;

  .is-pc [data-style-column="1"] & {
    ${Breakpoints.MEDIUM} {
      flex-basis: calc(25% - 9px);
      order: 3;
    }
  }
`;

export const StyledDeadLineText = styled.p`
  text-align: center;
  padding: 10px 0;
`;

export const StyledDeadLine = styled.span`
  font-size: 20px;
  color: ${Colors.PRIMARY};
`;

export const StyledTertiaryButton = styled(StyledButtonBasicStyles)`
  display: inline-flex;
  align-item: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  font-weight: bold;
  background-color: ${Colors.WHITE};
  border: 1px solid ${Colors.MEDIUM_GREY};
  color: ${Colors.LIGHT_BLACK};
  line-height: 1;

  &:hover {
    opacity: 0.8;
    text-decoration: none;
    border-color: ${Colors.WHITE};
    box-shadow:
      0px 0px 8px ${Colors.MEDIUM_GREY},
      inset 0px 0px 1px ${Colors.MEDIUM_GREY};
  }

  &:disabled {
    border-color: transparent;
    font-weight: normal;
    color: ${Colors.DEEP_GREY};
    background-color: ${Colors.LIGHT_GREY};

    &:hover {
      border-color: transparent;
      box-shadow: none;
    }
  }

  .is-pc [data-style-column="1"] & {
    ${Breakpoints.MEDIUM} {
      flex-direction: row;
    }
  }
`;
