import { FC } from "react";
import styled from "styled-components";
import { Breakpoints } from "@legacy_user_frontend/utils";
import { ShopAccordionProps } from "./shop_accordion";

const MainTableList = styled.li`
  span {
    display: block;

    a {
      font-weight: bold;
    }
  }

  :not(:first-child) {
    margin-top: 4px;

    ${Breakpoints.MEDIUM} {
      margin-top: 6px;
    }
  }
`;

export const Shop: FC<ShopAccordionProps> = ({ shopInformations, gaAction, gaLabels, query }) => (
  <ul>
    {shopInformations.map(({ detailUrlWithShopId, name, address, nearestStation }, index) => (
      <MainTableList key={detailUrlWithShopId}>
        <span>
          <a
            href={detailUrlWithShopId}
            className="js-add-query-strings ga-click"
            data-ga-action={gaAction}
            data-ga-label={gaLabels[index]}
            data-query-str={query}
          >
            {name}
          </a>
        </span>
        <span>
          {address} {nearestStation && `(${nearestStation})`}
        </span>
      </MainTableList>
    ))}
  </ul>
);
