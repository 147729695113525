import { useMemo, useCallback, ComponentProps } from "react";
import { CassetteSliderSectionList } from "@userFrontend/components/CassetteSliderSectionList";
import { FetchData } from "@root/utils/fetchData";
import { useEntryLink } from "@userFrontend/hooks/useEntryLink";
import { Cassette } from "./type";

type Section = {
  url: string;
  title: string;
  cassettes: Cassette[];
};

export const useCategoryTopSectionList = (
  fetchUrl: string,
): ComponentProps<typeof CassetteSliderSectionList>["sections"] | undefined => {
  const { handleClickEntryLink } = useEntryLink();

  const createData = useCallback(
    (sections: Section[]) => {
      return sections.map((section) => {
        return {
          url: section.url,
          title: section.title,
          cassettes: section.cassettes.map((item: Cassette) => ({
            type: "simplified" as const,
            gaAction: item.events.cassetteEvent.cassetteGaAction,
            gaLabel: item.events.cassetteEvent.cassetteGaLabel,
            detailUrl: item.detailUrl,
            headerContents: {
              imgSrc: item.imgSrc,
              dummyImgPath: item.dummyImgPath,
              shopOrBrandName: item.shopOrBrandName,
              businessTypeAndOccupation: item.businessTypeAndOccupation,
              title: item.title,
            },
            salaries: item.salaries,
            shopLocation: item.shopLocation,
            buttonProps: {
              workCode: item.workCode,
              hasKeeped: item.keepButtonContent.hasKeeped,
              keepButtonUrl: item.keepButtonContent.keepButtonUrl,
              keepGaAction: item.events.keepButtonEvent.keepGaAction,
              keepGaLabel: item.events.keepButtonEvent.keepGaLabel,
              entryUrl: item.entryUrl,
              onClickEntryLink: handleClickEntryLink(
                item.events.entryLinkEvent.entryGaAction,
                item.events.entryLinkEvent.entryGaLabel,
              ),
            },
          })),
        };
      });
    },
    [handleClickEntryLink],
  );

  const sections = FetchData<Section[]>(fetchUrl);

  return useMemo(() => (sections ? createData(sections) : undefined), [sections, createData]);
};
