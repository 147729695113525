import { ComponentProps, useCallback } from "react";
import { OptionProps } from "@root/shared/components/SelectBox";
import { Step1 } from "@userFrontend/features/reward/components/ContinuedRewardInputs/uiParts/Step1";
import { Step2 } from "@userFrontend/features/reward/components/ContinuedRewardInputs/uiParts/Step2";
import { Step3 } from "@userFrontend/features/reward/components/ContinuedRewardInputs/uiParts/Step3";
import { Step4 } from "@userFrontend/features/reward/components/ContinuedRewardInputs/uiParts/Step4";
// utils
import { SiteId } from "@root/shared/types/type";
import { PlaceholderText } from "./utils/placeholderText";
// types
import {
  EmploymentsProps,
  BusinessTypesProps,
  SelectOptionQuestionsProps,
  TextAreaQuestionsProps,
} from "./type";

export const useFormatQuestion = (
  siteId: SiteId,
  employments: EmploymentsProps,
  businessTypes: BusinessTypesProps,
  selectiveQuestions: SelectOptionQuestionsProps,
  textAreaQuestions: TextAreaQuestionsProps,
): {
  step1Questions: ComponentProps<typeof Step1>["questions"];
  step2Questions: ComponentProps<typeof Step2>["questions"];
  step3Questions: ComponentProps<typeof Step3>["questions"];
  step4Questions: ComponentProps<typeof Step4>["questions"];
} => {
  const siteKey = siteId === 1 ? "biyo" : "kaigo";

  const formatQuestions = useCallback(() => {
    const businessTypesSelectOptions: OptionProps[] = businessTypes[siteKey].map(
      (businessType) => ({
        label: businessType.businessTypeName,
        value: businessType.businessTypeId,
      }),
    );

    const employmentsSelectOptions: OptionProps[] = employments.map((employment) => ({
      label: employment.employmentName,
      value: employment.employmentId,
    }));

    const step1Questions = {
      employmentsSelectOptions,
      employmentStatus: selectiveQuestions.employmentStatus[siteKey],
      businessTypesSelectOptions,
    };

    const step2Questions = {
      treatmentQuestions: selectiveQuestions.treatments[siteKey],
      cultureQuestions: selectiveQuestions.cultures[siteKey],
    };

    const step3Questions = {
      atmosphere: selectiveQuestions.atmosphere[siteKey],
      salaryStandard: selectiveQuestions.salaryStandard[siteKey],
      debut: selectiveQuestions.debut[siteKey],
      education: selectiveQuestions.education[siteKey],
      workTime: selectiveQuestions.workTime[siteKey],
      salaryChange: selectiveQuestions.salaryChange[siteKey],
    };

    const step4Questions = {
      workplaceMatching: {
        ...textAreaQuestions.workplaceMatching[siteKey][0],
        placeholder: PlaceholderText.workplaceMatching,
      },
      interestReward: {
        ...textAreaQuestions.interestReward[siteKey][0],
        placeholder: PlaceholderText.interestReward,
      },
      challenge: {
        ...textAreaQuestions.challenge[siteKey][0],
        placeholder: PlaceholderText.challenge,
      },
      expectation: {
        ...textAreaQuestions.expectation[siteKey][0],
        placeholder: PlaceholderText.expectation,
      },
    };

    return {
      step1Questions,
      step2Questions,
      step3Questions,
      step4Questions,
    };
  }, [siteKey, employments, businessTypes, selectiveQuestions, textAreaQuestions]);

  return formatQuestions();
};
