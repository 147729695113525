import { FC } from "react";
import { Icon } from "@root/shared/components/Icon";
import { InformationText } from "@root/shared/components/InformationText";
import { RestoreUserRewardContinuedInputsModal } from "@userFrontend/features/reward/components/RestoreUserRewardContinuedInputsModal";
import { Step1 } from "@userFrontend/features/reward/components/ContinuedRewardInputs/uiParts/Step1";
import { Step2 } from "@userFrontend/features/reward/components/ContinuedRewardInputs/uiParts/Step2";
import { Step3 } from "@userFrontend/features/reward/components/ContinuedRewardInputs/uiParts/Step3";
import { Step4 } from "@userFrontend/features/reward/components/ContinuedRewardInputs/uiParts/Step4";
import { Step5 } from "@userFrontend/features/reward/components/ContinuedRewardInputs/uiParts/Step5";
import { Step6 } from "@userFrontend/features/reward/components/ContinuedRewardInputs/uiParts/Step6";
import { Completed } from "@userFrontend/features/reward/components/ContinuedRewardInputs/uiParts/Completed";
import { Button } from "@root/shared/components/Button";
import * as styles from "./styles.css";

// types
import { PresentationProps } from "./type";

export const Presentation: FC<PresentationProps> = ({
  currentStep,
  jobEntries,
  jobEntryValue,
  emailValue,
  formattedQuestions,
  businessTypeValue,
  employmentStatusValue,
  employmentValue,
  radioValues,
  checkboxValues,
  textAreaValue,
  errorTexts,
  handleEmailChange,
  handleBusinessTypeSelect,
  handleRadioChange,
  handleJobEntryChange,
  handleEmploymentSelect,
  handleCheckboxChange,
  handleTextAreaChange,
  handleEmploymentStatusChange,
  handleClickSelectButton,
  handleChangeFile,
  fileInputRef,
  previewUrlArray,
  handleClickDeleteButton,
  handleClickBackButton,
  handleClickSubmitButton,
  isShowBackupModal,
  restoreBackupPath,
  deleteBackupPath,
  termsPath,
  guidelinePath,
  handleRestore,
  inputContainerRefs,
  isAvailableSubmit,
  isLoading,
}) => {
  const renderContent = () => {
    if (currentStep === 6) {
      return (
        <Step6
          questions={formattedQuestions}
          jobEntryValue={jobEntryValue ?? ""}
          jobEntries={jobEntries}
          emailValue={emailValue ?? ""}
          businessTypeValue={businessTypeValue}
          employmentValue={employmentValue}
          employmentStatusValue={Number(employmentStatusValue) ?? undefined}
          checkboxValues={checkboxValues}
          radioValue={radioValues}
          textAreaValue={textAreaValue}
          previewUrls={previewUrlArray}
          termsUrl={termsPath}
          guidelineUrl={guidelinePath}
          handleClickBackButton={handleClickBackButton}
          handleClickSubmitButton={handleClickSubmitButton}
        />
      );
    }
    if (currentStep === 7) {
      return <Completed />;
    }
    return (
      <div>
        <div className={styles.informationTextWrapper}>
          <InformationText
            icon={<Icon name="information" />}
            text={
              <>
                入力した情報は、自動でバックアップされ、再訪問した際に復元が可能です。
                <br />
                ※アプリのバージョンによっては復元できません。
              </>
            }
          />
        </div>
        {/* 基本情報（勤務先・メールアドレス） 就業情報（就業状況・職種・雇用形態） */}
        <Step1
          // 勤務先選択肢
          jobEntries={jobEntries}
          // 就業状況の質問項目（ラジオボタン・セレクトボックス）
          questions={formattedQuestions.step1Questions}
          // values
          jobEntryValue={jobEntryValue ?? ""}
          emailValue={emailValue ?? ""}
          employmentStatusValue={employmentStatusValue ?? undefined}
          businessTypeValue={businessTypeValue ?? undefined}
          employmentValue={employmentValue ?? undefined}
          // error texts
          jobEntriesErrorText={errorTexts.jobEntry}
          employmentStatusErrorText={errorTexts.employmentStatus}
          emailErrorText={errorTexts.email}
          businessTypesErrorText={errorTexts.businessType}
          employmentsErrorText={errorTexts.employment}
          // handlers
          handleEmailChange={handleEmailChange}
          handleBusinessTypeSelect={handleBusinessTypeSelect}
          handleEmploymentStatusChange={handleEmploymentStatusChange}
          handleJobEntryChange={handleJobEntryChange}
          handleEmploymentSelect={handleEmploymentSelect}
          // refs
          jobEntryIdRef={inputContainerRefs?.current?.jobEntryId}
          emailRef={inputContainerRefs?.current?.email}
          businessTypeIdRef={inputContainerRefs?.current?.businessTypeId}
          employmentStatusRef={inputContainerRefs?.current?.employmentStatus}
          employmentIdRef={inputContainerRefs?.current?.employmentId}
        />
        {/* 待遇面の口コミ 社風面の口コミ */}
        <Step2
          // 質問項目（チェックボックス）
          questions={formattedQuestions.step2Questions}
          // values
          checkboxValues={checkboxValues}
          // error texts
          treatmentsErrorText={errorTexts.treatments}
          culturesErrorText={errorTexts.cultures}
          // handlers
          handleCheckboxChange={handleCheckboxChange}
          isDisabled={false} // TODO: fixme
          treatmentsRef={inputContainerRefs?.current?.treatments}
          culturesRef={inputContainerRefs?.current?.cultures}
        />
        {/* 職場の口コミ（特徴） */}
        <Step3
          // 質問項目（ラジオボタン）
          questions={formattedQuestions.step3Questions}
          // values
          radioValues={radioValues}
          // error texts
          errorTexts={errorTexts}
          // handlers
          handleRadioChange={handleRadioChange}
          atmosphereRef={inputContainerRefs?.current?.atmosphere}
          salaryStandardRef={inputContainerRefs?.current?.salaryStandard}
          debutRef={inputContainerRefs?.current?.debut}
          educationRef={inputContainerRefs?.current?.education}
          workTimeRef={inputContainerRefs?.current?.workTime}
          salaryChangeRef={inputContainerRefs?.current?.salaryChange}
        />
        {/* 職場の口コミ（あなたの考え/想い） */}
        <Step4
          // 質問項目（テキストエリア）
          questions={formattedQuestions.step4Questions}
          // values
          textAreaValues={textAreaValue}
          // error texts
          errorTexts={errorTexts}
          // handlers
          handleTextAreaChange={handleTextAreaChange}
          workplaceMatchingRef={inputContainerRefs?.current?.workplaceMatching}
          interestRewardRef={inputContainerRefs?.current?.interestReward}
          challengeRef={inputContainerRefs?.current?.challenge}
          expectationRef={inputContainerRefs?.current?.expectation}
        />
        {/* 勤続証明書類 */}
        <Step5
          handleClickSelectButton={handleClickSelectButton}
          handleChangeFile={handleChangeFile}
          isLoading={isLoading}
          fileInputRef={fileInputRef}
          previewUrlArray={previewUrlArray}
          handleClickDeleteButton={handleClickDeleteButton}
          errorText={errorTexts.files}
          imagesRef={inputContainerRefs?.current?.images}
        />
        <div className={styles.submitButtonContainer}>
          <div className={styles.submitButtonOuter}>
            <Button
              theme={isAvailableSubmit ? "primary" : "disabled"}
              onClick={handleClickSubmitButton}
            >
              次へ
            </Button>
          </div>
        </div>
        <RestoreUserRewardContinuedInputsModal
          initialIsShow={isShowBackupModal}
          restoreBackupUrl={restoreBackupPath}
          deleteBackupUrl={deleteBackupPath}
          onRestore={handleRestore}
        />
      </div>
    );
  };

  return <>{renderContent()}</>;
};
