import { FC } from "react";
import { DefaultStyleButtons } from "./uiParts/DefaultStyleButtons";
import { VerticallyStyleButtons } from "./uiParts/VerticallyStyleButtons";
import { ButtonProps } from "./type";

type Props = ButtonProps & {
  verticallyStyle?: boolean;
};

export const DetailPageActionButtons: FC<Props> = ({ verticallyStyle = false, ...props }) =>
  verticallyStyle ? <VerticallyStyleButtons {...props} /> : <DefaultStyleButtons {...props} />;
