import { ComponentProps, FC } from "react";
import { Icon } from "@root/shared/components/Icon";
import { Button } from "@root/shared/components/Button";
import { InformationText } from "@root/shared/components/InformationText";
import { InformationCard } from "@userFrontend/components/InformationCard";
import { SectionWithTitleAndLabel } from "@userFrontend/components/SectionWithTitleAndLabel";
import { ErrorInformationText } from "@userFrontend/components/ErrorInformationText";
import { SearchModal } from "@userFrontend/features/clientReview/components/ClientReviewInputs/uiParts/SearchModal";

// types
import { Client } from "@userFrontend/features/clientReview/components/ClientReviewInputs/type";
import { ErrorText } from "@userFrontend/features/reward/components/ContinuedRewardInputs/type";
import * as styles from "./styles.css";

type Props = {
  client?: Client;
  clientRef: React.RefObject<HTMLDivElement>;
  handleShowModal: () => void;
  clientErrorText: ErrorText;
} & ComponentProps<typeof SearchModal>;

export const ClientSelect: FC<Props> = ({
  client,
  clientRef,
  clientErrorText,
  handleShowModal,
  isShowSearchModal,
  handleCloseModal,
  clientNameValue,
  shopNameValue,
  clientNameErrorText,
  shopNameErrorText,
  handleClientSearch,
  handleChangeClientNameValue,
  handleChangeShopNameValue,
  searchResults,
  handleClientSelect,
  isShowClientsDetail,
  handleShowTextButton,
  searchResultErrorText,
  displaySearchText,
  isSearching,
  handleReturnToMyPageTop,
  userContactPath,
}) => (
  <SectionWithTitleAndLabel title="投稿先" containerRef={clientRef} labelType="required">
    <InformationCard className={styles.informationCard}>
      <div>
        {client?.clientName && <p className={styles.clientNameValue}>企業名 {client.clientName}</p>}
        <Button theme="primary" className={styles.button} onClick={handleShowModal}>
          選択する
        </Button>
      </div>
      {clientErrorText && <ErrorInformationText text={clientErrorText} />}
    </InformationCard>
    <div className={styles.wrapper}>
      <InformationText
        icon={<Icon name="information" />}
        text={<>現在、リジョブに求人を掲載したことのある企業のみ投稿を受け付けています。</>}
      />
    </div>
    <SearchModal
      isShowSearchModal={isShowSearchModal}
      handleCloseModal={handleCloseModal}
      clientNameValue={clientNameValue}
      shopNameValue={shopNameValue}
      clientNameErrorText={clientNameErrorText}
      shopNameErrorText={shopNameErrorText}
      handleClientSearch={handleClientSearch}
      handleChangeClientNameValue={handleChangeClientNameValue}
      handleChangeShopNameValue={handleChangeShopNameValue}
      searchResults={searchResults}
      handleClientSelect={handleClientSelect}
      isShowClientsDetail={isShowClientsDetail}
      handleShowTextButton={handleShowTextButton}
      searchResultErrorText={searchResultErrorText}
      displaySearchText={displaySearchText}
      isSearching={isSearching}
      handleReturnToMyPageTop={handleReturnToMyPageTop}
      userContactPath={userContactPath}
    />
  </SectionWithTitleAndLabel>
);
