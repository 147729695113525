/*
  ボタンタグのデフォルトのスタイルを打ち消したコンポーネント。
  本来reset.css的なものを利用したいが、既存のユーティリティークラスがデフォルトのスタイルを前提にしているため、
  新規に作成するボタンのスタイリングにこちらを利用する。
*/
import { FC } from "react";
import styled from "styled-components";

type ButtonProps = JSX.IntrinsicElements["button"];

const StyledButton = styled.button`
  line-height: 1;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
`;

export const UnstyledButton: FC<ButtonProps> = ({ type, className, onClick, children }) => (
  <StyledButton type={type} onClick={onClick} className={className}>
    {children}
  </StyledButton>
);
