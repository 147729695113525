import { FC } from "react";
import * as styles from "./styles.css";

type Props = {
  satisfactionRate: {
    numerator: number; // 分子
    denominator: number; // 分母
  };
  numberOfSatisfactions: number;
};

export const SatisfactionRate: FC<Props> = ({ satisfactionRate, numberOfSatisfactions }) => (
  <div>
    <p className={styles.boldWrapper}>
      平均<span className={styles.numerator}>{satisfactionRate?.numerator.toFixed(1)}</span>/
      {satisfactionRate?.denominator.toFixed(1)}
      <span className={styles.annotationWrapper}>※{numberOfSatisfactions}件の口コミに基づく</span>
    </p>
  </div>
);
