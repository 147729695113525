import { useMemo, useCallback, useEffect, ComponentProps } from "react";
import { CassetteSliderSectionList } from "@userFrontend/components/CassetteSliderSectionList";
import useSWR, { KeyedMutator } from "swr";
import { Cassette } from "./type";

/* eslint-disable @typescript-eslint/no-explicit-any */
declare const newrelic: any;

// NOTE: app/packs/utils/fetchData.ts内のFetchDataを使用すると、useSWRImmutableが使用されているため、操作手順によってはレコメンド求人の最新情報が再フェッチされません。
// それにより、レコメンド求人と、このファイルで生成しているキープ押下後レコメンド求人のキープステータスがズレる可能性があるため、再フェッチが走るように、useSWRを使用します。
const FetchData = <T>(
  fetchUrl: string,
  option = {},
): { data: T | undefined; error: any; mutate: KeyedMutator<T> } => {
  const fetcher = (url: string) => fetch(url, option).then((res) => res.json());
  const { data, error, mutate } = useSWR<T>(fetchUrl, fetcher);

  // 非同期通信に失敗した際にnewrelicにエラーを送る
  if (error) {
    if (typeof newrelic !== "undefined") {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      newrelic.noticeError(error);
    }
    return { data, error, mutate };
  }

  return { data, error, mutate };
};

export const useAfterKeepRecommendWorks = (
  fetchUrl: string,
  inView: boolean,
): ComponentProps<typeof CassetteSliderSectionList>["sections"] | undefined => {
  const { data, mutate } = FetchData<{ data: Cassette[]; mutate: KeyedMutator<Cassette[]> }>(
    fetchUrl,
  );

  // NOTE:
  // キープ押下後レコメンド(当コンポーネント)が画面内に入った場合、
  // レコメンド求人(RecommendWorks)とキープボタンの見た目を一致させる目的で、
  // 再フェッチを走らせる。
  useEffect(() => {
    if (inView) {
      mutate();
    }
  }, [inView, mutate]);

  const createData = useCallback((cassettes: Cassette[]) => {
    return cassettes.map((item) => ({
      gaAction: "detail_keep_recommend",
      gaLabel: item.gaLabel,
      detailUrl: item.linkHref,
      imgSrc: item.imgSrc,
      dummyImgPath: item.dummyImgPath,
      shopOrBrandName: item.shopOrBrandName,
      businessTypeAndOccupation: item.businessTypeAndOccupation,
      title: item.title,
      salaries: item.salaryTexts,
      shopLocation: item.shopLocation,
      workCode: item.workCode,
      hasKeeped: item.keepButtonContent.hasKeeped,
      keepButtonUrl: item.keepButtonContent.keepButtonUrl,
      keepGaAction: "detail_keep_recommend_keep",
      keepGaLabel: item.gaLabel,
    }));
  }, []);

  const cassettesData = useMemo(
    () => (data?.data ? createData(data.data) : []),
    [data, createData],
  );

  const sections = useMemo(
    () => (cassettesData.length ? [{ cassettes: cassettesData }] : undefined),
    [cassettesData],
  );

  return sections;
};
