import { FC } from "react";
import { MultiLineText } from "@root/shared/components/MultiLineText";
import { ReadMoreButton } from "@root/shared/components/ReadMoreButton";
import * as styles from "./styles.css";

type Props = {
  heading: string;
  body?: string;
  paragraphs?: {
    subHeading: string;
    subBody: string;
  }[];
};

export const HeadingAndContents: FC<Props> = ({ heading, body, paragraphs }) => (
  <section className={styles.section}>
    <h3 className={styles.heading}>{heading}</h3>
    {body && (
      <div className={styles.body}>
        <ReadMoreButton size="large">
          <MultiLineText text={body} />
        </ReadMoreButton>
      </div>
    )}
    {paragraphs &&
      paragraphs.map(({ subHeading, subBody }) => (
        <div key={subHeading} className={styles.paragraph}>
          <h4 className={styles.subHeading}>{subHeading}</h4>
          <div className={styles.subBody}>
            <ReadMoreButton size="large">
              <MultiLineText text={subBody} />
            </ReadMoreButton>
          </div>
        </div>
      ))}
  </section>
);
