import { FC, useState, lazy, Suspense, createRef, RefObject, useRef, useEffect } from "react";
import { CredentialItemListProps } from "@legacy_user_frontend/components/pages/mypage/profiles/credentials_edit/src/credentials_edit_props";
import { StyledOtherCategoryButton } from "@legacy_user_frontend/components/pages/mypage/profiles/credentials_edit/src/styled_elements";
import { JobList } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/job_list";
import { CategoryList } from "./src/category_list";

const CredentialModal = lazy(
  () =>
    import(
      "@legacy_user_frontend/components/pages/mypage/profiles/credentials_edit/src/ui_parts/credential_modal"
    ),
);

export const CredentialItemList: FC<CredentialItemListProps> = ({
  categorizedCredentialItemList,
  otherCredentialItemList,
  isBiyo,
}) => {
  // モーダルの開閉処理
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => setIsModalOpen(false);

  // モーダルは展開時にのみDOMが生成され、閉じると消えてしまう
  // 選択した内容を送信用に保持しておくチェックボックスが必要なため
  // 資格選択モーダルで選択した資格を、追加ボタン押下時にHiddenCredentialFormに反映する
  const credentialRefs = useRef<RefObject<HTMLInputElement>[]>([]);
  const isWillRefs = useRef<RefObject<HTMLInputElement>[]>([]);
  useEffect(() => {
    otherCredentialItemList.forEach((_, i) => {
      credentialRefs.current[i] = createRef();
      isWillRefs.current[i] = createRef();
    });
  }, [otherCredentialItemList]);

  return (
    <JobList>
      <>
        {categorizedCredentialItemList.map(({ categoryName, jobTypes }) => (
          <CategoryList
            key={categoryName}
            categoryName={categoryName}
            jobTypes={jobTypes}
            isBiyo={isBiyo}
          />
        ))}
      </>
      <dt>
        <StyledOtherCategoryButton type="button" onClick={openModal}>
          その他
        </StyledOtherCategoryButton>
      </dt>
      <dd>
        <Suspense fallback={null}>
          {isModalOpen && (
            <CredentialModal
              jobTypeName="その他"
              isModalOpen={isModalOpen}
              closeModal={closeModal}
              credentialItems={otherCredentialItemList}
              credentialRefs={credentialRefs}
              isWillRefs={isWillRefs}
            />
          )}
        </Suspense>
      </dd>
    </JobList>
  );
};
