import { FC } from "react";
import { Icon } from "@root/shared/components/Icon";
import { InformationCard } from "@userFrontend/components/InformationCard";
import * as styles from "./styles.css";

export const BasicInfoGuidanceBox: FC = () => (
  <InformationCard>
    <ul className={styles.list}>
      <li className={styles.item}>
        <span className={styles.iconOuter}>
          <Icon name="done" />
        </span>
        お手元に、
        <strong className={styles.strongText}>会社名・氏名・入社から1年経過したと分かる日付</strong>
        が記載されている資料をご用意ください。（例：給与明細、シフト表、サロンボード、在籍証明書）
      </li>
      <li className={styles.item}>
        <span className={styles.iconOuter}>
          <Icon name="done" />
        </span>
        次のページから始まる質問項目に回答いただき次第申請が完了します。（所要時間２０分程度）
      </li>
      <li className={styles.item}>
        <span className={styles.iconOuter}>
          <Icon name="done" />
        </span>
        本フォームより申請後、楽天よりメールが届きます。記載されている案内に従い、手続きをお願いいたします。
      </li>
    </ul>
  </InformationCard>
);
