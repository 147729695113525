import { FC } from "react";
import { LazyLoadImage } from "@root/shared/components/LazyLoadImage";
import { MultiLineText } from "@root/shared/components/MultiLineText";
import * as styles from "./styles.css";

type Props = {
  jobTypeName: string;
  ageAndPrefectureText: string;
  commentText: string;
  imgSrc: string;
  starsContent: JSX.Element[];
};

export const Presentation: FC<Props> = ({
  jobTypeName,
  ageAndPrefectureText,
  commentText,
  imgSrc,
  starsContent,
}) => {
  return (
    <div className={styles.outer}>
      <div className={styles.profileWrapper}>
        <div className={styles.profile}>
          <div className={styles.image}>
            <LazyLoadImage src={imgSrc} alt="ユーザーのイラスト" width={60} height={60} />
          </div>
          <ul className={styles.profileList}>
            <li>{jobTypeName}</li>
            <li>{ageAndPrefectureText}</li>
            <li className={styles.starsWrapper}>
              <p className={styles.starsText}>満足度</p>
              {starsContent}
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.commentText}>
        <MultiLineText text={commentText} />
      </div>
    </div>
  );
};
