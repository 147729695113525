import { FC, ComponentProps } from "react";
import { Icon } from "@root/shared/components/Icon";
import * as styles from "./styles.css";

type Props = ComponentProps<"button"> & {
  iconName: "arrow-bold-left" | "arrow-bold-right";
};

export const ImageNavigationButton: FC<Props> = ({ iconName, ...rest }) => {
  return (
    <button type="button" className={styles.button} {...rest}>
      <Icon name={iconName} fontSize="16px" />
    </button>
  );
};
