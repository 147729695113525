import { assignInlineVars } from "@vanilla-extract/dynamic";
import { FC, RefObject } from "react";
import * as styles from "@root/shared/styles/AnchorLinks/index.css";
import { AnchorLinkItem } from "@root/shared/components/AnchorLinkItem";

type Props = {
  anchorLinks: AnchorLinkProps[];
  anchorLinksRef: RefObject<HTMLDivElement>;
  className?: string;
  anchorLinkLength: number;
  size: "small" | "large";
};

type AnchorLinkProps = {
  iconName?: string;
  anchorLinkName: string;
  isCurrent: boolean;
  onClick: () => void;
};

export const Presentation: FC<Props> = ({
  anchorLinks,
  anchorLinksRef,
  className,
  anchorLinkLength,
  size,
}) => (
  <div className={className}>
    <div ref={anchorLinksRef} className={styles.wrapper}>
      <div
        className={styles.container}
        style={assignInlineVars({ [styles.size]: size === "large" ? "100%" : "800px" })}
      >
        <ul className={styles.ul}>
          {anchorLinks.map(({ iconName, anchorLinkName, isCurrent, onClick }) => (
            <AnchorLinkItem
              key={anchorLinkName}
              iconName={iconName}
              anchorLinkName={anchorLinkName}
              anchorLinkLength={anchorLinkLength}
              isCurrent={isCurrent}
              onClick={onClick}
            />
          ))}
        </ul>
      </div>
    </div>
  </div>
);
