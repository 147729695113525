import { FC } from "react";
import { navigateWithDeferredDeepLink } from "@userFrontend/utils/deferredDeepLinks";
import { GaScreenName, GaAppealPattern } from "@userFrontend/components/SmartAppBanner/type";
import { SiteType } from "@root/shared/types/type";
import * as styles from "./styles.css";

type BPatternProps = {
  isLogin: boolean;
  downloadUrl: string;
  siteType: SiteType;
  gaScreenName: GaScreenName;
  gaAppealPattern: GaAppealPattern;
};

export const BPattern: FC<BPatternProps> = ({
  isLogin,
  downloadUrl,
  siteType,
  gaScreenName,
  gaAppealPattern,
  children,
}) => {
  const smartBannerIconPath =
    siteType === "kaigo"
      ? "/images/user_frontend/sp/img_app_icon_kaigo.png"
      : "/images/user_frontend/sp/img_app_icon.png";

  return (
    <>
      <input type="checkbox" id="smartap_close" className={styles.smartappCheckbox} />
      <div
        className={`p-smartapp-banner js-smartapp-banner ${styles.gradientBackground}`}
        data-ga-action="imp_smart_app_banner"
        data-ga-screen_name={gaScreenName}
        data-ga-appeal_pattern={gaAppealPattern}
      >
        <div className={styles.flexCenter}>
          <div className={styles.smartappBannerColumnClose}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="smartap_close" id="js-smartapp-close">
              <i className="icon-cancel" />
            </label>
          </div>
          <div className={styles.smartappBannerColumnIcon}>
            {/* NOTE:buttonタグに修正すると見た目が崩れるため、disabledコメントで許容しつつaタグを使用している */}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <a
              data-ga-action="tap_smart_app_banner"
              data-ga-screen_name={gaScreenName}
              data-ga-appeal_pattern={gaAppealPattern}
              data-ga-label={gaScreenName}
              onClick={() => {
                navigateWithDeferredDeepLink({ isLogin, href: downloadUrl });
              }}
            >
              <img
                src={smartBannerIconPath}
                alt="アプリインストール訴求"
                className={styles.smartappBannerAppIcon}
              />
            </a>
          </div>
          {children}
        </div>
        <div className={styles.smartappBannerColumnDlbtn}>
          <button
            type="button"
            className={styles.smartappBannerDlbtn}
            data-ga-action="tap_smart_app_banner"
            data-ga-screen_name={gaScreenName}
            data-ga-appeal_pattern={gaAppealPattern}
            onClick={() => {
              navigateWithDeferredDeepLink({ isLogin, href: downloadUrl });
            }}
          >
            アプリで開く
          </button>
        </div>
      </div>
    </>
  );
};
