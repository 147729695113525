import { FC } from "react";
import { Icon } from "@root/shared/components/Icon";
import { USER_REWARD_NAME } from "@root/utils/constants";
import * as styles from "./styles.css";

export const TargetAndPeriodBox: FC = () => (
  <div className={styles.outer}>
    <dl className={styles.list}>
      <dt className={styles.title}>
        <span className={styles.iconOuter}>
          <Icon name="human" />
        </span>
        対象者
      </dt>
      <dd
        className={styles.description}
      >{`1回目の${USER_REWARD_NAME}の給付をすでに受けている方`}</dd>
      <dd className={styles.description}>入社日から1年間の勤務を勤続している方</dd>
      <dt className={styles.title}>
        <span className={styles.iconOuter}>
          <Icon name="calendar-check" />
        </span>
        申請期間
      </dt>
      <dd className={styles.description}>1年間の勤務を勤続した日から60日以内</dd>
    </dl>
  </div>
);
