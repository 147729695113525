import { FC, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  GroupedPulldown,
  Pulldown,
  ValidationErrorMessage,
} from "@legacy_user_frontend/components/ui_parts";
import {
  ExperienceCassetteProps,
  ExperiencesResponseProps,
} from "@legacy_user_frontend/components/pages/mypage/profiles/credentials_edit/src/credentials_edit_props";
import { DeletableCassette } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/deletable_cassette";
import {
  StyledExperienceCassetteInner,
  StyledErrorMessageOuter,
} from "@legacy_user_frontend/components/pages/mypage/profiles/credentials_edit/src/styled_elements";
import { FetchJson } from "@root/utils/fetchJson";

const arrayToObjects = (
  list: ExperiencesResponseProps,
): {
  key: string;
  value: number;
}[] =>
  list.map((value: (string | number)[]) => ({
    key: value[0] as string,
    value: value[1] as number,
  }));

export const ExperienceCassette: FC<ExperienceCassetteProps> = ({
  cassetteIndex,
  experienceOptionsFetchPath,
  categorizedJobTypeList,
  experienceList,
  remove,
}) => {
  const { resetField, clearErrors, formState } = useFormContext();
  const [selectedJobType, setSelectedJobType] = useState<string>();
  const [selectedExperiencesList, setSelectedExperiencesList] = useState(experienceList);
  const formName = useMemo(
    () => `form_user_frontend_user_update[experiences_attributes][${cassetteIndex}]`,
    [cassetteIndex],
  );
  const tmpCategorizedJobTypeList = categorizedJobTypeList.map(({ categoryName, jobTypes }) => ({
    name: categoryName,
    list: jobTypes,
  }));

  // 職種に応じた経験リストを表示する
  useEffect(() => {
    (async () => {
      if (selectedJobType === undefined) {
        return;
      }
      const res = await FetchJson<ExperiencesResponseProps>(experienceOptionsFetchPath, {
        job_type: selectedJobType || "",
      });
      if (!res) {
        return;
      }
      resetField(`${formName}[job_type_experience_id]`, { defaultValue: "" });
      setSelectedExperiencesList(arrayToObjects(res));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJobType]);

  // サーバから渡ってきたエラー表示を解除する処理
  const clearError = () => {
    clearErrors("form_user_frontend_user_update[experiences]");
    clearErrors("form_user_frontend_user_update[credentials]");
  };
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedJobType(event.target.value);
    clearError();
  };

  // エラーメッセージの表示用変数
  const validateErrors = formState.errors?.form_user_frontend_user_update?.experiences_attributes;
  const experienceError = validateErrors?.length ? validateErrors[cassetteIndex] : null;

  return (
    <DeletableCassette
      heading={`経験職種${1 + cassetteIndex}`}
      handleOnClick={() => {
        remove(cassetteIndex);
        clearError();
      }}
    >
      <>
        <StyledExperienceCassetteInner>
          <GroupedPulldown
            name={`${formName}[job_type_id]`}
            placeholder="職種を選択"
            groupedList={tmpCategorizedJobTypeList}
            isError={!!(experienceError && experienceError.job_type_id)}
            onChange={handleOnChange}
          />
          <Pulldown
            name={`${formName}[job_type_experience_id]`}
            placeholder="経験年数"
            list={selectedExperiencesList}
            isError={!!(experienceError && experienceError.job_type_experience_id)}
            onChange={clearError}
          />
        </StyledExperienceCassetteInner>
        {experienceError && (
          <StyledErrorMessageOuter>
            {experienceError.job_type_id && (
              <ValidationErrorMessage message={experienceError.job_type_id.message} />
            )}
            {experienceError.job_type_experience_id && (
              <ValidationErrorMessage message={experienceError.job_type_experience_id.message} />
            )}
          </StyledErrorMessageOuter>
        )}
      </>
    </DeletableCassette>
  );
};
