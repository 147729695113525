import { FC } from "react";
import { Link } from "@root/shared/components/Link";

type Props = {
  text: string;
  className?: string;
  url: string;
  onClick?: () => void;
  gaAction?: string;
  gaLabel?: string;
  // workCode,clientShopId は app/assets/javascripts/user_frontend/sp/webhook.js で使用する。classNameに.js-webhookがある場合のみ機能する。
  workCode: string;
  clientShopId?: number | string;
};

export const AcceptScoutLink: FC<Props> = ({
  text,
  className,
  url,
  onClick,
  gaAction,
  gaLabel,
  workCode,
  clientShopId,
}) => {
  return (
    <Link
      theme="primary"
      className={className}
      href={url}
      onClick={onClick}
      data-ga-action={gaAction}
      data-ga-label={gaLabel}
      data-code={workCode}
      data-client-shop-id={clientShopId}
      data-scout-view-type="screen_view"
    >
      {text}
    </Link>
  );
};
