import { FC } from "react";
import { ChoiceChip } from "@root/shared/components/ChoiceChip/legacy";
import { UseFormRegisterReturn } from "react-hook-form";
import * as styles from "./styles.css";

type Props = {
  items: {
    id: string;
    value: string;
    text: string;
  }[];
  defaultValue: string | null;
  inputRegistration: UseFormRegisterReturn;
};

export const Presentation: FC<Props> = ({ items, defaultValue, inputRegistration }) => {
  return (
    <ul className={styles.wrapper}>
      {items.map(({ id, value, text }) => (
        <li key={id} className={styles.li}>
          <ChoiceChip
            type="radio"
            value={value}
            id={id}
            defaultChecked={value === defaultValue}
            labelName={text}
            {...inputRegistration}
          />
        </li>
      ))}
    </ul>
  );
};
