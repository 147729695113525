import { FC } from "react";
import { Icon } from "@root/shared/components/Icon";
import * as styles from "./styles.css";

type Props = JSX.IntrinsicElements["button"] & {
  rightIconName?: string;
  leftIconName?: string;
  isBold?: boolean;
  isDisabled?: boolean;
  size: "small" | "large";
};

export const TextButton: FC<Props> = ({
  children,
  rightIconName,
  leftIconName,
  isBold,
  isDisabled,
  className,
  size,
  ...rest
}) => (
  <button
    type="button"
    className={`
    ${styles.button({
      size,
      isBold,
      isDisabled,
    })}
    ${styles.emptyClassForUnderline}
    ${className || ""}
  `}
    {...rest}
  >
    {leftIconName && (
      <span className={styles.iconOuter}>
        <Icon name={leftIconName} fontSize="16px" />
      </span>
    )}
    <span className={styles.text}>{children}</span>
    {rightIconName && (
      <span className={styles.iconOuter}>
        <Icon name={rightIconName} fontSize="16px" />
      </span>
    )}
  </button>
);
