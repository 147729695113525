// FIXME: AnchorLinksとロジックが分離できていないので修正が必要。
import { createRef, useCallback, useEffect, useState, useMemo } from "react";
import { isElementInArea } from "@root/utils/isElementInArea";
import { throttle } from "throttle-debounce";
import { sendGaEvent } from "@root/utils/sendGaEvent";

const HEADER_HIGHT = 56;

export type AnchorLinkProps = {
  iconName: string;
  anchorLinkName: string;
  anchorLinkHref: string;
  gaActionName?: string;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStickyAnchorLinks = (anchorLinkProps: AnchorLinkProps[], disableSticky = false) => {
  const [isFixed, setIsFixed] = useState(false);
  const [currentAreaName, setCurrentAreaName] = useState<string | undefined>(undefined);
  const anchorLinksRef = createRef<HTMLDivElement>();

  const fixTopIfNeeded = useCallback(() => {
    const areas = document.querySelectorAll("[data-scroll-current]");
    if (!anchorLinksRef.current) return;

    setIsFixed(
      isElementInArea({
        topElement: anchorLinksRef.current,
        bottomElement: areas[areas.length - 1],
      }),
    );
  }, [anchorLinksRef]);

  const determineCurrentArea = useCallback(() => {
    const areas = document.querySelectorAll("[data-scroll-current]");
    const isCurrentDisplayNeeded = isElementInArea({
      topElement: areas[0],
      bottomElement: areas[areas.length - 1],
      topMargin: HEADER_HIGHT,
      bottomMargin: HEADER_HIGHT,
    });

    if (!isCurrentDisplayNeeded) {
      setCurrentAreaName(undefined);
      return;
    }

    areas.forEach((area) => {
      const isCurrentArea = isElementInArea({
        topElement: area,
        bottomElement: area,
        topMargin: HEADER_HIGHT,
        bottomMargin: HEADER_HIGHT,
      });

      if (isCurrentArea && area instanceof HTMLElement) {
        setCurrentAreaName(area.dataset.scrollCurrent);
      }
    });
  }, []);

  const anchorLinks = useMemo(
    () => createAnchorLinks(anchorLinkProps, currentAreaName),
    [anchorLinkProps, currentAreaName],
  );

  useEffect(() => {
    if (!disableSticky) {
      window.addEventListener("scroll", throttle(100, fixTopIfNeeded));
      window.addEventListener("scroll", throttle(100, determineCurrentArea));
    }
  }, [disableSticky, isFixed, fixTopIfNeeded, determineCurrentArea]);

  return {
    anchorLinks,
    isFixed,
    anchorLinksRef,
  };
};

const createAnchorLinks = (anchorLinks: AnchorLinkProps[], currentAreaName: string | undefined) => {
  return anchorLinks.map(({ iconName, anchorLinkName, anchorLinkHref, gaActionName }) => {
    return {
      iconName,
      anchorLinkName,
      isCurrent: anchorLinkHref === currentAreaName,
      onClick: () => {
        scroll(anchorLinkHref);
        if (gaActionName) {
          sendGaEvent({ category: "Click", action: gaActionName, label: anchorLinkName });
        }
      },
    };
  });
};

const scroll = (anchorLinkHref: string) => {
  const element = document.getElementById(anchorLinkHref);
  if (element) {
    const position = window.scrollY + element.getBoundingClientRect().top - HEADER_HIGHT;
    window.scrollTo({
      top: position,
      behavior: "smooth",
    });
  }
};
