import { csrfToken } from "./csrf";

export function sendBeaconData(url: string, data: Record<string, unknown>): void {
  // データオブジェクトにCSRFトークンを追加
  const payload = { ...data, authenticity_token: csrfToken() };

  // Blobを作成（navigator.sendBeaconはBlobまたはFormDataを要求する）
  const blob = new Blob([JSON.stringify(payload)], { type: "application/json" });

  // navigator.sendBeaconを使ってデータを送信
  navigator.sendBeacon(url, blob);
}
