import styled from "styled-components";
import { Breakpoints, Colors } from "@legacy_user_frontend/utils";

export const StyledModal = styled.div`
  display: block;
  position: fixed;
  opacity: 1;
  z-index: 11000;
  left: 0;
  right: 0;
  top: 30px;
  width: 100%;

  ${Breakpoints.LARGE} {
    margin-bottom: 16px;
    top: 36px;
  }
`;

export const StyledOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: -100vh;
  left: -100vw;
  bottom: -100vh;
  right: -100vw;
  z-index: 1000;
`;

export const StyledContentWrapper = styled.div`
  background-color: ${Colors.WHITE};
  border-radius: 16px;
  width: 100%;
  color: ${Colors.DEEP_BLACK};
  z-index: 10999;
  position: fixed;
  height: -webkit-calc(100% - 30px);
  height: calc(100% - 30px);

  ${Breakpoints.LARGE} {
    position: fixed;
    width: 833px;
    max-height: 587px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const StyledModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${Colors.LIGHT_GRAY_02};
  border-radius: 16px 16px 0;
`;

export const StyledCloseButton = styled.button`
  background: none;
  border: none;
  outline: none;
  padding: 10px 19px;

  > i {
    font-size: 24px;
    color: ${Colors.GREY_01};
  }

  ${Breakpoints.LARGE} {
    :hover {
      opacity: 0.8;
    }
  }
`;

export const StyledModalMain = styled.div<{ modalType: string }>`
  overflow: scroll;
  position: relative;
  height: 100%;
  padding: ${(props) =>
    props.modalType === "city" || props.modalType === "station"
      ? "16px 16px 220px 16px"
      : "32px 32px 220px 32px"};
  background: ${(props) =>
    props.modalType === "city" || props.modalType === "station"
      ? `${Colors.LIGHT_GREY}`
      : `${Colors.WHITE}`};

  ${Breakpoints.LARGE} {
    max-height: calc(100% - 68px - 64px);
    padding: 16px;
    background: ${Colors.WHITE};
  }
`;

export const StyledTitle = styled.h1`
  font-weight: bold;
  font-size: 20px;
  padding: 14px;
`;

export const StyledModalBottom = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 8px 16px;
  background: ${Colors.WHITE};
  color: ${Colors.DEEP_BLACK};
  box-shadow: 0px -2px 2px rgba(153, 153, 153, 0.2);

  ${Breakpoints.LARGE} {
    justify-content: center;
    background: ${Colors.WHITE};
    border-radius: 0 0 16px 16px;
    bottom: -15px;
  }
`;

export const StyledNumArea = styled.div`
  font-size: 12px;
  font-weight: bold;
  width: 157px;
`;

export const StyledNewLine = styled.br`
  display: block;

  ${Breakpoints.LARGE} {
    display: none;
  }
`;

export const StyledNum = styled.span`
  font-size: 24px;
  color: ${Colors.PRIMARY};
  display: inline-block;
  text-align: center;
  min-width: 30px;
`;

export const StyledBtnArea = styled.div`
  width: 171px;

  ${Breakpoints.LARGE} {
    margin-left: 50px;
  }
`;

export const StyledSearchButton = styled.a`
  background: ${Colors.PRIMARY};
  padding: 12px 0;
  color: ${Colors.WHITE};
  border-radius: 4px;
  border: none;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  display: block;
  text-align: center;

  &:hover {
    text-decoration: none;
  }

  ${Breakpoints.LARGE} {
    &:hover {
      background: rgba(251, 99, 65, 0.8);
      opacity: 0.8;
      text-decoration: none;
      border-color: ${Colors.WHITE};
      box-shadow:
        0px 0px 8px ${Colors.PRIMARY},
        inset 0px 0px 1px ${Colors.PRIMARY};
    }
  }

  &.is-disabled {
    color: ${Colors.LIGHT_BLACK};
    background: ${Colors.LIGHT_GREY};
    cursor: default;
    pointer-events: none;

    ${Breakpoints.LARGE} {
      :hover {
        opacity: 1;
        box-shadow: none;
        border: none;
        background: ${Colors.LIGHT_GREY};
      }
    }
  }
`;
