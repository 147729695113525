import { ComponentPropsWithRef, FC, forwardRef } from "react";
import { RadioButton } from "@root/shared/components/RadioButton";
import * as styles from "./styles.css";

type RadioButtonProps = Omit<ComponentPropsWithRef<"input">, "type"> & {
  hasError?: boolean;
};

type Props = RadioButtonProps & {
  options: {
    value: string;
  }[];
  selectedOption: string;
  categoryName: string;
  handleRadioChange: (category: string, optionId: number) => void;
};

export const StepSelection: FC<Props> = forwardRef(
  (
    {
      className,
      hasError = false,
      options,
      selectedOption,
      categoryName,
      handleRadioChange,
      ...rest
    },
    ref,
  ) => (
    <div className={styles.container}>
      {options.map((option) => (
        <span className={styles.label} key={option.value}>
          <RadioButton
            {...rest}
            name={categoryName}
            hasError={hasError}
            value={option.value}
            ref={ref}
            className={className ?? ""}
            checked={selectedOption === option.value}
            onChange={() => handleRadioChange(categoryName, Number(option.value))}
          />
        </span>
      ))}
    </div>
  ),
);
StepSelection.displayName = "StepSelection";
