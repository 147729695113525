// TODO: 文言が確定していないため、繋ぎこみのタイミングで修正
import { GuidanceBox } from "@root/shared/components/GuidanceBox";
import { Icon } from "@root/shared/components/Icon";
import { Label } from "@root/shared/components/Label";
import { FC } from "react";
import * as styles from "./styles.css";

export const ClientReviewDescription: FC = () => {
  return (
    <div className={styles.container}>
      <GuidanceBox backgroundColor="pink">
        <div className={styles.guidanceBoxContents}>
          <div className={styles.iconWrapper}>
            <Icon name="information" fontSize="16px" />
          </div>
          <div>
            <Label icon={<Icon name="done" />} theme="deepPink" className={styles.label}>
              勤務確認済み
            </Label>
            がある口コミは、
            <br />
            内定・在籍経験が確認できた方の投稿です。
          </div>
        </div>
      </GuidanceBox>
    </div>
  );
};
