import { FC, useContext } from "react";
import { OUTPUT_NAV_NAME } from "@legacy_user_frontend/components/pages/jobs/job_search_navigation";
import { ModalContext } from "@legacy_user_frontend/components/pages/jobs/job_search_navigation/src/modules";
import {
  StyledHeading,
  StyledListOuter,
  StyledList,
  StyledButton,
  StyledTotalNum,
} from "./src/styled_elements";

type SearchNavigationProps = {
  navListGroup: {
    key: string;
    value: {
      [key: string]: string | number | boolean;
      /* 定義しないと代入箇所で怒られるものは個別に定義 */
      id: number;
      search_path: string; // eslint-disable-line camelcase
      is_number_displayed: boolean; // eslint-disable-line camelcase
    }[];
  };
  gaAction: string;
  moreSearchButtonDisplayed: boolean;
};

export const SearchNavigation: FC<SearchNavigationProps> = ({
  navListGroup,
  gaAction,
  moreSearchButtonDisplayed,
}) => {
  const { openModal } = useContext(ModalContext);
  return (
    <>
      <StyledHeading>{OUTPUT_NAV_NAME[navListGroup.key]}で絞り込む</StyledHeading>
      <StyledListOuter>
        <StyledList>
          {navListGroup.value.map((navList) => {
            const labelName =
              navListGroup.key === "station"
                ? `${navList[`${navListGroup.key}_name`]}駅`
                : navList[`${navListGroup.key}_name`];
            return (
              <li key={navList.id}>
                <StyledButton
                  href={navList.search_path}
                  className="ga-impression ga-click"
                  data-ga-action={gaAction}
                  data-ga-label={labelName}
                >
                  {labelName}
                  {navList.is_number_displayed && (
                    <StyledTotalNum>({navList.number})</StyledTotalNum>
                  )}
                </StyledButton>
              </li>
            );
          })}
          {moreSearchButtonDisplayed && (
            <li>
              <StyledButton
                data-ga-action={gaAction}
                data-ga-label={`${OUTPUT_NAV_NAME[navListGroup.key]}_他`}
                className="ga-impression ga-click"
                onClick={() => openModal(navListGroup.key)}
                type="button"
                as="button"
              >
                他＋
              </StyledButton>
            </li>
          )}
        </StyledList>
      </StyledListOuter>
    </>
  );
};
