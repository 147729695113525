import styled from "styled-components";
import { Colors } from "@legacy_user_frontend/utils";
import { UnstyledButton } from "@legacy_user_frontend/components/ui_parts";

export const StyledSkillItemListOuter = styled.div`
  margin-top: 24px;
`;

export const StyledHiddenCheckboxOuter = styled.div`
  display: none;
`;

export const StyledCategoryAccordionButton = styled(UnstyledButton)<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  position: relative;
  border-bottom: 1px solid ${(props) => (props.isActive ? Colors.BRAND_PINK_DEEP : "transparent")};
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => (props.isActive ? Colors.BRAND_PINK_DEEP : Colors.DEEP_BLACK)};
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%) ${(props) => (props.isActive ? "rotate(180deg)" : "rotate(0)")};
    width: 12px;
    height: 6px;
    background-image: ${(props) =>
      props.isActive
        ? "url(/images/user_frontend/global/icon/icn_arrow_bottom_pink.svg)"
        : "url(/images/user_frontend/global/icon/icn_arrow_bottom_black.svg)"};
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

export const StyledCategoryModalButton = styled(StyledCategoryAccordionButton)`
  color: ${Colors.LINK_BLUE_OLD_02};

  &::after {
    content: none;
  }
`;

export const StyledAccordionContent = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  width: 100%;
`;

export const StyledBusinessTypesList = styled.ul`
  list-style-type: none;
  > li + li {
    margin-top: 1px;
  }
`;

export const StyledBusinessTypeButton = styled(UnstyledButton)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  padding: 0 16px;
  background-color: ${Colors.OLD_LIGHT_GREY};
  color: ${Colors.LINK_BLUE_OLD_02};
  font-size: 14px;
  font-weight: bold;
`;

export const StyledLabelCategoryList = styled.dl`
  > dt {
    color: ${Colors.DEEP_GREY};
  }

  > dd {
    margin-top: 8px;

    & + dt {
      margin-top: 8px;
    }
  }
`;

export const StyledLabelList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  gap: 8px 4px;
`;
