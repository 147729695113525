import { FC } from "react";
import { Button } from "@root/shared/components/Button";
import { Icon } from "@root/shared/components/Icon";
import * as styles from "./styles.css";

type Props = JSX.IntrinsicElements["button"];

export const AdditionButton: FC<Props> = ({ children, onClick }) => {
  return (
    <div className={styles.wrapper}>
      <Button type="button" theme="tertiary" onClick={onClick}>
        <div className={styles.iconWrapper}>
          <Icon name="add-more" fontSize="8px" />
        </div>
        {children}
      </Button>
    </div>
  );
};
