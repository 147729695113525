import { FC } from "react";
import styled from "styled-components";
import { Breakpoints, Colors } from "@legacy_user_frontend/utils";

const Heading = styled.h3`
  font-size: 14px;
  color: ${Colors.DEEP_BLACK};
  line-height: 1.5;
  font-weight: bold;

  span {
    font-weight: normal;
  }

  ${Breakpoints.MEDIUM} {
    font-size: 16px;
    line-height: 1.3;
  }
`;

export type RelatedHeaderProps = {
  businessType: string;
  occupation: string;
};

export const RelatedHeader: FC<RelatedHeaderProps> = ({ businessType, occupation }) => (
  <Heading>
    {businessType}│<span>{occupation}</span>
  </Heading>
);
