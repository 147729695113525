import { ComponentProps, FC } from "react";
import { useInView } from "react-intersection-observer";
import { CassetteSliderSectionList } from "@userFrontend/components/CassetteSliderSectionList";
import { Presentation } from "./Presentation";
import { useRecommendWorksSection } from "./useRecommendWorksSection";

type Props = {
  fetchUrl: string;
  headingText: string;
  gaActionPrefix: string;
  // inViewを利用した再フェッチを有効にしたい場合、trueにする
  refetchTrigger?: boolean;
};

export const RecommendWorks: FC<Props> = ({
  fetchUrl,
  headingText,
  gaActionPrefix,
  refetchTrigger = false,
}) => {
  const { ref, inView: originalInView } = useInView();
  const inView = refetchTrigger ? originalInView : false;

  const sections: ComponentProps<typeof CassetteSliderSectionList>["sections"] | undefined =
    useRecommendWorksSection(fetchUrl, gaActionPrefix, inView);

  return (
    <div ref={refetchTrigger ? ref : undefined}>
      {sections ? <Presentation sections={sections} headingText={headingText} /> : null}
    </div>
  );
};
