// iOSアプリからのアクセスか、Androidアプリからのアクセスかを判断する
// iOSは1を返す
// Androidは2を返す

export const getMobileAppState = (): number | undefined => {
  const mobileAppData = document.body.dataset.mobileApp;
  if (mobileAppData) {
    return Number(mobileAppData);
  }
  return undefined;
};
