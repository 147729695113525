import { FC, ReactElement } from "react";
import { Colors } from "@legacy_user_frontend/utils";
import styled from "styled-components";

type Props = {
  heading: string;
  handleOnClick: () => void;
  children: ReactElement;
};

const StyledCassette = styled.div`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;

  & + & {
    margin-top: 8px;
  }
`;

const StyledHeading = styled.div`
  position: relative;
  padding: 16px;
  background-color: ${Colors.GREY};
  font-weight: bold;
  color: ${Colors.LIGHT_BLACK};
  line-height: 1.16;
`;

const StyledDeleteButton = styled.button`
  appearance: none;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  width: 17px;
  height: 18px;
  padding: 0;
  border: none;
  background-image: url("/images/user_frontend/global/icon/icn_delete.svg");
  background-color: transparent;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
    text-decoration: none;
  }
`;

const StyledCassetteBody = styled.div`
  padding: 16px;
`;

export const DeletableCassette: FC<Props> = ({ heading, handleOnClick, children }) => (
  <StyledCassette>
    <StyledHeading>
      {heading}
      <StyledDeleteButton type="button" onClick={handleOnClick} />
    </StyledHeading>
    <StyledCassetteBody>{children}</StyledCassetteBody>
  </StyledCassette>
);
