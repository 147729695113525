import { FC } from "react";
import { SectionTitle } from "@userFrontend/features/clientReview/components/ClientReviewInputs/uiParts/SectionTitle";
import { LazyLoadImage } from "@root/shared/components/LazyLoadImage";
import * as styles from "./styles.css";
// uiParts
import { ReviewContent } from "./uiParts/ReviewContent";
// types
import { Props } from "./type";

export const Step2: FC<Props> = ({
  questions,
  checkboxValues,
  handleCheckboxChange,
  isDisabled,
  treatmentsErrorText,
  culturesErrorText,
  treatmentsRef,
  culturesRef,
}) => {
  return (
    <>
      <div className={styles.sectionOuter}>
        <SectionTitle text="待遇面の口コミ" />
        <div className={styles.container}>
          <div className={styles.textAndImageOuter}>
            口コミは求人ページでご紹介します。多くの方の転職活動に役立ちます。
            <div className={styles.image}>
              <LazyLoadImage
                src="/images/user_frontend/sp/img_user_reward_continued_treatments.png"
                width={153}
                height={56}
              />
            </div>
          </div>
          <ReviewContent
            questions={questions.treatmentQuestions}
            categoryName="treatments"
            checkboxValues={checkboxValues}
            handleCheckboxChange={handleCheckboxChange}
            errorText={treatmentsErrorText}
            isDisabled={isDisabled}
            containerRef={treatmentsRef}
          />
        </div>
      </div>
      <div className={styles.sectionOuter}>
        <SectionTitle text="社風面の口コミ" />
        <div className={styles.container}>
          <ReviewContent
            questions={questions.cultureQuestions}
            categoryName="cultures"
            checkboxValues={checkboxValues}
            handleCheckboxChange={handleCheckboxChange}
            errorText={culturesErrorText}
            isDisabled={isDisabled}
            containerRef={culturesRef}
          />
        </div>
      </div>
    </>
  );
};
