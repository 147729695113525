import styled, { keyframes } from "styled-components";
import { Breakpoints, Colors } from "@legacy_user_frontend/utils";

// index
export const StyledDescription = styled.p`
  line-height: 1.5;
`;
export const StyledSmallDescription = styled(StyledDescription)`
  margin-top: 24px;
  font-size: 11px;

  > span {
    display: inline-block;
    margin-top: 4px;
    color: ${Colors.ALERT_RED};
  }
`;

export const StyledList = styled.ul`
  list-style-type: none;
  margin-top: 24px;
  color: ${Colors.DEEP_GREY};
  font-size: 11px;
  line-height: 1.5;
`;

export const StyledImageUploaderOuter = styled.div`
  margin-top: 32px;

  ${Breakpoints.MEDIUM} {
    max-width: 343px;
  }
`;

export const StyledImagesOuter = styled.div`
  margin-top: 32px;
`;

// photo
export const StyledCassetteOuter = styled.div`
  & + & {
    margin-top: 16px;
  }
`;

export const StyledCassette = styled.div`
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

export const StyledCassetteHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  padding: 0 16px;
  background-color: ${Colors.GREY};

  > p {
    color: ${Colors.LIGHT_BLACK};
    font-weight: bold;
  }

  > button {
    &::before {
      content: "";
      display: block;
      width: 17px;
      height: 18px;
      background-image: url("/images/user_frontend/global/icon/icn_delete.svg");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
`;

export const StyledCassetteContent = styled.div`
  display: flex;
`;

export const StyledImageOuter = styled.div`
  flex-basis: 94px;
  flex-shrink: 0;

  > button {
    width: 100%;
    position: relative;
    padding: 125px 0 0;
    border: none;
    border-radius: 0;
    background-color: ${Colors.LIGHT_GRAY_02};
    vertical-align: middle;

    > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;

export const StyledTextAreaOuter = styled.div<{ isError: boolean }>`
  flex-basis: calc(100% - 94px);
  padding: 4px;

  > textarea {
    display: block;
    width: 100%;
    height: 90px;
    padding: 8px;
    border: 1px solid transparent;
    border-radius: 0;
    box-shadow: ${({ isError }) => (isError ? `0px 0px 8px ${Colors.ALERT_RED}` : "none")};
    resize: none;
    outline: none;
    font-size: 14px;
    transition:
      border-color 0.1s,
      box-shadow 0.1s;

    &:focus {
      border-color: ${Colors.FOCUS_BLUE};
      box-shadow: 0px 0px 8px ${Colors.FOCUS_BLUE};
    }

    ::placeholder {
      color: ${Colors.DEEP_GREY};
    }
  }
`;

export const StyledTextCountOuter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  padding: 4px;
  font-size: 10px;
  color: ${Colors.LIGHT_BLACK};
  line-height: 1;
`;

// upload_image
export const StyledLabel = styled.label`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 46px;
  border: 1px solid ${Colors.MEDIUM_GREY};
  border-radius: 4px;
  background-color: transparent;
  font-size: 14px;
  font-weight: bold;
  color: ${Colors.LIGHT_BLACK};
  transition: opacity 0.2s;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
    text-decoration: none;
  }
  position: relative;

  &::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    background-image: url("/images/user_frontend/global/icon/icn_plus_map_button.svg");
  }
`;

export const StyledInput = styled.input`
  display: none;
`;

export const StyledIndicatorOverlay = styled.div<{ isShow: boolean }>`
  display: ${(props) => (props.isShow ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1001;
`;

const spinAnimation = keyframes`
  from {
    transform: translateY(-50%) translateX(-50%) rotate(0);
  }
  to {
    transform: translateY(-50%) translateX(-50%) rotate(360deg);
  }
`;

export const StyledLoadingSpinner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 44px;
  height: 44px;
  border: 5px solid ${Colors.LOADING_BLUE};
  border-top: 5px solid transparent;
  border-left: 5px solid transparent;
  border-radius: 50%;
  animation: ${spinAnimation} 1s infinite linear;
`;

export const StyledLoadingSpinnerText = styled.div`
  display: inline-block;
  text-align: center;
  position: absolute;
  height: 44px;
  top: calc(50% + 60px);
  left: 50%;
  font-size: 14px;
  font-weight: 600;
  transform: translateY(-50%) translateX(-50%);
`;

export const StyledConfirmedRemark = styled.p`
  padding-top: 28px;
  text-align: center;
`;
