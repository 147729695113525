import { FC } from "react";
import * as styles from "./styles.css";

type Props = {
  errorText?: string;
  displaySearchText: boolean;
  isSearching: boolean;
};

export const SearchResultText: FC<Props> = ({ errorText, displaySearchText, isSearching }) => (
  <div className={styles.container}>
    {errorText && <p className={styles.errorText}>{errorText}</p>}
    {displaySearchText && (
      <p className={styles.text}>{isSearching ? "検索中..." : "検索結果が表示されます"}</p>
    )}
  </div>
);
