import { ComponentProps, FC } from "react";
import { CassetteSliderSectionList } from "@userFrontend/components/CassetteSliderSectionList";
import * as styles from "./styles.css";

type Props = {
  cassetteType?: ComponentProps<typeof CassetteSliderSectionList>["cassetteType"];
  sections: ComponentProps<typeof CassetteSliderSectionList>["sections"];
};

export const Presentation: FC<Props> = ({ cassetteType, sections }) => {
  return (
    <div className={styles.contents}>
      <h2 className={styles.heading}>こちらの求人も見てみませんか？</h2>
      <CassetteSliderSectionList cassetteType={cassetteType} sections={sections} />
    </div>
  );
};
