import { FC } from "react";
import { OsType, SiteType } from "@root/shared/types/type";
import * as styles from "./styles.css";

type TitleProps = {
  osType: OsType;
  siteType: SiteType;
};
export const Title: FC<TitleProps> = ({ osType, siteType }) => {
  // ==================================================
  // NOTE:
  // レビュー数と評価を数値で取得して、その数値を元に「smartappBannerReviewNum」「smartappBannerReviewNum」を再描画
  // ※現状、jsのメソッドで外部からDOM書き換えを行っている状態
  // ==================================================
  let smartappBannerText = "App Name";
  let smartappBannerStars = (
    <>
      <i className="icon-star" />
      <i className="icon-star" />
      <i className="icon-star" />
      <i className="icon-star-half-alt" />
      <i className="icon-star-empty" />
    </>
  );
  let smartappBannerReviewNum = "0";

  if (osType === "iOS") {
    smartappBannerText = "iOS版リジョブ";
    smartappBannerStars = (
      <>
        <i className="icon-star" />
        <i className="icon-star" />
        <i className="icon-star" />
        <i className="icon-star" />
        <i className="icon-star-half-alt" />
      </>
    );
    smartappBannerReviewNum = "2,710";
  } else if (osType === "Android") {
    smartappBannerText = "Android版リジョブ";
    smartappBannerStars = (
      <>
        <i className="icon-star" />
        <i className="icon-star" />
        <i className="icon-star" />
        <i className="icon-star-half-alt" />
        <i className="icon-star-empty" />
      </>
    );
    smartappBannerReviewNum = "148";
  }

  return (
    <>
      <div className={styles.smartappBannerColumnTitle}>
        {siteType === "kaigo" ? (
          <>
            <p className={styles.smartappBannerTitle}>リジョブケア - アプリ新登場！</p>
            <p className={styles.smartappBannerSubTitleKaigo}>REJOB CO.,LTD.</p>
          </>
        ) : (
          <>
            <p className={styles.smartappBannerTitle}>{smartappBannerText}</p>
            <p className={styles.smartappBannerSubTitle}>
              <span id="js-smartapps__rating-stars">{smartappBannerStars}</span>
              <span className={styles.smartappBannerReviewNum}>
                （<span id="js-smartapps__rating-number">{smartappBannerReviewNum}</span>）
              </span>
            </p>
          </>
        )}
      </div>
    </>
  );
};
