import { ComponentProps, FC } from "react";
import { CassetteSlider } from "./uiParts/CassettesSlider";
import * as styles from "./styles.css";

type Props = {
  cassetteType?: ComponentProps<typeof CassetteSlider>["cassetteType"];
  sections: {
    url?: string;
    title?: string;
    cassettes: ComponentProps<typeof CassetteSlider>["cassettes"];
  }[];
};

export const CassetteSliderSectionList: FC<Props> = ({ cassetteType, sections }) => {
  return (
    <div className={styles.sectionsWrapper}>
      {sections.map((section, index) => (
        <div
          // url,title共に任意項目のため、indexを使用
          // eslint-disable-next-line react/no-array-index-key
          key={`section-${index}`}
          className={`${sections.length <= 1 ? styles.section : styles.multipleSection}`}
        >
          <span className={styles.sectionTitleAndSeeMoreWrapper}>
            <h4 className={styles.sectionTitle}>{section.title}</h4>
            {/* カセットが4枚以上あり、urlが返ってきている場合のみ、"もっと見る"を表示する */}
            {section.cassettes.length >= 4 && section.url && (
              <a href={section.url} className={styles.seeMore}>
                もっと見る
                <i className="c-icn c-icn-arrow-light-right" />
              </a>
            )}
          </span>
          <CassetteSlider cassetteType={cassetteType} cassettes={section.cassettes} />
        </div>
      ))}
    </div>
  );
};
