import styled from "styled-components";
import { Colors } from "@legacy_user_frontend/utils";
import { UnstyledButton } from "@legacy_user_frontend/components/ui_parts/buttons/unstyled_button";

// index
export const StyledCredentialHeading = styled.p`
  padding-top: 8px;
  font-size: 14px;
  font-weight: bold;
`;

export const StyledCredentialDescription = styled.p`
  padding-top: 24px;
`;

export const StyledRemarksOuter = styled.div`
  margin-top: 32px;
`;

export const StyledCredentialItemListOuter = styled.div`
  margin-top: 24px;
`;

export const StyledLabelList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  gap: 4px;
`;

// CredentialItemList
const StyledCategoryButton = styled(UnstyledButton)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  font-size: 14px;
  font-weight: bold;
`;

export const StyledCategoryAccordionButton = styled(StyledCategoryButton)<{ isActive: boolean }>`
  position: relative;
  border-bottom: 1px solid ${(props) => (props.isActive ? Colors.BRAND_PINK_DEEP : "transparent")};
  color: ${(props) => (props.isActive ? Colors.BRAND_PINK_DEEP : Colors.DEEP_BLACK)};

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%) ${(props) => (props.isActive ? "rotate(180deg)" : "rotate(0)")};
    width: 12px;
    height: 6px;
    background-image: ${(props) =>
      props.isActive
        ? "url(/images/user_frontend/global/icon/icn_arrow_bottom_pink.svg)"
        : "url(/images/user_frontend/global/icon/icn_arrow_bottom_black.svg)"};
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

export const StyledOtherCategoryButton = styled(StyledCategoryButton)`
  color: ${Colors.LINK_BLUE_OLD_02};
`;

export const StyledAccordionContent = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  width: 100%;
`;

export const StyledBusinessTypesList = styled.ul`
  list-style-type: none;

  > li + li {
    margin-top: 1px;
  }
`;

export const StyledBusinessTypeButton = styled(UnstyledButton)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  padding: 0 16px;
  background-color: ${Colors.OLD_LIGHT_GREY};
  color: ${Colors.LINK_BLUE_OLD_02};
  font-size: 14px;
  font-weight: bold;
`;

export const StyledModalCredentialList = styled.dl`
  height: calc(100% - 80px);
  overflow: auto;
  padding: 28px 16px 32px;
  > dt {
    &:not(:first-of-type) {
      margin-top: 24px;
    }
  }
  > dd {
    margin-top: 10px;
  }
`;

export const StyledModalFixedButtonOuter = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 16px;
  box-shadow: 0px -1px 9px rgba(0, 0, 0, 0.25);
  background-color: ${Colors.WHITE};
`;

export const StyledCredentialButtonList = styled.dd`
  display: flex;
  gap: 16px;
`;

export const StyledCredentialButtonOuter = styled.span`
  display: inline-flex;
  width: 100%;
  height: 44px;

  > input[type="checkbox"] {
    display: none;

    &:checked + label {
      background-color: ${Colors.REJOB_BRAND_DEEP};
      color: ${Colors.WHITE};
      font-weight: bold;
    }
  }

  > label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 44px;
    border-radius: 22px;
    background-color: ${Colors.LIGHT_GREY};
    font-size: 12px;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.8;
      text-decoration: none;
    }
  }
`;

// Experiences
export const StyledCassetteOuter = styled.div`
  margin-top: 24px;
`;

export const StyledCassetteButtonOuter = styled.div`
  margin-top: 16px;
`;

export const StyledExperienceCassetteInner = styled.div`
  display: flex;
  gap: 8px;

  > select {
    flex-basis: 50%;
  }
`;

export const StyledErrorMessageOuter = styled.div`
  margin-top: 10px;
`;

// HiddenCredentialForm
export const StyledHiddenFormOuter = styled.div`
  display: none;
`;
