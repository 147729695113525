import { FC, lazy, Suspense } from "react";

const Presentation = lazy(() => import("./Presentation"));

type Props = {
  ratioData: RatioDataProps;
};

type RatioDataProps = {
  // left
  leftItemRate: number;
  leftItemLabel?: string;
  leftItemBgColor: string;
  leftItemBorderColor?: string;
  // right
  rightItemRate: number;
  rightItemLabel?: string;
  rightItemBgColor: string;
  rightItemBorderColor?: string;
};

export const RatioChart: FC<Props> = ({ ratioData }) => (
  <Suspense fallback={null}>
    <Presentation ratioData={ratioData} />
  </Suspense>
);
