import { Button } from "@root/shared/components/Button";
import { TextButton } from "@root/shared/components/TextButton";
import { FC, Fragment } from "react";
import * as styles from "./styles.css";

type Props = {
  clientName: string;
  clientShopName: string[];
  onClickSelect: () => void;
  isShowShopName?: boolean;
  onClickShowTextButton?: () => void;
};

const MAX_SHOWN_SHOP_NAMES = 3;

export const SearchResultBox: FC<Props> = ({
  clientName,
  clientShopName,
  onClickSelect,
  isShowShopName,
  onClickShowTextButton,
}) => {
  const clientShopNameLength = clientShopName.length;
  return (
    <>
      <div className={styles.wrapper}>
        <div>
          <div className={styles.item}>
            <p className={styles.label}>会社名</p>
            <p>{clientName}</p>
          </div>
          {clientShopNameLength > 0 && (
            <div className={styles.item}>
              <p className={styles.label}>勤務地</p>
              <ul>
                {clientShopName.map((shopName, index) => (
                  <Fragment key={shopName}>
                    {index < MAX_SHOWN_SHOP_NAMES ? (
                      <li className={styles.clientShoplist}>{shopName}</li>
                    ) : (
                      <li
                        className={
                          isShowShopName ? styles.clientShoplist : styles.clientShoplistHidden
                        }
                      >
                        {shopName}
                      </li>
                    )}
                  </Fragment>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className={styles.selectButtonWrapper}>
          <Button
            theme="primary"
            onClick={onClickSelect}
            className={styles.selectButton}
            size="small"
          >
            選択
          </Button>
        </div>
      </div>
      {!isShowShopName && clientShopNameLength > MAX_SHOWN_SHOP_NAMES && (
        <div className={styles.textButtonWrapper}>
          <TextButton size="large" onClick={onClickShowTextButton}>
            もっと見る
          </TextButton>
        </div>
      )}
    </>
  );
};
