import { ComponentProps, FC } from "react";
import { useInView } from "react-intersection-observer";
import { CassetteSliderSectionList } from "@userFrontend/components/CassetteSliderSectionList";
import { Presentation } from "./Presentation";
import { useAfterKeepRecommendWorks } from "./useAfterKeepRecommendWorks";

type Props = {
  fetchUrl: string;
  cassetteType?: ComponentProps<typeof CassetteSliderSectionList>["cassetteType"];
};

export const AfterKeepRecommendWorks: FC<Props> = ({
  fetchUrl,
  cassetteType = "SmallWorkCassette",
}) => {
  const { ref, inView } = useInView();

  const sections: ComponentProps<typeof CassetteSliderSectionList>["sections"] | undefined =
    useAfterKeepRecommendWorks(fetchUrl, inView);

  return (
    <div ref={ref} id="after-keep-recommend-works-component">
      {sections ? <Presentation cassetteType={cassetteType} sections={sections} /> : null}
    </div>
  );
};
